//dependencias
import React, { Component } from 'react';

//componentes
import ActivarProfeDosifi from './activarProfeDosifi'

class Index extends Component {

    render(){
        return(
            <div>
                <ActivarProfeDosifi />
            </div>
        );
    }

}
export default Index;