import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import renderHTML from "react-render-html";
//componentes
import "./autorizarMEPlani.css";
import Modal from "../modal2/modal";
import Alert from "../../modal/alert";

class AutorizarMEAgenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      fechaSeleccion: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      dateArray: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      trimestre: "",
      idmateria: "",
      verplanillaOn: false,
      mostrarOn: false,
      nalumno: [],
      ianoEscolar: [],
      icursos: [],
      ifechaPlani: [],
      someHTML: [],
      someHTML2: [],
      iprofesor: [],
      imaterias: [],
      fechaTemporal: "",
      idagenda: "",
    };
  }

  limpiar = () => {
    this.setState({
      fecha: "",
      fechaSeleccion: "",
      ifechamedi: [],
      mensajealerta: false,
      mostrarOn: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      trimestre: "",
      idmateria: "",
      someHTML: [],
      someHTML2: [],
      verplanillaOn: false,
      nalumno: [],
      dateArray: [],
      fechaTemporal: "",
      idagenda: "",
    });
  };
  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "anoEscolar");
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idcurso") this.buscarFP(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
      someHTML: [],
      someHTML2: [],
    });
    if (index === "trimestre") this.buscarMaterias(this.state.idprofesor);
  }
  onChange4(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
      someHTML: [],
      someHTML2: [],
      fecha1: "",
      fecha2: "",
    });
  }

  onChange5 = (e) => {
    let element = e.target;
    if (element.checked) {
      // console.log(e.target.value);
      // console.log(e.target.name);
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idagenda", e.target.name);
      data.append("boton", "guardarChequeoModificar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/autorizarMEAgenda.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consulta();
          } else {
            //this.setState({});
          }
        });
    }
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  onChange6 = (e) => {
    let element = e.target;
    //console.log(e.target.name);
    if (element.checked) {
       //console.log(e.target.value);
       //console.log(e.target.name);
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idagenda", e.target.name);
      data.append("boton", "guardarChequeoEliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/autorizarMEAgenda.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consulta();
          } else {
            //this.setState({});
          }
        });
    }
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  buscarFP = (e) => {
    if (e.length > 0) {
      let form = new FormData();
      form.append("idgrado", e);
      form.append("boton", "buscarFP");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios.post(xurl, form, config).then((res) => {
        let xifechaPlani = res.data.variable2;
        this.setState({
          ifechaPlani: xifechaPlani,
          idfechaPlani: "",
          someHTML: [],
          someHTML2: [],
          fecha1: "",
          fecha2: "",
        });
      });
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Grado, gracias.",
      });
    }
  };
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
  handleClose2 = () => {
    this.setState({ mostrarOn: false });
    this.consulta();
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e !== "") {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
                someHTML: [],
                someHTML2: [],
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  // buscarCurso = (e) => {
  //   if (this.state.anoEscolar.length > 0) {
  //     let data = new FormData();
  //     data.append("boton", "buscarCurso2");

  //     let config = {
  //       headers: {
  //         "Content-Type": "text/html",
  //       },
  //     };
  //     const xurl =
  //       "https://institutobilinguelasnaciones.com/backend/planificacion.php";
  //     axios
  //       .post(xurl, data, config)

  //       .then((res) => {
  //         let xicursos = res.data.variable2;
  //         if (res.data.variable1) {
  //           this.setState({
  //             icursos: xicursos,
  //             someHTML: [],
  //             someHTML2: [],
  //           });
  //         } else {
  //           this.setState({
  //             icursos: [],
  //           });
  //           if (res.data.variable3.length > 0) {
  //             this.setState({
  //               mensajealerta: true,
  //               tituloM: "Menu Asignar Notas.",
  //               cuerpoM: res.data.variable3,
  //             });
  //           }
  //         }
  //       });
  //   } else {
  //     this.setState({
  //       idprofesor: "",
  //       mensajealerta: true,
  //       tituloM: "Menu Reporte Planificacion.",
  //       cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
  //     });
  //   }
  // };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  imprimirPlanificacion = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idfechaPlani.length > 0 &&
      // this.state.idprofesor.length > 0 &&
      this.state.fecha1.length > 0 &&
      this.state.tipoR.length > 0
    ) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idfechaPlanificacion", this.state.idfechaPlani);
      data.append("idprofesor", this.state.idprofesor);
      data.append("trimestre", this.state.trimestre);

      // data.append("tipoR", this.state.tipoR);
      // data.append("fecha1", this.state.fecha1);
      // data.append("fecha2", this.state.fecha2);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reportePlanificacionFechas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM:
          "Debe Seleccionar un Profesor y seleccionar todos los datos que se piden, gracias.",
      });
    }
  };

  consulta = () => {
    //if (e.length > 0) {
    this.setState({ someHTML: [] });
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("idgrado", this.state.idcurso);
    data.append("idfechaPlanificacion", this.state.idfechaPlani);
    data.append("idprofesor", this.state.idprofesor);
    data.append("idmateria", this.state.idmateria);
    data.append("trimestre", this.state.trimestre);
    // data.append("tipoR", this.state.tipoR);
    // data.append("fecha1", this.state.fecha1);
    // data.append("fecha2", this.state.fecha2);
    data.append("boton", "consulta");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/autorizarMEAgenda.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        //this.getDates(this.state.fecha1, this.state.fecha2)
        let xsomeHTML = res.data.variable2;
        this.setState({
          //anoEscolar: res.data.variable2,
          someHTML: xsomeHTML,
        });
      }
    });
    //}
  };

  copiarPlanificacion = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idfechaPlani.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.tipoR.length > 0 &&
      this.state.fecha1.length > 0 &&
      this.state.idmateria.length > 0
    ) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idfechaPlanificacion", this.state.idfechaPlani);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idmateria", this.state.idmateria);
      data.append("tipoR", this.state.tipoR);
      data.append("fecha1", this.state.fecha1);
      data.append("fecha2", this.state.fecha2);
      data.append("boton", "copiarPlanificacion");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Reporte Planificacion.",
            cuerpoM:
              "Se Procesaron los Datos, Las Planificaciones No Habilitadas no se procesan.",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM:
          "Para realizar el proceso se deben seleccionar todos los campos ejemplo un grado, un profesor, una materia, etc.",
      });
    }
  };

  habilitar = () => {
    if (this.state.idprofesor > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idfechaPlanificacion", this.state.idfechaPlani);
      data.append("idprofesor", this.state.idprofesor);
      data.append("tipoR", this.state.tipoR);
      data.append("fecha1", this.state.fecha1);
      data.append("fecha2", this.state.fecha2);
      data.append("usuario", this.props.usuario1.usuario);
      data.append("habilitar", "SI");
      data.append("boton", "habilitar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Reporte Planificacion.",
            cuerpoM:
              "Las Planificaciones fueron habilitadas para el Representante, gracias.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Reporte Planificacion.",
            cuerpoM:
              "Ocurrio un error o no hay planificación en la fecha que indicó, gracias.",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  habilitarI = (e) => {
    let data = new FormData();
    data.append("idplanificacion", e);
    data.append("usuario", this.props.usuario1.usuario);
    data.append("habilitar", "SI");
    data.append("boton", "habilitarI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Reporte Planificacion.",
          cuerpoM:
            "La Planificacion fué habilitada para el Representante, gracias.",
        });
        this.consultaI(this.state.fechaTemporal);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Reporte Planificacion.",
          cuerpoM: "Ocurrio un error verifique, gracias.",
        });
      }
    });
  };

  deshabilitar = () => {
    if (this.state.idprofesor > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idfechaPlanificacion", this.state.idfechaPlani);
      data.append("idprofesor", this.state.idprofesor);
      data.append("tipoR", this.state.tipoR);
      data.append("fecha1", this.state.fecha1);
      data.append("fecha2", this.state.fecha2);
      data.append("habilitar", "NO");
      data.append("boton", "habilitar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Reporte Planificacion.",
            cuerpoM:
              "Las Planificaciones fueron deshabilitadas para el Representante, gracias.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Reporte Planificacion.",
            cuerpoM:
              "Ocurrio un error o no hay planificación en la fecha que indicó, gracias.",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  deshabilitarI = (e) => {
    let data = new FormData();
    data.append("idplanificacion", e);
    data.append("usuario", this.props.usuario1.password);
    data.append("habilitar", "NO");
    data.append("boton", "habilitarI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Reporte Planificacion.",
          cuerpoM:
            "La Planificacion fué Deshabilitada para el Representante, gracias.",
        });
        this.consultaI(this.state.fechaTemporal);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Reporte Planificacion.",
          cuerpoM: "Ocurrio un error verifique, gracias.",
        });
      }
    });
  };
  derecha = () => {
    let data = new FormData();
    data.append("fechaS", this.state.fechaSeleccion);
    data.append("arregloFecha", JSON.stringify(this.state.someHTML));
    data.append("boton", "derecha");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.consultaI(res.data.variable2);
      }
    });
  };

  izquierda = () => {
    let data = new FormData();
    data.append("fechaS", this.state.fechaSeleccion);
    data.append("arregloFecha", JSON.stringify(this.state.someHTML));
    data.append("boton", "izquierda");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.consultaI(res.data.variable2);
      }
    });
  };

  // consultarDosificacion(e) {
  //   let data = new FormData();
  //   data.append("idprofesor", this.state.idprofesor);
  //   data.append("idgrado", this.state.idcurso);
  //   data.append("idmateria", this.state.idmateria);
  //   data.append("fecha", '2024-03-04');
  //   data.append("trimestre", this.state.trimestre);
  //   data.append("boton", "buscarEvaPro");
  //   axios({
  //     method: "post",
  //     url:
  //       "https://institutobilinguelasnaciones.com/backend/dosificaciones.php",
  //     data: data,
  //   }).then((res) => {
  //     let xinombreEva = res.data.variable2;
  //     if (res.data.variable1) {
  //       this.setState({
  //         inombreEva: xinombreEva,
  //       });
  //     } else {
  //       this.setState({
  //         inombreEva: xinombreEva,
  //       });
  //     }
  //   });
  // }

  consultaI = (e) => {
    this.setState({ mostrarOn: false });
    //if (e.length > 0) {
    let data = new FormData();
    data.append("fecha1", e);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("idgrado", this.state.idcurso);
    data.append("idfechaPlanificacion", this.state.idfechaPlani);
    data.append("idprofesor", this.state.idprofesor);
    data.append("idmateria", this.state.idmateria);
    data.append("trimestre", this.state.trimestre);
    data.append("tipoR", this.state.tipoR);

    data.append("boton", "consultaI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/autorizarMEAgenda.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xsomeHTML = res.data.variable2;
        this.setState({
          idagenda: res.data.variable3,
          fechaTemporal: e,
          mostrarOn: true,
          fechaSeleccion: e,
          someHTML2: xsomeHTML,
        });
      }
    });
    //}
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  // buscarProfesor = () => {
  //   let form = new FormData();
  //   form.append("clave", this.props.usuario1.password);
  //   form.append("boton", "buscarPersonal3");
  //   let config = {
  //     headers: { "Content-Type": "text/html" },
  //   };
  //   const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
  //   axios.post(xurl, form, config).then((res) => {
  //     let iprofesor = res.data.variable1;
  //     if (res.data.variable3 === "SI") {
  //       iprofesor.map((item, i) => {
  //         this.setState({ idprofesor: item.variable1 });
  //       });
  //     } else {
  //       this.setState({
  //         idprofesor: "",
  //         mensajealerta: true,
  //         tituloM: "Menu Actualizar Fecha de Agendas.",
  //         cuerpoM:
  //           "Usted No Esta Autorizado Para Actualizar Fecha de Agendas, Gracias.",
  //       });
  //     }
  //   });
  // };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  getDates = (startDate, stopDate) => {
    let xdateArray = new Array();
    let currentDate = moment(startDate);
    //while (currentDate <= stopDate) {
    while (
      currentDate.isBetween(
        moment(stopDate).add(-1, "days"),
        moment(stopDate).add(1, "days")
      )
    ) {
      xdateArray.push(currentDate);
      currentDate = moment(currentDate).add(1, "days");
    }
    this.setState({
      dateArray: xdateArray,
    });
  };

  buscarMaterias = (e) => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("idgrado", this.state.idcurso);
    data.append("idprofesor", e);
    data.append("boton", "buscarPlanE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable8;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
            idmateria: "",
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  // buscarMaterias = () => {
  //   let data = new FormData();
  //   data.append("boton", "buscarMaterias");

  //   let config = {
  //     headers: {
  //       "Content-Type": "text/html",
  //     },
  //   };
  //   const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
  //   axios
  //     .post(xurl, data, config)

  //     .then((res) => {
  //       let ximaterias = res.data.variable2;
  //       if (res.data.variable1) {
  //         this.setState({
  //           imaterias: ximaterias,
  //         });
  //       } else {
  //         this.setState({
  //           imaterias: [],
  //         });
  //       }
  //     });
  // };

  componentDidMount() {
    //this.buscarMaterias();
    this.buscarAnioEscolar();
    this.cargarFechas();
    this.buscarProfesor();
    //this.buscarCurso();
  }

  ax = () => {};
  render() {
    //console.log(this.state.fecha1)
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>AUTORIZAR PARA MODIFICAR PLANIFICACION. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Año Escolar --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Profesor --</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1fr col-2">
                    <select
                      id="idfechaPlani"
                      name="idfechaPlani"
                      value={this.state.idfechaPlani}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-- Tipo Planificacion --</option>
                      {this.state.ifechaPlani.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idmateria"
                      name="idmateria"
                      value={this.state.idmateria}
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="">-- Seleccionar Materia --</option>
                      {this.state.imaterias.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* <div className="contenedorcedula1f col-3"> */}
                </div>
                <div className="cont-personal col-12">
                  {/* <div className="contenedorcedula1f col-2">
                    <select
                      id="tipoR"
                      name="tipoR"
                      value={this.state.tipoR}
                      onChange={this.onChange4.bind(this)}
                    >
                      <option>-- Tipo Consulta --</option>
                      <option value="1">Mensual</option>
                      <option value="2">Por Fechas</option>
                      <option value="3">Diario</option>
                    </select>
                  </div>
                  {this.state.tipoR === "1" ? (
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="fecha1"
                        name="fecha1"
                        value={this.state.fecha1}
                        onChange={this.onChange3.bind(this)}
                      >
                        <option>-- Seleccione el Mes --</option>
                        {this.state.mes.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tipoR === "2" || this.state.tipoR === "3" ? (
                    <div className="contenedorcedula1fx col-2">
                      <input
                        className="form-control"
                        type="date"
                        name="fecha1"
                        id="fecha1"
                        autoComplete="off"
                        onChange={this.onChange3.bind(this)}
                        value={this.state.fecha1.toUpperCase()}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tipoR === "2" ? (
                    <div className="contenedorcedula1fx col-2">
                      <input
                        className="form-control"
                        type="date"
                        name="fecha2"
                        id="fecha2"
                        autoComplete="off"
                        onChange={this.onChange3.bind(this)}
                        value={this.state.fecha2.toUpperCase()}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                  <div className="contenedorcedula1f col-8">
                    <div className="acciones">
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={this.consulta.bind(this)}
                      >
                        Consultar
                      </button>
                      {/* <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={this.imprimirPlanificacion.bind(this)}
                      >
                        Imprimir Selección
                      </button>
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={this.habilitar.bind(this)}
                      >
                        Habilitar
                      </button>
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={this.deshabilitar.bind(this)}
                      >
                        Deshabilitar
                      </button>
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={this.copiarPlanificacion.bind(this)}
                      >
                        Copiar Planificacion Selección
                      </button> */}
                      <Link to="/">
                        <button className="boton">Salir</button>
                      </Link>
                      <button
                        className="boton"
                        onClick={this.limpiar.bind(this)}
                      >
                        Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container col-12">
            <div className="titulo-cxc ">
              <p>CONSULTA DE PLANIFICACIÓN POR FECHA. </p>
            </div>

            {this.state.mostrarOn === true && (
              <Modal
                open={this.state.mostrarOn}
                someHTML2={this.state.someHTML2}
                derecha={this.derecha}
                izquierda={this.izquierda}
                habilitarI={this.habilitarI}
                deshabilitarI={this.deshabilitarI}
                idagenda={this.state.idagenda}
                idprofesor={this.state.idprofesor}
                idcurso={this.state.idcurso}
                idmateria={this.state.idmateria}
                trimestre={this.state.trimestre}
                onClose={this.handleClose2}
                //onClose={() => this.setState({ mostrarOn: false })}
              />
            )}
            {this.state.someHTML.length > 0 && (
              <div className="listadopcr col-12">
                <table className="tabla2pcr">
                  <thead>
                    <tr>
                      <th className="tituevo">Fecha Agenda</th>
                      <th className="tituevo">Activar</th>
                      <th className="tituevo">Desactivar</th>
                    </tr>
                  </thead>
                  <tbody className="cuerpo">
                    {/* {this.state.dateArray.map((item, i) => { */}
                    {this.state.someHTML.map((item, i) => {
                      //console.log(this.state.dateArray);

                      return (
                        <tr
                          className={i % 2 === 0 ? "oddr" : ""}
                          key={item.variable1}
                        >
                          <td
                            className="itemtablaR"
                            key={item.variable1}
                            onClick={this.consultaI.bind(this, item.variable1)}
                          >
                            {item.variable2}
                          </td>

                          <td className="itemtablaR" width="20">
                           
                            <input
                              type="radio"
                              className="opvacunacionsiA"
                              name={item.variable1}
                              id={item.variable1}
                              value="SI"
                              checked={item.variable3 === "SI"}
                              onChange={this.onChange5.bind(
                                this
                              )}
                            />
                          
                          </td>

                          <td className="itemtablaR" width="20">
                            <input
                              type="radio"
                              className="opvacunacionsiA"
                              name={item.variable1}
                              id={item.variable1}
                              value="SI"
                              checked={item.variable4 === "SI"}
                              onChange={this.onChange6.bind(
                                this
                              )}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(AutorizarMEAgenda);
