//dependencias
import React, { Component } from 'react';

//componentes
import ConsultaIT2Action from './consultaIT2'

class Index extends Component {

    render(){
        return(
            <div>
                <ConsultaIT2Action />
            </div>
        );
    }

}
export default Index;