//dependencias
import React, { Component } from 'react';

//Component
import HeaderNav from'./Header.js';

class Header extends Component {

  render() {

    return (
     <HeaderNav />
     
    );
  }
}

export default Header;
