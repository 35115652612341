import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./generarPagos.css";
import Alert from "../../modal/alert";

class GenerarPagos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referencia: "",
      banco: "",
      oppago: "TRANSFERENCIA",
      fecha: "",
      fechaPago: "",
      monto: "",
      nombreper: "",
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      cedulaAlumno: "",
      concepto: "",
      oppagoP: "",
      loading: false,
      numeroRecibo:"",
    };
  }

  limpiar = () => {
    this.setState({
      referencia: "",
      banco: "",
      oppago: "TRANSFERENCIA",
      fecha: "",
      fechaPago: "",
      monto: "",
      nombreper: "",
      oppagoP: "",
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      cedulaAlumno: "",
      concepto: "",
      loading: false,
      numeroRecibo:"",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    let nomb = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (nomb === "oppagoP" && e.target.value === "MADRE")
      this.setState({ nombreper: this.props.madre });
    if (nomb === "oppagoP" && e.target.value === "PADRE")
      this.setState({ nombreper: this.props.padre });
    if (nomb === "oppagoP" && e.target.value === "OTRO")
      this.setState({ nombreper: "" });
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultaRecibo = () => {
    
      let data = new FormData();
      data.append("numeroRecibo", this.state.numeroRecibo);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
  
  };

  enviarFacturaCorreo = () => {
    let data = new FormData();
    data.append("numeroRecibo", this.state.numeroRecibo);
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago.php";
    axios
      .post(xurl, data, config)

      .then((res) => {});
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Enviar Factura por Correo.",
        cuerpoM:
          "La Factura fue enviada por correo de forma correcta",
      });
  };

  aprobarPago = async () => {
    if (this.state.nombreper.length > 0) {
      this.setState({
        loading: true,
      });
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsuario", this.props.usuario1.password);
      data.append("cedulaAlumno", this.props.cedulaAlumno);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("fechaPago", this.state.fechaPago);
      data.append("nombreper", this.state.nombreper);
      data.append("boton", "aprobarPago2");
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportarPago2.php";
      let response = await axios.post(xurl, data);
      //console.log(response.data.variable3);
      if (response.data.variable1) {
        let xnreci=response.data.variable3
        this.setState({ numeroRecibo: xnreci,});

        // axios
        //   .post(
        //     "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago.php/?numeroRecibo=" +
        //       response.data.variable3
        //   )
        //   .then((res) => {});
        this.setState({ btnEnviarOn: true, loading: false });
      } else {
        this.setState({ btnEnviarOn: false, loading: false });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Aprobar Pagos.",
        cuerpoM:
          "Debe Incluir el nombre de la Persona que Realizó la Transferencia",
      });
    }
  };

  

  componentDidMount() {}

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago col-12">
          <div className="titulo-pago ">
            <p>PAGOS REALIZADOS POR LA ADMINISTRACION. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}
          <div className="contenedor-titulo row">
            <div className="contenedor-cedula col-3">
              <label className="labelcedulac">Banco.</label>

              <input
                className="form-control"
                type="text"
                name="banco"
                id="banco"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.banco.toUpperCase()}
              />
            </div>
            <div className="contenedor-cedula col-3">
              <label className="labelcedulac">Referencia.</label>
              <input
                className="form-control"
                type="text"
                name="referencia"
                id="referencia"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.referencia.toUpperCase()}
              />
            </div>
            <div className="contenedor-cedula col-4">
              <label className="labelcedulac">
                Quien Realizó la Transferencia.
              </label>
              <input
                className="form-control"
                type="text"
                name="nombreper"
                id="nombreper"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.nombreper.toUpperCase()}
              />
            </div>

            <div className="contenedor-cedula col-2">
              {this.props.madre.length > 0 && (
                <div className="radio">
                  <label className="labelcedulac">Selccione.</label>
                  <label className="container">
                    Madre.
                    <input
                      className="opnivel1"
                      type="radio"
                      value="MADRE"
                      name="oppagoP"
                      checked={this.state.oppagoP === "MADRE"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              )}
              {this.props.padre.length > 0 && (
                <div className="radio">
                  <label className="container">
                    Padre.
                    <input
                      className="opnivel2"
                      type="radio"
                      value="PADRE"
                      name="oppagoP"
                      checked={this.state.oppagoP === "PADRE"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              )}
              <div className="radio">
                <label className="container">
                  Otro.
                  <input
                    className="opnivel2"
                    type="radio"
                    value="OTRO"
                    name="oppagoP"
                    checked={this.state.oppagoP === "OTRO"}
                    onChange={this.onChange.bind(this)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="contenedor-cedula col-2">
                <label className="labelcedulac">Monto a Pagar.</label>

                <input
                  className="form-control"
                  type="text"
                  name="monto"
                  id="monto"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.monto}
                />
              </div>
              <div className="contenedor-cedula col-3">
                <label className="labelcedulac">Concepto.</label>

                <input
                  className="form-control"
                  type="text"
                  name="concepto"
                  id="concepto"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.concepto.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-2">
                <label className="labelcedulac">Fecha Transacción.</label>
                <input
                  className="form-control"
                  type="date"
                  name="fecha"
                  id="fecha"
                  // readOnly="readonly"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fecha}
                />
              </div>
              <div className="contenedor-cedula col-2">
                <label className="labelcedulac">Fecha del Pago.</label>
                <input
                  className="form-control"
                  type="date"
                  name="fechaPago"
                  id="fechaPago"
                  // readOnly="readonly"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fechaPago}
                />
              </div>

              <div className="radio-identificacion col-2">
                <div className="radio">
                  <label className="labelcedulac">Transacción.</label>
                  <label className="container">
                    Transferencia.
                    <input
                      className="opnivel1"
                      type="radio"
                      value="TRANSFERENCIA"
                      name="oppago"
                      checked={this.state.oppago === "TRANSFERENCIA"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="radio">
                  <label className="container">
                    Deposito.
                    <input
                      className="opnivel2"
                      type="radio"
                      value="DEPOSITO"
                      name="oppago"
                      checked={this.state.oppago === "DEPOSITO"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="radio">
                  <label className="container">
                    Tarjeta.
                    <input
                      className="opnivel2"
                      type="radio"
                      value="TARJETA"
                      name="oppago"
                      checked={this.state.oppago === "TARJETA"}
                      onChange={this.onChange.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            {!this.state.btnEnviarOn && (
              <div className="contenedorcedula1 col-12">
                <button className="boton" onClick={this.aprobarPago}>
                  Aprobar Pago
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <button className="boton" onClick={this.props.generarPagoClose}>
                  Salir
                </button>
              </div>
            )}
          </div>

          {this.state.btnEnviarOn && (
            <div className="titulo-pago col-12">
              <p>EL PROCESO SE COMPLETO DE FORMA EXITOSA.</p>
              
              <button className="boton" onClick={this.consultaRecibo}>Ver Recibo</button>
              <button className="boton" onClick={this.enviarFacturaCorreo}>Enviar Correo</button>
              <button className="boton" onClick={this.props.generarPagoClose}>
                Continuar
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(GenerarPagos);
