import React, { useState, useRef } from "react";
import { Card, CardContent, Grid, TextField, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import Alert from "../../modal/alert";
import QRCode from "qrcode";
import "./crearCodigoQr.css";

export default function CodigoQR(props) {
  const [text, setText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [scanResultFile, setScanResultFile] = useState("");
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [mensajealerta, setMensajealerta] = useState(false);
  const [tituloM, setTituloM] = useState("");
  const [cuerpoM, setCuerpoM] = useState("");
  //const classes = useStyles();
  const qrRef = useRef(null);

  const generateQrCode = async () => {
    try {
      const response = await QRCode.toDataURL(text);
      setImageUrl(response);
    } catch (error) {
      console.log(error);
    }
  };

  const verificar = () => {
    let data = new FormData();
    data.append("cedula", text);
    data.append("boton", "buscarPersonalI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    let res = axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          generateQrCode();
        } else {
          setMensajealerta(true);
          setTituloM("Menu Crear Codigo QR.");
          setCuerpoM(
            "La Cedula Del Personal No Existe Como Empleado, Verifique..."
          );
        }
      });
  };

  const handleClose = () => {
    setMensajealerta(false);
  };

  return (
    <div className="conatiner">
      {mensajealerta === true && (
        <Alert
          tituloMensaje={tituloM}
          cuerpoMensaje={cuerpoM}
          open={mensajealerta}
          handleClose={handleClose}
        />
      )}
      <Card>
        <h2 className="title">Descargar & Scanear codigo QR</h2>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <TextField
                label="Indique Cedula"
                onChange={(e) => setText(e.target.value)}
              />
              <Button
                className="btn22"
                variant="contained"
                color="primary"
                onClick={() => verificar()}
              >
                Crear
              </Button>
              <span className="btn22"> </span>
              {/* <Link to="/">
                <button className="boton">Salir</button>
              </Link> */}
              <Link to="/">
                <Button
                  className="btn22"
                  variant="contained"
                  color="primary"
                  onClick={() => verificar()}
                >
                  Salir
                </Button>
              </Link>
              <br />
              <br />
              <br />
              {imageUrl ? (
                <a href={imageUrl} download={text}>
                  <img src={imageUrl} alt="img" />
                </a>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
