import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./reporte.css";
import Alert from "../../modal/alert";

class Reporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      verplanillaOn: false,
      nalumno: [],
      provee: "",
      nombreProvee: "",
      idprovee: "",
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2 = (e) => {
    let idprovee = e.target.value;
    let itemfind = this.state.proveedores.find((e) => e.variable1 === idprovee);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.proveedores.indexOf(itemfind)
        : 0;
    //console.log(index)
    let proveed = this.state.proveedores[index].variable3;
    let idproveed = this.state.proveedores[index].variable2;

    // console.log(iddescrip)
    // console.log(saloncurso)
    this.setState({
      provee: idprovee,
      nombreProvee: proveed,
      idprovee: idproveed,
    });
  };

  // onChange2(e) {
  //   let index = e.target.value;
  //   let proveed = this.state.icurso[index].variable2;
  //   //let concepto= this.state.icurso[index].variable2
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //     nombreProvee:proveed,
  //   });
  // }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  limpiar = () => {
    this.setState({
      fecha: "",
      ifechamedi: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      verplanillaOn: false,
      nalumno: [],
      provee: "",
      idprovee: "",
      nombreProvee: "",
    });
  };

  buscarTipoReporte = () => {
    if (this.state.tbusqueda === "1") this.reporteComprasG();
    if (this.state.tbusqueda === "2") this.reporteComprasP();
    // if (this.state.tbusqueda === "3") this.facturasGeneral();
    // if (this.state.tbusqueda === "4") this.reporteProveedorConciliar();
  };

  reporteProveedorConciliar = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteProveedorConciliar.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  facturasGeneral = () => {
    if (this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("anio", this.state.codigo4);
      data.append("proveedor", "");
      data.append("idproveedor", "");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/facturaPGeneral.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteComprasG = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      data.append("proveedor", "");
      data.append("idproveedor", "");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteComprasG.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteComprasP = () => {
    if (
      this.state.codigo3.length > 0 &&
      this.state.codigo4.length > 0 &&
      this.state.provee.length > 0
    ) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      data.append("proveedor", this.state.nombreProvee);
      data.append("idproveedor", this.state.provee);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteComprasP.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes, un Año y selecconar un Proveedor para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };
  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  consultarProveedores = () => {
    let data = new FormData();
    data.append("boton", "consultarProveedor");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  componentDidMount() {
    this.cargarFechas();
    this.consultarProveedores();
  }

  ax = () => {};
  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago1 col-12">
          <div className="titulo-pago ">
            <p>MENU PARA GENERAR REPORTES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {!this.state.tokenVerificarOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>SELECCIONE EL TIPO DE REPORTE A EJECUTAR. </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Tipo de Reporte --</option>
                    <option value="1">Compras Mensual General</option>
                    <option value="2">Compras Mensual por Cliente</option>
                    {/* <option value="3">Facturas Mensual General</option>
                    <option value="4">Pagos Mensual General Conciliar</option> */}
                  </select>
                </div>
              </div>
              {this.state.tbusqueda === "1" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "2" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="provee"
                      name="provee"
                      value={this.state.provee}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Proveedor --</option>
                      {this.state.proveedores.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2 + " - " + item.variable3}{" "}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "4" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarTipoReporte.bind(this)}
                >
                  Generar Reporte
                </button>
                <button className="boton" onClick={this.props.reporteClose}>
                  Salir
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Reporte;
