import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./configuracionA.css";
import ModificarMontoA from "../modificarMontoA/modificarMontoA"
import Alert from "../../modal/alert";

class ConfiguracionA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identificacionp1: "",
      tokenVerificar: "",
      opnivel: "",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      ifechamedi: [],
      listaOn: false,
      mensajealerta: false,
      correo: "",
      meses: [],
      anos: [],
      nalumno: [],
      codigo3: "",
      codigo4: "",
      pantalla1On: false,
      fecharegistro: "",
      verplanillaOn: false,
      tbusqueda: "",
      identificacionp1: "",
      cedula: "",
      habersaldo: "",
      debesaldo: "",
      saldo: "",
      saldoAnterior: "",
      procesoOn: false,
      flotadorPDOn: false,
      flotadorMOn: false,
      flotadorPCaOn: false,
      flotadorPCOn: false,
      flotadorVOn: false,
      flotadorREOn: false,
      recibos: [],
      icursos: [],
      igrados: [],
      cursoa: "",
      grado: "0",
      modificarSaldoOn: false,
      reversarPagoOn: false,
      idcxc: "",
      montoDebe: "",
      montoAbono: "",
      montoModificar: "",
      idcontrolPago: "",
      status: "",
      datosChequeOn: false,
      concepto: "",
      origen: "",
      modificarMensualidadOn: false,
      mensualidad: "",
      inscripcion: "",
      idmensualidad: "",
      anioEscolar: "",
      fechaEscolar: [],
    };
  }

  limpiar = () => {
    this.setState({
      identificacionp1: "",
      tokenVerificar: "",
      opnivel: "",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      ifechamedi: [],
      nalumno: [],
      listaOn: false,
      mensajealerta: false,
      correo: "",
      codigo3: "",
      codigo4: "",
      pantalla1On: false,
      fecharegistro: "",
      idpago: "",
      verplanillaOn: false,
      tbusqueda: "",
      identificacionp1: "",
      cedula: "",
      habersaldo: "",
      debesaldo: "",
      saldoAnterior: "",
      procesoOn: false,
      flotadorPDOn: false,
      flotadorMOn: false,
      flotadorPCaOn: false,
      flotadorPCOn: false,
      flotadorVOn: false,
      flotadorREOn: false,
      recibos: [],
      cursoa: "",
      grado: "0",
      modificarSaldoOn: false,
      reversarPagoOn: false,
      idcxc: "",
      montoDebe: "",
      montoAbono: "",
      montoModificar: "",
      idcontrolPago: "",
      status: "",
      concepto: "",
      origen: "",
      modificarMensualidadOn: false,
      mensualidad: "",
      inscripcion: "",
      idmensualidad: "",
    });
  };

  
  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.value;
    let curso = this.state.icurso[index].variable1;
    //let concepto= this.state.icurso[index].variable2
    this.setState({
      curso: curso,
    });
  }

  onChange3(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarCXC1(this.state.cedula, e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
 
  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                procesoOn:true,
              });
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                procesoOn:true,
              });
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarCursos = () => {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/backend/maternal.php/?boton=consultarCursos"
      )
      .then((res) => {
        if (res.data) {
          let xcursos = res.data;
          //console.log(res.data)
          this.setState({
            icursos: xcursos,
          });
        }
      });
  };
  buscargrado = (e) => {
    //console.log(e);
    let data = new FormData();
    data.append("boton", "buscarGrados2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/grado.php";
    axios.post(xurl, data, config).then((res) => {
      let xgrado = res.data;
      this.setState({
        igrados: [],
        igrados: xgrado,
      });
    });
  };

  componentDidMount() {
    this.buscarAnioEscolar();
    this.buscargrado();
  }

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          anioEscolar: res.data.variable2,
          fechaEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                procesoOn:true,
              });
              
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                procesoOn:true,
              });
              
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM: "Debe seleccionar un tipo de busqueda, gracias...",
      });
    }
  };

  buscarCursosGrados = () => {
    let data = new FormData();
    data.append("grado", this.state.grado);
    data.append("boton", "buscarCursosGrados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante, gracias...",
          });
        }
      });
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };


  proceso = (e) => {
    if (this.state.procesoOn) {
      this.setState({ procesoOn: false });
      this.limpiar()
    } else {
      this.setState({
        procesoOn: true,
        
      });
    }
  };

  ax = () => {};
  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU PARA ACTUALIZAR MONTOS DEL ALUMNO AÑO ESCOLAR {this.props.usuario1.anioE}. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {!this.state.verplanillaOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>
                  SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                  CORRESPONDIENTA A SU SELECCIÓN.{" "}
                </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar por --</option>
                    <option value="1">CEDULA/PASAPORTE</option>
                    <option value="2">NOMBRE ESTUDIANTE</option>
                    <option value="3">CURSO/GRADO</option>
                  </select>
                </div>
              </div>
              {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="grado"
                      name="grado"
                      value={this.state.grado}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccione Grado --</option>
                      {this.state.igrados.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda !== "3" && (
                <div className="contenedorcedula1 row">
                  <input
                    className="form-control"
                    type="text"
                    name="identificacionp1"
                    id="identificacionp1"
                    autoComplete="off"
                    placeholder={
                      this.state.tbusqueda === "1"
                        ? "Identificación Alumno."
                        : "Nombre Alumno."
                    }
                    maxLength="300"
                    onChange={this.onChange.bind(this)}
                    value={this.state.identificacionp1.toUpperCase()}
                  />
                </div>
              )}
              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarPlanilla.bind(this)}
                >
                  Consultar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
 
              {this.state.listaOn && (
                <div className="cont-tablamedi row">
                  <div className="tablamedi1 col-11">
                    <div className="cont-titulomedi col-12">
                      <span className="titulocasos">
                        Listado de estudiantes
                      </span>
                      <span
                        className="titulo-cerrarcasos"
                        onClick={this.cerrarLista.bind(this)}
                      >
                        X
                      </span>
                    </div>
                    {/* <span className="titulo-casos">Datos del Pago</span> */}
                    <div className="cont-listmedi row">
                      <div className="listmedi col-12">
                        <table className="tablame" border="1">
                          <tbody className="evocuerpo1">
                            {this.state.nalumno.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "odd" : ""}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td
                                    className="renglon"
                                    width="10"
                                    onClick={this.consulta.bind(
                                      this,
                                      item.variable1
                                    )}
                                  >
                                    {item.variable2}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          

          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc1 col-10">
                <p>ESTUDIANTE: {this.state.alumno}</p>
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc ">
                <p>CEDULA: {this.state.cedula}</p>
              </div>
            </div>
          )}
          {this.state.procesoOn === true && (
            <div className="flotador1xma">
              <div className="flotador1ma">
                <ModificarMontoA
                  cedulaAlumno={this.state.cedula}
                  anioEscolar={this.props.usuario1.anioE}
                  modificarMontoAClose={this.proceso}
                />
              </div>
            </div>
          )}

          {this.state.verplanillaOn &&
            this.state.status === "S" &&
            this.props.usuario1.nivel === "4" && (
              <div className="contenedorcedula1 col-12">
                <button className="boton" onClick={this.edoCuenta.bind(this)}>
                  Edo. Cuenta
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <button
                  className="boton"
                  onClick={this.recibosEmitido.bind(this)}
                >
                  Recibos Emitidos
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            )}

        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ConfiguracionA);
