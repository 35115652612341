import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./aprobarRe.css";
import FeatherIcon from "feather-icons-react";
import CxPAbonos from "../cxpAbonos/cxpAbonos";
import Alert from "../../modal/alert";

class Facturacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcedulaP: "INTERNOS",
      fecha: moment().format("YYYY-MM-DD"),
      fechaAprobado: moment().format("YYYY-MM-DD"),
      anioEscolar: moment().format("YYYY"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      externoOn: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      otroProducto: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
      numeroFactu: "",
      fechaReci: "",
      fechaT: "",
      cxcPendientesOn: "",
      cxcRe: [],
      tipoCliente: "",
      referenciaRe: "",
      notaCredito: "",
      enviarFacturaCorreoOn: false,
      aceptarOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      opcedulaP: "INTERNOS",
      fecha: moment().format("YYYY-MM-DD"),
      fechaAprobado: moment().format("YYYY-MM-DD"),
      anioEscolar: moment().format("YYYY"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      //facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      externoOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      otroProducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      identificacionp1: "",
      numeroFactu: "",
      fechaReci: "",
      fechaT: "",
      cxcPendientesOn: "",
      cxcRe: [],
      tipoCliente: "",
      referenciaRe: "",
      notaCredito: "",
      enviarFacturaCorreoOn: false,
      aceptarOn: false,
    });
  };

  limpiar2 = () => {
    this.setState({
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      otroProducto: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
    });
  };

  cerrarLista3 = () => {
    if (this.state.cxcPendientesOn) {
      this.setState({ cxcPendientesOn: false });
    } else {
      this.setState({ cxcPendientesOn: true });
    }
  };

  cerrarLista2 = () => {
    //console.log(this.state.listaOn)
    if (this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: false,
      });
    }
    if (!this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: true,
      });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    if (this.state.oppago === "INTERNOS") {
      let index = e.target.value;
      let ceduA = this.state.clientes[index].variable2;
      this.setState({ cedulaAlumno: ceduA });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarClientesI(e.target.value);
    //this.consultarFactura(e.target.value)
    //console.log(e.target.value)
  }

  onChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      loading: true,
      [e.target.name]: e.target.value,
    });
    this.consultarClientes("I");
    this.setState({ loading: false, verplanillaOn: true });
  }

  onChange4 = (e) => {
    // let element = e.target;
    // if (element.checked) {
    // console.log(e.target.value);
    // console.log(e.target.name);
    let data = new FormData();
    data.append("idfacturaRe", e);
    data.append("boton", "guardarPendienteRe");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura(this.state.numeroFactu);
          //this.limpiar2();
        } else {
          this.setState({});
        }
      });
    //}
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  externoCli = () => {
    if (this.state.externoOn) {
      this.setState({ externoOn: false });
      this.limpiar();
    } else {
      this.setState({ externoOn: true });
    }
  };

  externoCliente = () => {
    if (this.state.tipoFac === "PRODUCTO") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
    if (this.state.tipoFac === "SERVICIOS") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
  };

  activarFactura = () => {
    if (this.state.facturacionOn) {
      this.setState({
        facturacionOn: false,
        datosChequeOn: false,
      });

      this.limpiar();
      this.consultarFacturaRe();
      this.consultarInventario();
    } else {
      // this.consultarItenFactura();
      // this.consultarInventario();
      this.setState({
        facturacionOn: true,
      });
    }
  };

  consultarClientes = (e) => {
    //this.setState({loading:true})
    let data = new FormData();
    if (e === "I") data.append("boton", "consultarClienteI");
    if (e === "E") data.append("boton", "consultarClienteE");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/factura.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            clientes: xrutas,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarClientesI = (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("idcliente", e);
    data.append("tipoCliente", this.state.opcedulaP);
    data.append("boton", "clienteI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/factura.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          this.setState({
            cliente: res.data.variable2,
            ruc: res.data.variable3,
            fecha: res.data.variable4,
            cedulaAlumno: res.data.variable5,
          });
          this.consultarFactura(res.data.variable3);
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFactura = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("codigoCliente", e);
    data.append("boton", "consultarFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/factura.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            impuesto: res.data.variable8,
            cantidad: "1",
          });
        }
      });
  };

  consultarItenFactura = async (e) => {
    this.setState({
      itenFacturas: [],
      subtotal: "",
      timpuesto: "",
      totalg: "",
    });
    let data = new FormData();
    data.append("idregistro", e);
    data.append("boton", "consultarItenPagoPagina");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            itenFacturas: xrutas,
            subtotal: res.data.variable3,
            timpuesto: res.data.variable4,
            totalg: res.data.variable5,
            referencia: res.data.variable6,
            banco: res.data.variable7,
            oppago: res.data.variable8,
            concepto: res.data.variable9,
            fechaReci: res.data.variable10,
            cedulaAlumno: res.data.variable11,
            tipoCliente: res.data.variable12,
            referenciaRe: res.data.variable13,
            notaCredito: res.data.variable14,
            monto: res.data.variable15,
            numeroFactu: e,
            facturacionOn: true,
            factuPendientesOn: false,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  facturarIten = () => {
    let paso = 0;
    // if (
    //   parseFloat(this.state.cantidad) > parseFloat(this.state.cantidadActual)
    // ) {
    //   paso = 1;
    //   this.setState({
    //     mensajealerta: true,
    //     tituloM: "Menu Facturacion.",
    //     cuerpoM:
    //       "La cantidad que intenta facturar es mayor que la encontrada en el inventario, la cantidad actual es de:" +
    //       this.state.cantidadActual,
    //   });
    // }
    if (parseFloat(this.state.precio) < parseFloat(this.state.costo)) {
      paso = 1;
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El precio es menor que el costo, Verifique por Favor.",
      });
    }

    if (paso === 0) {
      let data = new FormData();
      data.append("idproducto", this.state.idproducto);
      data.append(
        "nombreProducto",
        this.state.nombrepro + " " + this.state.otroProducto
      );
      data.append("numeroF", this.state.numeroFactu);
      data.append("cantidad", this.state.cantidad);
      data.append("precioU", this.state.precio);
      data.append("itebi", this.state.itebi);
      data.append("precioT", this.state.precioT);
      data.append("itebi", this.state.itebi);
      data.append("precioT", this.state.precioT);
      data.append("impuesto", this.state.impuesto);
      data.append("cantidadActual", this.state.cantidadActual);
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("boton", "facturarItenPagoPagina");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe22.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultarItenFactura(this.state.numeroFactu);
            this.limpiar2();
          }
        });
    }
  };

  eliminarItenFactura = (e) => {
    let data = new FormData();
    data.append("iditenfactura", e);
    data.append("boton", "eliminarItenPagoPagina");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura(this.state.numeroFactu);
        }
      });
  };

  guardarFactura = () => {
    if (
      this.state.opcedulaP != "INTERNOS" &&
      parseFloat(this.state.totalg) > 0
    ) {
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El Monto a Pagar No Coincide con el Total Facturado.",
      });
    }
  };

  enviarFacturaCorreo = () => {
    let data = new FormData();
    data.append("numeroRecibo", this.state.numeroFactu);
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Enviar Factura por Correo.",
          cuerpoM: "La Factura fue enviada por correo de forma correcta",
        });
      });
  };

  guardarFactura1 = () => {
    if (this.state.oppago.length > 0) {
      this.setState({
        aceptarOn: true,
      });
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("nfactura", this.state.numeroFactu);
      data.append("fechaAprobado", this.state.fechaAprobado);
      data.append("fechaReci", this.state.fechaReci);
      data.append("fechaT", this.state.fechaT);
      data.append("fecha", this.state.fecha);
      data.append("anioEscolar", this.state.anioEscolar);
      data.append("concepto", this.state.concepto);
      data.append("oppago", this.state.oppago);
      data.append("referencia", this.state.referencia);
      data.append("referenciaRe", this.state.referenciaRe);
      
      data.append("boton", "aprobarPagoPagina");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            if (res.data.variable3 === "2") {
              //this.enviarFacturaCorreo();
            }
            this.activarFactura();
            this.setState({
              aceptarOn: false,
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Aprobar Recibo.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              aceptarOn: false,
              mensajealerta: true,
              tituloM: "Menu Aprobar Recibo.",
              cuerpoM: "Ocurrio un error con la actualizacion.",
            });
          }
        });
    } else {
      this.setState({
        aceptarOn: false,
        mensajealerta: true,
        tituloM: "Menu Aprobar Recibo.",
        cuerpoM: "Debe seleccionar una opcion de Pago.",
      });
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      if (this.state.oppago !== "CXC") {
        this.setState({
          datosChequeOn: true,
          //monto: this.state.totalg,
        });
        if (this.state.monto > this.state.totalg) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Aprobar Recibo.",
            cuerpoM:
              "El monto del Cheque es Mayor que lo Facturado, Verifique.",
          });
        }
      } else {
        this.setState({
          datosChequeOn: true,
        });
      }
    }
  };

  guardarDatosCheque = () => {
    let data = new FormData();

    data.append("nFactura", this.state.numeroFactu);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("oppago", this.state.oppago);
    data.append("concepto", this.state.concepto);
    data.append("fechaReci", this.state.fechaReci);

    data.append("boton", "guardarDatosPagoRe");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            //totalg: "",
            mensajealerta: true,
            tituloM: "Menu Aprobar Recibo.",
            cuerpoM: "Los datos de pago se actualizaron con Exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Aprobar Recibo.",
            cuerpoM: "Ocurrio un error con la actualizacion.",
          });
        }
      });
  };

  pendientes = () => {
    let data = new FormData();
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/pendientesRecep.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  consultarFacturasI = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idregistro", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/recipoPagoPaginaWeb.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  consultarFacturasI2 = () => {
    if (this.state.numeroFactu.length > 0) {
      let data = new FormData();
      data.append("idregistro", this.state.numeroFactu);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/recipoPagoPaginaWeb.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  eliminarFacturasI = (e) => {
    let data = new FormData();
    data.append("idregistro", e);
    data.append("boton", "eliminarPagoPagina");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar();
          this.consultarFacturaRe();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Aprobar Pago por Pagina Web.",
            cuerpoM: "El Pago Web se Elimino con Exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Aprobar Pago por Pagina Web.",
            cuerpoM:
              "Ocurrio un error con la Eliminacion, verifique por favor.",
          });
        }
      });
    //console.log(e);
  };

  reporteLibroV = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/libroVentas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  libroV = () => {
    if (this.state.libroVOn) {
      this.setState({ libroVOn: false });
    } else {
      this.setState({ libroVOn: true });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (this.state.tbusqueda1 === "1") {
      this.buscarPlanillaIndividual();
    }
    if (this.state.tbusqueda1 === "2") {
      this.buscarPorNombres();
    }
  };

  consultarFacturaRe = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("boton", "consultarPagosPagina");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        //this.consultarProveedores()
        let xfacturasP = res.data.variable2;
        this.setState({
          facturasCRe: xfacturasP,
        });
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFacturasIRe = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  eliminarFacturasIRe = (e) => {
    let data = new FormData();
    data.append("nfactura", e);
    data.append("boton", "notaCredito");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: "La Factura se Elimino con Exito.",
            factuPendientesOn: false,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM:
              "Ocurrio un error Verifique que la Factura No este Aprobada.",
          });
        }
      });
  };

  deudaCxC = () => {
    let data = new FormData();
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "buscarCXCRE");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xcxcRe = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            cxcPendientesOn: true,
            cxcRe: xcxcRe,
          });
          if (res.data.variable3 !== "") {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Deuda ",
              cuerpoM: res.data.variable3,
            });
          }
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Deuda ",
            cuerpoM: res.data.variable2,
            cxcPendientesOn: false,
          });
        }
      });
  };

  calcularSumaValorEntrada3 = (index, value, restaP) => {
    //console.log("value " + value);
    //console.log("deuda " + restaP);

    // if (value <= restaP) {
    let cxcRe_ = this.state.cxcRe; //Array.from(cxcRe);
    cxcRe_[index].valorEntrada3 = 0;
    cxcRe_[index].valorEntrada3 = value;
    this.setState({ cxcRe: cxcRe_ });
    //}
  };

  facturarItenCxCRE = (d, e, f, g, h) => {
    let resta = 0;
    resta = this.state.monto - this.state.totalg;
    if (e <= resta) {
      let data = new FormData();
      data.append("idregistro", this.state.numeroFactu);
      data.append("idproducto", "cxcre");
      data.append("nombreProducto", d);
      data.append("cantidad", "1");
      data.append("precioU", e);
      data.append("itebi", "0");
      data.append("precioT", e);
      data.append("impuesto", "");
      data.append("cantidadActual", "");
      data.append("idcontrolPago", f);
      data.append("deuda", g);
      data.append("idanioEscolar", h);


      data.append("boton", "facturarItenPagoPagina");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({ cxcPendientesOn: false });
            this.consultarItenFactura(this.state.numeroFactu);
            this.limpiar2();
            //this.limpiar2();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Aprobacion de recibo.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Aprobacion de recibo.",
        cuerpoM: "El monto que intenta agregar es mayor que la diferencia con el monto del cheque",
      });
    }
  };

  enviarFacturaCorreoOn = () => {
    if (this.state.enviarFacturaCorreoOn) {
      this.setState({ enviarFacturaCorreoOn: false, numeroFactu: "" });
    } else {
      this.setState({ enviarFacturaCorreoOn: true });
    }
  };

  componentDidMount() {
    this.consultarFacturaRe();
    this.consultarInventario();
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU PARA APROBAR RECIBOS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.facturacionOn === true && (
            <div className="flotador3xf">
              <div className="flotador1f">
                <div className="cont-factura col-12">
                  <div className="titulo-cxc ">
                    <p>DETALLE DEL RECIBO.</p>
                    {/* {this.state.tipoFac === "PRODUCTO" ? (
                      <p>FACTURACION DE PRODUCTOS.</p>
                    ) : (
                      <p>FACTURACION DE SERVICIOS.</p>
                    )} */}
                  </div>
                  <div className="cont-campos">
                    <div className="campos-factura">
                      {/* <div className="contenedorcedula1f1 col-4">
                        <select
                          id="idproducto"
                          name="idproducto"
                          value={this.state.idproducto}
                          onChange={this.onChange1.bind(this)}
                        >
                          {this.state.tipoFac === "SERVICIOS" ? (
                            <option>-- Seleccionar Servicios --</option>
                          ) : (
                            <option>-- Seleccionar Producto --</option>
                          )}

                          {this.state.inventario.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="otroProducto"
                          id="otroProducto"
                          
                          autoComplete="off"
                          placeholder="Descripcion."
                          maxLength="25"
                          onChange={this.onChange.bind(this)}
                          value={this.state.otroProducto.toUpperCase()}
                        />
                      </div>
                      <div className="contenedorcedula1f col-1">
                        <input
                          className="form-control"
                          type="text"
                          name="cantidad"
                          id="cantidad"
                         
                          autoComplete="off"
                          placeholder={
                            this.state.tipoFac === "SERVICIOS"
                              ? "Item."
                              : "Cantidad."
                          }
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.cantidad.toUpperCase()}
                        />
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="precio"
                          id="precio"
                         
                          autoComplete="off"
                          placeholder={
                            this.state.tipoFac === "SERVICIOS"
                              ? "Sub-Total."
                              : "Precio."
                          }
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.precio.toUpperCase()}
                        />
                      </div>
                      {this.state.tipoFac === "SERVICIOS" && (
                        <div className="contenedorcedula1f col-1">
                          <input
                            className="form-control"
                            type="text"
                            name="itebi"
                            id="itebi"
                            
                            autoComplete="off"
                            placeholder="Itebi."
                            maxLength="5"
                            onChange={this.onChange.bind(this)}
                            value={this.state.itebi.toUpperCase()}
                          />
                        </div>
                      )}
                      {this.state.tipoFac === "SERVICIOS" && (
                        <div className="contenedorcedula1f col-2">
                          <input
                            className="form-control"
                            type="text"
                            name="precioT"
                            id="precioT"
                            autoComplete="off"
                            placeholder="Total."
                            maxLength="7"
                            onChange={this.onChange.bind(this)}
                            value={this.state.precioT.toUpperCase()}
                          />
                        </div>
                      )} */}

                      {/* <div className="contenedorcedula1 col-3">
                        <input
                          className="form-control"
                          type="date"
                          name="fechaAprobado"
                          id="fechaAprobado"
                          //readOnly="readonly"
                          placeholder="Fecha"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.fechaAprobado}
                        />
                      </div> */}
                    </div>
                    <div className="cont-totales">
                      {/* <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.facturarIten}
                      >
                        Agregar Items
                      </button> */}
                      {this.state.tipoCliente === "INTERNOS" &&
                        this.state.monto > this.state.totalg && (
                          <button
                            className={
                              this.state.guardarOn ? "botonOf" : "boton"
                            }
                            onClick={this.deudaCxC}
                          >
                            Deuda CxC
                          </button>
                        )}

                      <button
                        className={
                          this.state.oppago !== "CXC" ? "boton" : "botonOf"
                        }
                        onClick={
                          this.state.oppago !== "CXC"
                            ? this.datosCheque.bind(this)
                            : ""
                        }
                      >
                        Datos del Pago
                      </button>

                      {/* <button className="boton" onClick={this.guardarFactura1}>
                        Procesar
                      </button> */}

                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.activarFactura}
                      >
                        Salir
                      </button>
                    </div>
                  </div>
                  <div className="cont-listmedi row">
                    <div className="listcxc col-12">
                      <table className="tablacxc" border="1">
                        <thead>
                          <tr>
                            <th className="titucxc2">PRODUCTO</th>
                            <th className="titucxc3">CANTIDAD</th>
                            <th className="titucxc1">P/UNITARIO</th>
                            <th className="titucxc3">ITBMS</th>
                            <th className="titucxc1">TOTAL</th>
                            {/* <th className="titucxc3">PENDIENTE</th> */}
                            <th className="titucxc1">
                              <FeatherIcon
                                icon="delete"
                                size="30px"
                                stroke="#999"
                                color="red"
                              />
                            </th>
                          </tr>
                        </thead>
                        {this.state.itenFacturas.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : "odd"}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td className="totalescxc" width="10">
                                {item.variable2}
                              </td>
                              <td className="renglon" width="10">
                                {item.variable3}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable4}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable5}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable6}
                              </td>
                              {/* <td className="totalescxcx" width="10">
                                <input
                                  className="chskfacRe"
                                  type="checkbox"
                                  name={item.variable1}
                                  id={item.variable1}
                                  value="SI"
                                  checked={item.variable7 === "SI"}
                                  onChange={this.onChange4.bind(
                                    this,
                                    item.variable1
                                  )}
                                />
                              </td> */}
                              <td className="titucxc3" width="10"> 
                                <FeatherIcon
                                  icon="delete"
                                  size="30px"
                                  stroke="#999"
                                  color="red"
                                  onClick={this.eliminarItenFactura.bind(
                                    this,
                                    item.variable1
                                  )}
                                ></FeatherIcon>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                  <div className="cont-totales">
                    <div className="campos-totales">
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="subtotal"
                          id="subtotal"
                          readOnly="readonly"
                          autoComplete="off"
                          placeholder="Sub-Total."
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.subtotal}
                        />
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="timpuesto"
                          id="timpuesto"
                          readOnly="readonly"
                          autoComplete="off"
                          placeholder="ITBMS."
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.timpuesto}
                        />
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="totalg"
                          id="totalg"
                          readOnly="readonly"
                          autoComplete="off"
                          placeholder="Total."
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.totalg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.cxcPendientesOn === true && (
            <div className="flotador4xf">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Listado de Deudas por Cobrar
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista3.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1">FECHA</th>
                          <th className="titucxc1">DESCRIPCION</th>
                          <th className="titucxc2">MONTO</th>
                          <th className="titucxc2"></th>
                          <th className="titucxc2"></th>
                        </tr>
                      </thead>
                      {this.state.cxcRe.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td className="totalescxc" width="10">
                              {item.variable2}
                            </td>
                            <td className="totalescxc" width="10">
                              {item.variable3}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable4}
                            </td>
                            <td className="renglon" width="10">
                              <div className="contenedorcedula col-5">
                                <input
                                  className="form-control"
                                  type="text"
                                  name={item.variable1}
                                  id={item.variable1}
                                  autoComplete="off"
                                  step="any"
                                  onChange={(e) =>
                                    this.calcularSumaValorEntrada3(
                                      i,
                                      e.target.value,
                                      item.variable4
                                    )
                                  }
                                  onKeyPress={(e) =>
                                    this.calcularSumaValorEntrada3(
                                      i,
                                      e.target.value,
                                      item.variable4
                                    )
                                  }
                                  //value={item.valorEntrada}
                                  value={
                                    parseFloat(item.valorEntrada3) > 0
                                      ? item.valorEntrada3
                                      : ""
                                  }
                                />
                              </div>
                            </td>
                            <td className="renglon" width="10">
                              <button
                                className="boton"
                                onClick={this.facturarItenCxCRE.bind(
                                  this,
                                  item.variable3,
                                  item.valorEntrada3,
                                  item.variable1,
                                  item.variable4,
                                  item.variable5
                                )}
                              >
                                Aceptar
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.enviarFacturaCorreoOn === true && (
            <div className="flotador4xf">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Enviar Recibo por Correo</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.enviarFacturaCorreoOn.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <div className="contenedorcedula1f col-2">
                      <input
                        className="form-control"
                        type="text"
                        name="numeroFactu"
                        id="numeroFactu"
                        autoComplete="off"
                        placeholder="Numero de Recibo."
                        maxLength="5"
                        onChange={this.onChange.bind(this)}
                        value={this.state.numeroFactu}
                      />
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <button
                        className="boton"
                        onClick={this.enviarFacturaCorreo.bind(this)}
                      >
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="form-group row">
            {/* <div className="contenedorcedula1 col-5">
              <input
                className="form-control"
                type="text"
                name="cliente"
                id="cliente"
                readOnly="readonly"
                autoComplete="off"
                placeholder="Cliente."
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.cliente.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="text"
                name="ruc"
                id="ruc"
                readOnly="readonly"
                autoComplete="off"
                placeholder="Ruc/Cedula"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.ruc.toUpperCase()}
              />
            </div> */}
            {/* <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="date"
                name="fechaAprobado"
                id="fecha"
                //readOnly="readonly"
                placeholder="Fecha"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fechaAprobado}
              />
            </div> */}
          </div>

          {this.state.datosChequeOn === true && (
            <div className="flotador4xf">
              <div className="flotador1f">
                <div className="contenedor-titulo row">
                  <div className="contenedor-cedula col-3">
                    <label className="labelcedulac">Banco.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="banco"
                      id="banco"
                      readOnly="readonly"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.banco.toUpperCase()}
                    />
                  </div>
                  <div className="contenedor-cedula col-3">
                    <label className="labelcedulac">Referencia.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="referencia"
                      id="referencia"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.referencia}
                    />
                  </div>
                  <div className="contenedor-cedula col-2">
                    <label className="labelcedulac">Monto a Pagar.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="monto"
                      id="monto"
                      readOnly="readonly"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.monto}
                    />
                  </div>
                  <div className="contenedor-cedula col-2">
                    <label className="labelcedulac">
                      Referencia Procesada.
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="referenciaRe"
                      id="referenciaRe"
                      autoComplete="off"
                      readOnly="readonly"
                      onChange={this.onChange.bind(this)}
                      value={this.state.referenciaRe.toUpperCase()}
                    />
                  </div>
                  {/* <div className="contenedor-cedula col-2">
                    <label className="labelcedulac">Nota de Credito.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="notaCredito"
                      id="notaCredito"
                      autoComplete="off"
                      readOnly="readonly"
                      onChange={this.onChange.bind(this)}
                      value={this.state.notaCredito.toUpperCase()}
                    />
                  </div> */}
                  <div className="row">
                    <div className="contenedor-cedula col-4">
                      <label className="labelcedulac">Concepto.</label>

                      <input
                        className="form-control"
                        type="text"
                        name="concepto"
                        id="concepto"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.concepto.toUpperCase()}
                      />
                    </div>
                    <div className="contenedor-cedula col-2">
                      <label className="labelcedulac">Fecha del Pago.</label>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaT"
                        id="fechaT"
                        // readOnly="readonly"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.fechaT}
                      />
                    </div>
                    <div className="contenedor-cedula col-2">
                      <label className="labelcedulac">Fecha Recibo.</label>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaReci"
                        id="fechaReci"
                        readOnly="readonly"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.fechaReci}
                      />
                    </div>
                    <div className="radio-identificacion col-3">
                      <div className="radio">
                        <label className="labelcedulac">Transacción.</label>
                        <label className="container">
                          Transferencia.
                          <input
                            className="opnivel1"
                            type="radio"
                            value="TRANSFERENCIA"
                            name="oppago"
                            checked={this.state.oppago === "TRANSFERENCIA"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="radio">
                        <label className="container">
                          Deposito.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="DEPOSITO"
                            name="oppago"
                            checked={this.state.oppago === "DEPOSITO"}
                             onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="radio">
                        <label className="container">
                          Tarjeta Visa.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="TARJETA"
                            name="oppago"
                            checked={this.state.oppago === "TARJETA"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {/* <div className="radio">
                        <label className="container">
                          Efectivo.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="EFECTIVO"
                            name="oppago"
                            checked={this.state.oppago === "EFECTIVO"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}
                      {/* <div className="radio">
                        <label className="container">
                          C.x.C.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="CXC"
                            name="oppago"
                            checked={this.state.oppago === "CXC"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}
                      {/* <div className="radio">
                        <label className="container">
                          N/C.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="N/C"
                            name="oppago"
                            checked={this.state.oppago === "N/C"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}
                      {/* <div className="radio">
                        <label className="container">
                          Canjes.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="CANJES"
                            name="oppago"
                            checked={this.state.oppago === "CANJES"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div> */}
                    </div>
                  </div>

                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.datosCheque}>
                      Salir
                    </button>
                    {/* <button className="boton" onClick={this.guardarDatosCheque}>
                      Guardar Datos Pago
                    </button> */}
                    <button
                      className="boton"
                      onClick={this.consultarFacturasI2}
                    >
                      Visualizar Recibo
                    </button>
                    {this.state.aceptarOn === false &&
                      this.state.monto <= this.state.totalg && (
                        <button
                          className="boton"
                          onClick={this.guardarFactura1}
                        >
                          Procesar Recibo
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="contenedorcedula1 col-12">
            {/* <button
              className={this.state.guardarOn ? "botonOf" : "boton"}
              onClick={this.activarFactura}
            >
              Facturar
            </button> */}
            {/* <button className={this.state.totalg > 0 ?"boton":"botonOf"} onClick={this.datosCheque.bind(this)}>Datos Cheque</button>
                        <button className={this.state.totalg > 0 ?"boton":"botonOf"} onClick={this.guardarFactura}>Guardar</button> */}
            {/* <button className={this.state.guardarOn?"boton":"botonOf"} disabled={!this.state.guardarOn} onClick={this.eliminarCxP}>Eliminar</button> */}
            {/* <button className="boton" onClick={this.pendientes.bind(this)}>
              Reporte Pendientes
            </button> */}
            <button
              className="boton"
              onClick={this.enviarFacturaCorreoOn.bind(this)}
            >
              Enviar Recibo por Correo
            </button>

            <button className="boton" onClick={this.limpiar.bind(this)}>
              Limpiar
            </button>
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">FECHA</th>
                    {/* <th className="titucxc1">FACTURA</th> */}
                    <th className="titucxc2">ALUMNO</th>
                    <th className="titucxc2">REPRESENTANTE</th>
                    <th className="titucxc1">TOTAL</th>
                    <th className="titucxc1">FORMA DE PAGO</th>
                    {/* <th className="titucxc1">TOTAL</th> */}
                    <th className="titucxc1">
                      <FeatherIcon
                        icon="search"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <FeatherIcon
                        icon="delete"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                    </th>
                  </tr>
                </thead>
                {this.state.facturasCRe.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxc"
                        width="10"
                        onClick={this.consultarItenFactura.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarItenFactura.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarItenFactura.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable4}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarItenFactura.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable5}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarItenFactura.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable6}
                      </td>
                      <td className="titucxc3" width="10">
                        <FeatherIcon
                          icon="search"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.consultarFacturasI.bind(
                            this,
                            item.variable1
                          )}
                        ></FeatherIcon>{" "}
                        &nbsp;&nbsp;&nbsp;{" "}
                        <FeatherIcon
                          icon="delete"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.eliminarFacturasI.bind(
                            this,
                            item.variable1
                          )}
                        ></FeatherIcon>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Facturacion);
