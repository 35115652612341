//dependencias
import React, { Component } from 'react';

//componentes
import PermisoNotasAction from './permisoNota'

class Index extends Component {

    render(){
        return(
            <div>
                <PermisoNotasAction />
            </div>
        );
    }

}
export default Index;