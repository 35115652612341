//dependencias
import React, { Component } from 'react';

//componentes
import CrearCodigoQR from './crearCodigoQr';
class Index extends Component {
    render() {
        return(
            <div className="">
                <CrearCodigoQR /> 
            </div>
        );
    }
}
export default Index;