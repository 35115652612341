import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";


//componentes
import './Configuracion.css';
import Alert from '../../modal/alert';

class ConfiguracionAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
            listaOn:false,
            codigo:'',
            tcaso:'',
            guardarOn:false,
            ifechamedi:[],
            oferta:'NO',
            nivel:'',
            horario:'',
            inscripcion:'',
            mensualidad:'',
            mensualidadVirtual:'',
            inscripcionO:'',
            mensualidadO:'',
            ncuotas:'',
            consumibles:'',
            atraso:'',
            morosidad:'',
            pretiro:'',
            ocargos:'',
            chequeE:'',
            valorordenar:'',
            chequede:'',
            cantAlumnos:'',
            modificarOn:false,
        }

    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    onChange1(e) {
        // console.log(this.state.nana)
        // console.log(e.target.checked)
        if (e.target.checked){
            this.setState({
                oferta: 'SI',
            })
        }else {
            this.setState({
                oferta: 'NO',
            })

        }
      }


    guardar=()=>{

        if (this.state.nivel.length>0) {
            
            let data = new FormData();
            data.append('descripcion', this.state.nivel);
            data.append('horario', this.state.horario);
            data.append('montoInscripcion', this.state.inscripcion);
            data.append('mensualidadVirtual', this.state.mensualidadVirtual);
            data.append('mensualidad', this.state.mensualidad);
            data.append('oferta', this.state.oferta);
            data.append('ofertaInscripcion', this.state.inscripcionO);
            data.append('ofertaMensualidad', this.state.mensualidadO);
            data.append('numeroCuotas', this.state.ncuotas);
            data.append('consumibles', this.state.consumibles);
            data.append('recargoAtraso', this.state.atraso);
            data.append('morosidadPago', this.state.morosidad);
            data.append('penalidadRetiro', this.state.pretiro);
            data.append('otrosCargos', this.state.ocargos);
            data.append('chequeExtranjero', this.state.chequeE);
            data.append('cantidadMaximaAlumno', this.state.cantAlumnos);
            data.append('cheqdev', this.state.chequede);
            data.append('ordenar', this.state.valorordenar);
            data.append('boton', 'agregarCursos');
    
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/maternal2.php';
            axios.post(xurl, data, config)
    
            .then(res => {
                if (res.data.variable1) {
                    this.buscarCasos();
                    this.limpiar();
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Maestro de Niveles/Grados',
                        cuerpoM: 'Se Agrego correctamente',
                    })
                } else {
                    //console.log(res.data.variable2);
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Maestro de Niveles/Grados',
                        cuerpoM: 'No se pudo agregar, verifique Gracias...',
                        })
                }
            })            
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Maestro de Niveles/Grados',
                cuerpoM: 'Debe haber una descripcion como minimo para poder Agregarlo, verifique Gracias...',
             })
        }

    }

    consultar=()=>{
        //console.log(this.state.listaOn)
        if (this.state.listaOn){
            this.setState({
                listaOn:false,
            })
        }
        if (!this.state.listaOn) {
            this.setState({
                listaOn:true,
            })
        }
    }
    consulta=(e)=>{
        if (e.length>0) {
            //console.log(e)
            axios.post('https://institutobilinguelasnaciones.com/backend/maternal2.php/?codigo='+ e +'&boton=consultarCursosIndividual')
            .then(res => {
                    if (res.data) {
                       
                        this.setState({
                            modificarOn:true,
                            listaOn:false,
                            codigo:e,
                            nivel: res.data.variable1,
                            horario: res.data.variable2,
                            inscripcion: res.data.variable3,
                            mensualidad: res.data.variable4,
                            oferta: res.data.variable5,
                            inscripcionO: res.data.variable6,
                            mensualidadO: res.data.variable7,
                            ncuotas: res.data.variable8,
                            consumibles: res.data.variable9,
                            atraso: res.data.variable10,
                            morosidad: res.data.variable11,
                            pretiro: res.data.variable12,
                            ocargos: res.data.variable13,
                            chequeE: res.data.variable14,
                            cantAlumnos: res.data.variable15,
                            chequede: res.data.variable16,
                            valorordenar: res.data.variable17,
                            mensualidadVirtual: res.data.variable18,
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Niveles/Grados',
                            cuerpoM: 'No se encontro el Nivel o Grado que se desea consultar, verifique Gracias...',
                         })
                    }
            })
        }
    }

    modificar=()=>{
        if (this.state.nivel.length>0) {
            let data = new FormData();
            data.append('descripcion', this.state.nivel);
            data.append('horario', this.state.horario);
            data.append('montoInscripcion', this.state.inscripcion);
            data.append('mensualidad', this.state.mensualidad);
            data.append('mensualidadVirtual', this.state.mensualidadVirtual);
            data.append('oferta', this.state.oferta);
            data.append('ofertaInscripcion', this.state.inscripcionO);
            data.append('ofertaMensualidad', this.state.mensualidadO);
            data.append('numeroCuotas', this.state.ncuotas);
            data.append('consumibles', this.state.consumibles);
            data.append('recargoAtraso', this.state.atraso);
            data.append('morosidadPago', this.state.morosidad);
            data.append('penalidadRetiro', this.state.pretiro);
            data.append('otrosCargos', this.state.ocargos);
            data.append('chequeExtranjero', this.state.chequeE);
            data.append('cantidadMaximaAlumno', this.state.cantAlumnos);
            data.append('cheqdev', this.state.chequede);
            data.append('ordenar', this.state.valorordenar);
            data.append('codigo', this.state.codigo);
            data.append('boton', 'modificarCursos');
    
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/maternal.php';
            axios.post(xurl, data, config)            .then(res => {
                    if (res.data.variable1) {
                        this.buscarCasos();
                        this.limpiar();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Niveles/Grados',
                            cuerpoM: 'Se Modifico correctamente el Caso',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Niveles/Grados',
                            cuerpoM: 'No se pudo Modificar el Caso, verifique Gracias...',
                         })
                    }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Maestro de Niveles/Grados',
                cuerpoM: 'Debe haber un Codigo y un Caso para poder Modificar, verifique Gracias...',
             })
        }

    }
    eliminar=(e)=>{
        if (e.length>0) {
            axios.post('https://institutobilinguelasnaciones.com/backend/maternal.php/?codigo='+ e +'&boton=eliminarCursos')
            .then(res => {
                    if (res.data.variable1) {
                        this.buscarCasos();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Niveles/Grados',
                            cuerpoM: 'Se Elimino correctamente el Nivel o Grado',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Niveles/Grados',
                            cuerpoM: 'No se pudo Eliminar el Nivel o Grado, verifique Gracias...',
                         })
                    }
            })
        }
    }

    limpiar=()=>{
        this.setState({
             botonOn:true,
            mensajealerta:false,
            listaOn:false,
            codigo:'',
            tcaso:'',
            guardarOn:false,
            ifechamedi:[],
            oferta:'NO',
            nivel:'',
            horario:'',
            inscripcion:'',
            mensualidad:'',
            mensualidadVirtual:'',
            oferta:'',
            inscripcionO:'',
            mensualidadO:'',
            ncuotas:'',
            consumibles:'',
            atraso:'',
            morosidad:'',
            pretiro:'',
            ocargos:'',
            chequeE:'',
            valorordenar:'',
            chequede:'',
            cantAlumnos:'',
            modificarOn:false,

        })   
    }

    buscarCasos=()=>{
        axios.post('https://institutobilinguelasnaciones.com/backend/maternal.php/?boton=consultarCursos')
        //console.log('https://fehensa.com/consultorio/maestroCasosPHP.php/?codigo=&boton=Consultar')
        .then(res => { 
            if (res.data) {               
                let xifechamedi = res.data
                //console.log(res.data)
                this.setState({
                    ifechamedi:xifechamedi
                });
            }  
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
      }

    componentDidMount(){
       this.buscarCasos();
    }

    render(){
        //console.log(this.state.oferta)
        return(
            <div className="cont-casos row">
                <div className="cont-casos-formulario col-12">
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    }
                    <div className="row">
                        <div className="cont-titulocasos col-12">    
                            <span className="titulocasos">Maestro de Configuracion</span>
                            <Link to="/"><span className="titulo-cerrarcasos">X</span></Link>
                        </div>

                        <div className="cont-datoscasos col-12">
                            <div className="row">
                                <div className="cont-opcioncas col-6">
                                    <label className="opcion" >Descripción Nivel/Grado.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nivel"
                                        id="nivel"
                                        autoComplete="off"
                                        value={this.state.nivel}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas col-6">
                                    <label className="opcion" >Horario.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="horario"
                                        id="horario"
                                        autoComplete="off"
                                        value={this.state.horario}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas col-2">
                                    <label className="opcion" >Inscripción.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="inscripcion"
                                        id="inscripcion"
                                        autoComplete="off"
                                        value={this.state.inscripcion}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas col-2">
                                    <label className="opcion" >Mensualidad.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="mensualidad"
                                        id="mensualidad"
                                        autoComplete="off"
                                        value={this.state.mensualidad}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas col-2">
                                    <label className="opcion" >Mens./Virtual.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="mensualidadVirtual"
                                        id="mensualidadVirtual"
                                        autoComplete="off"
                                        value={this.state.mensualidadVirtual}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas col-1">
                                    <p className="titulooferta">Oferta:</p>
                                    <input type="checkbox" id="oferta1" name="oferta" value="SI"  checked={this.state.oferta === 'SI'} onChange={this.onChange1.bind(this)}/>
                                </div>
                                <div className="cont-opcioncas col-2">
                                    <label className="opcion" >Inscripción Oferta.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="inscripcionO"
                                        id="inscripcionO"
                                        autoComplete="off"
                                        value={this.state.inscripcionO}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas col-2">
                                    <label className="opcion" >Mensualidad Oferta.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="mensualidadO"
                                        id="mensualidadO"
                                        autoComplete="off"
                                        value={this.state.mensualidadO}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >N/Cuotas.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="ncuotas"
                                        id="ncuotas"
                                        autoComplete="off"
                                        value={this.state.ncuotas}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Consumibles.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="consumibles"
                                        id="consumibles"
                                        autoComplete="off"
                                        value={this.state.consumibles}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div> 
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Atraso.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="atraso"
                                        id="atraso"
                                        autoComplete="off"
                                        value={this.state.atraso}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Morosidad.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="morosidad"
                                        id="morosidad"
                                        autoComplete="off"
                                        value={this.state.morosidad}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Pena./Retiro.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="pretiro"
                                        id="pretiro"
                                        autoComplete="off"
                                        value={this.state.pretiro}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Otros/Cargos.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="ocargos"
                                        id="ocargos"
                                        autoComplete="off"
                                        value={this.state.ocargos}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>                              
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Cheque/Extran.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chequeE"
                                        id="chequeE"
                                        autoComplete="off"
                                        value={this.state.chequeE}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Cheq/devuelto.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="chequede"
                                        id="chequede"
                                        autoComplete="off"
                                        value={this.state.chequede}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>                             
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Valor/Ordenar.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="valorordenar"
                                        id="valorordenar"
                                        autoComplete="off"
                                        value={this.state.valorordenar}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas1 col-1">
                                    <label className="opcion" >Cant./Alumnos.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="cantAlumnos"
                                        id="cantAlumnos"
                                        autoComplete="off"
                                        value={this.state.cantAlumnos}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>                             
                            </div>
                            <div className="botonescasos row">
                                    <div className="accionescasos col-12">
                                        <button className={this.state.guardarOn?"botoncasosOff":"botoncasos"}  disabled={this.state.guardarOn} onClick={this.guardar.bind(this)}>Agregar</button>
                                        <button className="botoncasos" onClick={this.consultar.bind(this)}>Consultar</button>
                                        <button className={this.state.modificarOn?"botoncasos":"botoncasosOff"} disabled={!this.state.modificarOn} onClick={this.modificar.bind(this)}>Modificar</button>
                                        {/* <button className={this.state.guardarOn?"botoncasos":"botoncasosOff"} disabled={!this.state.guardarOn} onClick={this.eliminar.bind(this)}>Eliminar</button> */}
                                        <button className="botoncasos" onClick={this.limpiar.bind(this)}>Limpiar</button>
                                        <Link to="/"><button className="botoncasos">Salir</button></Link>
                                    </div>
                                </div>
                        </div>
                        {this.state.listaOn &&
                        <div className="cont-tablamedi row">
                            <div className="tablamedi1 col-7">
                                <div className="cont-titulomedi col-12">
                                    <span className="titulocasos">Listado de Casos</span>
                                    <span className="titulo-cerrarcasos" onClick={this.consultar.bind(this)}>X</span>
                                </div>
                                <span className="titulo-casos">Codigo</span>
                                <span className="titulo-casos">Tipo de Casos</span>
                                <span className="titulo-eliminar"><span className='cerrarme3'></span>= Eliminar</span>
                                <div className="cont-listmedi row">
                                    <div className="listmedi col-12">
                                        <table className="tablame" border="1">
                                            <tbody className="evocuerpo1">
                                                {this.state.ifechamedi.map((item, i)=>{
                                                     return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1} id="tabla"><td width="20" onClick={this.consulta.bind(this,item.variable1)}>{item.variable1}</td>
                                                         <td width="20" onClick={this.consulta.bind(this,item.variable1)}>{item.variable2}</td>                                
                                                         <td width="20"><span className='cerrarme1' onClick={this.eliminar.bind(this,item.variable1)}></span> </td></tr>                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                            </div>  
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}export default ConfiguracionAction;    