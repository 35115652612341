import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
//import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./profesorGuia.css";
import Alert from "../../modal/alert";

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      fecha: "",
      textoevo: "",
      anoEscolar: "",
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      trimestre: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      iactividades: [],
      imaterias: [],
      icursos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      ianoEscolar: [],
      trimestre2: "",
      activarCopiarTrimestreOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      fecha: "",
      textoevo: "",
      anoEscolar: "",
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      trimestre: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      imaterias: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      trimestre2: "",
      activarCopiarTrimestreOn: false,
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") {
      this.buscarProfesor();
      this.consultarActividades(e.target.value);
    }
    if (index === "idprofesor") this.buscarCurso(e.target.value);
  }

  eliminar = (e) => {
    if (e.length > 0) {
      let data = new FormData();

      data.append("idprofesorGuia", e);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/profesorGuia.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          let e = "";
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Asignar Consejero",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  };

  consultarActividades(e) {
    let data = new FormData();
    if (e.length > 0) data.append("anoEscolar", e);
    if (e.length === 0) data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscar");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/profesorGuia.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  copiarTrimestre = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.trimestre2.length > 0
    ) {
      let data = new FormData();
      data.append("trimestre1", this.state.trimestre);
      data.append("trimestre2", this.state.trimestre2);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "copiarPGT");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/profesorGuia.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Asignar Consejero",
            cuerpoM: "Se Realizo la Copia Correctamente.",
          });
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Asignar Consejero",
            cuerpoM: "Hubo un Problema Verifique",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Asignar Consejero",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  };

  guardar() {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.idcurso.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("trimestre", this.state.trimestre);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "guardar");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/profesorGuia.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Asignar Consejero",
            cuerpoM: res.data.variable2,
          });
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Asignar Consejero",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Asignar Consejero",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  activarCopiarTrimestre = () => {
    //console.log(this.state.listaOn)
    if (this.state.activarCopiarTrimestreOn) {
      this.setState({
        activarCopiarTrimestreOn: false,
      });
      this.limpiar();
    }
    if (!this.state.activarCopiarTrimestreOn) {
      this.setState({
        activarCopiarTrimestreOn: true,
      });
    }
  };
  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;

      this.setState({ iprofesor: xiprofesor });
      //this.buscarCurso(item.variable1);
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              idmateria: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  componentDidMount() {
    this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU PARA CREAR CONSEJEROS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          {this.state.activarCopiarTrimestreOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <div className="contenedor-cedula col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Año Escolar --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Trimestre a Copiar--</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre2"
                      name="trimestre2"
                      value={this.state.trimestre2}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Trimestre Nuevo --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                </div>

                <div className="titulo-reprecxc row">
                  <div className="xcontenedorcedula1x col-12">
                    <button
                      className="boton"
                      onClick={this.copiarTrimestre.bind(this)}
                    >
                      Procesar Copia
                    </button>
                    <button
                      className="boton"
                      onClick={this.activarCopiarTrimestre.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Año Escolar --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option value="">--- Seleccione un Profesor ---</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    <button className="boton" onClick={this.guardar.bind(this)}>
                      Guardar
                    </button>
                    <button
                      className="boton"
                      onClick={this.activarCopiarTrimestre.bind(this)}
                    >
                      Copiar Personal a Trimestre
                    </button>
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div className="cont-listmedix row">
                <div className="listcxcx col-12">
                  <table className="tablacxcx" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc3">GRADO</th>
                        <th className="titucxc3">PROFESOR</th>
                        <th className="titucxc3">TRIMESTRE</th>
                        <th className="titucxc3xpe">
                          <span className="titulo-eliminar"></span>
                        </th>
                      </tr>
                    </thead>

                    {this.state.iactividades.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td className="totalescxcx">{item.variable2}</td>
                          <td className="totalescxcx">{item.variable3}</td>
                          <td className="totalescxcx">{item.variable4}</td>

                          <td className="totalescxc">
                            <span
                              className="cerrarme1"
                              onClick={this.eliminar.bind(this, item.variable1)}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Actividades);
