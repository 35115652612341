import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";

 

//componentes
import './veranito.css';
import Alert from '../../modal/alert';

class Veranito extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inscripcion:'',
            fecha:'',
            monto1:'',
            monto2:'',
            btnEnviarOn:false,
            listaOn:false,
            mensajealerta:false,
            cedulaAlumno:'',
            concepto:'',
            iconcepto:[],
        }
       
      
    }

    limpiar =() =>{
        this.setState({
            inscripcion:'',
            fecha:'',
            monto1:'',
            monto2:'',
            btnEnviarOn:false,
            listaOn:false,
            mensajealerta:false,
            cedulaAlumno:'',
            concepto:'',
        })
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }
    onChange2(e) {
        let index = e.target.value
        let value = this.state.iconcepto[index].variable1
        let concepto= this.state.iconcepto[index].variable2
        this.setState({
            concepto: concepto     
        })
    }
    
    onChange3(e) {
        //console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value             
        })
        this.calcularEdad(e.target.value)
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    aprobarVeranito =()=>{

        let data = new FormData();
        data.append('anoEscolar', this.props.usuario1.anioE);         
        data.append('claveUsuario', this.props.usuario1.password); 
        data.append('cedulaAlumno', this.props.cedulaAlumno); 
        data.append('inscripcion', this.state.inscripcion); 
        data.append('monto1', this.state.monto1); 
        data.append('monto2', this.state.monto2); 
        data.append('boton', 'InscripcionVeranito');
        let config = {
            headers: {
                'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/backend/cxc2.php';
        axios.post(xurl, data, config)

            .then(res => {
               // console.log('respuesta ',res)
                if (res.data.variable1) {
                    this.setState({
                        btnEnviarOn:true
                    });
                } else {
                    this.setState({
                        btnEnviarOn:false
                    });

                }
            })
    }

    buscarConceptos=()=>{

        let data = new FormData();
        data.append('boton', 'buscarConceptos');

        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/backend/conceptos.php';
        axios.post(xurl, data, config)

        .then(res => {
            let xiconcepto = res.data.variable2
        if (res.data.variable1) {
                this.setState({
                    iconcepto:xiconcepto,
                });
                
            } else {
                this.setState({
                    iconcepto:[],
                });

            }
        })
    }

    componentDidMount(){
       
    }


    render(){
        return(
            <div className="principal-pago row">
                <div className="contenedor-pago col-12">
                    <div className="titulo-pago ">
                        <p>CREAR CXC VERANITO. </p>
                    </div>
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    
                    }
                    <div className="contenedor-titulo row">
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Inscripción.</label>   
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="inscripcion"
                                        id="inscripcion"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.inscripcion.toUpperCase()}
                                    />  
                        </div>
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Monto Enero.</label>   
                            
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="monto1"
                                        id="monto1"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.monto1}
                                    />                
                        </div>
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Monto Febrero.</label>   
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="monto2"
                                        id="monto2"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.monto2}
                                    />                
                        </div>
                        <div className="row">
                        </div>        
                        {!this.state.btnEnviarOn &&
                        <div className="contenedorcedula1 col-12">
                            <button className="boton" onClick={this.aprobarVeranito.bind(this)}>Aprobar Veranito</button>
                            <button className="boton" onClick={this.limpiar.bind(this)}>Limpiar</button>
                            <button className="boton" onClick={this.props.generarPagoClose}>Salir</button>
                        </div>        
                        }
                    </div>                 
                    
                    {this.state.btnEnviarOn &&
                        <div className="titulo-pago col-12">
                            <p>EL PROCESO SE COMPLETO DE FORMA EXITOSA.</p>
                            <button className="boton" onClick={this.props.generarPagoClose}>Continuar</button>
                        </div>
                    }    
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token
    }
}
export default connect(mapStateToProps,)(Veranito);