import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async-creatable";
//componentes
import "./planEvaluacionM.css";
import Alert from "../../modal/alert";
import PublicarNotas from "../publicarNotas/index";

class PlanEMAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcionE: "",
      //inputValue:'',
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: moment().format("YYYY"),
      idtareas: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nombreEvaluacion_selected: null,
      nota: "",
      trimestre: "",
      idarchivos: "",
      ianoEscolar: [moment().format("YYYY")],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      iactividades: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      activarNotaOn: false,
      valueSelect: null,
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      nombreEvaluacion_selected: null,
      idcurso: "",
      //idprofesor: "",
      descripcionE: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      nombreAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      idtareas: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nota: "",
      idarchivos: "",
      ievaluaciones: [],
      listaOn: false,
      modificarOn: false,
      activarNotaOn: false,
      valueSelect: null,
    });
  };

  limpiar2(e) {
    this.setState({
      idmateria: "",
      idcurso: "",
      //idprofesor: "",
      idplanE: "",
      cedulaAlumno: "",
      nombreAlumno: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nombreEvaluacion_selected: null,
      nota: "",
      trimestre: "",
      idarchivos: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
      valueSelect: null,
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") {
      this.limpiar2();
      this.buscarProfesor();
    }
    if (index === "trimestre") {
      this.setState({
        cedulaAlumno: "",
        descripcionE: "",
        ievaluaciones: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        nombreEvaluacion_selected: null,
      });
      this.buscarTrimestre(e.target.value);
    }
    if (index === "idcurso") {
      this.setState({
        cedulaAlumno: "",
        descripcionE: "",
        trimestre: "",
        idmateria: "",
        ievaluaciones: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        nombreEvaluacion_selected: null,
      });
      this.buscarPlanE2(e.target.value);
    }
    if (index === "descripcionE") {
      this.setState({
        cedulaAlumno: "",
        ievaluaciones: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        nombreEvaluacion_selected: null,
      });
      this.buscarPlanE(e.target.value);
    }
    if (index === "idmateria")
      this.setState({
        cedulaAlumno: "",
        descripcionE: "",
        trimestre: "",
        ievaluaciones: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        nombreEvaluacion_selected: null,
      });
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.imaterias.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : 0;
    //console.log(index)
    let xcantidadE = this.state.imaterias[index].variable3;
    let xidmateria = this.state.imaterias[index].variable4;
    //console.log("cantidad: "+xcantidadE)
    //console.log("idmateria: "+xidmateria)
    this.consultarActividades(xidmateria);
    this.setState({
      [e.target.name]: e.target.value,
      cantidadE: xcantidadE,
      idmateria: xidmateria,
    });
  };

  buscarTrimestre = (e) => {
    let data = new FormData();
    data.append("codigo", e);
    data.append("boton", "consultarActivo");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
      } else {
        this.setState({
          trimestre: "",
          mensajealerta: true,
          tituloM: "Menu Crear Plan de Evaluacion.",
          cuerpoM:
            "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
        });
      }
    });
  };

  consultar(e) {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "buscarPlanEI");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              idcurso: res.data.variable3,
              idmateria: res.data.variable4,
              descripcionE: res.data.variable5,
              cantidadE: res.data.variable6,
              idplanEvaluacion: res.data.variable7,
              modificarOn: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan Evaluacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  }

  modificar = () => {
    if (this.state.idmateria.length > 0 && this.state.idcurso.length > 0) {
      let data = new FormData();
      data.append("idmaestroE", this.state.idplanEvaluacion);
      data.append("cantidad", this.state.cantidadE);
      data.append("descripcion", this.state.descripcionE);
      data.append("boton", "modificarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  guardar = () => {
    if (
      this.state.idmateria.length > 0 &&
      this.state.idplanE.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.descripcionE.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.nombreEvaluacion.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idplanE", this.state.idplanE);
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idcurso);
      data.append("idtituloNota", this.state.descripcionE);
      data.append("fecha", this.state.fechaE);
      data.append("idactividad", this.state.idtareas);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", this.state.trimestre);
      data.append("nombreEvaluacion", this.state.nombreEvaluacion);
      data.append("evaluacionNombre", this.state.evaluacionNombre);
      data.append("boton", "guardarPlanificacion");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacionMaestro.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.limpiar2();
            this.buscarPlanEvaluaciones();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Plan de Evaluacion.",
              cuerpoM: res.data.variable2,
              idnombreEvaluacion: "",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Plan de Evaluacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Plan de Evaluacion.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "eliminarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacionMaestro.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Plan de Evaluacion.",
            cuerpoM: res.data.variable2,
          });
          //this.limpiar();
          this.buscarPlanEvaluaciones();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Plan de Evaluacion.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la MAteria Asignada.",
      });
    }
  };

  buscarPlanEvaluaciones = () => {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.descripcionE.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idplanE", this.state.idplanE);
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idcurso);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcionE", this.state.descripcionE);
      data.append("trimestre", this.state.trimestre);
      data.append("boton", "buscarPE2");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacionMaestro.php";
        axios.post(xurl, data, config).then((res) => {
          if (res.data.variable1) {
            let xievaluaciones = res.data.variable2;
            this.setState({
              ievaluaciones: xievaluaciones,
            });
          } else {
            this.setState({
              ievaluaciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Plan de Evaluacion.",
        cuerpoM: "Debe Seleccionar un Profesor y un Grado, gracias.",
      });
    }
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Crear Plan de Evaluacion.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Plan de Evaluacion.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Crear Plan de Evaluacion.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    let data = new FormData();
    data.append("idmateria", this.state.idmateria);
    data.append("idgrado", this.state.idcurso);
    data.append("trimestre", this.state.trimestre);
    data.append("iddescripcion", e);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarMaestroE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          cantidadE: res.data.variable2,
          idplanE: res.data.variable3,
          valueSelect: null,
        });
      } else {
        this.setState({
          cantidadE: "",
          idplanE: "",
          descripcionE: "",
          mensajealerta: true,
          tituloM: "Menu Crear Plan de Evaluacion.",
          cuerpoM: res.data.variable2,
          valueSelect: null,
        });
      }
    });
  };

  buscarPlanE2 = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE2");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          let xialumnos = res.data.variable3;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              ialumnos: xialumnos,
              idmateria: "",
              cedulaAlumno: "",
              descripcionE: "",
              trimestre: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Plan de Evaluacion.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  buscarSalones = () => {
    let data = new FormData();
    data.append("boton", "buscarSalones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;

        this.setState({
          icursos: xicursos,
        });
      });
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let iprofesor = res.data.variable1;
      iprofesor.map((item, i) => {
        this.setState({ idprofesor: item.variable1 });
        this.buscarCurso(item.variable1);
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  buscarAlumno = (a, b) => {
    // console.log(a);
    // console.log(b);
    if (b.length === 0) {
      let data = new FormData();
      data.append("cedulaAlumno", a);
      data.append("boton", "buscarPlanillaIndividual");

      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/maternal.php",
        data: data,
      }).then((res) => {
        if (res.data.variable136) {
          this.setState({
            nombreAlumno: res.data.variable1 + " " + res.data.variable2,
            cedulaAlumno: a,
          });
        } else {
          this.setState({
            nombreAlumno: "",
            cedulaAlumno: "",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Plan de Evaluacion.",
        cuerpoM:
          "El Alumno ya tiene una Nota, si quiere cambiarla Debe eliminarla y luego agregar la Nota.",
      });
    }
  };

  loadOptions = async (inputValue) => {
    //if (inputValue.length > 0) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idmateria", this.state.idmateria);
    data.append("descripcionE", this.state.descripcionE);
    data.append("trimestre", this.state.trimestre);
    data.append("idcurso", this.state.idcurso);
    data.append("inputValue", inputValue);
    if (inputValue.length > 1) data.append("boton", "buscarNE");
    if (inputValue.length <= 1) data.append("boton", "buscarNE2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacionMaestro.php";
    let res = await axios.post(xurl, data, config);
    if (res.data.length > 0) {
      let arr = [];
      for (let x = 0; x < res.data.length; x++) {
        arr.push({
          label: res.data[x].variable1,
          value: res.data[x].variable2,
        });
      }
      return arr;
    } // else {
    //   const arr = [{ value: "-1", label: "Crear Evaluación()" }];
    //   return arr;
    // }
    //}
  };

  onCreate = (e) => {
    //console.log(e);
    if (
      this.state.idprofesor.length > 0 &&
      this.state.descripcionE.length > 0 &&
      this.state.idmateria.length > 0 &&
      e.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idmateria", this.state.idmateria);
      data.append("idtituloNota", this.state.descripcionE);
      data.append("trimestre", this.state.trimestre);
      data.append("evaluacionNombre", e);
      data.append("boton", "guardarMaestroNombreEvaluacion");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/planificacionMaestro.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            nombreEvaluacion: res.data.variable2,
            evaluacionNombre: e,
            label: e,
            value: res.data.variable2,
            nombreEvaluacion_selected: { label: e, value: res.data.variable2 },
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Plan de Evaluacion.",
            cuerpoM: "El Nombre de la Evaluación no se pudo Crear.",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Plan de Evaluacion.",
        cuerpoM:
          "Debe haber un profefor Seleccionado, una Materia y elejir la Descripción de la Evaluación para crear un Nombre de Evaluación.",
      });
    }
  };

  onSelectChange = (e) => {
    console.log(e);
    this.setState({ nombreEvaluacion_selected: e });
    if (e !== null) {
      this.setState({
        nombreEvaluacion: e.value,
        evaluacionNombre: e.label,
      });
    }
  };

  componentDidMount() {
    this.buscarDE();
    this.buscarProfesor();
  }

  render() {
    console.log(this.state.descripcionE);
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>PLAN DE EVALUACION. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.activarNotaOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <PublicarNotas
                  idarchivos={this.state.idarchivos}
                  handleClose={this.notasTrabajo}
                />
              </div>
            </div>
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Materia --</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1 col-2">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>
              <div className="contenedorcedula1 col-3">
                <select
                  id="descripcionE"
                  name="descripcionE"
                  value={this.state.descripcionE}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Descripcion Evaluacion --</option>
                  {this.state.idescripcionE.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-5">
                  <AsyncSelect
                    id="nombreEvaluacion"
                    name="nombreEvaluacion"
                    className="nombreEvaluacion"
                    //cacheOptions
                    defaultOptions
                    isClearable
                    classNamePrefix="select"
                    loadOptions={(inputValue) => this.loadOptions(inputValue)}
                    onChange={this.onSelectChange}
                    onCreateOption={this.onCreate}
                    value={this.state.nombreEvaluacion_selected}
                  />
                </div>
                <div className="contenedorcedula1 col-2">
                  <input
                    className="form-control"
                    type="date"
                    name="fechaE"
                    id="fechaE"
                    autoComplete="off"
                    onChange={this.onChange.bind(this)}
                    value={this.state.fechaE.toUpperCase()}
                  />
                </div>
              </div>
            </div>
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  <button
                    className="boton"
                    onClick={this.buscarPlanEvaluaciones.bind(this)}
                  >
                    Consultar
                  </button>

                  <button
                    className= "boton"
                    onClick={this.guardar.bind(this)}
                  >
                    Agregar
                  </button>
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">TEMA EVALUACION</th>
                    <th className="titucxc5">FECHA</th>
                    <th className="titucxc3xpe">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.ievaluaciones.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx5">{item.variable2}</td>
                      <td className="totalescxcx5">{item.variable3}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(PlanEMAction);
