import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
//import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./cambioProfesores.css";
import Alert from "../../modal/alert";

class MostrarTrabajos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      cedulaAlumno: "",
      nombreAlumno: "",
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idprofesor2: "",
      idcurso: "",
      idcurso2: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      idtareas: "",
      idarchivos: "",
      nombreEvaluacion: "",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      activarNotaOn: false,
      activarEvaluacionOn: false,
      iactividades: [],
      imaterias: [],
      icursos: [],
      icursos2: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      ianoEscolar: [moment().format("YYYY")],
      itrabajoAlumnos: [],
      openvio: "",
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      cedulaAlumno: "",
      nombreAlumno: "",
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idprofesor2: "",
      idcurso: "",
      idcurso2: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      idarchivos: "",
      nombreEvaluacion: "",
      imaterias: [],
      icursos: [],
      icursos2: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      activarNotaOn: false,
      activarEvaluacionOn: false,
      openvio: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idprofesor2") this.buscarCurso2(e.target.value);
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    //if (index === "idmateria") this.consultarActividades(e.target.value);
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.iactividades.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.iactividades.indexOf(itemfind)
        : 0;
    //console.log(index)
    let nombreA = this.state.iactividades[index].variable5;
    //console.log("nombre Actividad: "+nombreA)
    //console.log("idmateria: "+xidmateria)
    this.setState({
      [e.target.name]: e.target.value,
      nombreEvaluacion: nombreA,
    });
  };

  cambiar() {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idprofesor2.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idprofesor2", this.state.idprofesor2);
      data.append("idcurso", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      
      data.append("boton", "cambiarMateria");

      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/subirTareas.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Sustituir Materias ",
            cuerpoM:
              "El proceso de sustituir la materia con otro profesor fue realizado con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Sustituir Materias ",
            cuerpoM:
              "Ocurrio un error y no se pudo sustituir la materia, verifique por favor Gracias",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Sustituir Materias ",
        cuerpoM:
          "Debe seleccionar todas las opciones para poder realizar el cambio de materia a el profesor. Gracias",
      });
    }
  }

  cambiarS() {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idprofesor2.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idcurso2.length > 0 &&
      this.state.idmateria.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idprofesor2", this.state.idprofesor2);
      data.append("idcurso", this.state.idcurso);
      data.append("idcurso2", this.state.idcurso2);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "copiarMateria");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/subirTareas.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Sustituir Materias ",
            cuerpoM:
              "El proceso de sustituir la materia con otro profesor fue realizado con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Sustituir Materias ",
            cuerpoM:
              "Ocurrio un error y no se pudo sustituir la materia, verifique por favor Gracias",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Sustituir Materias ",
        cuerpoM:
          "Debe seleccionar todas las opciones para poder realizar el cambio de materia a el profesor. Gracias",
      });
    }
  }

  consultar2() {
    if (this.state.idtareas > 0) {
      let data = new FormData();
      data.append("idactividad", this.state.idtareas);
      data.append("boton", "consultarAdjuntosAlumnos4");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/subirTareas.php",
        data: data,
      }).then((res) => {
        let xitrabajoAlumnos = res.data.variable2;
        this.setState({
          itrabajoAlumnos: xitrabajoAlumnos,
        });
      });
    }
  }

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Planificacion",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    console.log(this.props.usuario1.nivel);
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    if (this.props.usuario1.nivel === "3") {
      form.append("boton", "buscarPersonal3");
    } else {
      form.append("boton", "buscarPersonal2");
    }
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      if (this.props.usuario1.nivel === "3") {
        let iprofesor = res.data.variable1;
        iprofesor.map((item, i) => {
          this.setState({ idprofesor: item.variable1 });
          this.buscarCurso(item.variable1);
        });
      } else {
        let xiprofesor = res.data.variable1;
        this.setState({ iprofesor: xiprofesor });
      }
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarCurso2 = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos2: xicursos,
              });
            } else {
              this.setState({
                icursos2: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              idmateria: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Actividad.",
      cuerpoM: "Realmente Quiere Eliminar la Actividad...",
      idactividad: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  EvaluarTrabajo = (e, f, x) => {
    if (this.state.activarEvaluacionOn) {
      this.setState({
        activarEvaluacionOn: false,
        idarchivos: "",
        cedulaAlumno: "",
        nombreAlumno: "",
      });
    } else {
      this.setState({
        activarEvaluacionOn: true,
        idarchivos: e,
        nombreAlumno: f,
        cedulaAlumno: x,
      });
    }
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    this.buscarProfesor();
    //this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU PARA SUSTITUIR LAS MATERIAS DE UN PROFESOR A OTRO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              {this.state.openvio === "" && (
                <div className="row">
                  <div className="cont-personal col-12">
                    <div className="radio">
                      <label className="container">
                        Sustituir
                        <input
                          type="radio"
                          value="1"
                          name="openvio"
                          checked={this.state.openvio === "1"}
                          onChange={this.onChange.bind(this)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="radio">
                      <label className="container">
                        Salón Nuevo
                        <input
                          type="radio"
                          value="2"
                          name="openvio"
                          checked={this.state.openvio === "2"}
                          onChange={this.onChange.bind(this)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {this.state.openvio === "1" && (
                <div className="row">
                  <div className="cont-personal col-12">
                    {this.props.usuario1.nivel === "2" && (
                      <div className="contenedorcedula1f col-4">
                        <select
                          id="idprofesor"
                          name="idprofesor"
                          value={this.state.idprofesor}
                          onChange={this.onChange2.bind(this)}
                        >
                          <option value="">
                            {" "}
                            Indique Profesor a Sustituir{" "}
                          </option>
                          {this.state.iprofesor.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {this.props.usuario1.nivel === "1" && (
                      <div className="contenedorcedula1f col-4">
                        <select
                          id="idprofesor"
                          name="idprofesor"
                          value={this.state.idprofesor}
                          onChange={this.onChange2.bind(this)}
                        >
                          <option value="">
                            {" "}
                            Indique Profesor a Sustituir{" "}
                          </option>
                          {this.state.iprofesor.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idcurso"
                        name="idcurso"
                        value={this.state.idcurso}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Seleccionar Grado --</option>
                        {this.state.icursos.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idmateria"
                        name="idmateria"
                        value={this.state.idmateria}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Materia --</option>
                        {this.state.imaterias.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {this.props.usuario1.nivel === "2" && (
                      <div className="contenedorcedula1f col-3">
                        <select
                          id="idprofesor2"
                          name="idprofesor2"
                          value={this.state.idprofesor2}
                          onChange={this.onChange.bind(this)}
                        >
                          <option value="">
                            {" "}
                            Seleccione el Profesor Nuevo{" "}
                          </option>
                          {this.state.iprofesor.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {this.props.usuario1.nivel === "1" && (
                      <div className="contenedorcedula1f col-3">
                        <select
                          id="idprofesor2"
                          name="idprofesor2"
                          value={this.state.idprofesor2}
                          onChange={this.onChange.bind(this)}
                        >
                          <option value="">
                            {" "}
                            Seleccione el Profesor Nuevo{" "}
                          </option>
                          {this.state.iprofesor.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.state.openvio === "2" && (
                <div className="row">
                  <div className="cont-personal col-12">
                    {this.props.usuario1.nivel === "2" && (
                      <div className="contenedorcedula1f col-5">
                        <select
                          id="idprofesor"
                          name="idprofesor"
                          value={this.state.idprofesor}
                          onChange={this.onChange2.bind(this)}
                        >
                          <option value=""> Indique Profesor con Datos </option>
                          {this.state.iprofesor.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idcurso"
                        name="idcurso"
                        value={this.state.idcurso}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Seleccionar Grado --</option>
                        {this.state.icursos.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idmateria"
                        name="idmateria"
                        value={this.state.idmateria}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Materia --</option>
                        {this.state.imaterias.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="cont-personal col-12">
                    {this.props.usuario1.nivel === "2" && (
                      <div className="contenedorcedula1f col-5">
                        <select
                          id="idprofesor2"
                          name="idprofesor2"
                          value={this.state.idprofesor2}
                          onChange={this.onChange2.bind(this)}
                        >
                          <option value="">
                            {" "}
                            Seleccione el Profesor Nuevo{" "}
                          </option>
                          {this.state.iprofesor.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                        {this.props.usuario1.nivel === "1" && (
                      <div className="contenedorcedula1f col-5">
                        <select
                          id="idprofesor2"
                          name="idprofesor2"
                          value={this.state.idprofesor2}
                          onChange={this.onChange2.bind(this)}
                        >
                          <option value="">
                            {" "}
                            Seleccione el Profesor Nuevo{" "}
                          </option>
                          {this.state.iprofesor.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idcurso2"
                        name="idcurso2"
                        value={this.state.idcurso2}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Seleccionar Grado --</option>
                        {this.state.icursos2.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    {this.state.openvio === "1" && (
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={
                          !this.state.botonguardarOn
                            ? this.cambiar.bind(this)
                            : ""
                        }
                      >
                        Sustituir Profesor
                      </button>
                    )}
                    {this.state.openvio === "2" && (
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={
                          !this.state.botonguardarOn
                            ? this.cambiarS.bind(this)
                            : ""
                        }
                      >
                        Apertura de Salon
                      </button>
                    )}
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(MostrarTrabajos);
