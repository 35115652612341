import React, { Component } from "react";
import { connect } from "react-redux";
import socket from "../socketio/socketio";
import axios from "axios";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { BuscarChats } from "../../API/CoreI";
import {Link} from "react-router-dom";

//componentes
import "./Chat.css";
import Alert from "../../modal/alert";

class ChatAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      usuarioactivo: "",
      activarchatOn: false,
      botonrecibido: false,
      idprofesor: "",
      cedulaR: "",
      mensaje: "",
      idplanE: "",
      idcurso: "",
      idmateria: "",
      openvio: "",
      cedulaAlumno2: "",
      anoEscolar: moment().format("YYYY"),
      imaterias: [],
      icursos: [],
      ialumnos: [],
      mensajes: [],
    };

    // const divRef = useRef(null);
    // useEffect(() =>{
    //     divRef.current.scrollIntoView({behavior: 'smooth'});
    // })
  }
  activarchat = () => {
    if (this.state.botonOn) {
      this.setState({
        botonOn: false,
        activarchatOn: true,
      });
    } else {
      this.setState({
        botonOn: true,
        activarchatOn: false,
        botonrecibido: false,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    // e.getElementById('final').scrollIntoView(true)
  }
  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    // if (index === "idcurso") this.buscarPlanE2(e.target.value);
    if (index === "idcurso") this.buscarPlanE2(e.target.value);
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    if (index === "cedulaAlumno2") this.buscarChat(e.target.value);
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xidprofesor = "";
      res.data.variable1.map((item, i) => {
        return (xidprofesor = item.variable1);
      });
      //console.log(xidprofesor)
      this.initData4(xidprofesor);
      this.setState({
        idprofesor: xidprofesor,
      });
    });
  };

  initData4 = async (e) => {
    let response = await BuscarChats(e);
    if (response.data.variable1) {
      this.setState({ mensajes: response.data.variable2, });
    } else {
      this.setState({ mensajes: [] });
    }
  };

  componentDidMount() {
    this.buscarProfesor();
  }
  render() {
    // console.log("paso: "+this.props.usuario1.usuario)
    // console.log(this.state.activarchatOn)
    return (
      <div className="cont-chat row">
        <div className="cont-chat-formulario col-11">
          <div className="cot-titulochat col-12">
            <span className="titulochat">Datos Pendientes Por Resolver</span>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="row">
            <div className="cont-listmedix row">
              <div className="listcxcx col-12">
                <table className="tablacxcx" border="1">
                  <thead>
                    <tr>
                      <th className="titucxc3">ORIGEN</th>
                      <th className="titucxc3">MENSAJE</th>
                    </tr>
                  </thead>

                  {this.state.mensajes.map((item, i) => {
                    return (
                      <tr
                        className={i % 2 === 0 ? "odd" : "odd"}
                        key={i}
                        id="tabla"
                      >
                        <td className="totalescxcx">{item.variable1}</td>
                        <td className="totalescxcx">{item.variable2}</td>
                        {/* <td className="totalescxcx">{item.variable5}</td>
                        <td className="totalescxcx">{item.variable6}</td> */}
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
          <Link to="/"><button className="boton">Salir</button></Link>
          
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(ChatAction);
