import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

//componente
import "./fechasTri.css";
import FeatherIcon from "feather-icons-react";
import Alert from "../../modal/alert";

class CxPAbonos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idcompra: "",
      beneficiario: "",
      xbeneficiario: "",
      beneficiario2: "",
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      monto1: "",
      xmonto1: "",
      tpago: "",
      fechaI1: "",
      fechaI2: "",
      fechaI3: "",
      grupo: "",
      fecha1: "",
      fecha2: "",
      fecha3: "",
      descripcion1: "",
      documento: "",
      monto2: "",
      mes: moment().format("MM"),
      anoEscolar: moment().format("YYYY"),
      registrarCOn: false,
      compras: [],
      fechasT: [],
      comprasDetalle: [],
      xsaldo: "",
      reporteOn: false,
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      proveedores: [],
      cxpproveedor: [],
    };
  }

  limpiar = () => {
    this.setState({
      idcompra: "",
      beneficiario: "",
      beneficiario2: "",
      xbeneficiario: "",
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      monto1: "",
      xmonto1: "",
      xsaldo: "",
      tpago: "",
      fechaI1: "",
      fechaI2: "",
      fechaI3: "",
      grupo: "",
      fecha1: "",
      fecha2: "",
      fecha3: "",
      descripcion1: "",
      documento: "",
      monto2: "",
      mes: moment().format("MM"),
      anoEscolar: moment().format("YYYY"),
      registrarCOn: false,
      reporteOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
    });
  };

  limpiar2 = () => {
    this.setState({
      descripcion1: "",
      subtotal: "",
      impuesto: "",
      monto2: "",
      documento: "",
      beneficiario2: "",
      xmonto1: "",
      xfecha: "",
      xsaldo: "",
      fecha2: "",
      idcompra: "",
      comprasDetalle: [],
      reporteOn: false,
    });
  };
  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "subtotal") {
      this.state.impuesto = (
        parseFloat(e.target.value) * 1.07 -
        parseFloat(e.target.value)
      ).toFixed(2);
      this.state.monto2 = (parseFloat(e.target.value) * 1.07).toFixed(2);
    }
    if (index === "impuesto") {
      let valor = 0;
      if (e.target.value !== "") valor = e.target.value;

      this.state.monto2 = (
        parseFloat(this.state.subtotal) + parseFloat(valor)
      ).toFixed(2);
    }
  }

  onChange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarCxP2(e.target.value);
  }
  onChange5(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarCxP(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultarDetalleC = (e) => {
    let data = new FormData();
    data.append("idbeneficiario", e);
    data.append("boton", "consultarDetalleCompras");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/registroCompra.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          comprasDetalle: xcompras,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          comprasDetalle: xcompras,
        });
      }
    });
  };

  consultarCxP = (e) => {
    let data = new FormData();
    data.append("codProve", e);
    data.append("fecha", this.state.anoEscolar + "-" + this.state.mes);
    data.append("boton", "consultarCompras");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/registroCompra.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          compras: xcompras,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          compras: xcompras,
        });
      }
    });
  };

  consultarCxP2 = (e) => {
    if (this.state.beneficiario.length > 0) {
      let data = new FormData();
      data.append("codProve", this.state.beneficiario);
      data.append("fecha", this.state.anoEscolar + "-" + e);
      data.append("boton", "consultarCompras");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/registroCompra.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          let xcompras = res.data.variable2;
          this.setState({
            compras: xcompras,
          });
        } else {
          let xcompras = res.data.variable2;
          this.setState({
            compras: xcompras,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Detalle de Compras.",
        cuerpoM: "Debe seleccionar un Proveedor, Verifique por favor.",
      });
    }
  };

  guardarCxP = () => {
    let data = new FormData();
    if (
      this.state.fechaI1.length > 0 &&
      this.state.fechaI2.length > 0 &&
      this.state.fechaI3.length > 0 &&
      this.state.grupo.length > 0 &&
      this.state.fecha1.length > 0 &&
      this.state.fecha2.length > 0 &&
      this.state.fecha3.length > 0
    ) {
      data.append("fechaI1", this.state.fechaI1);
      data.append("fechaI2", this.state.fechaI2);
      data.append("fechaI3", this.state.fechaI3);
      data.append("grupo", this.state.grupo);
      data.append("fecha1", this.state.fecha1);
      data.append("fecha2", this.state.fecha2);
      data.append("fecha3", this.state.fecha3);
      data.append("boton", "guardarFechaT");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cierre de Fechas por Trimestre.",
              cuerpoM: "Se Guardaron las fechas con exito.",
            });
            this.consultarFechaTri();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Detalle de Compras.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Cierre de Fechas por Trimestre.",
        cuerpoM:
          "Debe seleccionar un grupo e indicar todas las fechas de cierre.",
      });
    }
  };

  consultarFechaTri = () => {
    let data = new FormData();
    data.append("boton", "consultarFechaTri");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xfechas = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            fechasT: xfechas,
          });
        } else {
          this.setState({
            fechasT: [],
          });
        }
      });
  };

  eliminar = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("id", e);
      data.append("boton", "eliminarTri");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultarFechaTri();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cierre de Fechas por Trimestre.",
              cuerpoM: "Se Elimino correctamente la Fecha",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cierre de Fechas por Trimestre.",
              cuerpoM: "No se pudo Eliminar la Fecha, verifique Gracias...",
            });
          }
        });
    }
  };

  componentDidMount() {
    this.consultarFechaTri();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc3 col-12">
          <div className="titulo-cxc ">
            <p>
              MODULO PARA INDICAR INICIO DEL PERIODO ESCOLAR Y LA FECHA DE
              CIERRE DE LOS 3 TRIMESTRE.
            </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="form-group row">
            <div className="contenedorcedula1f col-12">
              <select
                id="grupo"
                name="grupo"
                value={this.state.grupo}
                onChange={this.onChange.bind(this)}
              >
                <option>-- Seleccionar Grupo --</option>
                <option value="1">MATERNALES</option>
                <option value="2">EDUC. INICAL</option>
                <option value="3">PRIMARIA</option>
                <option value="4">SECUNDARIA</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <div className="titulo-cxc11 col-3">
              <p> Fecha/Ini. Esc. 1er Tri.</p>
            </div>
            <div className="titulo-cxc11 col-3">
              <p> Fecha Cierre 1er. Trimestre.</p>
            </div>
            <div className="titulo-cxc11 col-3">
              <p> Fecha/Ini. Esc. 2do Tri.</p>
            </div>
            <div className="titulo-cxc11 col-3">
              <p> Fecha Cierre 2do. Trimestre.</p>
            </div>
          </div>

          <div className="form-group row">
            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fechaI1"
                id="fechaI1"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fechaI1}
              />
            </div>

            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fecha1"
                id="fecha1"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha1}
              />
            </div>
            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fechaI2"
                id="fechaI2"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fechaI2}
              />
            </div>
            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fecha2"
                id="fecha2"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha2}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="titulo-cxc11 col-3">
              <p> Fecha/Ini. Esc. 3er Tri.</p>
            </div>
            <div className="titulo-cxc11 col-3">
              <p> Fecha Cierre 3er Trimestre.</p>
            </div>
          </div>

          <div className="form-group row">
            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fechaI3"
                id="fechaI3"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fechaI3}
              />
            </div>
            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fecha3"
                id="fecha3"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha3}
              />
            </div>
          </div>

          <div className="row">
            <div className="contenedorcedula2 col-12">
              <button
                className={this.state.guardarOn ? "botonOf" : "boton"}
                onClick={this.state.guardarOn ? "" : this.guardarCxP}
              >
                Guardar
              </button>
              <button className="boton" onClick={this.props.asignacionClose}>
                Salir
              </button>
            </div>
          </div>
          <div className="row">
            <div className="groupevDO col-12">
              <table className="tabla2pc">
                <thead>
                  <tr>
                    <th className="tituevoT">Grupo.</th>
                    <th className="tituevo">Fecha/I. 1er Tri.</th>
                    <th className="tituevo">Fecha Cierre 1er. Tri.</th>
                    <th className="tituevo">Fecha/I. 2do Tri.</th>
                    <th className="tituevo">Fecha Cierre 2do. Tri.</th>
                    <th className="tituevo">Fecha/I. 3er Tri.</th>
                    <th className="tituevo">Fecha Cierre 3er. Tri.</th>
                    <span className="titulo-eliminar">
                      <span className="cerrarme3"></span>= Eliminar
                    </span>
                  </tr>
                </thead>
                <tbody className="cuerpo">
                  {this.state.fechasT.map((item, i) => {
                    return (
                      <tr
                        className={i % 2 === 0 ? "odd" : ""}
                        key={item.variable1}
                      >
                        <td className="itemtabla" key={item.variable1}>
                          {item.variable8}
                        </td>
                        <td className="itemtabla" key={item.variable1}>
                          {item.variable2}
                        </td>
                        <td className="itemtabla" key={item.variable1}>
                          {item.variable3}
                        </td>
                        <td className="itemtabla" key={item.variable1}>
                          {item.variable4}
                        </td>
                        <td className="itemtabla" key={item.variable1}>
                          {item.variable5}
                        </td>
                        <td className="itemtabla" key={item.variable1}>
                          {item.variable6}
                        </td>
                        <td className="itemtabla" key={item.variable1}>
                          {item.variable7}
                        </td>
                        <td width="20">
                          <span
                            className="cerrarme1"
                            onClick={this.eliminar.bind(this, item.variable1)}
                          ></span>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPAbonos);
