import React, { Component } from 'react';

//componentes
import HorarioPlani from './horarioPlani'

class HorarioP extends Component {

    render(){
        return(
            <div>
                <HorarioPlani />
            </div>
        );
    }

}
export default HorarioP;