import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
} from "../../accion/accion";
import { connect } from "react-redux";

//componentes
import "./dosificacionEva.css";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      horario: "",
      numeroH: "",
      ihorario: [],
      inumeroH: [],
      icursos: [],
      igradohorario: [],
      imaterias: [],
      idescripcionE: [],
      iprofesor: [],
      inombreDE: [],
      listaOn: false,
      numeroHOn: false,
      asociarGHOn: false,
      activar: "",
      idgrado: "",
      idevaluacion: "",
      idmateria: "",
      trimestre: "",
      nombreDE: "",
      idprofesor: "",
      porcentaje: "",
      fecha: moment().format("YYYY-MM-DD"),
      nsemana: "",
      maxporce: 0,
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      horario: "",
      numeroH: "",
      listaOn: false,
      numeroHOn: false,
      asociarGHOn: false,
      activar: "",
      idgrado: "",
      idevaluacion: "",
      idmateria: "",
      trimestre: "",
      nombreDE: "",
      idprofesor: "",
      ihorario: [],
      porcentaje: "",
      fecha: moment().format("YYYY-MM-DD"),
      nsemana: "",
      maxporce: 0,
    });
  };

  onChange(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "idprofesor") {
      //this.buscarCurso(e.target.value);
      this.setState({
        idgrado: "",
        idmateria: "",
        ihorario: [],
      });
    }
    if (index === "idgrado") {
      this.setState({
        idmateria: "",
        ihorario: [],
      });
      //this.buscarMaterias(e.target.value);
    }
    if (index === "idmateria") {
      if (this.state.idprofesor.length > 0) this.consultar(e.target.value);
      //this.buscarPlanE(e.target.value);
    }
    if (index === "idevaluacion") {
      //this.buscarEvaluacionesDosi(e.target.value);
    }
  }

  imprimir = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idprofesor.length > 0 &&
      this.state.idgrado.length > 0 &&
      this.state.idmateria.length > 0
    ) {
      let data = new FormData();
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idgrado);
      data.append("trimestre", this.state.trimestre);
      data.append("idprofesor", this.state.idprofesor);
      data.append("anoEscolar", this.props.usuario1.anioE);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteDosifiP.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asociar Nombre Evaluacion a Profesores.",
        cuerpoM: "Debe llenar todos los datos que se piden.",
      });
    }
  };

  imprimirF = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idprofesor.length > 0 &&
      this.state.idgrado.length > 0 &&
      this.state.idmateria.length > 0
    ) {
      let data = new FormData();
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idgrado);
      data.append("trimestre", this.state.trimestre);
      data.append("idprofesor", this.state.idprofesor);
      data.append("anoEscolar", this.props.usuario1.anioE);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteDosifiP2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asociar Nombre Evaluacion a Profesores.",
        cuerpoM: "Debe llenar todos los datos que se piden.",
      });
    }
  };

  guardar = () => {
    if (
      this.state.trimestre.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idgrado.length > 0 &&
      this.state.idmateria.length > 0
    ) {
      let data = new FormData();
      data.append("trimestre", this.state.trimestre);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idgrado);
      data.append("materia", this.state.idmateria);
      data.append("arreglo", JSON.stringify(this.state.ihorario));
      data.append("boton", "guardarDProfesor");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/dosificaciones.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultar(this.state.idmateria);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Nombre Evaluacion a Profesores.",
              cuerpoM: res.data.variable2,
              idevaluacion: "",
              nombreDE: "",
              porcentaje: "",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Nombre Evaluacion a Profesores.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asociar Nombre Evaluacion a Profesores.",
        cuerpoM: "Debe llenar todos los datos que se piden.",
      });
    }
  };

  eliminar = (e) => {
    let data = new FormData();

    data.append("iddosificacionP", e);
    data.append("boton", "eliminarDProfesores");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/dosificaciones.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asociar Nombre Evaluacion a Profesores.",
          cuerpoM: "Se Elimino correctamente el registro.",
        });
        this.consultar(this.state.idmateria);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asociar Nombre Evaluacion a Profesores.",
          cuerpoM: res.data.variable2,
        });
      }
    });
  };

  consultar = (e) => {
    let data = new FormData();
    data.append("idmateria", e);
    data.append("idgrado", this.state.idgrado);
    data.append("trimestre", this.state.trimestre);
    data.append("idprofesor", this.state.idprofesor);
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("boton", "buscarDProfesores");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xihorario = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            ihorario: xihorario,
          });
        } else {
          this.setState({
            ihorario: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarCurso = () => {
    let data = new FormData();
    //data.append("idprofesor", e);
    data.append("boton", "buscarCurso2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            icursos: xicursos,
          });
        } else {
          this.setState({
            icursos: [],
          });
          if (res.data.variable3.length > 0) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Notas.",
              cuerpoM: res.data.variable3,
            });
          }
        }
      });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  // buscarMaterias = (e) => {
  //   let data = new FormData();
  //   data.append("idprofesor", this.state.idprofesor);
  //   data.append("idgrado", e);
  //   data.append("boton", "buscarMateriasAsignadasDosifi");

  //   let config = {
  //     headers: {
  //       "Content-Type": "text/html",
  //     },
  //   };
  //   const xurl =
  //     "https://institutobilinguelasnaciones.com/backend/dosificaciones.php";
  //   axios
  //     .post(xurl, data, config)

  //     .then((res) => {
  //       let ximaterias = res.data.variable2;
  //       if (res.data.variable1) {
  //         this.setState({
  //           imaterias: ximaterias,
  //         });
  //       } else {
  //         this.setState({
  //           imaterias: [],
  //         });
  //       }
  //     });
  // };

  buscarEvaluacionesDosi = (e) => {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("trimestre", this.state.trimestre);
    data.append("idgrado", this.state.idgrado);
    data.append("idmateria", this.state.idmateria);
    data.append("idevaluacion", e);
    data.append("boton", "buscarEvaluacionesDosi");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            inombreDE: xidescripcionE,
            maxporce: res.data.variable3,
            porcentaje: res.data.variable3,
          });
        } else {
          this.setState({
            inombreDE: [],
            porcentaje: "",
          });
        }
      });
  };

  buscarPlanE = (e) => {
    let data = new FormData();
    data.append("trimestre", this.state.trimestre);
    data.append("idgrado", this.state.idgrado);
    data.append("idmateria", e);
    data.append("boton", "buscarPlanEDosi");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  buscarProfesor = () => {
    let form = new FormData();

    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  calcularEntradaFecha = (index, value) => {
    let ihorario_ = Array.from(this.state.ihorario);
    ihorario_[index].variable10 = value;
    this.setState({ ihorario: ihorario_ });
  };

  calcularEntradaSemana = (index, value) => {
    let ihorario_ = Array.from(this.state.ihorario);
    ihorario_[index].variable11 = value;
    this.setState({ ihorario: ihorario_ });
  };

  calcularEntradaPorce = (index, value) => {
    let ihorario_ = Array.from(this.state.ihorario);
    ihorario_[index].variable12 = value;
    this.setState({ ihorario: ihorario_ });
  };

  componentDidMount() {
    this.buscarProfesor();
    this.buscarCurso();
    this.buscarMaterias();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>Dosificación de Profesores. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          <div className="cont-datoscasos col-12">
            <div className="botonescasos row">
              <div className="contenedorcedula1f col-2">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Indique Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idprofesor"
                  name="idprofesor"
                  value={this.state.idprofesor}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Profesor --</option>
                  {this.state.iprofesor.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idgrado"
                  name="idgrado"
                  value={this.state.idgrado}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-Indique Grado-</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange.bind(this)}
                >
                  <option value="">-Indique Materia-</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className="contenedorcedula1f col-4">
                <select
                  id="idevaluacion"
                  name="idevaluacion"
                  value={this.state.idevaluacion}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-Tipo de Evaluacion-</option>
                  {this.state.idescripcionE.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-4">
                <select
                  id="nombreDE"
                  name="nombreDE"
                  value={this.state.nombreDE}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-Nombre Evaluacion-</option>
                  {this.state.inombreDE.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div> */}

              {/* </div> */}
              {/* </div> */}
              <div className="xcontenedorcedula1x col-12">
                {/* <div className="xcontenedorcedula1x col-2">
                  <input
                    className="form-control"
                    type="date"
                    name="fecha"
                    id="fecha"
                    autoComplete="off"
                    placeholder="Fecha:"
                    onChange={this.onChange.bind(this)}
                    value={this.state.fecha}
                  />
                </div>
                <div className="xcontenedorcedula1x col-2">
                  <input
                    className="form-control"
                    type="text"
                    name="porcentaje"
                    id="porcentaje"
                    autoComplete="off"
                    placeholder="Porcentaje:"
                    onChange={this.onChange.bind(this)}
                    value={this.state.porcentaje}
                  />
                </div>
                <div className="xcontenedorcedula1x col-2">
                  <input
                    className="form-control"
                    type="text"
                    name="nsemana"
                    id="nsemana"
                    autoComplete="off"
                    placeholder="No. Semana:"
                    onChange={this.onChange.bind(this)}
                    value={this.state.nsemana}
                  />
                </div> */}
              </div>

              {/* <div className="contenedorp row"> */}
              <div className="xcontenedorcedula1x col-12">
                {this.props.usuario1.nivel !== "11" && (
                  <button className="boton" onClick={this.guardar.bind(this)}>
                    Guardar
                  </button>
                )}
                {this.props.usuario1.nivel !== "11" && (
                  <button className="boton" onClick={this.imprimir.bind(this)}>
                    Imprimir
                  </button>
                )}
                {this.props.usuario1.nivel !== "11" && (
                  <button className="boton" onClick={this.imprimirF.bind(this)}>
                    Imprimir por Fecha
                  </button>
                )}
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar Datos
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>

              <div className="cont-listmedixDE row">
                <div className="listcxcx col-12">
                  <table className="tablacxcx" border="1">
                    <thead>
                      <tr>
                        {/* <th className="titucxc2">Grado</th> */}
                        <th className="titucxc2">Materia</th>
                        <th className="titucxc2">Tipo Evaluación</th>
                        <th className="titucxc2">Nombre Evaluación</th>
                        <th className="titucxc4">Trimestre</th>
                        <th className="titucxc2">Fecha Eva.</th>
                        <th className="titucxc4">No/Semana</th>
                        {/* <th className="titucxc4">Porcentaje.</th> */}
                        {this.props.usuario1.nivel !== "11" && (
                          <th className="titucxc4">
                            <span className="titulo-eliminarDE">
                              <span className="cerrarme3"></span>
                            </span>
                          </th>
                        )}
                      </tr>
                    </thead>

                    {this.state.ihorario.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          {/* <td className="totalescxcx1">{item.variable2}</td> */}
                          <td className="totalescxcx1">{item.variable4}</td>
                          <td className="totalescxcx1">{item.variable7}</td>
                          <td className="totalescxcx1">{item.variable8}</td>
                          <td className="totalescxcx1">{item.variable9}</td>
                          <td className="totalescxcx1">
                            <div className="contenedorcedula col-12">
                              <input
                                className="form-control"
                                type="date"
                                name={item.variable1}
                                id="fecha"
                                autoComplete="off"
                                step="any"
                                onChange={(e) =>
                                  this.calcularEntradaFecha(i, e.target.value)
                                }
                                value={item.variable10}
                              />
                            </div>
                          </td>
                          <td className="totalescxcx1">
                            <div className="contenedorcedula col-12">
                              <input
                                className="form-control"
                                type="text"
                                name={item.variable1}
                                id="porcentaje"
                                autoComplete="off"
                                step="any"
                                onChange={(e) =>
                                  this.calcularEntradaSemana(i, e.target.value)
                                }
                                value={item.variable11}
                              />
                            </div>
                          </td>
                          {/* <td className="totalescxcx1">
                            <div className="contenedorcedula col-12">
                              <input
                                className="form-control"
                                type="text"
                                name={item.variable1}
                                id="nsemana"
                                autoComplete="off"
                                step="any"
                                onChange={(e) =>
                                  this.calcularEntradaPorce(i, e.target.value)
                                }
                                value={item.variable12}
                              />
                            </div>
                          </td> */}
                          {this.props.usuario1.nivel !== "11" && (
                            <td className="xcerr">
                              {parseInt(item.variable2) > 0 && (
                                <span
                                  className="cerrarme1"
                                  onClick={this.eliminar.bind(
                                    this,
                                    item.variable2
                                  )}
                                ></span>
                              )}{" "}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConceptoAction);
