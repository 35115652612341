//dependencias
import React, { Component } from 'react';

//componentes
import DespachoPendiente from './despachoP';
class Index extends Component {
    render() {
        return(
            <div className="Inicio">
                <DespachoPendiente /> 
            </div>
        );
    }
}
export default Index;