//dependencias
import React, { Component } from 'react';

//componentes
import ConceptoAction from './conceptos'

class Concepto extends Component {

    render(){
        return(
            <div>
                <ConceptoAction />
            </div>
        );
    }

}
export default Concepto;