import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
//import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./informeAde.css";
import Alert from "../../modal/alert";

class InformeA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      trimestre: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      informeAde: "",
      ocultar: "",
      idactividad: "",
      valorTarea: "",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      iactividades: [],
      imaterias: [],
      icursos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      ianoEscolar: [],
      ialumnos: [],
      cedulaAlumno: "",
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      informeAde: "",
      trimestre: "",
      ocultar: "",
      idactividad: "",
      valorTarea: "",
      imaterias: [],
      ialumnos: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      cedulaAlumno: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idcurso") {
      this.buscarPlanE(e.target.value);
      this.buscarAlumnos(e.target.value);
    }
    if (index === "idmateria") this.consultarActividades(e.target.value);
  }

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosGD");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  eliminar = () => {
    if (this.state.idactividad.length > 0) {
      let data = new FormData();

      data.append("idactividad", this.state.idactividad);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/informeAdecuaciones.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Informes",
            cuerpoM: res.data.variable2,
          });
          let e = "";
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Informes",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Informes.",
        cuerpoM: "Debe existir un registro para poder eliminarlo.",
      });
    }
  };

  consultar(e) {
    if (e.length > 0) {
      this.setState({ idactividad: e });
      let data = new FormData();
      data.append("idactividad", e);
      data.append("boton", "buscarActividadesI");

      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/informeAdecuaciones.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            // idmateria: res.data.variable2,
            // idcurso: res.data.variable3,
            informeAde: res.data.variable4,
            botonguardarOn: true,
            botonmodiOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Informes",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  }

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    data.append("trimestre", this.state.trimestre);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/informeAdecuaciones.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  grabartexto() {
    if (
      this.state.informeAde.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.cedulaAlumno.length > 0 &&
      this.state.idmateria.length > 0 
      
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("informeAde", this.state.informeAde);
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "crearActividades");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/informeAdecuaciones.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Informes",
            cuerpoM: res.data.variable2,
          });
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Informes",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Informes",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  modificartexto() {
    if (
      this.state.informeAde.length > 0 &&
      this.state.idactividad.length > 0
    ) {
      let data = new FormData();
      data.append("informeAde", this.state.informeAde);
      data.append("idactividad", this.state.idactividad);
      data.append("boton", "modificar");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/informeAdecuaciones.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Informes",
            cuerpoM: res.data.variable2,
            botonguardarOn: false,
            botonmodiOn: false,
            informeAde: "",
          });
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Informes",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Informes",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Informes",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let iprofesor = res.data.variable1;
      iprofesor.map((item, i) => {
        this.setState({ idprofesor: item.variable1 });
        this.buscarCurso(item.variable1);
      });
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Informes.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Informes.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Informes.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              idmateria: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Informes.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Informes.",
      cuerpoM: "Realmente Quiere Eliminar el Informe...",
      idactividad: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    this.buscarAnioEscolar();
    this.buscarProfesor();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU PARA INFORMAR ADECUACIONES POR MATERIA. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="cedulaAlumno"
                      name="cedulaAlumno"
                      value={this.state.cedulaAlumno}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Alumno --</option>

                      {this.state.ialumnos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                      {/* <option value="">TODOS LOS ALUMNOS</option> */}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idmateria"
                      name="idmateria"
                      value={this.state.idmateria}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Materia --</option>
                      {this.state.imaterias.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-12">
                    <textarea
                      className="form-control"
                      name="informeAde"
                      id="informeAde"
                      placeholder="Descripcion del Informe a la Materia"
                      rows="3"
                      cols="135"
                      value={this.state.informeAde}
                      onChange={this.onChange.bind(this)}
                    />
                  </div>

                  <div className="contenedorcedula1 col-12">
                    <button
                      className={
                        !this.state.botonguardarOn ? "boton" : "botonOf"
                      }
                      onClick={
                        !this.state.botonguardarOn
                          ? this.grabartexto.bind(this)
                          : ""
                      }
                    >
                      Guardar
                    </button>
                    <button
                      className={this.state.botonmodiOn ? "boton" : "botonOf"}
                      onClick={
                        this.state.botonmodiOn
                          ? this.modificartexto.bind(this)
                          : ""
                      }
                    >
                      Modificar
                    </button>
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>

              <div className="cont-listmedix row">
                <div className="listcxcx col-12">
                  <table className="tablacxcx" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc4Ad">INFORME</th>
                        <th className="titucxc3xpe">
                          <span className="titulo-eliminar">
                            <span className="cerrarme5"></span>
                            <span className="cerrarme3"></span>
                          </span>
                        </th>
                      </tr>
                    </thead>

                    {this.state.iactividades.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td className="totalescxcx">
                            {item.variable2}
                          </td>
                          <td className="xcerr">
                            {/* {item.variable6 === "SI" && ( */}
                            <span
                              className="cerrarme5"
                              onClick={this.consultar.bind(
                                this,
                                item.variable1
                              )}
                            ></span>
                           
                            <span
                              className="cerrarme1"
                              onClick={this.eliminar1.bind(
                                this,
                                item.variable1
                              )}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(InformeA);
