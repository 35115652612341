import React, { Component } from 'react';

//componentes
import ExtensionM from './extensionM'

class Index extends Component {

    render(){
        return(
            <div>
                <ExtensionM />
            </div>
        );
    }

}
export default Index;