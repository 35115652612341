import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./correos.css";
import AdjuntarArchivo from "../adjuntarArchivos/adjuntararchivos";
import Alert from "../../modal/alert";

class Correos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ifechamedi: [],
      listaOn: false,
      mensajealerta: false,
      correoR: [],
      listaCorreo: [],
      flotadorMOn: false,
      correoaEnviar: [],
      correoI: "",
      flotadorCOn: false,
      asuntoOn: false,
      asunto: "",
      cuerpo: "",
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      correoI: "",
      flotadorMOn: false,
      correoaEnviar: [],
      listaCorreo: [],
      correoI: "",
      flotadorCOn: false,
      asuntoOn: false,
      asunto: "",
      cuerpo: "",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.value;
    //let curso = this.state.icurso[index].variable1
    let correoaEnviar = this.state.icurso[index].variable2;

    this.setState({
      correoaEnviar: correoaEnviar,
    });
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.checked]: !e.target.checked,
    });
    //console.log(e.target.checked)
    if (e.target.checked) this.state.listaCorreo.push(e.target.value);

    if (!e.target.checked) {
      let pos = this.state.listaCorreo.indexOf(e.target.value);
      this.state.listaCorreo.splice(pos, 1);
    }
    //console.log(this.state.listaCorreo)
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarCorreosPagina() {
    let data = new FormData();
    data.append("boton", "buscarCorreoPagina");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/correos.php";
    axios.post(xurl, data, config).then((res) => {
      let xcorreo = res.data.variable1;
      this.setState({
        correoR: xcorreo,
      });
    });
  }

  buscarCorreosxEnviar() {
    let data = new FormData();
    data.append("boton", "buscarCorreoxEnviar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/correos.php";
    axios.post(xurl, data, config).then((res) => {
      let xcorreo = res.data.variable1;
      this.setState({
        correoaEnviar: xcorreo,
      });
    });
  }

  agregarEnviarCorreo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("correo", e);
      data.append("boton", "guardarCorreosxEnviar");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/correos.php";
      axios.post(xurl, data, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.limpiar();
          this.buscarCorreosxEnviar();
          this.buscarCorreosPagina();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Envio de Correos.",
            cuerpoM: "Ocurrio un problema, Verifique por favor.",
          });
        }
      });
    }
  };

  eliminarCorreoxEnviar(e) {
    if (e.length > 0) {
      let data = new FormData();
      data.append("correo", e);
      data.append("boton", "eliminarCorreosxEnviar");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/correos.php";
      axios.post(xurl, data, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.buscarCorreosxEnviar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Envio de Correos.",
            cuerpoM: "Ocurrio un problema, Verifique por favor.",
          });
        }
      });
    }
  }
  adjuntarArchivo = () => {
    if (this.state.flotadorCOn === true) {
      this.setState({ flotadorCOn: false });
    } else {
      this.setState({ flotadorCOn: true });
    }
  };

  asunto = () => {
    if (this.state.asuntoOn === true) {
      this.setState({ asuntoOn: false });
    } else {
      this.setState({ asuntoOn: true });
    }
  };

  enviarCorreo = () => {
    let data = new FormData();
    data.append("asunto", this.state.asunto);
    data.append("cuerpo", this.state.cuerpo);
    data.append("listaCorreo", this.state.listaCorreo);
    data.append("boton", "enviarCorreo");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/correos.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log(res.data.variable4);
      //console.log(res.data.variable3);
      if (res.data.variable1) {
        this.limpiar();
        this.buscarCorreosxEnviar();
        this.buscarCorreosPagina();

        this.setState({
          mensajealerta: true,
          tituloM: "Menu Envio de Correos.",
          cuerpoM: "Los Correos se Enviaron Satisfactoriamente.",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Envio de Correos.",
          cuerpoM: "Ocurrio un problema, Verifique por favor.",
        });
      }
    });
  };

  agregarTodos = () => {
    let data = new FormData();
    data.append("boton", "agregarTodos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/correos.php";
    axios.post(xurl, data, config).then((res) => {
      this.buscarCorreosxEnviar();
    });
  };

  eliminarCorreoxEnviarT = () => {
    let form = new FormData();
    form.append("boton", "eliminarCorreosxEnviarExternos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/correos.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log(res.data.variable4);
      //console.log(res.data.variable3);
      if (res.data.variable1) {
        this.buscarCorreosxEnviar();
      } else {
        this.setState({
            mensajealerta: true,
            tituloM: "Menu Envio de Correos.",
            cuerpoM: "Ocurrio un problema, Verifique por favor.",
          });
      }
    });
  };

  componentDidMount() {
    //this.buscarCursos();
    this.buscarCorreosPagina();
    this.buscarCorreosxEnviar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CORREOS INTERNOS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.flotadorCOn === true && (
            <div className="flotadorc">
              <div className="flotadorc1">
                <AdjuntarArchivo adjuntarArchivo={this.adjuntarArchivo} />
              </div>
            </div>
          )}

          {this.state.asuntoOn === true && (
            <div className="flotadora">
              <div className="flotadora1">
                <div className="contenedor-titulo row">
                  <div className="titulo-pago col-12">
                    <p>
                      INGRESE UN ASUNTO E INFORMACION EN EL CUERPO SI ASI LO
                      DESEA.{" "}
                    </p>
                  </div>
                  <div className="contenedor-cedula row">
                    <div className="contenedorcedula1 col-4">
                      <input
                        className="form-control"
                        type="text"
                        name="asunto"
                        id="asunto"
                        autoComplete="off"
                        placeholder="Asunto del Mensaje."
                        maxLength="100"
                        onChange={this.onChange.bind(this)}
                        value={this.state.asunto.toUpperCase()}
                      />
                    </div>
                  </div>
                  <div className="contenedor-cedula row">
                    <div className="contenedorcedula1 col-12">
                      <textarea
                        className="form-control"
                        name="cuerpo"
                        id="cuerpo"
                        rows="7"
                        cols="100"
                        autoComplete="off"
                        placeholder="Cuerpo del Mensaje."
                        onChange={this.onChange.bind(this)}
                        defaultValue={this.state.cuerpo.toUpperCase()}
                      />
                    </div>
                  </div>
                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.asunto.bind(this)}>
                      salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="cont-tablacorreo row">
            <div className="tablacorreo1 col-11">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Listado de Correos Recibidos por la Pagina
                </span>
              </div>
              {/* <span className="titulo-casos">Datos del Pago</span> */}
              <div className="cont-listcorreo row">
                <div className="listcorreo col-12">
                  <table className="tablacorreo" border="1">
                    <tbody className="evocuerpo1">
                      {this.state.correoR.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.agregarEnviarCorreo.bind(
                                this,
                                item.variable2
                              )}
                            >
                              {item.variable2}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="contenedor-cedula row">
            <div className="cedulac col-12">
              <div col-6>
                <input
                  className="form-control"
                  type="text"
                  name="correoI"
                  id="correoI"
                  autoComplete="off"
                  placeholder="Indique un Correo que no este en el Listado"
                  maxLength="300"
                  onChange={this.onChange.bind(this)}
                  value={this.state.correoI.toUpperCase()}
                />
              </div>
              <div>
                <button
                  className="botonc"
                  onClick={this.agregarEnviarCorreo.bind(
                    this,
                    this.state.correoI
                  )}
                >
                  Agregar Correo
                </button>
                <button
                  className="botonc"
                  onClick={this.agregarTodos.bind(this, this.state.correoI)}
                >
                  Agregar Todos
                </button>
              </div>
            </div>
          </div>

          <div className="cont-tablacorreo2 row">
            <div className="tablacorreo2 col-12">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Correos a Enviar la Información.
                </span>
              </div>
              {/* <span className="titulo-casos">Datos del Pago</span> */}
              <div className="cont-listcorreo2 row">
                <div className="listcorreo2 col-12">
                  <table className="tablacorreo2x" border="1">
                    <tbody className="evocuerpo1">
                      {this.state.correoaEnviar.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            {item.variable2.length > 0 && (
                              <td className="renglon" width="10">
                                <input
                                  className=""
                                  type="checkbox"
                                  name={item.variable2}
                                  id={item.variable2}
                                  value={item.variable2}
                                  onChange={this.onChange4.bind(this)}
                                />
                              </td>
                            )}
                            {item.variable2.length > 0 && (
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.eliminarCorreoxEnviar.bind(
                                  this,
                                  item.variable2
                                )}
                              >
                                {item.variable2}
                              </td>
                            )}
                            {item.variable3.length > 0 && (
                              <td className="renglon" width="10">
                                <input
                                  className=""
                                  type="checkbox"
                                  name={item.variable3}
                                  id={item.variable3}
                                  value={item.variable3}
                                  onChange={this.onChange4.bind(this)}
                                />
                              </td>
                            )}
                            {item.variable3.length > 0 && (
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.eliminarCorreoxEnviar.bind(
                                  this,
                                  item.variable3
                                )}
                              >
                                {item.variable3}
                              </td>
                            )}
                            {item.variable4.length > 0 && (
                              <td className="renglon" width="10">
                                <input
                                  className=""
                                  type="checkbox"
                                  name={item.variable4}
                                  id={item.variable4}
                                  value={item.variable4}
                                  onChange={this.onChange4.bind(this)}
                                />
                              </td>
                            )}
                            {item.variable4.length > 0 && (
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.eliminarCorreoxEnviar.bind(
                                  this,
                                  item.variable4
                                )}
                              >
                                {item.variable4}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="contenedorcedula1 col-12">
            <button className="boton" onClick={this.enviarCorreo.bind(this)}>
              Enviar Correo
            </button>
            <button className="boton" onClick={this.asunto.bind(this)}>
              Asunto/Mensaje
            </button>
            <button className="boton" onClick={this.adjuntarArchivo.bind(this)}>
              Adjuntar Archivo
            </button>
            <button className="boton" onClick={this.eliminarCorreoxEnviarT.bind(this)}>
              Eliminar Todos los Correo
            </button>
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Correos);
