import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./proveedores.css";
import Alert from "../../modal/alert";
import FeatherIcon from "feather-icons-react";

class Proveedores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajealerta: false,
      idcliente: "",
      documento: "",
      nombre: "",
      direccion: "",
      ruc: "",
      telefono: "",
      flotadorCOn: false,
      irutas: [],
      clientes: [],
      guardarOn: false,
      cuentaContable: "",
      cuenta: [],
      dvp: "",
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      idcliente: "",
      documento: "",
      nombre: "",
      ruc: "",
      direccion: "",
      telefono: "",
      flotadorCOn: false,
      guardarOn: false,
      cuentaContable: "",
      dvp: "",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.value;
    let curso = this.state.icurso[index].variable1;
    //let concepto= this.state.icurso[index].variable2
    this.setState({
      curso: curso,
    });
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultarProveedores = () => {
    let data = new FormData();
    data.append("boton", "consultarProveedor");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            clientes: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  guardar = () => {
    //console.log(e);
    if (this.state.nombre.length > 0) {
      let data = new FormData();
      data.append("documento", this.state.documento);
      data.append("nombre", this.state.nombre);
      data.append("direccion", this.state.direccion);
      data.append("telefono", this.state.telefono);
      data.append("ruc", this.state.ruc);
      data.append("dvp", this.state.dvp);
      data.append("boton", "guardar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/proveedores.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          this.setState({
            loading: false,
          });
          if (res.data.variable1) {
            this.limpiar();
            this.consultarProveedores();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Proveedores.",
              cuerpoM: "El Proveedor se guardo correctamente",
            });
          } else {
            this.setState({
              guardarOn: true,
              mensajealerta: true,
              tituloM: "Menu Proveedores.",
              cuerpoM: "El Proveedor ya Existe, verifique por favor",
            });
          }
        });
    }
  };

  imprimirLExcel = () => {
    let data = new FormData();
    // data.append("mes", this.state.codigo3);
    // data.append("anio", this.state.codigo4);

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteProveedoresLExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "ProveedoresExcel.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  imprimirL = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteProveedoresL.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  // imprimirL = async () => {

  //   this.setState({
  //     loading: true,
  //   });

  //   let data = new FormData();
  //   // data.append("arregloProveedores", JSON.stringify(this.state.ialumnos));
  //   // data.append("boton", "aprobar");

  //   const xurl =
  //     "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteProveedoresL.php";

  //   const responseType="arraybuffer";
  //   let response = await axios.post(xurl, responseType, data);
  //   //console.log(response.data.variable2);
  //   if (response) {
  //     this.setState({loading: false, });
  //     let blob = new Blob([response.data], { type: "application/pdf" });
  //     var fileURL = URL.createObjectURL(blob);
  //     window.open(fileURL);
  //   } else {
  //     this.setState({
  //       loading: false,
  //     });
  //   }
  // }

  modificar = () => {
    //console.log(e);
    if (this.state.nombre.length > 0) {
      let data = new FormData();
      data.append("idcliente", this.state.idcliente);
      data.append("documento", this.state.documento);
      data.append("nombre", this.state.nombre);
      data.append("direccion", this.state.direccion);
      data.append("telefono", this.state.telefono);
      data.append("ruc", this.state.ruc);
      data.append("dvp", this.state.dvp);
      data.append("boton", "modificar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/proveedores.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.consultarProveedores();
            this.setState({
              // mensajealerta: true,
              tituloM: "Menu Proveedores.",
              cuerpoM: "El Proveedor se modifico con  exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Proveedores.",
              cuerpoM: "El Proveedor No se pudo Modificar.",
            });
          }
        });
    }
  };

  eliminar = () => {
    //console.log(e);
    if (this.state.nombre.length > 0) {
      let data = new FormData();
      data.append("idcliente", this.state.idcliente);
      data.append("boton", "eliminar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/proveedores.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.setState({
              clientes: [],
              // mensajealerta: true,
              tituloM: "Menu Proveedores.",
              cuerpoM: "El Proveedor se Elimino con exito.",
            });
            this.consultarProveedores();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Proveedores.",
              cuerpoM: "El Proveedor No se pudo Eliminar.",
            });
          }
        });
    }
  };

  consultaIndividual = (e) => {
    let data = new FormData();
    data.append("idcliente", e);
    data.append("boton", "consultaProveedorIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar();
          this.setState({
            guardarOn: true,
            idcliente: res.data.variable2,
            documento: res.data.variable3,
            nombre: res.data.variable4,
            direccion: res.data.variable5,
            telefono: res.data.variable6,
            ruc: res.data.variable7,
            dvp: res.data.variable8,
          });
        } else {
          this.setState({
            guardarOn: false,
            mensajealerta: true,
            tituloM: "Menu Proveedores.",
            cuerpoM: "El Proveedor no se encontro, verifique por favor",
          });
        }
        //console.log(res);
      });
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  componentDidMount() {
    //this.cargarCuentas();
    this.consultarProveedores();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE PROVEEDORES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="text"
                name="documento"
                id="documento"
                autoComplete="off"
                placeholder="Identificacion."
                maxLength="50"
                onChange={this.onChange.bind(this)}
                value={this.state.documento.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-5">
              <input
                className="form-control"
                type="text"
                name="nombre"
                id="nombre"
                autoComplete="off"
                placeholder="Nombre del Proveedor."
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.nombre.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="text"
                name="ruc"
                id="ruc"
                autoComplete="off"
                placeholder="R.U.C. del Proveedor."
                maxLength="100"
                onChange={this.onChange.bind(this)}
                value={this.state.ruc.toUpperCase()}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula1 col-5">
              <input
                className="form-control"
                type="text"
                name="direccion"
                id="direccion"
                autoComplete="off"
                placeholder="Detalle."
                maxLength="300"
                onChange={this.onChange.bind(this)}
                value={this.state.direccion.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="telefono"
                id="telefono"
                autoComplete="off"
                placeholder="Telefonos Contacto."
                maxLength="100"
                onChange={this.onChange.bind(this)}
                value={this.state.telefono.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="dvp"
                id="dvp"
                autoComplete="off"
                placeholder="D.V."
                maxLength="100"
                onChange={this.onChange.bind(this)}
                value={this.state.dvp.toUpperCase()}
              />
            </div>
            {/* <div className="contenedorcedula1 col-5">
              <select
                id="cuentaContable"
                name="cuentaContable"
                value={this.state.cuentaContable}
                onChange={this.onChange.bind(this)}
              >
                <option>Cuenta Contable</option>

                {this.state.cuenta.map((item, i) => {
                  return (
                    <option key={i} value={item.variable2}>
                      {item.variable6 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div> */}
            <div className="contenedorcedula3 col-12">
              <button
                className={this.state.guardarOn ? "botonOf" : "boton"}
                onClick={this.guardar.bind(this)}
                disabled={this.state.guardarOn}
              >
                Guardar
              </button>
              <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                onClick={this.modificar.bind(this)}
                disabled={!this.state.guardarOn}
              >
                Modificar
              </button>
              <button
                className={this.state.clientes.length > 0 ? "boton" : "botonOf"}
                onClick={this.imprimirL.bind(this)}
              >
                Imprimir Listado PDF
              </button>
              <button
                className={this.state.clientes.length > 0 ? "boton" : "botonOf"}
                onClick={this.imprimirLExcel.bind(this)}
              >
                Imprimir Listado Excel
              </button>
              <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                onClick={this.eliminar.bind(this)}
                disabled={!this.state.guardarOn}
              >
                Eliminar
              </button>
              <button className="boton" onClick={this.limpiar}>
                Limpiar
              </button>
              {/* <button className={this.state.guardarOn?"boton":"botonOf"} onClick={this.Upload.bind(this)} disabled={!this.state.guardarOn}>Eliminar</button> */}
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="ititucxc3">IDENTIFICACION</th>
                    <th className="ititucxc1">NOMBRE</th>
                    <th className="ititucxc1">DETALLE</th>
                    <th className="ititucxc2">R.U.C.</th>
                    <th className="ititucxc1">D.V..</th>
                    {/* <th className="titucxc3"><FeatherIcon icon="search" size="20px" stroke="#999" /></th> */}
                  </tr>
                </thead>
                {this.state.clientes.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd1"}
                      key={item.variable1}
                      id="tabla"
                      onClick={this.consultaIndividual.bind(
                        this,
                        item.variable1
                      )}
                    >
                      <td className="rtotalescxc" width="10">
                        {item.variable2}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable3}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable6}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Proveedores);
