import React from "react";
import { Row, Col, Container } from "reactstrap";
import styled from "styled-components";

export default function FuncionName(props) {
  return (
    <Row key={props.index}>
      <Col>
        <ChatCard
          selected={props.selected}
          onClick={() => {
            props.MarcarComoLeido(props.chat.variable);
            props.onClick({
              id: props.chat.variable,
              profesor: props.chat.variable3,
              alumno: props.chat.variable4,
              nombre_alumno: props.chat.variable5,
              materia: props.chat.variable6,
            });
          }}
        >
          <Row>
            <Col xs={2}>
              <LetterName>{props.chat.variable5.charAt(0)}</LetterName>
            </Col>
            <Col>
              <NombreAlumno>{props.chat.variable5.toLowerCase()}</NombreAlumno>
              <NombreMateria>
                Materia: <span>{props.chat.variable7.toLowerCase()}</span>
              </NombreMateria>
            </Col>
          </Row>
          {props.chat.variable2 === "0" && <ReadIndicator />}
        </ChatCard>
      </Col>
    </Row>
  );
}

const LetterName = styled.div`
  color: #fff;
  text-transform: uppercase;
  background-color: #1d94e9;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 3px 0;
`;

const ReadIndicator = styled.span`
  background-color: #3761f9;
  width: 8px;
  height: 8px;
  right: 25px;
  top: 45%;
  border-radius: 50%;
  position: absolute;
  float: right;
`;

const ChatCard = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#eee" : "#fff")};
  border: solid 1px #eee;
  border-radius: 3px;
  padding: 5px 10px;
  transition: all 0.3s ease;
  :hover {
    background-color: #eee;
  }
`;
const NombreAlumno = styled.p`
  font-size: 14px;
  text-transform: capitalize;
  margin: 0;
`;
const NombreMateria = styled.p`
  margin: 0;
  font-size: 12px;
  text-transform: capitalize;
  span {
    font-style: italic;
  }
`;
