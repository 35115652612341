import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import moment from "moment";


//componentes
import './canje.css';
import Alert from '../../modal/alert'

class CanjeAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo:'',
            concepto:'',
            iconcepto:[],
            listaOn:false,
            verplanillaOn:false,
            tbusqueda:'',
            identificacionp1:'',
            cedula:'',
            acudiente:'',
            alumno:'',
            montoCanje:'',
            abonoCanje:'',
            fecha:'',
            
            
        }
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    guardar=()=>{
        if (this.state.concepto.length>0 && this.state.montoCanje.length>0) {
            let data = new FormData();
            data.append('cedulaAlumno', this.state.cedula );  
            data.append('concepto', this.state.concepto );  
            data.append('montoCanje', this.state.montoCanje );  
            data.append('fecha', this.state.fecha );  
            data.append('boton', 'guardar');

            let config = {
                headers: {
                'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/canjes.php';
            axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data.variable3);
                //console.log(res.data.variable2);
                if (res.data.variable1) {
                    this.buscarCanjes();
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Crear Canjes.',
                        cuerpoM: 'Se guardo correctamente el canje.',
                    });
                    
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Crear Canje.',
                        cuerpoM: 'Ocurrio un problema para guardar el canje.',
                    });
                }
            })
        }
    }

    limpiar=()=>{
        this.setState({
            codigo:'',
            concepto:'',
            listaOn:false,
            verplanillaOn:false,
            tbusqueda:'',
            identificacionp1:'',
            cedula:'',
            acudiente:'',
            alumno:'',
            montoCanje:'',
            abonoCanje:'',
            fecha:'',

        })

    }
    
    eliminar=(e)=>{
        console.log(this.state.abonoCanje)
        if (parseFloat(e)>0){
           
                let data = new FormData();

                data.append('codigo', e);
                data.append('boton', 'Eliminar');
        
                let config = {
                    headers: {
                    'Content-Type': 'text/html'}
                };
                const xurl = 'https://institutobilinguelasnaciones.com/backend/canjes.php';
                axios.post(xurl, data, config)
                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Crear Conceptos.',
                            cuerpoM: 'Se Elimino correctamente el concepto.',
                        })
                        this.buscarCanjes();
                    }else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Crear Conceptos.',
                            cuerpoM: 'Ocurrio un error, No Se Pudo Eliminar el concepto.',
                        })
                    } 
                })
           
        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Crear Canjes.',
                cuerpoM: 'Debe existir un registro para poder eliminar el registro.',
            })
        } 
    }
    
    buscarCanjes=()=>{

        let data = new FormData();
        data.append('cedulaAlumno', this.state.cedula);
        data.append('boton', 'buscarCanjes');

        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/backend/canjes.php';
        axios.post(xurl, data, config)

        .then(res => {
            let xiconcepto = res.data.variable2
        if (res.data.variable1) {
                this.setState({
                    iconcepto:xiconcepto,
                    abonoCanje: res.data.variable3,
                });
                
            } else {
                this.setState({
                    iconcepto:[],
                });

            }
        })
    }

    
    handleClose = () => {
        this.setState({ mensajealerta: false })
      }
    

    componentDidMount(){
        
    }

    cerrarLista=()=>{
        //console.log(this.state.listaOn)
        if (this.state.listaOn){
            this.setState({
                listaOn:false,
            })
        }
        if (!this.state.listaOn) {
            this.setState({
                listaOn:true,
            })
        }
    }
    buscarPlanilla=()=>{
        if (this.state.tbusqueda ==='1' || this.state.tbusqueda ==='2' ) {
            if (this.state.tbusqueda ==='1') {
                this.buscarPlanillaIndividual()
            }else{
                this.buscarPorNombres()
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Busqueda de Alumno',
                cuerpoM: 'Debe seleccionar un tipo de busqueda, gracias...',
            })
        }

    }

    buscarPlanillaIndividual=()=>{
        
        if (this.state.identificacionp1.length>0) {
            
            let data = new FormData();
            data.append('cedulaAlumno', this.state.identificacionp1); 
            data.append('boton', 'buscarPlanillaIndividual');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/maternal.php';
            axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data)
                if (res.data.variable136) {
                   if (res.data.variable129==='MADRE'){
                        this.setState({
                            alumno: res.data.variable1+' '+res.data.variable2,
                            cedula:res.data.variable4,
                            acudiente:res.data.variable13+' '+res.data.variable14,
                            verplanillaOn:true,
                            
                        });
                        this.buscarCanjes();
                        //this.buscarCXC(res.data.variable4)
                   }else{
                    this.setState({
                        alumno: res.data.variable1+' '+res.data.variable2,
                        cedula:res.data.variable4,
                        acudiente:res.data.variable25+' '+res.data.variable26,
                        verplanillaOn:true,
                    });
                    this.buscarCanjes();
                    //this.buscarCXC(res.data.variable4)
                   }
                }else{
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Busqueda de Canjes',
                        cuerpoM: 'La cédula que intenta consultar no existe, gracias...',
                    })
                }
            })
        }
    }

    buscarPorNombres = () =>{
        let data = new FormData();
        data.append('nombres', this.state.identificacionp1);  
        data.append('boton', 'buscarNombres');

        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/backend/maternal.php';
        axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data.variable3);
                //console.log(res.data.variable2);
                if (res.data.variable1) {
                    let xnalumno = res.data.variable2
                    this.setState({
                        nalumno:xnalumno,
                        listaOn:true,
                    });
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Busqueda de Planillas',
                        cuerpoM: 'No existe ningún Estudiante con ese Nombre, gracias...',
                    });

                }
            })

    }

    consulta=(e)=>{
        
        if (e.length>0) {
            
            let data = new FormData();
            data.append('idAlumno', e); 
            data.append('boton', 'buscarPlanillaIndividual1');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/maternal.php';
            axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data)
                if (res.data.variable136) {
                    if (res.data.variable129==='MADRE'){
                        this.setState({
                            alumno: res.data.variable1+' '+res.data.variable2,
                            cedula:res.data.variable4,
                            acudiente:res.data.variable13+' '+res.data.variable14,
                            verplanillaOn:true,
                        });
                        this.buscarCanjes();
                        //this.buscarCXC(res.data.variable4)

                   }else{
                        this.setState({
                            alumno: res.data.variable1+' '+res.data.variable2,
                            cedula:res.data.variable4,
                            acudiente:res.data.variable25+' '+res.data.variable26,
                            verplanillaOn:true,
                        });
                        this.buscarCanjes();
                        //this.buscarCXC(res.data.variable4)
                   }
                }else{
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Busqueda de Planillas',
                        cuerpoM: 'La cédula que intenta consultar no existe, gracias...',
                    })
                }
            })
        }
    }


    render(){
        return(

            <div className="principal-pago row">
                <div className="contenedor-cxc col-12">
                    <div className="titulo-cxc ">
                        <p>MENU DE CANJES. </p>
                    </div>
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    }

                    {!this .state.verplanillaOn &&
                        <div className="contenedorp row">
                            <div className="titulo-planillai col-12">
                                <p>SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS CORRESPONDIENTA A SU SELECCIÓN. </p>
                            </div>
                            <div className="row">
                                <div className="fechas col-12">                                    
                                    <select id="tbusqueda" name="tbusqueda" value={this.state.tbusqueda} onChange={this.onChange.bind(this)}>
                                        <option>-- Buscar por --</option>
                                        <option  value="1">CEDULA/PASAPORTE</option>
                                        <option  value="2">NOMBRE ESTUDIANTE</option>
                                    </select>
                                </div>
                            </div>    
                            <div className="contenedorcedula1 row">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="identificacionp1"
                                    id="identificacionp1"
                                    autoComplete="off"
                                    placeholder={this.state.tbusqueda === '1' ? "Identificación Alumno." : "Nombre Alumno."}
                                    maxLength="300"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.identificacionp1.toUpperCase()}
                                />  
                            </div>                               
                            <div className="xcontenedorcedula1 col-12">
                                <button className="boton" onClick={this.buscarPlanilla.bind(this)}>Consultar</button>
                                <Link to="/"><button className="boton">Salir</button></Link>
                            </div>
                    
                            {this.state.listaOn &&
                                <div className="cont-tablamedi row">
                                    <div className="tablamedi1 col-12">
                                        <div className="cont-titulomedi col-12">
                                            <span className="titulocasos">Listado de estudiantes</span>
                                            <span className="titulo-cerrarcasos" onClick={this.cerrarLista.bind(this)}>X</span>
                                        </div>
                                        {/* <span className="titulo-casos">Datos del Pago</span> */}
                                        <div className="cont-listmedi row">
                                            <div className="listmedi col-12">
                                                <table className="tablame" border="1">
                                                    <tbody className="evocuerpo1">
                                                        {this.state.nalumno.map((item, i)=>{
                                                                return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1} id="tabla"><td className="renglon" width="10" onClick={this.consulta.bind(this,item.variable1)}>{item.variable2}</td></tr>                                
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>    
                                        </div>
                                    </div>  
                                </div>
                            }
                        </div>
                    }




                    {this .state.verplanillaOn &&
                        <div className="contenedorp row">
                            <div className="titulo-reprecxc row ">
                                <p>ACUDIENTE: {this.state.acudiente}</p>
                                <div className="titulo-reprecxc ">
                                    <p>ESTUDIANTE: {this.state.alumno}</p>
                                </div>
                            </div>
                            
                            <div className="titulo-reprecxc row">
                                <div className="contenedorcedula1x col-4">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="concepto"
                                        id="concepto"
                                        autoComplete="off"
                                        placeholder='Introduzca un Concepto'
                                        maxLength="150"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.concepto.toUpperCase()}
                                    />  
                                </div>                               
                                <div className="montocanje col-4">
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="montoCanje"
                                        id="montoCanje"
                                        autoComplete="off"
                                        placeholder='Introduzca el moto del Canje'
                                        maxLength="150"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.montoCanje.toUpperCase()}
                                    />  
                                </div>
                                <div className="contenedor-cedula col-4">
                                    {/* <label className="labelcedulac">Fecha Transacción.</label>    */}
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="fecha"
                                            id="fecha"
                                            // readOnly="readonly"
                                            autoComplete="off"
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.fecha}
                                        />  
                                </div>                        
                            </div>
                            <div className="xcontenedorcedula1x col-12">
                                <button className="boton" onClick={this.guardar.bind(this)}>Agregar</button>
                                <Link to="/"><button className="boton">Salir</button></Link>
                            </div>
                    
                        </div>
                    }
                    {this .state.verplanillaOn &&
                    <div className="cont-listmedix row">
                        <div className="listcxcx col-12">
                            <table className="tablacxcx" border="1">
                                <thead>
                                    <tr>
                                        <th className="titucxc2">CONCEPTO</th>
                                        <th className="titucxc3x"><span className="titulo-eliminar"><span className='cerrarme3'></span></span></th>
                                    </tr>
                                </thead>

                                {this.state.iconcepto.map((item, i)=>{
                                    return<tr className={i%2 === 0 ? "odd" : "odd"}  key={item.variable1} id="tabla"><td className="totalescxcx">{item.variable2}</td>
                                        <td className="xcerr"><span className='cerrarme1' onClick={this.eliminar.bind(this,item.variable1)}></span> </td> 
                                    </tr>
                                })}
                            </table>
                        </div>    
                    </div>
                    }
                </div>
            </div>


        );
    }
}
export default CanjeAction; 