import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//componentes
import "./horarioPlani.css";
import Alert from "../../modal/alert";

class PermisosAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      scurso: null,
      mensajealerta: false,
      codigo1: "",
      codigo2: "",
      descripcion: "",
      salonCurso: "",
      guardarOn: false,
      icursos: [],
      ipermisos: [],
      igrados: [],
      imaterias: [],
      iprofesor: [],
      iprofesor2: [],
      ianoEscolar: [],
      cantAlumnos: "",
      horario: "",
      idgrado: "",
      tipoConsulta: "",
      trimestre: "",
      modificarOn: false,
      lunes: "",
      martes: "",
      miercoles: "",
      jueves: "",
      viernes: "",
      anoEscolar: "",
      idprofesor: "",
      idprofesor2: "",
      idcurso: "",
      idmateria: "",
      codigos: "",
      lunesCheck: "",
      martesCheck: "",
      miercolesCheck: "",
      juevesCheck: "",
      viernesCheck: "",
      lunesH: "",
      martesH: "",
      miercolesH: "",
      juevesH: "",
      viernesH: "",
      ihorarioLu: [],
      ihorarioMa: [],
      ihorarioMi: [],
      ihorarioJu: [],
      ihorarioVi: [],
      copiarHOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      botonOn: true,
      mensajealerta: false,
      codigo1: "",
      codigo2: "",
      descripcion: "",
      salonCurso: "",
      guardarOn: false,
      cantAlumnos: "",
      horario: "",
      idgrado: "",
      tipoConsulta: "",
      trimestre: "",
      modificarOn: false,
      lunes: "",
      martes: "",
      miercoles: "",
      jueves: "",
      viernes: "",
      anoEscolar: "",
      idprofesor: "",
      idprofesor2: "",
      idcurso: "",
      idmateria: "",
      codigos: "",
      lunesCheck: "",
      martesCheck: "",
      miercolesCheck: "",
      juevesCheck: "",
      viernesCheck: "",
      lunesH: "",
      martesH: "",
      miercolesH: "",
      juevesH: "",
      viernesH: "",
      ihorarioLu: [],
      ihorarioMa: [],
      ihorarioMi: [],
      ihorarioJu: [],
      ihorarioVi: [],
      ipermisos: [],
      copiarHOn: false,
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange1(e) {
    let index = e.target.getAttribute("id");

    if (e.target.checked) {
      if (index === "lunesCheck") {
        this.setState({ lunesCheck: "SI" });
      }
      if (index === "martesCheck") {
        this.setState({ martesCheck: "SI" });
      }
      if (index === "miercolesCheck") {
        this.setState({ miercolesCheck: "SI" });
      }
      if (index === "juevesCheck") {
        this.setState({ juevesCheck: "SI" });
      }
      if (index === "viernesCheck") {
        this.setState({ viernesCheck: "SI" });
      }
    } else {
      if (index === "lunesCheck") {
        this.setState({ lunesCheck: "NO", lunesH: "" });
      }
      if (index === "martesCheck") {
        this.setState({ martesCheck: "NO", martesH: "" });
      }
      if (index === "miercolesCheck") {
        this.setState({ miercolesCheck: "NO", miercolesH: "" });
      }
      if (index === "juevesCheck") {
        this.setState({ juevesCheck: "NO", juevesH: "" });
      }
      if (index === "viernesCheck") {
        this.setState({ viernesCheck: "NO", viernesH: "" });
      }
    }
  }

  onChange2(e) {
    if (e.target.checked) {
      //console.log("grado " + this.state.idcurso);
      this.setState({
        [e.target.name]: true,
      });
    } else {
      this.setState({
        [e.target.name]: false,
      });
    }
  }

  onChange3(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar"){
      this.setState({ idcurso: "",idprofesor:'', idmateria:'', ipermisos:[] });
       this.buscarProfesor();
    }
    if (index === "idprofesor") {
      this.buscarCurso(e.target.value);
      this.buscarPermisos2(e.target.value);
      this.buscarPlanE(e.target.value);
      this.setState({ codigos: "" });
    }
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    if (index === "idmateria") this.buscarCodigo(e.target.value);
    if (
      index === "lunesH" ||
      index === "martesH" ||
      index === "miercolesH" ||
      index === "juevesH" ||
      index === "viernesH"
    )
      this.verificarHora(index, e.target.value);
  }

  verificarHora = (e, f) => {
    let form = new FormData();
    form.append("idprofesor", this.state.idprofesor);
    form.append("dia", e);
    form.append("bloque", f);
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("boton", "verificarHora");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/horarioPlanificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({});
      } else {
        if (e === "lunesH") this.setState({ lunesH: "" });
        if (e === "martesH") this.setState({ martesH: "" });
        if (e === "miercolesH") this.setState({ miercolesH: "" });
        if (e === "juevesH") this.setState({ juevesH: "" });
        if (e === "viernesH") this.setState({ viernesH: "" });

        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Horarios.",
          cuerpoM: res.data.variable2,
        });
      }
      //this.buscarCurso(item.variable1);
    });
  };

  buscarCodigo = (e) => {
    let form = new FormData();
    form.append("codigo", e);
    form.append("boton", "buscarHorario");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/horarioPlanificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xcodigos = res.data.variable1;
      if (xcodigos !== "")
        this.setState({
          codigos: xcodigos,
          lunes: xcodigos + "1",
          martes: xcodigos + "2",
          miercoles: xcodigos + "3",
          jueves: xcodigos + "4",
          viernes: xcodigos + "5",
        });
      //this.buscarCurso(item.variable1);
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;

      this.setState({
        iprofesor: xiprofesor,
        iprofesor2: xiprofesor,
      });
      //this.buscarCurso(item.variable1);
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    // if (this.state.idprofesor.length > 0) {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("idgrado", e);
    data.append("idprofesor", this.state.idprofesor);
    data.append("boton", "buscarPlanE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable8;
        let xilunes = res.data.variable3;
        let ximartes = res.data.variable4;
        let ximiercoles = res.data.variable5;
        let xijueves = res.data.variable6;
        let xiviernes = res.data.variable7;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
            idmateria: "",
            ihorarioLu: xilunes,
            ihorarioMa: ximartes,
            ihorarioMi: ximiercoles,
            ihorarioJu: xijueves,
            ihorarioVi: xiviernes,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
    // } else {
    //   this.setState({
    //     mensajealerta: true,
    //     tituloM: "Menu Asignar Notas.",
    //     cuerpoM: "Debe Seleccionar un Profesor, gracias.",
    //   });
    // }
  };

  imprimirH = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      // this.state.idcurso.length > 0 &&
      this.state.idprofesor.length > 0
    ) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteHorario.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Horario.",
        cuerpoM:
          "Debe Seleccionar un Profesor y seleccionar todos los datos que se piden, gracias.",
      });
    }
  };

  imprimirHG = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idprofesor.length > 0
    ) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteHorarioGrado.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Horario.",
        cuerpoM:
          "Debe Seleccionar un Profesor, Grado y todos los datos que se piden, gracias.",
      });
    }
  };

  procesarCopiaH = () => {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idprofesor2", this.state.idprofesor2);
    data.append("idgrado", this.state.idcurso);
    data.append("anoEscolar", this.state.anoEscolar);
   
    data.append("boton", "copiarHorario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/horarioPlanificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Horario Planificación",
            cuerpoM: "La copia se realizo de forma Correcta",
            copiarHOn:false,
            idprofesor2:'',

          });
        } else {
          //console.log(res.data.variable2);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Horario Planificación",
            cuerpoM: "Hubo un Error No se pudo Generar la Copia.",
          });
        }
      });
  };


  guardar = () => {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    data.append("idmateria", this.state.idmateria);
    data.append("lunes", this.state.lunes);
    data.append("martes", this.state.martes);
    data.append("miercoles", this.state.miercoles);
    data.append("jueves", this.state.jueves);
    data.append("viernes", this.state.viernes);
    data.append("horario", this.state.horario);
    data.append("lunesH", this.state.lunesH);
    data.append("martesH", this.state.martesH);
    data.append("miercolesH", this.state.miercolesH);
    data.append("juevesH", this.state.juevesH);
    data.append("viernesH", this.state.viernesH);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "guardar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/horarioPlanificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarPermisos();
          this.setState({
            lunes: "",
            martes: "",
            miercoles: "",
            jueves: "",
            viernes: "",
            idmateria: "",
            codigos: "",
            lunesCheck: "",
            martesCheck: "",
            miercolesCheck: "",
            juevesCheck: "",
            viernesCheck: "",
            lunesH: "",
            martesH: "",
            miercolesH: "",
            juevesH: "",
            viernesH: "",
            ihorarioLu: [],
            ihorarioMa: [],
            ihorarioMi: [],
            ihorarioJu: [],
            ihorarioVi: [],
            ipermisos: [],
            mensajealerta: true,
            tituloM: "Menu Horario Planificación",
            cuerpoM: "Se Agrego correctamente",
          });
          this.buscarPlanE(this.state.idcurso);
        } else {
          //console.log(res.data.variable2);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Horario Planificación",
            cuerpoM: "No se pudo agregar o ya existe, verifique Gracias...",
          });
        }
      });
  };

  eliminar = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idpermiso", e);
      data.append("boton", "eliminar");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/horarioPlanificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarPermisos();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Horario Planificación",
              cuerpoM: "Se Elimino correctamente.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Horario Planificación",
              cuerpoM: "No se pudo Eliminar, verifique Gracias...",
            });
          }
        });
    }
  };

  buscarPermisos2 = (e) => {
    let data = new FormData();
    data.append("idprofesor", e);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "consultaPermisos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/horarioPlanificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            ipermisos: res.data.variable2,
          });
        }
      });
  };

  buscarPermisos = () => {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "consultaPermisos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/horarioPlanificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            ipermisos: res.data.variable2,
          });
        }
      });
  };

  buscarGrado = () => {
    let data = new FormData();
    data.append("boton", "consultaGrado");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/curriculum.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            igrados: res.data.variable2,
          });
        }
      });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
  copiarH = () => {
    if (this.state.copiarHOn) {
      this.setState({ copiarHOn: false });
    } else {
      this.setState({ copiarHOn: true });
    }
  };

  componentDidMount() {
    this.buscarAnioEscolar();
  }

  render() {
    //console.log(this.state.oferta)
    return (
      <div className="cont-casos row">
        <div className="cont-casos-formulario col-12">
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.copiarHOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <div className="cont-datoscasos col-12">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idprofesor2"
                      name="idprofesor2"
                      value={this.state.idprofesor2}
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="">--- Seleccione un Profesor ---</option>
                      {this.state.iprofesor2.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="cont-titulocasos col-12">
                   
                      <button
                        className="botoncasos2"
                        onClick={this.procesarCopiaH.bind(this)}
                      >
                        Procesar
                      </button>
                      <button
                        className="botoncasos2"
                        onClick={this.copiarH.bind(this)}
                      >
                        Salir
                      </button>
                   
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="cont-titulocasos col-12">
              <span className="titulocasos">
                Crear Horarios de Planificacion Profesor
              </span>
            </div>
            <div className="cont-datoscasos col-12">
              <div className="botonescasos row">
                <div className="contenedorcedula1f col-2">
                  <select
                    id="anoEscolar"
                    name="anoEscolar"
                    value={this.state.anoEscolar}
                    onChange={this.onChange3.bind(this)}
                  >
                    <option>-- Año Escolar --</option>
                    {this.state.ianoEscolar.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1f col-3">
                  <select
                    id="idprofesor"
                    name="idprofesor"
                    value={this.state.idprofesor}
                    onChange={this.onChange3.bind(this)}
                  >
                    <option value="">--- Seleccione un Profesor ---</option>
                    {this.state.iprofesor.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1f col-2">
                  <select
                    id="idcurso"
                    name="idcurso"
                    value={this.state.idcurso}
                    onChange={this.onChange3.bind(this)}
                  >
                    <option>-- Seleccionar Grado --</option>
                    {this.state.icursos.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1f col-3">
                  <select
                    id="idmateria"
                    name="idmateria"
                    value={this.state.idmateria}
                    onChange={this.onChange3.bind(this)}
                  >
                    <option>-- Materia --</option>
                    {this.state.imaterias.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1x col-2">
                  {/* <label className="opcion">
                    CODIGOS. */}
                  <input
                    className="form-control"
                    type="text"
                    name="codigos"
                    id="codigos"
                    autoComplete="off"
                    placeholder="Codigos"
                    readOnly="readonly"
                    //onChange={this.onChange.bind(this)}
                    value={this.state.codigos.toUpperCase()}
                  />
                  {/* </label> */}
                </div>

                <div className="cont-datoscasos col-12">
                  <div className="botonescasosxx row">
                    <div className="cont-opcioncas col-1">
                      <input
                        className="horariochk"
                        type="checkbox"
                        name="lunesCheck"
                        id="lunesCheck"
                        value="SI"
                        disabled={
                          this.state.codigos.length > 0 ? "" : "disabled"
                        }
                        checked={this.state.lunesCheck === "SI"}
                        onChange={this.onChange1.bind(this)}
                      />
                    </div>
                    <div className="contenedorcedula1x col-1">
                      <label className="opcionxx">
                        LUNES.
                        <input
                          className="form-control"
                          type="text"
                          name="lunes"
                          id="lunes"
                          autoComplete="off"
                          placeholder="Codigo"
                          readOnly="readonly"
                          onChange={this.onChange.bind(this)}
                          value={this.state.lunes.toUpperCase()}
                        />
                      </label>
                    </div>

                    <div className="cont-opcioncas col-1">
                      <input
                        className="horariochk"
                        type="checkbox"
                        name="martesCheck"
                        id="martesCheck"
                        value="SI"
                        disabled={
                          this.state.codigos.length > 0 ? "" : "disabled"
                        }
                        checked={this.state.martesCheck === "SI"}
                        onChange={this.onChange1.bind(this)}
                      />
                    </div>
                    <div className="contenedorcedula1x col-1">
                      <label className="opcionxx">
                        MARTES.
                        <input
                          className="form-control"
                          type="text"
                          name="martes"
                          id="martes"
                          autoComplete="off"
                          placeholder="Codigo"
                          readOnly="readonly"
                          onChange={this.onChange.bind(this)}
                          value={this.state.martes.toUpperCase()}
                        />
                      </label>
                    </div>
                    <div className="cont-opcioncas col-1">
                      <input
                        className="horariochk"
                        type="checkbox"
                        name="miercolesCheck"
                        id="miercolesCheck"
                        value="SI"
                        disabled={
                          this.state.codigos.length > 0 ? "" : "disabled"
                        }
                        checked={this.state.miercolesCheck === "SI"}
                        onChange={this.onChange1.bind(this)}
                      />
                    </div>
                    <div className="contenedorcedula1x col-1">
                      <label className="opcionxx">
                        MIERCOLES.
                        <input
                          className="form-control"
                          type="text"
                          name="miercoles"
                          id="miercoles"
                          autoComplete="off"
                          placeholder="Codigo"
                          readOnly="readonly"
                          onChange={this.onChange.bind(this)}
                          value={this.state.miercoles.toUpperCase()}
                        />
                      </label>
                    </div>
                    <div className="cont-opcioncas col-1">
                      <input
                        className="horariochk"
                        type="checkbox"
                        name="juevesCheck"
                        id="juevesCheck"
                        value="SI"
                        disabled={
                          this.state.codigos.length > 0 ? "" : "disabled"
                        }
                        checked={this.state.juevesCheck === "SI"}
                        onChange={this.onChange1.bind(this)}
                      />
                    </div>
                    <div className="contenedorcedula1x col-1">
                      <label className="opcionxx">
                        JUEVES.
                        <input
                          className="form-control"
                          type="text"
                          name="jueves"
                          id="jueves"
                          autoComplete="off"
                          placeholder="Codigo"
                          readOnly="readonly"
                          onChange={this.onChange.bind(this)}
                          value={this.state.jueves.toUpperCase()}
                        />
                      </label>
                    </div>
                    <div className="cont-opcioncas col-1">
                      <input
                        className="horariochk"
                        type="checkbox"
                        name="viernesCheck"
                        id="viernesCheck"
                        value="SI"
                        disabled={
                          this.state.codigos.length > 0 ? "" : "disabled"
                        }
                        checked={this.state.viernesCheck === "SI"}
                        onChange={this.onChange1.bind(this)}
                      />
                    </div>
                    <div className="contenedorcedula1x col-1">
                      <label className="opcionxx">
                        VIERNES.
                        <input
                          className="form-control"
                          type="text"
                          name="viernes"
                          id="viernes"
                          autoComplete="off"
                          placeholder="Codigo"
                          readOnly="readonly"
                          onChange={this.onChange.bind(this)}
                          value={this.state.viernes.toUpperCase()}
                        />
                      </label>
                    </div>
                    <div className="cont-datoscasosxx col-12">
                      <div className="contenedorcedula1fh col-3">
                        <select
                          id="lunesH"
                          name="lunesH"
                          disabled={
                            this.state.lunesCheck === "SI" ? "" : "disabled"
                          }
                          value={this.state.lunesH}
                          onChange={this.onChange3.bind(this)}
                        >
                          <option>--Horario--</option>
                          {this.state.ihorarioLu.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="contenedorcedula1fh col-3">
                        <select
                          id="martesH"
                          name="martesH"
                          value={this.state.martesH}
                          disabled={
                            this.state.martesCheck === "SI" ? "" : "disabled"
                          }
                          onChange={this.onChange3.bind(this)}
                        >
                          <option>--Horario--</option>
                          {this.state.ihorarioMa.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="contenedorcedula1fh col-3">
                        <select
                          id="miercolesH"
                          name="miercolesH"
                          value={this.state.miercolesH}
                          disabled={
                            this.state.miercolesCheck === "SI" ? "" : "disabled"
                          }
                          onChange={this.onChange3.bind(this)}
                        >
                          <option>--Horario--</option>
                          {this.state.ihorarioMi.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="contenedorcedula1fh col-3">
                        <select
                          id="juevesH"
                          name="juevesH"
                          value={this.state.juevesH}
                          disabled={
                            this.state.juevesCheck === "SI" ? "" : "disabled"
                          }
                          onChange={this.onChange3.bind(this)}
                        >
                          <option>--Horario--</option>
                          {this.state.ihorarioJu.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="contenedorcedula1fh col-3">
                        <select
                          id="viernesH"
                          name="viernesH"
                          value={this.state.viernesH}
                          disabled={
                            this.state.viernesCheck === "SI" ? "" : "disabled"
                          }
                          onChange={this.onChange3.bind(this)}
                        >
                          <option>--Horario--</option>
                          {this.state.ihorarioVi.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="cont-datoscasos col-12">
                        <div className="accionescasos col-12">
                          <button
                            className={
                              this.state.guardarOn
                                ? "botoncasosOff"
                                : "botoncasos"
                            }
                            disabled={this.state.guardarOn}
                            onClick={this.guardar.bind(this)}
                          >
                            Agregar
                          </button>
                          <button
                            className={
                              this.state.guardarOn
                                ? "botoncasosOff"
                                : "botoncasos"
                            }
                            disabled={this.state.guardarOn}
                            onClick={this.imprimirH.bind(this)}
                          >
                            Imprimir Horario Profesor
                          </button>
                          <button
                            className={
                              this.state.guardarOn
                                ? "botoncasosOff"
                                : "botoncasos"
                            }
                            disabled={this.state.guardarOn}
                            onClick={this.imprimirHG.bind(this)}
                          >
                            Imprimir Horario Grado
                          </button>
                          <button
                            className="botoncasos"
                            onClick={this.limpiar.bind(this)}
                          >
                            Limpiar
                          </button>
                          {this.state.idcurso.length > 0 && (
                            <button
                              className="botoncasos"
                              onClick={this.copiarH.bind(this)}
                            >
                              Copia de Horario
                            </button>
                          )}
                          <Link to="/">
                            <button className="botoncasos">Salir</button>
                          </Link>
                        </div>

                        <div className="cont-listmedi row">
                          <div className="listmedi col-12">
                            <table className="tablame" border="1">
                              <thead>
                                <tr>
                                  <th className="titucxc1">GRADO</th>
                                  <th className="titucxc1">MATERIA</th>
                                  <th className="titucxc1">DIAS</th>
                                  <th className="titucxc3">
                                    <span className="cerrarme3"></span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="evocuerpo1">
                                {this.state.ipermisos.map((item, i) => {
                                  return (
                                    <tr
                                      className={i % 2 === 0 ? "odd" : ""}
                                      key={item.variable1}
                                      id="tabla"
                                    >
                                      <td width="20">{item.variable2}</td>
                                      <td width="20">{item.variable3}</td>
                                      <td width="20">{item.variable4}</td>
                                      <td width="20">
                                        <span
                                          className="cerrarme1"
                                          onClick={this.eliminar.bind(
                                            this,
                                            item.variable1
                                          )}
                                        ></span>{" "}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(PermisosAction);
