import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

//componentes
import "./alumnosMaestro.css";
import Alert from "../../modal/alert";

class EvaluacionAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcion: "",
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: "",
      ianoEscolar: [],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      anoEscolar: "",
      idcurso: "",
      idprofesor: "",
      descripcion: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      ievaluaciones: [],
      ialumnos:[],
      listaOn: false,
      modificarOn: false,
    });
  };

  limpiar2(e) {
    this.setState({
      idmateria: "",
      idcurso: "",
      idprofesor: "",
      idplanE: "",
      cedulaAlumno: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar" && this.state.idprofesor.length > 0)
      this.limpiar2();
    if (index === "idprofesor") this.buscarCurso(e.target.value);
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.imaterias.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : 0;
    //console.log(index)
    let xcantidadE = this.state.imaterias[index].variable3;
    let xidmateria = this.state.imaterias[index].variable4;
    this.setState({
      [e.target.name]: e.target.value,
      cantidadE: xcantidadE,
      idmateria: xidmateria,
    });
  };

  consultar() {
  let data = new FormData();
  data.append("idcurso", this.state.idcurso);
  data.append("idprofesor", this.state.idprofesor);
  data.append("anoEscolar", this.state.anoEscolar);
  data.append("boton", "buscarAlumnos");

  let config = {
    headers: {
      "Content-Type": "text/html",
    },
  };
  const xurl =
    "https://institutobilinguelasnaciones.com/backend/alumnoMaestro.php";
  axios
    .post(xurl, data, config)

    .then((res) => {
      let xialumno = res.data.variable2;
      if (res.data.variable1) {
        this.setState({
          ialumnos: xialumno,
        });
      } else {
        this.setState({
          ialumnos: [],
        });
      }
    });
  }

  modificar = () => {
    if (this.state.idmateria.length > 0 && this.state.idcurso.length > 0) {
      let data = new FormData();
      data.append("idmaestroE", this.state.idplanEvaluacion);
      data.append("cantidad", this.state.cantidadE);
      data.append("descripcion", this.state.descripcion);
      data.append("boton", "modificarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  guardar = () => {
    if (this.state.idmateria.length > 0 && this.state.idplanE.length > 0 && this.state.idcurso.length > 0 && this.state.cedulaAlumno.length > 0 && this.state.descripcion.length > 0 && this.state.nota.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idplanE", this.state.idplanE);
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idcurso);
      data.append("idtituloNota", this.state.descripcion);
      data.append("nota", this.state.nota);
      data.append("fecha", this.state.fechaE);
      data.append("cantidadE", this.state.cantidadE);
      data.append("anoEscolar", this.state.anoEscolar);

      data.append("boton", "guardarEvaluacion");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.limpiar2();
            this.buscarEvaluaciones(this.state.cedulaAlumno);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Notas.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Notas.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "eliminarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias.",
            cuerpoM: res.data.variable2,
          });
          //this.limpiar();
          this.buscarEvaluaciones(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la MAteria Asignada.",
      });
    }
  };

  buscarEvaluaciones = (e) => {
    if (
      e.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaAlumno", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idplanE", this.state.idplanE);
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idcurso);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "buscarEvaluaciones");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xievaluaciones = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              ievaluaciones: xievaluaciones,
            });
          } else {
            this.setState({
              ievaluaciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor y un Grado, gracias.",
      });
    }
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          let xialumnos = res.data.variable3;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              ialumnos: xialumnos,
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  buscarSalones = () => {
    let data = new FormData();
    data.append("boton", "buscarSalones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;

        this.setState({
          icursos: xicursos,
        });
      });
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  
  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  
  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    //this.buscarMaterias();
    this.buscarDE();
    this.buscarAnioEscolar();
    this.buscarProfesor();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CONSULTA ALUMNOS POR GRADO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="anoEscolar"
                  name="anoEscolar"
                  value={this.state.anoEscolar}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idprofesor"
                  name="idprofesor"
                  value={this.state.idprofesor}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Profesor --</option>
                  {this.state.iprofesor.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.consultar.bind(this)}
              >
                Consultar
              </button>
              <button className="boton" onClick={this.limpiar.bind(this)}>
                Limpiar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc3">ALUMNO</th>
                    <th className="titucxc3">F/NACIMIENTO</th>
                    <th className="titucxc1">TIPO DE ALERGIA</th>
                  </tr>
                </thead>

                {this.state.ialumnos.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="totalescxcx">{item.variable4}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(EvaluacionAction);
