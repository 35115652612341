import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./modificarRefe.css";
import FeatherIcon from "feather-icons-react";
import CxPAbonos from "../cxpAbonos/cxpAbonos";
import Alert from "../../modal/alert";
import { element } from "prop-types";

class Recepcion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcedulaP: "",
      verplanillaOn: false,
      fecha: moment().format("YYYY-MM-DD"),
      anioEscolar: moment().format("YYYY"),
      ruc: "",
      cliente: "",
      gradoAlumno: "",
      tbusqueda: "",
      tbusqueda1: "",
      tbusqueda3: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      lista2On: false,
      mensajealerta: false,
      externoOn: false,
      datosPagoOn: false,
      clientes: [],
      facturasC: [],
      facturasCP: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      nocre: [],
      cxpproveedor: [],
      cuenta: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      cantidadx: "",
      idproducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      otroProducto: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      nalumno: [],
      alumno: "",
      cedulaAlumno: "",
      identificacionp1: "",
      montoOtros: "",
      cxcPendientesOn: "",
      cxcRe: [],
      transferencia: false,
      deposito: false,
      tarjetaVisa: false,
      tarjetaClave: false,
      efectivo: false,
      cheque: false,
      otrosPago: false,
      cxc: false,
      ncredito: false,
      canjes: false,
      mcanjes: "",
      mtransferencia: "",
      mdeposito: "",
      mtarjetaVisa: "",
      mtarjetaClave: "",
      mefectivo: "",
      mcheque: "",
      mOtrosPago: "",
      mncredito: "",
      botonTransOn: false,
      botonDepoOn: false,
      botontarjetaVOn: false,
      botontarjetaCOn: false,
      botonEfecOn: false,
      botonChequeOn: false,
      botonOtrosPagoOn: false,
      botonNCOn: false,
      botonCANOn: false,
      botonCXCOn: false,
      montoAbono: 0,
      pagoncOn: false,
      consulPagoOn: false,
      aceptarOn: false,
      nombrex: "",
      oppagoP: "",
      madre: "",
      padre: "",
      concepto2: "",
      fechaT: "",
      cantipago: 0,

      bancoTrans: "",
      refetransferencia: "",
      bancodeposito: "",
      refedeposito: "",
      bancotarjetaVisa: "",
      refetarjetaVisa: "",
      bancotarjetaClave: "",
      refetarjetaClave: "",
      bancoefectivo: "",
      refeefectivo: "",
      bancocheque: "",
      cuentaContable: "",
      refecheque: "",
      refeOtrosPago: "",
      numeroFactu: "",
      botonNoAprobadosOn: false,
      numeReci: "",
      cumpleOn: false,
      opgasto: "E",
      fechaFactura: moment().format("YYYY-MM-DD"),
      facturaMdatos: "",
      montoDeudor: 0,
    };
  }

  limpiar = () => {
    this.setState({
      opcedulaP: "",
      verplanillaOn: false,
      fecha: moment().format("YYYY-MM-DD"),
      anioEscolar: moment().format("YYYY"),
      ruc: "",
      cliente: "",
      gradoAlumno: "",
      tbusqueda: "",
      tbusqueda1: "",
      tbusqueda3: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      lista2On: false,
      mensajealerta: false,
      clientes: [],
      facturasC: [],
      facturasCP: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      cxpproveedor: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      externoOn: false,
      datosPagoOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      cantidadx: "",
      idproducto: "",
      otroProducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      nalumno: [],
      alumno: "",
      cedulaAlumno: "",
      identificacionp1: "",
      montoOtros: "",
      cxcPendientesOn: "",
      cxcRe: [],
      transferencia: false,
      deposito: false,
      tarjetaVisa: false,
      tarjetaClave: false,
      efectivo: false,
      cheque: false,
      otrosPago: false,
      cxc: false,
      ncredito: false,
      canjes: false,
      mtransferencia: "",
      mdeposito: "",
      mtarjetaVisa: "",
      mtarjetaClave: "",
      mefectivo: "",
      mcheque: "",
      mOtrosPago: "",
      mncredito: "",
      botonTransOn: false,
      botonDepoOn: false,
      botontarjetaVOn: false,
      botontarjetaCOn: false,
      botonEfecOn: false,
      botonChequeOn: false,
      botonOtrosPagoOn: false,
      botonNCOn: false,
      botonCANOn: false,
      botonCXCOn: false,
      montoAbono: 0,
      pagoncOn: false,
      consulPagoOn: false,
      aceptarOn: false,
      nombrex: "",
      oppagoP: "",
      madre: "",
      padre: "",
      concepto2: "",
      fechaT: "",
      cantipago: 0,

      bancoTrans: "",
      refetransferencia: "",
      bancodeposito: "",
      refedeposito: "",
      bancotarjetaVisa: "",
      refetarjetaVisa: "",
      bancotarjetaClave: "",
      refetarjetaClave: "",
      bancoefectivo: "",
      refeefectivo: "",
      bancocheque: "",
      cuentaContable: "",
      refecheque: "",
      refeOtrosPago: "",
      numeroFactu: "",
      botonNoAprobadosOn: false,
      numeReci: "",
      cumpleOn: false,
      opgasto: "E",
      fechaFactura: moment().format("YYYY-MM-DD"),
      facturaMdatos: "",
      montoDeudor: 0,
    });
  };

  limpiar2 = () => {
    this.setState({
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      otroProducto: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      transferencia: false,
      deposito: false,
      tarjetaVisa: false,
      tarjetaClave: false,
      efectivo: false,
      cheque: false,
      otrosPago: false,
      cxc: false,
      ncredito: false,
      canjes: false,
      mtransferencia: "",
      mdeposito: "",
      mtarjetaVisa: "",
      mtarjetaClave: "",
      mefectivo: "",
      mcheque: "",
      mOtrosPago: "",
      mncredito: "",
      bancoTrans: "",
      refetransferencia: "",
      bancodeposito: "",
      refedeposito: "",
      bancotarjetaVisa: "",
      refetarjetaVisa: "",
      bancotarjetaClave: "",
      refetarjetaClave: "",
      bancoefectivo: "",
      refeefectivo: "",
      bancocheque: "",
      cuentaContable: "",
      refecheque: "",
      refeOtrosPago: "",
      numeroFactu: "",
      oppagoP: "",
      concepto2: "",
      fechaT: "",
      botonTransOn: false,
      botonDepoOn: false,
      botontarjetaVOn: false,
      botontarjetaCOn: false,
      botonEfecOn: false,
      botonChequeOn: false,
      botonOtrosPagoOn: false,
      botonNCOn: false,
      botonCANOn: false,
      botonCXCOn: false,
      montoAbono: 0,
      botonNoAprobadosOn: false,
      numeReci: "",
      //cumpleOn: false,
      opgasto: "E",
    });
  };

  limpiar3 = () => {
    this.setState({
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      otroProducto: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      transferencia: false,
      deposito: false,
      tarjetaVisa: false,
      tarjetaClave: false,
      efectivo: false,
      cheque: false,
      otrosPago: false,
      cxc: false,
      ncredito: false,
      canjes: false,
      mtransferencia: "",
      mdeposito: "",
      mtarjetaVisa: "",
      mtarjetaClave: "",
      mefectivo: "",
      mcheque: "",
      mOtrosPago: "",
      mncredito: "",
      bancoTrans: "",
      refetransferencia: "",
      bancodeposito: "",
      refedeposito: "",
      bancotarjetaVisa: "",
      refetarjetaVisa: "",
      bancotarjetaClave: "",
      refetarjetaClave: "",
      bancoefectivo: "",
      refeefectivo: "",
      bancocheque: "",
      cuentaContable: "",
      refecheque: "",
      refeOtrosPago: "",
      oppagoP: "",
      concepto2: "",
      fechaT: "",
      botonTransOn: false,
      botonDepoOn: false,
      botontarjetaVOn: false,
      botontarjetaCOn: false,
      botonEfecOn: false,
      botonChequeOn: false,
      botonOtrosPagoOn: false,
      botonNCOn: false,
      botonCANOn: false,
      botonCXCOn: false,
      montoAbono: 0,
      opgasto: "E",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  cerrarLista3 = () => {
    //console.log(this.state.listaOn)
    if (this.state.lista2On) {
      this.setState({
        lista2On: false,
      });
    }
    if (!this.state.lista2On) {
      this.setState({
        lista2On: true,
      });
    }
  };

  cerrarDatosPago = () => {
    //console.log(this.state.listaOn)
    if (this.state.datosPagoOn) {
      this.setState({
        datosPagoOn: false,
        transferencia: false,
        deposito: false,
        tarjetaVisa: false,
        tarjetaVisa: false,
        efectivo: false,
        cheque: false,
        otrosPago: false,
        ncredito: false,
        canjes: false,
        cxc: false,
        botonTransOn: false,
        botonDepoOn: false,
        botontarjetaVOn: false,
        botontarjetaCOn: false,
        botonEfecOn: false,
        botonChequeOn: false,
        botonOtrosPagoOn: false,
        botonNCOn: false,
        botonCANOn: false,
        botonCXCOn: false,
      });
    }
    if (!this.state.datosPagoOn) {
      this.setState({
        datosPagoOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    let nomb = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (nomb === "oppagoP" && e.target.value === "MADRE")
      this.setState({ nombreper: this.state.madre });
    if (nomb === "oppagoP" && e.target.value === "PADRE")
      this.setState({ nombreper: this.state.padre });
    if (nomb === "oppagoP" && e.target.value === "OTRO")
      this.setState({ nombreper: "" });

    if (nomb === "bancoTrans" && e.target.value === "3")
      this.buscarReferencia(nomb);
    if (nomb === "bancocheque" && e.target.value === "3")
      this.buscarReferencia(nomb);
    if (nomb === "bancoefectivo" && e.target.value === "3")
      this.buscarReferencia(nomb);
    if (nomb === "bancotarjetaClave" && e.target.value === "3")
      this.buscarReferencia(nomb);
    if (nomb === "bancotarjetaVisa" && e.target.value === "3")
      this.buscarReferencia(nomb);
    if (nomb === "bancodeposito" && e.target.value === "3")
      this.buscarReferencia(nomb);
  }

  onChange2(e) {
    if (this.state.oppago === "INTERNOS") {
      let index = e.target.value;
      let ceduA = this.state.clientes[index].variable2;
      this.setState({ cedulaAlumno: ceduA });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarClientesI(e.target.value);
    //this.consultarFactura(e.target.value)
    //console.log(e.target.value)
  }

  // onChange2(e) {
  //   let index = e.target.getAttribute("id");
  //   console.log(e.target.value);
  //   console.log(index);

  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // }

  onChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      loading: true,
      [e.target.name]: e.target.value,
    });
    this.consultarClientes("I");
    this.setState({ loading: false, verplanillaOn: true });
  }
  onChange6(e) {
    //console.log(e.target.value)
    this.setState({
      // loading: true,
      [e.target.name]: e.target.value,
      externoOn: true,
    });
  }

  onChange4 = (e) => {
    // let element = e.target;
    // if (element.checked) {
    // console.log(e.target.value);
    // console.log(e.target.name);
    let data = new FormData();
    data.append("idfacturaRe", e);
    data.append("boton", "guardarPendiente");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura();
          //this.limpiar2();
        } else {
          this.setState({});
        }
      });
    //}
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  onChange5(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (this.state.botonNoAprobadosOn === false) {
      if (e.target.checked) {
        if (this.state.cantipago <= "2") {
          if (e.target.name == "transferencia") {
            this.state.cantipago += 1;
            this.setState({
              botonTransOn: !this.state.botonTransOn,
            });
          }
          if (e.target.name == "deposito") {
            this.state.cantipago += 1;
            this.setState({
              botonDepoOn: !this.state.botonDepoOn,
            });
          }
          if (e.target.name == "tarjetaVisa") {
            this.state.cantipago += 1;
            this.setState({
              botontarjetaVOn: !this.state.botontarjetaVOn,
            });
          }
          if (e.target.name == "tarjetaClave") {
            this.state.cantipago += 1;
            this.setState({
              botontarjetaCOn: !this.state.botontarjetaCOn,
            });
          }
          if (e.target.name == "efectivo") {
            this.state.cantipago += 1;
            this.setState({
              botonEfecOn: !this.state.botonEfecOn,
            });
          }
          if (e.target.name == "cheque") {
            this.state.cantipago += 1;
            this.setState({
              botonChequeOn: !this.state.botonChequeOn,
            });
          }
          if (e.target.name == "otrosPago") {
            this.state.cantipago += 1;
            this.setState({
              botonOtrosPagoOn: !this.state.botonOtrosPagoOn,
            });
          }
          if (e.target.name == "ncredito") {
            this.setState({
              botonNCOn: !this.state.botonNCOn,
            });
          }
          if (e.target.name == "canjes") {
            this.setState({
              botonCANOn: !this.state.botonCANOn,
            });
          }
          if (e.target.name == "cxc") {
            this.setState({
              botonCXCOn: !this.state.botonCXCOn,
            });
          }
          this.setState({
            [e.target.name]: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Pago de Recibo.",
            cuerpoM:
              "Ha exedido el Maximo permitido de formas de pago que son 3",
          });
        }
      } else {
        if (e.target.name == "transferencia") {
          this.state.cantipago -= 1;
          this.setState({
            botonTransOn: !this.state.botonTransOn,
            transferencia: false,
          });
        }
        if (e.target.name == "deposito") {
          this.state.cantipago -= 1;
          this.setState({
            botonDepoOn: !this.state.botonDepoOn,
            deposito: false,
          });
        }
        if (e.target.name == "tarjetaVisa") {
          this.state.cantipago -= 1;
          this.setState({
            botontarjetaVOn: !this.state.botontarjetaVOn,
            tarjetaVisa: false,
          });
        }
        if (e.target.name == "tarjetaClave") {
          this.state.cantipago -= 1;
          this.setState({
            botontarjetaCOn: !this.state.botontarjetaCOn,
            tarjetaVisa: false,
          });
        }
        if (e.target.name == "efectivo") {
          this.state.cantipago -= 1;
          this.setState({
            botonEfecOn: !this.state.botonEfecOn,
            efectivo: false,
          });
        }
        if (e.target.name == "cheque") {
          this.state.cantipago -= 1;
          this.setState({
            botonChequeOn: !this.state.botonChequeOn,
            cheque: false,
          });
        }
        if (e.target.name == "otrosPago") {
          this.state.cantipago -= 1;
          this.setState({
            botonOtrosPagoOn: !this.state.botonOtrosPagoOn,
            cuentaContable: "",
            mOtrosPago: "",
            refeOtrosPago: "",
            opgasto: "E",
            otrosPago: false,
          });
        }
        if (e.target.name == "ncredito") {
          this.setState({
            botonNCOn: !this.state.botonNCOn,
            ncredito: false,
          });
        }
        if (e.target.name == "canjes") {
          this.setState({
            botonCANOn: !this.state.botonCANOn,
            canjes: false,
          });
        }
        if (e.target.name == "cxc") {
          this.setState({
            botonCXCOn: !this.state.botonCXCOn,
            cxc: false,
          });
        }
        this.setState({
          [e.target.name]: false,
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Procesar Recibo No Aprobados.",
        cuerpoM:
          "Solo puede modificar Referencia, Bancos, Comentario, Fecha Aprobada y Quien realizo la Transferencia.",
      });
    }
    //console.log(this.state.cantipago);
  }

  externoCli = () => {
    if (this.state.externoOn) {
      this.setState({ externoOn: false });
      this.limpiar();
    } else {
      this.setState({ externoOn: true });
    }
  };

  buscarReferencia = async (e) => {
    //console.log(e)
    let data = new FormData();
    data.append("boton", "controlNumeroBG");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (e === "bancoTrans") {
          this.setState({ refetransferencia: res.data.variable1 });
        }
        if (e === "bancocheque") {
          this.setState({ refecheque: res.data.variable1 });
        }
        if (e === "bancoefectivo") {
          this.setState({ refeefectivo: res.data.variable1 });
        }
        if (e === "bancotarjetaClave") {
          this.setState({ refetarjetaClave: res.data.variable1 });
        }
        if (e === "bancotarjetaVisa") {
          this.setState({ refetarjetaVisa: res.data.variable1 });
        }
        if (e === "bancodeposito") {
          this.setState({ refedeposito: res.data.variable1 });
        }
      });
  };

  pagoNc = () => {
    if (this.state.pagoncOn) {
      this.setState({ pagoncOn: false });
      //this.limpiar();
    } else {
      this.buscarNC();
      this.setState({ pagoncOn: true });
    }
  };

  consulPago = () => {
    if (this.state.consulPagoOn) {
      this.setState({ consulPagoOn: false });
    } else {
      this.buscarNCPagas();
      this.setState({ consulPagoOn: true });
    }
  };

  buscarNCPagas = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("cedulaA", this.state.cedulaAlumno);
    data.append("boton", "consultarNCPagadas");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasCP: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  pagarNotaCredito = () => {
    if (this.state.mncredito.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("referencia", this.state.concepto);
      data.append("banco", this.state.banco);
      data.append("transferencia", this.state.transferencia);
      data.append("deposito", this.state.deposito);
      data.append("efectivo", this.state.efectivo);
      data.append("cheque", this.state.cheque);
      data.append("numeroNcredito", this.state.mncredito);
      data.append("fecha", this.state.fecha);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarReciboNC");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Pago de Nota de Credito.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Pago de Nota de Credito.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  externoCliente = () => {
    if (this.state.tipoFac === "PRODUCTO") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
    if (this.state.tipoFac === "SERVICIOS") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
  };

  activarFactura2 = () => {
    if (this.state.facturacionOn) {
      this.setState({ facturacionOn: false, datosChequeOn: false });
    } else {
      this.consultarItenFactura2();
      this.consultarInventario();
      this.setState({
        facturacionOn: true,
      });
    }
  };

  activarFactura = () => {
    if (this.state.facturacionOn) {
      this.setState({ facturacionOn: false, datosChequeOn: false });
    } else {
      this.consultarItenFactura();
      this.consultarInventario();
      this.setState({
        facturacionOn: true,
      });
    }
  };

  consultarClientes = (e) => {
    //this.setState({loading:true})
    let data = new FormData();
    if (e === "I") data.append("boton", "consultarClienteI");
    if (e === "E") data.append("boton", "consultarClienteE");
    //data.append("boton", "consultarClienteI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            clientes: xrutas,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarClientesI = (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("idcliente", e);
    data.append("tipoCliente", this.state.opcedulaP);
    data.append("boton", "clienteI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          this.setState({
            cliente: res.data.variable2,
            ruc: res.data.variable3,
            fecha: res.data.variable4,
            cedulaAlumno: res.data.variable5,
          });
          this.consultarFactura(res.data.variable3);
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFactura = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("boton", "recibosSinRefe");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            impuesto: res.data.variable8,
            cantidad: "1",
          });
        }
      });
  };

  consultarItenFactura2 = async () => {
    this.setState({
      itenFacturas: [],
      subtotal: "",
      timpuesto: "",
      totalg: "",
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("boton", "consultarItenFactura2");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            itenFacturas: xrutas,
            subtotal: res.data.variable3,
            timpuesto: res.data.variable4,
            totalg: res.data.variable5,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarItenFactura = async () => {
    this.setState({
      itenFacturas: [],
      subtotal: "",
      timpuesto: "",
      totalg: "",
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("boton", "consultarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            itenFacturas: xrutas,
            subtotal: res.data.variable3,
            timpuesto: res.data.variable4,
            totalg: res.data.variable5,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  facturarIten = () => {
    let paso = 0;
    if (
      parseFloat(this.state.cantidad) > parseFloat(this.state.cantidadActual)
    ) {
      paso = 1;
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM:
          "La cantidad que intenta facturar es mayor que la encontrada en el inventario, la cantidad actual es de:" +
          this.state.cantidadActual,
      });
    }
    if (parseFloat(this.state.precio) < parseFloat(this.state.costo)) {
      paso = 1;
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El precio es menor que el costo, Verifique por Favor.",
      });
    }

    if (paso === 0) {
      let data = new FormData();
      data.append("idproducto", this.state.idproducto);
      data.append(
        "nombreProducto",
        this.state.nombrepro + " " + this.state.otroProducto
      );
      data.append("cantidad", this.state.cantidad);
      data.append("precioU", this.state.precio);
      data.append("itebi", this.state.itebi);
      data.append("precioT", this.state.precioT);
      data.append("itebi", this.state.itebi);
      data.append("precioT", this.state.precioT);
      data.append("impuesto", this.state.impuesto);
      data.append("cantidadActual", this.state.cantidadActual);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("boton", "facturarIten");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultarItenFactura();
            this.limpiar2();
          }
        });
    }
  };

  facturarItenCxCRE = (d, e, f, g) => {
    let data = new FormData();
    data.append("idproducto", "cxcre");
    data.append("nombreProducto", d);
    data.append("cantidad", "1");
    data.append("precioU", e);
    data.append("itebi", "0");
    data.append("precioT", e);
    data.append("impuesto", "");
    data.append("cantidadActual", "");
    data.append("idcontrolPago", f);
    data.append("deuda", g);
    data.append("claveUsu", this.props.usuario1.password);
    data.append("arregloCxCPM", JSON.stringify(this.state.cxcRe));

    data.append("boton", "facturarItenCxC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({ cxcPendientesOn: false });
          this.consultarItenFactura();
          //this.limpiar2();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  eliminarItenFactura = (e) => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("iditenfactura", e);
    data.append("boton", "eliminarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura();
        }
      });
  };

  modificarDatosPago = () => {
    let data = new FormData();
    data.append("nfactura", this.state.facturaMdatos);

    data.append("transferencia", this.state.transferencia);
    data.append("mtransferencia", this.state.mtransferencia);
    data.append("deposito", this.state.deposito);
    data.append("mdeposito", this.state.mdeposito);
    data.append("tarjetaVisa", this.state.tarjetaVisa);
    data.append("mtarjetaVisa", this.state.mtarjetaVisa);
    data.append("tarjetaClave", this.state.tarjetaClave);
    data.append("mtarjetaClave", this.state.mtarjetaClave);
    data.append("efectivo", this.state.efectivo);
    data.append("mefectivo", this.state.mefectivo);
    data.append("cheque", this.state.cheque);
    data.append("mcheque", this.state.mcheque);
    data.append("otrosPagos", this.state.otrosPago);
    data.append("motrosPagos", this.state.mOtrosPago);

    data.append("bancoTrans", this.state.bancoTrans);
    data.append("refetransferencia", this.state.refetransferencia);
    data.append("bancodeposito", this.state.bancodeposito);
    data.append("refedeposito", this.state.refedeposito);
    data.append("bancotarjetaVisa", this.state.bancotarjetaVisa);
    data.append("refetarjetaVisa", this.state.refetarjetaVisa);
    data.append("bancotarjetaClave", this.state.bancotarjetaClave);
    data.append("refetarjetaClave", this.state.refetarjetaClave);
    data.append("bancoefectivo", this.state.bancoefectivo);
    data.append("refeefectivo", this.state.refeefectivo);
    data.append("bancocheque", this.state.bancocheque);
    data.append("refecheque", this.state.refecheque);
    data.append("cuentaContable", this.state.cuentaContable);
    data.append("refeOtrosPago", this.state.refeOtrosPago);
    data.append("tipoGastoOtros", this.state.opgasto);
    data.append("boton", "modificarReferencias");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          // this.limpiar2();
          this.cerrarDatosPago();
          this.setState({
            datosPagoOn: false,
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: "Se Modifico con Exito el Registro.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: "Ocurrio un Error Verifique.",
          });
        }
      });
  };

  guardarFactura = () => {
    if (
      this.state.opcedulaP != "INTERNOS" &&
      parseFloat(this.state.totalg) === parseFloat(this.state.monto)
    ) {
      this.setState({
        aceptarOn: true,
      });
      let data = new FormData();
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("transferencia", this.state.transferencia);
      data.append("mtransferencia", this.state.mtransferencia);
      data.append("deposito", this.state.deposito);
      data.append("mdeposito", this.state.mdeposito);
      data.append("tarjetaVisa", this.state.tarjetaVisa);
      data.append("mtarjetaVisa", this.state.mtarjetaVisa);
      data.append("tarjetaClave", this.state.tarjetaClave);
      data.append("mtarjetaClave", this.state.mtarjetaClave);
      data.append("efectivo", this.state.efectivo);
      data.append("mefectivo", this.state.mefectivo);
      data.append("cheque", this.state.cheque);
      data.append("mcheque", this.state.mcheque);
      data.append("otrosPagos", this.state.otrosPago);
      data.append("motrosPagos", this.state.mOtrosPago);
      data.append("ncredito", this.state.ncredito);
      data.append("numeroNcredito", this.state.mncredito);
      data.append("cxc", this.state.cxc);
      data.append("canjes", this.state.canjes);
      data.append("mcanjes", this.state.mcanjes);
      data.append("idfacturaProve", this.state.tbusqueda3);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("montoOtros", this.state.montoOtros);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("anoEscolar", this.props.usuario1.anioE);

      data.append("bancoTrans", this.state.bancoTrans);
      data.append("refetransferencia", this.state.refetransferencia);
      data.append("bancodeposito", this.state.bancodeposito);
      data.append("refedeposito", this.state.refedeposito);
      data.append("bancotarjetaVisa", this.state.bancotarjetaVisa);
      data.append("refetarjetaVisa", this.state.refetarjetaVisa);
      data.append("bancotarjetaClave", this.state.bancotarjetaClave);
      data.append("refetarjetaClave", this.state.refetarjetaClave);
      data.append("bancoefectivo", this.state.bancoefectivo);
      data.append("refeefectivo", this.state.refeefectivo);
      data.append("bancocheque", this.state.bancocheque);
      data.append("refecheque", this.state.refecheque);
      data.append("cuentaContable", this.state.cuentaContable);
      data.append("refeOtrosPago", this.state.refeOtrosPago);
      data.append("tipoGastoOtros", this.state.opgasto);
      data.append("concepto2", this.state.concepto2);
      data.append("fechaT", this.state.fechaT);
      data.append("fechaFactura", this.state.fechaFactura);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.aprobarRecibo(res.data.variable4);
            // this.activarFactura();
            // this.limpiar2();
            // this.consultarFactura(this.state.ruc);
            this.setState({
              numeroFactu: res.data.variable4,
              aceptarOn: true,
              totalg: "",
              // mensajealerta: true,
              // tituloM: "Menu Facturacion.",
              // cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              aceptarOn: false,
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: res.data.variable2,
              //cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El Monto a Pagar No Coincide con el Total Facturado.",
      });
    }
  };

  guardarFactura1 = () => {
    if (this.state.opcedulaP === "INTERNOS") {
      this.setState({
        aceptarOn: true,
      });
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("transferencia", this.state.transferencia);
      data.append("mtransferencia", this.state.mtransferencia);
      data.append("deposito", this.state.deposito);
      data.append("mdeposito", this.state.mdeposito);
      data.append("tarjetaVisa", this.state.tarjetaVisa);
      data.append("mtarjetaVisa", this.state.mtarjetaVisa);
      data.append("tarjetaClave", this.state.tarjetaClave);
      data.append("mtarjetaClave", this.state.mtarjetaClave);
      data.append("efectivo", this.state.efectivo);
      data.append("mefectivo", this.state.mefectivo);
      data.append("cheque", this.state.cheque);
      data.append("mcheque", this.state.mcheque);
      data.append("otrosPagos", this.state.otrosPago);
      data.append("motrosPagos", this.state.mOtrosPago);
      data.append("ncredito", this.state.ncredito);
      data.append("numeroNcredito", this.state.mncredito);
      data.append("cxc", this.state.cxc);
      data.append("canjes", this.state.canjes);
      data.append("mcanjes", this.state.mcanjes);
      data.append("idfacturaProve", this.state.tbusqueda3);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("montoOtros", this.state.montoOtros);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("nombrePer", this.state.nombreper);

      data.append("bancoTrans", this.state.bancoTrans);
      data.append("refetransferencia", this.state.refetransferencia);
      data.append("bancodeposito", this.state.bancodeposito);
      data.append("refedeposito", this.state.refedeposito);
      data.append("bancotarjetaVisa", this.state.bancotarjetaVisa);
      data.append("refetarjetaVisa", this.state.refetarjetaVisa);
      data.append("bancotarjetaClave", this.state.bancotarjetaClave);
      data.append("refetarjetaClave", this.state.refetarjetaClave);
      data.append("bancoefectivo", this.state.bancoefectivo);
      data.append("refeefectivo", this.state.refeefectivo);
      data.append("bancocheque", this.state.bancocheque);
      data.append("refecheque", this.state.refecheque);
      data.append("cuentaContable", this.state.cuentaContable);
      data.append("refeOtrosPago", this.state.refeOtrosPago);
      data.append("tipoGastoOtros", this.state.opgasto);
      data.append("concepto2", this.state.concepto2);
      data.append("fechaT", this.state.fechaT);
      data.append("fechaFactura", this.state.fechaFactura);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            // this.activarFactura();
            // this.limpiar2();
            // this.consultarFactura(this.state.ruc);
            this.setState({
              numeroFactu: res.data.variable4,
              aceptarOn: true,
              totalg: "",
              // mensajealerta: true,
              // tituloM: "Menu Facturacion.",
              // cuerpoM: res.data.variable2,
            });
            this.aprobarRecibo(res.data.variable4);
          } else {
            this.setState({
              aceptarOn: false,
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  guardarFactura2 = () => {
    if (this.state.opcedulaP === "INTERNOS") {
      this.setState({
        aceptarOn: true,
      });
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("transferencia", this.state.transferencia);
      data.append("mtransferencia", this.state.mtransferencia);
      data.append("deposito", this.state.deposito);
      data.append("mdeposito", this.state.mdeposito);
      data.append("tarjetaVisa", this.state.tarjetaVisa);
      data.append("mtarjetaVisa", this.state.mtarjetaVisa);
      data.append("tarjetaClave", this.state.tarjetaClave);
      data.append("mtarjetaClave", this.state.mtarjetaClave);
      data.append("efectivo", this.state.efectivo);
      data.append("mefectivo", this.state.mefectivo);
      data.append("cheque", this.state.cheque);
      data.append("mcheque", this.state.mcheque);
      data.append("otrosPagos", this.state.otrosPago);
      data.append("motrosPagos", this.state.mOtrosPago);
      data.append("ncredito", this.state.ncredito);
      data.append("numeroNcredito", this.state.mncredito);
      data.append("cxc", this.state.cxc);
      data.append("canjes", this.state.canjes);
      data.append("mcanjes", this.state.mcanjes);
      data.append("idfacturaProve", this.state.tbusqueda3);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("montoOtros", this.state.montoOtros);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("nombrePer", this.state.nombreper);

      data.append("bancoTrans", this.state.bancoTrans);
      data.append("refetransferencia", this.state.refetransferencia);
      data.append("bancodeposito", this.state.bancodeposito);
      data.append("refedeposito", this.state.refedeposito);
      data.append("bancotarjetaVisa", this.state.bancotarjetaVisa);
      data.append("refetarjetaVisa", this.state.refetarjetaVisa);
      data.append("bancotarjetaClave", this.state.bancotarjetaClave);
      data.append("refetarjetaClave", this.state.refetarjetaClave);
      data.append("bancoefectivo", this.state.bancoefectivo);
      data.append("refeefectivo", this.state.refeefectivo);
      data.append("bancocheque", this.state.bancocheque);
      data.append("refecheque", this.state.refecheque);
      data.append("cuentaContable", this.state.cuentaContable);
      data.append("refeOtrosPago", this.state.refeOtrosPago);
      data.append("tipoGastoOtros", this.state.opgasto);
      data.append("concepto2", this.state.concepto2);
      data.append("fechaT", this.state.fechaT);
      data.append("fechaFactura", this.state.fechaFactura);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            // this.activarFactura();
            // this.limpiar2();
            // this.consultarFactura(this.state.ruc);
            this.setState({
              numeroFactu: res.data.variable4,
              aceptarOn: true,
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "El recibo fué creado y a la espera de su aprobación",
            });
            // this.aprobarRecibo(res.data.variable4);
          } else {
            this.setState({
              aceptarOn: false,
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  aprobarRecibo = (e) => {
    this.setState({
      aceptarOn: true,
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("nfactura", e);
    data.append("anioEscolar", this.state.anioEscolar);
    data.append("boton", "aprobarPagoRe");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar3();
          this.consultarFactura(this.state.ruc);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Aprobar Recibo.",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            aceptarOn: false,
            mensajealerta: true,
            tituloM: "Menu Aprobar Recibo.",
            cuerpoM: "Ocurrio un error con la actualizacion.",
          });
        }
      });
  };

  modificarNoAprobado = (e) => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("nfactura", e);
    data.append("anioEscolar", this.state.anioEscolar);

    data.append("bancoTrans", this.state.bancoTrans);
    data.append("refetransferencia", this.state.refetransferencia);
    data.append("transferencia", this.state.transferencia);
    data.append("mtransferencia", this.state.mtransferencia);

    data.append("bancodeposito", this.state.bancodeposito);
    data.append("refedeposito", this.state.refedeposito);
    data.append("deposito", this.state.deposito);
    data.append("mdeposito", this.state.mdeposito);

    data.append("bancotarjetaVisa", this.state.bancotarjetaVisa);
    data.append("refetarjetaVisa", this.state.refetarjetaVisa);
    data.append("tarjetaVisa", this.state.tarjetaVisa);
    data.append("mtarjetaVisa", this.state.mtarjetaVisa);

    data.append("bancotarjetaClave", this.state.bancotarjetaClave);
    data.append("refetarjetaClave", this.state.refetarjetaClave);
    data.append("tarjetaClave", this.state.tarjetaClave);
    data.append("mtarjetaClave", this.state.mtarjetaClave);

    data.append("cheque", this.state.cheque);
    data.append("mcheque", this.state.mcheque);
    data.append("bancocheque", this.state.bancocheque);
    data.append("refecheque", this.state.refecheque);

    data.append("otrosPagos", this.state.otrosPago);
    data.append("motrosPagos", this.state.mOtrosPago);
    data.append("cuentaContable", this.state.cuentaContable);
    data.append("refeOtrosPago", this.state.refeOtrosPago);
    data.append("tipoGastoOtros", this.state.opgasto);

    data.append("efectivo", this.state.efectivo);
    data.append("mefectivo", this.state.mefectivo);
    data.append("bancoefectivo", this.state.bancoefectivo);
    data.append("refeefectivo", this.state.refeefectivo);

    data.append("concepto2", this.state.concepto2);
    data.append("fechaT", this.state.fechaT);
    data.append("nombrePer", this.state.nombreper);

    data.append("boton", "modificarNoAprobado");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Aprobar Recibo.",
            cuerpoM: "El Recibo se Modifico con exito.",
          });
        } else {
          this.setState({
            aceptarOn: false,
            mensajealerta: true,
            tituloM: "Menu Aprobar Recibo.",
            cuerpoM: "Ocurrio un error con la Modificación.",
          });
        }
      });
  };

  consultarFacturasI2 = () => {
    let data = new FormData();
    if (this.state.botonNoAprobadosOn !== true)
      data.append("numeroRecibo", this.state.numeroFactu);
    if (this.state.botonNoAprobadosOn === true)
      data.append("numeroRecibo", this.state.numeReci);
    data.append("tipoFact", this.state.tipoFac);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago4.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  enviarFacturaCorreo = () => {
    let data = new FormData();
    if (this.state.botonNoAprobadosOn !== true)
      data.append("numeroRecibo", this.state.numeroFactu);
    if (this.state.botonNoAprobadosOn !== true)
      data.append("numeroRecibo", this.state.numeroFactu);

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago.php";
    axios
      .post(xurl, data, config)

      .then((res) => {});
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Enviar Factura por Correo.",
      cuerpoM: "La Factura fue enviada por correo de forma correcta",
    });
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
      if (this.state.botonNoAprobadosOn === true) {
        this.limpiar2();
        this.setState({
          facturacionOn: false,
          datosChequeOn: false,
          aceptarOn: false,
        });
      }

      if (this.state.aceptarOn === true) {
        this.limpiar2();
        this.setState({
          facturacionOn: false,
          datosChequeOn: false,
          aceptarOn: false,
        });
      }
    } else {
      this.buscarNC();
      this.setState({
        datosChequeOn: true,
        monto: this.state.totalg,
      });
    }
  };

  buscarNC = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("cedulaA", this.state.cedulaAlumno);
    data.append("boton", "consultarNCPC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            nocre: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  buscarDatosPago = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("numeroFactura", e);
    data.append("boton", "buscarDatosFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          if (res.data.variable6 !== "") {
            this.setState({ botonTransOn: true });
          }
          if (res.data.variable10 !== "") {
            this.setState({ botontarjetaVOn: true });
          }
          if (res.data.variable14 !== "") {
            this.setState({ botontarjetaCOn: true });
          }
          if (res.data.variable18 !== "") {
            this.setState({ botonChequeOn: true });
          }
          if (res.data.variable22 !== "") {
            this.setState({ botonEfecOn: true });
          }
          if (res.data.variable26 !== "") {
            this.setState({ botonOtrosPagoOn: true });
          }
          this.setState({
            datosChequeOn: true,
            concepto2: res.data.variable2,
            fechaT: res.data.variable3,
            nombreper: res.data.variable4,
            numeReci: res.data.variable5,
            transferencia: res.data.variable6,
            mtransferencia: res.data.variable7,
            bancoTrans: res.data.variable8,
            refetransferencia: res.data.variable9,
            tarjetaVisa: res.data.variable10,
            mtarjetaVisa: res.data.variable11,
            bancotarjetaVisa: res.data.variable12,
            refetarjetaVisa: res.data.variable13,
            tarjetaClave: res.data.variable14,
            mtarjetaClave: res.data.variable15,
            bancotarjetaClave: res.data.variable16,
            refetarjetaClave: res.data.variable17,
            cheque: res.data.variable18,
            mcheque: res.data.variable19,
            bancocheque: res.data.variable20,
            refecheque: res.data.variable21,
            efectivo: res.data.variable22,
            mefectivo: res.data.variable23,
            bancoefectivo: res.data.variable24,
            refeefectivo: res.data.variable25,
            otrosPago: res.data.variable26,
            mOtrosPago: res.data.variable27,
            cuentaContable: res.data.variable28,
            refeOtrosPago: res.data.variable29,
            opgasto: res.data.variable30,
            botonNoAprobadosOn: true,
          });
        } else {
          this.setState({
            datosChequeOn: false,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarPagoFactura = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("nfactura", e);
      data.append("boton", "consultarFPago");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            if (res.data.variable2 === "true") {
              this.setState({
                transferencia: "TRANSFERENCIA",
                mtransferencia: res.data.variable3,
                bancoTrans: res.data.variable4,
                refetransferencia: res.data.variable5,
                botonTransOn: true,
              });
            }
            if (res.data.variable6 === "true") {
              this.setState({
                tarjetaVisa: "TARJETA VISA",
                mtarjetaVisa: res.data.variable7,
                bancotarjetaVisa: res.data.variable8,
                refetarjetaVisa: res.data.variable9,
                botontarjetaVOn: true,
              });
            }
            if (res.data.variable10 === "true") {
              this.setState({
                tarjetaClave: "TARJETA CLAVE",
                mtarjetaClave: res.data.variable11,
                bancotarjetaClave: res.data.variable12,
                refetarjetaClave: res.data.variable13,
                botontarjetaCOn: true,
              });
            }
            if (res.data.variable14 === "true") {
              this.setState({
                cheque: "CHEQUE",
                mcheque: res.data.variable15,
                bancocheque: res.data.variable16,
                refecheque: res.data.variable17,
                botonChequeOn: true,
              });
            }
            if (res.data.variable18 === "true") {
              this.setState({
                otrosPago: "OTROSPAGO",
                mOtrosPago: res.data.variable19,
                cuentaContable: res.data.variable20,
                refeOtrosPago: res.data.variable21,
                opgasto: res.data.variable22,
                botonOtrosPagoOn: true,
              });
            }
            if (res.data.variable23 === "true") {
              this.setState({
                efectivo: "EFECTIVO",
                mefectivo: res.data.variable24,
                bancoefectivo: res.data.variable25,
                refeefectivo: res.data.variable26,
                botonEfecOn: true,
              });
            }
            if (res.data.variable27 === "true") {
              this.setState({
                deposito: "DEPOSITO",
                mdeposito: res.data.variable28,
                bancodeposito: res.data.variable29,
                refedeposito: res.data.variable30,
                botonDepoOn: true,
              });
            }

            this.setState({
              datosPagoOn: true,
              facturaMdatos: e,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "No se encontraron datos de pago.",
            });
          }
        });
    }
  };

  consultarFacturasI = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  consultarPagosI = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      //data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago5.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  eliminarFacturasI = (e) => {
    let data = new FormData();
    data.append("nfactura", e);
    data.append("boton", "notaCredito");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar2();
          this.consultarFactura(this.state.ruc);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: "La Factura se Elimino con Exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM:
              "Ocurrio un error Verifique que la Fecha de la Factura debe ser del Mes en curso.",
          });
        }
      });
    //console.log(e);
  };

  reporteLibroV = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/libroVentas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  libroV = () => {
    if (this.state.libroVOn) {
      this.setState({ libroVOn: false });
    } else {
      this.setState({ libroVOn: true });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable143 === "1") this.setState({ cumpleOn: true });
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                gradoAlumno: res.data.variable144,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                gradoAlumno: res.data.variable144,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable143 === "1") this.setState({ cumpleOn: true });
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
                gradoAlumno: res.data.variable144,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
                gradoAlumno: res.data.variable144,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (this.state.tbusqueda1 === "1") {
      this.buscarPlanillaIndividual();
    }
    if (this.state.tbusqueda1 === "2") {
      this.buscarPorNombres();
    }
  };

  cerrarLista2 = () => {
    if (this.state.cxcPendientesOn) {
      this.setState({ cxcPendientesOn: false });
    } else {
      this.setState({ cxcPendientesOn: true });
    }
  };

  deudaCxC = () => {
    let data = new FormData();
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "buscarCXCRE");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xcxcRe = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            cxcPendientesOn: true,
            cxcRe: xcxcRe,
            montoDeudor: 0,
          });
          if (res.data.variable3 !== "") {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Deuda ",
              cuerpoM: res.data.variable3,
            });
          }
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Deuda ",
            cuerpoM: res.data.variable2,
            cxcPendientesOn: false,
          });
        }
      });
  };

  calcularSumaValorEntrada3 = (index, value, restaP) => {
    let cxcRe_ = this.state.cxcRe; //Array.from(cxcRe);
    cxcRe_[index].valorEntrada3 = 0;
    cxcRe_[index].valorEntrada3 = value;
    this.setState({ cxcRe: cxcRe_ });

    cxcRe_ = this.state.cxcRe; //Array.from(cxcRe);
    let total = 0;
    for (let x in cxcRe_) {
      if (cxcRe_[x].valorEntrada3 > 0) {
        total += parseFloat(cxcRe_[x].valorEntrada3);
      }
    }

    this.setState({
      montoDeudor: total,
    });
  };

  onChange7(index, value, e) {
    // console.log(index);
    // console.log(value);
    let cxcRe_ = "";
    this.setState({
      [e.target.checked]: !e.target.checked,
    });
    //console.log(e.target.checked)
    if (e.target.checked) {
      cxcRe_ = this.state.cxcRe; //Array.from(cxcRe);
      cxcRe_[index].valorEntrada3 = 0;
      cxcRe_[index].valorEntrada3 = value;
      this.setState({ cxcRe: cxcRe_ });
    }

    if (!e.target.checked) {
      cxcRe_ = this.state.cxcRe; //Array.from(cxcRe);
      cxcRe_[index].valorEntrada3 = "";
      this.setState({ cxcRe: cxcRe_ });
    }

    cxcRe_ = this.state.cxcRe; //Array.from(cxcRe);
    let total = 0;
    for (let x in cxcRe_) {
      if (cxcRe_[x].valorEntrada3 > 0) {
        total += parseFloat(cxcRe_[x].valorEntrada3);
      }
    }

    this.setState({
      montoDeudor: total,
    });
  }

  buscarOn = () => {
    this.consultarProveedores2();
    this.setState({
      tbusqueda3: "",
    });
  };

  consultarProveedores2 = () => {
    let data = new FormData();
    data.append("nombre", this.state.nombrex);
    data.append("boton", "consultarProveedorN");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
            lista2On: true,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarCxP = (e, f) => {
    let data = new FormData();
    data.append("idproveedor", e);
    data.append("boton", "consultarCxP3");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xfacturasP = res.data.variable2;
          this.setState({
            nombrex: f,
            cxpproveedor: xfacturasP,
            lista2On: false,
          });
        } else {
          this.setState({
            cxpproveedor: [],
            lista2On: false,
            tbusqueda3: "",
          });
        }
      });
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  componentDidMount() {
    this.consultarFactura();
    // this.consultarInventario();
    this.cargarFechas();
    this.cargarCuentas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU PARA MODIFICAR REFERENCIAS DE RECIBOS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.datosPagoOn === true && (
            <div className="flotador4xfFP">
              <div className="flotador1">
                <div className="fila10x-datos-antecedentesescolar row">
                  <label className="labelcedulac">Transacción.</label>
                </div>
                <div className="fila10x-datos-antecedentesescolar row">
                  <div className="radio-identificacion col-2">
                    {/* {this.state.opcedulaP === "INTERNOS" && */}
                    {this.state.botonCANOn !== true &&
                      this.state.botonCXCOn !== true && (
                        <div className="radio">
                          {/* <div className="cont-opcion2 col-1"> */}
                          <label className="container">
                            Transferencia.
                            <input
                              className="form-control"
                              type="checkbox"
                              name="transferencia"
                              id="transferencia"
                              value="TRANSFERENCIA"
                              checked={this.state.transferencia}
                              // onChange={this.onChange5.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )}
                    {/* {this.state.opcedulaP === "INTERNOS" && */}
                    {this.state.botonCANOn !== true &&
                      this.state.botonCXCOn !== true && (
                        <div className="radio">
                          <label className="container">
                            Deposito.
                            <input
                              className="form-control"
                              type="checkbox"
                              name="deposito"
                              id="deposito"
                              value="DEPOSITO"
                              checked={this.state.deposito}
                              //onChange={this.onChange5.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )}
                    {/* {this.state.opcedulaP === "INTERNOS" && */}
                    {this.state.botonCANOn !== true &&
                      this.state.botonCXCOn !== true && (
                        <div className="radio">
                          <label className="container">
                            Tarjeta Visa.
                            <input
                              className="form-control"
                              type="checkbox"
                              name="tarjetaVisa"
                              id="tarjetaVisa"
                              value="TARJETA VISA"
                              checked={this.state.tarjetaVisa}
                              //onChange={this.onChange5.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )}
                    {/* {this.state.opcedulaP === "INTERNOS" && */}
                    {this.state.botonCANOn !== true &&
                      this.state.botonCXCOn !== true && (
                        <div className="radio">
                          <label className="container">
                            Tarjeta Clave.
                            <input
                              className="form-control"
                              type="checkbox"
                              name="tarjetaClave"
                              id="tarjetaClave"
                              value="TARJETA CLAVE"
                              checked={this.state.tarjetaClave}
                              //onChange={this.onChange5.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )}
                    {/* {this.state.opcedulaP === "INTERNOS" && */}
                    {this.state.botonCANOn !== true &&
                      this.state.botonCXCOn !== true && (
                        <div className="radio">
                          <label className="container">
                            Efectivo.
                            <input
                              className="form-control"
                              type="checkbox"
                              name="efectivo"
                              id="efectivo"
                              value="EFECTIVO"
                              checked={this.state.efectivo}
                              //onChange={this.onChange5.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )}
                    {/* {this.state.opcedulaP === "INTERNOS" && */}
                    {this.state.botonCANOn !== true &&
                      this.state.botonCXCOn !== true && (
                        <div className="radio">
                          <label className="container">
                            Cheque.
                            <input
                              className="form-control"
                              type="checkbox"
                              name="cheque"
                              id="cheque"
                              value="CHEQUE"
                              checked={this.state.cheque}
                              //onChange={this.onChange5.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )}
                    {this.state.botonCANOn !== true &&
                      this.state.botonCXCOn !== true && (
                        <div className="radio">
                          <label className="container">
                            OTROS.
                            <input
                              className="form-control"
                              type="checkbox"
                              name="otrosPago"
                              id="otrosPago"
                              value="OTROSPAGO"
                              checked={this.state.otrosPago}
                              //onChange={this.onChange5.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )}
                  </div>
                  {this.state.botonTransOn && (
                    <div className="radio-identificacion2 col-3">
                      <div className="cont-opcion23x col-3">
                        <select
                          id="bancoTrans"
                          name="bancoTrans"
                          value={this.state.bancoTrans}
                          //onChange={this.onChange.bind(this)}
                        >
                          <option>-- Seleccionar Banco --</option>
                          <option value="1"> Banco BAC</option>
                          <option value="2"> Banco BANESCO</option>
                          <option value="3"> Banco GENERAL</option>
                        </select>
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="refetransferencia"
                          id="refetransferencias"
                          placeholder="Referencia.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.refetransferencia}
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="mtransferencia"
                          id="mtransferencias"
                          placeholder="Monto Transf.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.mtransferencia}
                          readOnly="readonly"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.botonDepoOn && (
                    <div className="radio-identificacion2 col-3">
                      <div className="cont-opcion23x col-3">
                        <select
                          id="bancodeposito"
                          name="bancodeposito"
                          value={this.state.bancodeposito}
                          //onChange={this.onChange.bind(this)}
                        >
                          <option>-- Seleccionar Banco --</option>
                          <option value="1"> Banco BAC</option>
                          <option value="2"> Banco BANESCO</option>
                          <option value="3"> Banco GENERAL</option>
                        </select>
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="refedeposito"
                          id="refedeposito"
                          placeholder="Referencia.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.refedeposito}
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="mdeposito"
                          id="mdeposito"
                          placeholder="Monto Deposito.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.mdeposito}
                          readOnly="readonly"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.botontarjetaVOn && (
                    <div className="radio-identificacion2 col-3">
                      <div className="cont-opcion23x col-3">
                        <select
                          id="bancotarjetaVisa"
                          name="bancotarjetaVisa"
                          value={this.state.bancotarjetaVisa}
                          //onChange={this.onChange.bind(this)}
                        >
                          <option>-- Seleccionar Banco --</option>
                          <option value="1"> Banco BAC</option>
                          <option value="2"> Banco BANESCO</option>
                          <option value="3"> Banco GENERAL</option>
                        </select>
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="refetarjetaVisa"
                          id="refetarjetaVisa"
                          placeholder="Referencia.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.refetarjetaVisa}
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="mtarjetaVisa"
                          id="mtarjetaVisa"
                          placeholder="Monto Tar/Visa.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.mtarjetaVisa}
                          readOnly="readonly"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.botontarjetaCOn && (
                    <div className="radio-identificacion2 col-3">
                      <div className="cont-opcion23x col-3">
                        <select
                          id="bancotarjetaClave"
                          name="bancotarjetaClave"
                          value={this.state.bancotarjetaClave}
                          //onChange={this.onChange.bind(this)}
                        >
                          <option>-- Seleccionar Banco --</option>
                          <option value="1"> Banco BAC</option>
                          <option value="2"> Banco BANESCO</option>
                          <option value="3"> Banco GENERAL</option>
                        </select>
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="refetarjetaClave"
                          id="refetarjetaClave"
                          placeholder="Referencia.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.refetarjetaClave}
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="mtarjetaClave"
                          id="mtarjetaClave"
                          placeholder="Monto Tar/Clave.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.mtarjetaClave}
                          readOnly="readonly"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.botonEfecOn && (
                    <div className="radio-identificacion2 col-3">
                      <div className="cont-opcion23x col-3">
                        <select
                          id="bancoefectivo"
                          name="bancoefectivo"
                          value={this.state.bancoefectivo}
                          //onChange={this.onChange.bind(this)}
                        >
                          <option>-- Seleccionar Banco --</option>
                          <option value="1"> Banco BAC</option>
                          <option value="2"> Banco BANESCO</option>
                          <option value="3"> Banco GENERAL</option>
                        </select>
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="refeefectivo"
                          id="refeefectivo"
                          placeholder="Referencia.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.refeefectivo}
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="mefectivo"
                          id="mefectivo"
                          placeholder="Monto Efectivo.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.mefectivo}
                          readOnly="readonly"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.botonChequeOn && (
                    <div className="radio-identificacion2 col-3">
                      <div className="cont-opcion23x col-3">
                        <select
                          id="bancocheque"
                          name="bancocheque"
                          value={this.state.bancocheque}
                          //onChange={this.onChange.bind(this)}
                        >
                          <option>-- Seleccionar Banco --</option>
                          <option value="1"> Banco BAC</option>
                          <option value="2"> Banco BANESCO</option>
                          <option value="3"> Banco GENERAL</option>
                        </select>
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="refecheque"
                          id="refecheque"
                          placeholder="Referencia.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.refecheque}
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="mcheque"
                          id="mcheque"
                          placeholder="Monto Cheque.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.mcheque}
                          readOnly="readonly"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.botonOtrosPagoOn && (
                    <div className="radio-identificacion2 col-3">
                      <div className="cont-opcion24x col-3">
                        <select
                          id="cuentaContable"
                          name="cuentaContable"
                          value={this.state.cuentaContable}
                          //onChange={this.onChange.bind(this)}
                        >
                          <option>Cuenta Contable</option>

                          {this.state.cuenta.map((item, i) => {
                            return (
                              <option key={i} value={item.variable2}>
                                {item.variable6 + " - " + item.variable3}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="refeOtrosPago"
                          id="refeOtrosPago"
                          placeholder="Referencia.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.refeOtrosPago}
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <input
                          className="form-control"
                          type="text"
                          name="mOtrosPago"
                          id="mOtrosPago"
                          placeholder="Monto Otros.:"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.mOtrosPago}
                          readOnly="readonly"
                        />
                      </div>
                      <div className="cont-opcion22x">
                        <div className="radio">
                          <label className="labelcedulac">Tipo de Gasto.</label>
                          <label className="container">
                            Ingresos.
                            <input
                              className="opnivel1"
                              type="radio"
                              value="I"
                              name="opgasto"
                              checked={this.state.opgasto === "I"}
                              //onChange={this.onChange.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="radio">
                          <label className="container">
                            Egresos.
                            <input
                              className="opnivel2"
                              type="radio"
                              value="E"
                              name="opgasto"
                              checked={this.state.opgasto === "E"}
                              //onChange={this.onChange.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="contenedorcedula1 col-12">
                  <button className="boton" onClick={this.modificarDatosPago}>
                    Modificar
                  </button>
                  <button className="boton" onClick={this.cerrarDatosPago}>
                    Salir
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="contenedorcedula1 col-12">
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">FECHA</th>
                    <th className="titucxc1">FACTURA</th>
                    <th className="titucxc1">APROBADO</th>
                    <th className="titucxc1">TOTAL</th>
                    <th className="titucxc1">FORMA DE PAGO</th>
                    <th className="titucxc1">ANULADO</th>
                    <th className="titucxc1">
                      <FeatherIcon
                        icon="edit"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <FeatherIcon
                        icon="search"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                      &nbsp;&nbsp;&nbsp;
                    </th>
                  </tr>
                </thead>
                {this.state.facturasC.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxc"
                        width="10"
                        onClick={
                          item.variable9 === ""
                            ? this.buscarDatosPago.bind(this, item.variable2)
                            : ""
                        }
                      >
                        {item.variable6}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={
                          item.variable9 === ""
                            ? this.buscarDatosPago.bind(this, item.variable2)
                            : ""
                        }
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={
                          item.variable9 === ""
                            ? this.buscarDatosPago.bind(this, item.variable2)
                            : ""
                        }
                      >
                        {item.variable9}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={
                          item.variable9 === ""
                            ? this.buscarDatosPago.bind(this, item.variable2)
                            : ""
                        }
                      >
                        {item.variable5}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={
                          item.variable9 === ""
                            ? this.buscarDatosPago.bind(this, item.variable2)
                            : ""
                        }
                      >
                        {item.variable4}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={
                          item.variable9 === ""
                            ? this.buscarDatosPago.bind(this, item.variable2)
                            : ""
                        }
                      >
                        {item.variable7}
                      </td>
                      <td className="titucxc3" width="10">
                        {this.props.usuario1.nivel === "1" && (
                          <FeatherIcon
                            icon="edit"
                            size="30px"
                            stroke="#999"
                            color="red"
                            onClick={this.consultarPagoFactura.bind(
                              this,
                              item.variable2
                            )}
                          ></FeatherIcon>
                        )}{" "}
                        &nbsp;&nbsp;&nbsp;{" "}
                        <FeatherIcon
                          icon="search"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.consultarFacturasI.bind(
                            this,
                            item.variable2
                          )}
                        ></FeatherIcon>{" "}
                        &nbsp;&nbsp;&nbsp;{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Recepcion);
