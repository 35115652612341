import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./materiasProfesor.css";
import Alert from "../../modal/alert";

class MateriaPAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idprofesor: "",
      idseccion: "",
      idmateriaProfesor:'',
      imaterias: [],
      iprofesor: [],
      imateriasA: [],
      iseccionesA: [],
      modificarOn:false,
      listaOn: false,
      elimi: false,

      verplanillaOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      idprofesor: "",
      idseccion: "",
      idmateriaProfesor:'',
      imaterias: [],
      imateriasA: [],
      iprofesor: [],
      modificarOn:false,
      listaOn: false,
      elimi: false,
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarMateriasAsignadas(e.target.value);
  }
  consultar(e) {
    let data = new FormData();
    data.append("idmateriaProfesor", e);
    data.append("boton", "buscarMateriasAsignadasProfesorI");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idmateria: res.data.variable2,
            idseccion: res.data.variable3,
            idmateriaProfesor: res.data.variable4,
            modificarOn:true,
          });
        } 
      });
  }

  modificar = () => {
    if (this.state.idmateria.length > 0 && this.state.idseccion.length > 0) {
      let data = new FormData();
      data.append("idmateria", this.state.idmateria);
      data.append("idmateriaProfesor", this.state.idmateriaProfesor);
      data.append("idseccion", this.state.idseccion);
      data.append("boton", "modificarMateriaProfesor");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/materia.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarMateriasAsignadas(this.state.idprofesor);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias a Profesor.",
              cuerpoM: res.data.variable2,
              modificarOn:false,
              idmateria:'',
              idseccion:'',
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias a Profesor.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias a Profesor.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  guardar = () => {
    if (this.state.idmateria.length > 0 && this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("idmateria", this.state.idmateria);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idseccion", this.state.idseccion);
      data.append("boton", "guardarMateriaProfesor");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/materia.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarMateriasAsignadas(this.state.idprofesor);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias a Profesor.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias a Profesor.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias a Profesor.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (parseFloat(this.state.idmateria) > 0) {
      let data = new FormData();
      data.append("idmateriaProfesor", this.state.idmateria);
      data.append("boton", "eliminarMateriaProfesor");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/materia.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias a Profesor.",
            cuerpoM: res.data.variable2,
          });
          this.buscarMateriasAsignadas(this.state.idprofesor);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias a Profesor.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias a Profesor.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la MAteria Asignada.",
      });
    }
  };

  buscarMateriasAsignadas = (e) => {
    let data = new FormData();
    data.append("idprofesor", e);
    data.append("boton", "buscarMateriasAsignadasProfesor");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximateriasA = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imateriasA: ximateriasA,
          });
        } else {
          this.setState({
            imateriasA: [],
          });
        }
      });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("dedonde", "materiaProfesor");
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  buscarSecciones = () => {
    let data = new FormData();
    data.append("boton", "buscarSecciones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/secciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiseccionesA = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iseccionesA: xiseccionesA,
          });
        } else {
          this.setState({
            iseccionesA: [],
          });
        }
      });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Actividad.",
      cuerpoM: "Realmente Quiere Eliminar la Actividad...",
      idmateria: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarMaterias();
    this.buscarProfesor();
    this.buscarSecciones();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ASIGNAR MATERIAS A PROFESORES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-4">
                <select
                  id="idprofesor"
                  name="idprofesor"
                  value={this.state.idprofesor}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Profesor --</option>
                  {this.state.iprofesor.map((item, i) => {
                    return (
                      <option key={i} value={item.variable7}>
                        {item.variable3}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Materia --</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idseccion"
                  name="idseccion"
                  value={this.state.idseccion}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Grado/Sección --</option>
                  {this.state.iseccionesA.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2 + " Seccion: " + item.variable3}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className={this.state.modificarOn ? "botonOf" : "boton"} onClick={this.state.modificarOn ? '' : this.guardar.bind(this)}>
                Agregar
              </button>
              <button className={this.state.modificarOn ? "boton" : "botonOf"} onClick={this.state.modificarOn ? this.modificar.bind(this) : ''}>
                Modificar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">MATERIAS</th>
                    <th className="titucxc2">GRADO/SECCION</th>
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.imateriasA.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme5"
                          onClick={this.consultar.bind(this, item.variable1)}
                        ></span>
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar1.bind(this, item.variable1)}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MateriaPAction;
