import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./cambioMaternales.css";
import Alert from "../../modal/alert";

class CambioMaternales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcion: "",
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      ianoEscolar: [],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      maternal: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      loading: false,
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      idcurso: "",
      idprofesor: "",
      descripcion: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      fechaDesde: "",
      trimestre: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      ievaluaciones: [],
      ialumnos: [],
      listaOn: false,
      modificarOn: false,
      loading: false,
    });
  };

  limpiar2(e) {
    this.setState({
      idmateria: "",
      idcurso: "",
      idprofesor: "",
      idplanE: "",
      trimestre: "",
      cedulaAlumno: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange6 = (index, value) => {
    //console.log(index);
    //console.log(value);
    let alumnosP_ = Array.from(this.state.ialumnos);
    alumnosP_[index].variable5 = value;
    this.setState({ ialumnos: alumnosP_ });
  };

  consultar() {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("tipoBusqueda", this.state.trimestre);
    data.append("boton", "buscarMaternalSeleccionado");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/cambioMaternales.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xialumno = res.data.variable2;
        let xcursos = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            ialumnos: xialumno,
            maternal: xcursos,
          });
        } else {
          this.setState({
            ialumnos: [],
            maternal: [],
          });
        }
      });
  }

  aprobarPago = async () => {
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("arregloMaternal", JSON.stringify(this.state.ialumnos));
    data.append("boton", "aprobar");
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/cambioMaternales.php";
    let response = await axios.post(xurl, data);
    //console.log(response.data.variable2);
    if (response.data.variable1) {
      this.limpiar();
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Aprobar Cambios de Maternales.",
        cuerpoM: "Las Modificaciones se realizaron con Exito.",
        loading: false,
      });
    } else {
      this.limpiar();
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Aprobar Cambios de Maternales.",
        cuerpoM: "No se Realizaron Cambios.",
        loading: false,
      });
    }
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CAMBIO DE MATERNALES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
           {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-4">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar una Opcion --</option>
                  <option value="1">Cambiar de Maternal A a B</option>
                  <option value="2">Cambiar de Maternal B a C</option>
                </select>
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.consultar.bind(this)}>
                Consultar
              </button>
              <button className="boton" onClick={this.limpiar.bind(this)}>
                Limpiar
              </button>

              <button className="boton" onClick={this.props.salir.bind(this)}>
                Salir
              </button>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc5">ALUMNO</th>
                    <th className="titucxc5">CURSO ACTUAL</th>
                    <th className="titucxc5">CURSO A CAMBIAR</th>
                  </tr>
                </thead>

                {this.state.ialumnos.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="totalescxcx" width="10">
                        <div className="contenedorcedula col-12">
                          <select
                            id={item.variable1}
                            name={item.variable1}
                            value={item.variable5}
                            onChange={this.onChange6.bind(this)}
                            onChange={(e) => this.onChange6(i, e.target.value)}
                          >
                            <option>Seleccione el Maternal</option>

                            {this.state.maternal.map((item2, f) => {
                              return (
                                <option key={f} value={item2.variable1}>
                                  {item2.variable2}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.aprobarPago.bind(this)}>
                Procesar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CambioMaternales);
