//dependencias
import React from "react";
import { Route, Switch } from "react-router-dom";

//componentes

import PlaniHabilitada from "./components/buscarPlaniHabilitados"; 
import ReferenciaPago from "./components/referenciaPago";
import ReporteCxP from "./components/cuentasxpagar/reporte";
import CorreosMu from "./components/correosMasivos";
import CierreRE from "./components/cierreRecepcion";
import ReporteF from "./components/reporteF";
import Inasistencia from "./components/consultaInasistencia";
//import InasistenciaG from "./components/consultarInasistenciaMateria";
import PlanillaV from "./components/planillaVeranito";
import PlanEvaluacionM from "./components/planEvaluacionMaestro";
import AutorizarCT from "./components/autorizarCopiadeTrimestre";
import MaestroUsuario from "./components/maestroUsuarios";
import RegistrarPagos from "./components/registroCompras";
import CierreTrimestre from "./components/cierreTrimestral";
import HabilitarAdecuaciones from "./components/autorizarAdecuaciones";
import AutorizarExpediente from "./components/autorizarExpedientes";
import AutorizarAgenda from "./components/autorizarAgenda";
import AutorizarBoletines from "./components/autorizarBoletin";
import HabitosActitudes from "./components/habitoActitudes";
import ProfesorGuia from "./components/profesorGuia";
import ConsultaEvaluaciones from "./components/consultaEvaluaciones";
import CambioProfesor from "./components/cambioProfesores";
import EvaluacioneT from "./components/evaluacionesTotales";
import ExpedienteNotas from "./components/expedienteNotas";
import ExpedienteNotasN from "./components/expedienteNotasN";
import ExpedienteNotasDN from "./components/expedienteNotasDiagnosticoN";
import ExpedienteNotasNM from "./components/expedienteNotasMaestro";
import ExpedienteMaterias from "./components/expedienteMaterias";
import ExpedienteMateriasN from "./components/expedienteMateriasNuevo";
import ExpedienteMateriasDN from "./components/expedienteMateriasDiagnosticoN";
import ExpedienteMateriasNP from "./components/expedienteMateriasNuevoProfesores";
import ReporteMaestros from "./components/reporteMaestros";
import ArchivosPlanificacion from "./components/archivosPlani";
import ConsultaActividades from "./components/consultaActividadesC";
import ControlAsistencia from "./components/controlAsistencia";
import MostrarTrabajos from "./components/mostrarTrabajosAlumnos";
import Actividades from "./components/actividades";
import ReportePM from "./components/reportePlaniMaestro";
import AlumnosMaestro from "./components/alumnosXMaestro";
import FechaPlanificacion from "./components/fechaPlanificacion";
import ReporteP from "./components/reportePlanificacion";
import ActualizarPlanificacion from "./components/actualizarPlanificacion";

import PlanificacionAprobados from "./components/planificacionAprobados";
import Planificacion from "./components/planificacion";
import DescripcionE from "./components/descripcionEvaluacion";
import SeccionesA from "./components/seccionAlumnos";
import SeccionesP from "./components/seccionProfesor";
import Secciones from "./components/secciones";
import Evaluacion from "./components/evaluaciones";
import EvaluacionD from "./components/evaluacionesDiagnostico";
import PlanE from "./components/planEvaluacion";
import MateriasP from "./components/materiaProfesor";
import MateriasA from "./components/materiasAsignadas";
import Materias from "./components/materias";
import ReciboIndividual from "./components/reciboI";
import Nomina from "./components/nomina";
import FacturacionRe from "./components/aprobarRe";
import NotaCreditoM from "./components/notaCreditoManual";
import NotaCredito from "./components/notaCredito";
import NotaCreditoProveedor from "./components/notaCreditoProveedor";
import Facturacion from "./components/facturacion";
import Recepcion from "./components/recepcion";
import FacturacionTera from "./components/facturacionTe";
import CxP from "./components/cuentasxpagar";
import Proveedores from "./components/proveedores";
import Clientes from "./components/clientes";
import CorreosIC from "./components/correosiC";
import CorreosI from "./components/correosInternos";
import EntradasInventario from "./components/entradasInv";
import DespachoP from "./components/despachoPendientes";
import InventarioL from "./components/inventarioLogico";
import Inventario from "./components/inventario";
import Correos from "./components/correos";
import ReportesAca2 from "./components/reportesAca2";
import ReportesAca3 from "./components/reportesAca3";
import ReportesAca from "./components/reportesAca"; 
import Reportes from "./components/reportes";
import ReportesContables from "./components/reporteContabilidad";
import ReporteInvFac from "./components/reporteInvFac";
import Reinscripcion from "./components/reinscripcion";
import ExtensionM from "./components/extencionM";
import ActivarEscolarT from "./components/anioescolarT";
import ActivarEscolar from "./components/anioescolar";
import Canje from "./components/canje";
import Conceptos from "./components/conceptos";
import MensualidadMa from "./components/mensualidadGeneralM";
import Mensualidad from "./components/mensualidadGeneral";
import Inscripcion from "./components/inscripcionG";
import ArchivosUp from "./components/subirarchivos";
import PlanillaI from "./components/planillaInscripcion";
import CxC from "./components/cpc";
import CxCNuevo from "./components/cxcNuevo";
import CxCCli from "./components/cxcCli";
import PagosAprobados from "./components/pagosAprobados";
import PagosRecibidos from "./components/pagosRecibidos";
import Grados from "./components/grados";
import Pago from "./components/reportarPago/";
import Configuracion from "./components/configuracion/";
import ConfiguracionAlumnos from "./components/configuracionA";
import Inicio from "./components/Inicio";
import Login from "./components/Login";
import Pagina404 from "./components/Pagina404";
import Chat from "./components/Chat";
import ChatI from "./components/ChatI";
import MaternalV from "./components/maternalVeranito";
import SeccionV from "./components/maternalVeranitoL";
import CodigoQR from "./components/codigoQr";
import PorcentajeM from "./components/porcentajeMaterias";
import PorcentajeMD from "./components/porcentajeMD";
import HorarioPla from "./components/horarioPlani";
import CambioReferencias from "./components/modificarReferencias";
import BloqueHorario from "./components/bloqueHorario";
import DosificarDEPN from "./components/dosificacionDEPN";
import DosificarDE from "./components/dosificacionDE";
import DosificarDEAd from "./components/dosificacionDEAd";
import DosificarEva from "./components/dosificacionEva";
import DosificarEvaPN from "./components/dosificacionEvaPN";
import DosificarEvaAd from "./components/dosificacionEvaAd";
import AsociarEva from "./components/asociarEvaluaciones";
import ReporteNotasP from "./components/reporteNotas";
import ReporteSemanaDP from "./components/reporteSemanaDosificacionP";
import ReporteSemanaDPD from "./components/reporteSemanaDosificacionPD"; 
import InformeAdecuaciones from "./components/informeAdecuaciones"; 
import InformeAdecuacionesC from "./components/informeAdecuacionesCor"; 
import ReporteNotasPendiente from "./components/reporteEvaPend";
import VerPagos from "./components/consultarPagosProve";
import TiposArea from "./components/tiposAreas";
import NombresAreas from "./components/nombreArea";
import Escala from "./components/escalaLiteral";
import EvaluacionM from "./components/evaluacionMaternal";
import PermisosNotas from "./components/permisosNotas";
import PermisosNotasD from "./components/permisosNotasDiagnostico";
import AutorizarProfeDosi from "./components/activarProfeDosificacion";
import ActualizarProfeDosi from "./components/actualizarProfeDosi";
import ActualizarProfeDosiD from "./components/actualizarProfeDosiDiag";
import AutorizarMEAgenda from "./components/autorizarModiEliPlanificacion";
import DiasF from "./components/feriados";
import CopiaAgendaA from "./components/copiaAgendaPorAnio";
import PermisoAO from "./components/permisoAgendaOtros";
import ConsultaInf2 from './components/consultarInfTe2';
import ObservacionBPN from './components/onservacionBPN';


const AppRoutes = () => (
  <Switch>
    <Route exact path="/observacionesBPN" component={ObservacionBPN} />
    <Route exact path="/consutaInf2" component={ConsultaInf2} />
    <Route exact path="/permisoAgendaOtros" component={PermisoAO} />
    <Route exact path="/copiarAgen" component={CopiaAgendaA} />
    <Route exact path="/diasF" component={DiasF} />
    <Route exact path="/autorizarMEAgenda" component={AutorizarMEAgenda} />
    <Route exact path="/actualizarDosiD" component={ActualizarProfeDosiD} />
    <Route exact path="/actualizarDosi" component={ActualizarProfeDosi} />
    <Route exact path="/autorizarPD" component={AutorizarProfeDosi} />
    <Route exact path="/permisosNotas" component={PermisosNotas} />
    <Route exact path="/permisosNotasD" component={PermisosNotasD} />
    <Route exact path="/evaluacionM" component={EvaluacionM} />
    <Route exact path="/escala" component={Escala} />
    <Route exact path="/nombresAreas" component={NombresAreas} />
    <Route exact path="/tiposArea" component={TiposArea} />
    <Route exact path="/verPagos" component={VerPagos} />
    <Route exact path="/NotasPendiente" component={ReporteNotasPendiente} />
    <Route exact path="/informeAdeC" component={InformeAdecuacionesC} />
    <Route exact path="/informeAde" component={InformeAdecuaciones} />
    <Route exact path="/reporteSemanaDPD" component={ReporteSemanaDPD} />
    <Route exact path="/reporteSemanaDP" component={ReporteSemanaDP} />
    <Route exact path="/reporteNotasP" component={ReporteNotasP} />
    <Route exact path="/asociarEva" component={AsociarEva} />
    <Route exact path="/dosificarEvaAd" component={DosificarEvaAd} />
    <Route exact path="/dosificarEvaPN" component={DosificarEvaPN} />
    <Route exact path="/dosificarEva" component={DosificarEva} />
    <Route exact path="/dosificarDEAd" component={DosificarDEAd} />
    <Route exact path="/dosificarDE" component={DosificarDE} />
    <Route exact path="/dosificarDEPN" component={DosificarDEPN} />
    <Route exact path="/bloqueHora" component={BloqueHorario} />
    <Route exact path="/cambioRefe" component={CambioReferencias} />
    <Route exact path="/horarioPla" component={HorarioPla} />
    <Route exact path="/porcentajeM" component={PorcentajeM} />
    <Route exact path="/porcentajeMD" component={PorcentajeMD} />
    <Route exact path="/codigoQr" component={CodigoQR} />
    <Route exact path="/seccionVeranito" component={SeccionV} />
    <Route exact path="/maternalVeranito" component={MaternalV} />
    <Route exact path="/planiH" component={PlaniHabilitada} />
    <Route exact path="/referencia" component={ReferenciaPago} />
    <Route exact path="/reporteCxP" component={ReporteCxP} />
    <Route exact path="/correosMu" component={CorreosMu} />
    <Route exact path="/cierreRe" component={CierreRE} />
    <Route exact path="/reporteF" component={ReporteF} />
    <Route exact path="/inasistencias" component={Inasistencia} />
    {/* <Route exact path="/inasistenciasG" component={InasistenciaG} /> */}
    <Route exact path="/planillaV" component={PlanillaV} />
    <Route exact path="/planEvaluacionM" component={PlanEvaluacionM} />
    <Route exact path="/autorizarCT" component={AutorizarCT} />
    <Route exact path="/usuarios" component={MaestroUsuario} />
    <Route exact path="/registroCompras" component={RegistrarPagos} />
    <Route exact path="/cierreT" component={CierreTrimestre} />
    <Route exact path="/autorizarBoletines" component={AutorizarBoletines} />
    <Route exact path="/autorizarExpediente" component={AutorizarExpediente} />
    <Route exact path="/autorizarAgenda" component={AutorizarAgenda} />
    <Route exact path="/habiliarAde" component={HabilitarAdecuaciones} />
    <Route exact path="/habitos" component={HabitosActitudes} />
    <Route exact path="/profesorGuia" component={ProfesorGuia} />
    <Route exact path="/consultaEvaluaciones" component={ConsultaEvaluaciones} />
    <Route exact path="/cambioProfesor" component={CambioProfesor} />
    <Route exact path="/evaluacionesT" component={EvaluacioneT} />
    <Route exact path="/expedienteNotas" component={ExpedienteNotas} />
    <Route exact path="/expedienteNotasN" component={ExpedienteNotasN} />
    <Route exact path="/expedienteNotasDN" component={ExpedienteNotasDN} />
    <Route exact path="/expedienteNotasNM" component={ExpedienteNotasNM} />
    <Route exact path="/expedienteMaterias" component={ExpedienteMaterias} />
    <Route exact path="/expedienteMateriasN" component={ExpedienteMateriasN} />
    <Route exact path="/expedienteMateriasDN" component={ExpedienteMateriasDN} />
    <Route exact path="/expedienteMateriasNP" component={ExpedienteMateriasNP} />
    <Route exact path="/reporteMaestros" component={ReporteMaestros} />
    <Route exact path="/archivosPlani" component={ArchivosPlanificacion} />
    <Route exact path="/consultaActividades" component={ConsultaActividades} />
    <Route exact path="/asistencia" component={ControlAsistencia} />
    <Route exact path="/actividadesA" component={MostrarTrabajos} />
    <Route exact path="/actividades" component={Actividades} />
    <Route exact path="/reportePlaniMaestro" component={ReportePM} />
    <Route exact path="/alumnosxMaestros" component={AlumnosMaestro} />
    <Route exact path="/reporteP" component={ReporteP} />
    <Route exact path="/actualizarAgenda" component={ActualizarPlanificacion} />
    <Route exact path="/fechaPlanificacion" component={FechaPlanificacion} />
    <Route
      exact
      path="/planificacionAprobados"
      component={PlanificacionAprobados}
    />
    <Route exact path="/planificacion" component={Planificacion} />
    <Route exact path="/descripcionE" component={DescripcionE} />
    <Route exact path="/seccionAlumnos" component={SeccionesA} />
    <Route exact path="/seccionProfesor" component={SeccionesP} />
    <Route exact path="/secciones" component={Secciones} />
    <Route exact path="/evaluaciones" component={Evaluacion} />
    <Route exact path="/evaluacionesD" component={EvaluacionD} />
    <Route exact path="/planEvaluacion" component={PlanE} />
    <Route exact path="/materiaProfesor" component={MateriasP} />
    <Route exact path="/materiasAsignadas" component={MateriasA} />
    <Route exact path="/materias" component={Materias} />
    <Route exact path="/reciboI" component={ReciboIndividual} />
    <Route exact path="/nomina" component={Nomina} />
    <Route exact path="/facturacion" component={Facturacion} />
    <Route exact path="/notaCreditoM" component={NotaCreditoM} />
    <Route exact path="/notaCredito" component={NotaCredito} />
    <Route exact path="/notaCreditoP" component={NotaCreditoProveedor} />
    <Route exact path="/facturaRe" component={FacturacionRe} />
    <Route exact path="/recepcion" component={Recepcion} />
    <Route exact path="/facturaTerapia" component={FacturacionTera} />
    <Route exact path="/cxp" component={CxP} />
    <Route exact path="/proveedores" component={Proveedores} />
    <Route exact path="/clientes" component={Clientes} />
    <Route exact path="/correosi" component={CorreosI} />
    <Route exact path="/correosiC" component={CorreosIC} />
    <Route exact path="/entradasInventario" component={EntradasInventario} />
    <Route exact path="/despachoP" component={DespachoP} />
    <Route exact path="/inventarioL" component={InventarioL} />
    <Route exact path="/inventario" component={Inventario} />
    <Route exact path="/correos" component={Correos} />
    <Route exact path="/reporteInvFac" component={ReporteInvFac} />
    <Route exact path="/reportes" component={Reportes} />
    <Route exact path="/reporteContabilidad" component={ReportesContables} />
    <Route exact path="/reportesAca2" component={ReportesAca2} />
    <Route exact path="/reportesAca3" component={ReportesAca3} />
    <Route exact path="/reportesAca" component={ReportesAca} />
    <Route exact path="/reinscripcion" component={Reinscripcion} />
    <Route exact path="/extension" component={ExtensionM} />
    <Route exact strict path="/anioescolarT" component={ActivarEscolarT} />
    <Route exact strict path="/anioescolar" component={ActivarEscolar} />
    <Route exact strict path="/canje" component={Canje} />
    <Route exact strict path="/conceptos" component={Conceptos} />
    <Route exact strict path="/mensualidadM" component={MensualidadMa} />
    <Route exact strict path="/mensualidad" component={Mensualidad} />
    <Route exact strict path="/inscripcionG" component={Inscripcion} />
    <Route exact strict path="/adjuntos" component={ArchivosUp} />
    <Route exact strict path="/planillai" component={PlanillaI} />
    <Route exact strict path="/cxc" component={CxC} />
    <Route exact strict path="/cxcNuevo" component={CxCNuevo} />
    <Route exact strict path="/cxcCli" component={CxCCli} />
    <Route exact strict path="/pagosaprobados" component={PagosAprobados} />
    <Route exact strict path="/pagosrecibidos" component={PagosRecibidos} />
    <Route exact strict path="/grados" component={Grados} />
    <Route exact strict path="/pago" component={Pago} />
    <Route exact strict path="/configuracionA" component={ConfiguracionAlumnos} />
    <Route exact strict path="/configuracion" component={Configuracion} />
    <Route exact strict path="/login" component={Login} />
    <Route exact strict path="/" component={Inicio} />
    <Route exact strict path="/chat" component={Chat} />
    <Route exact strict path="/chatI" component={ChatI} />
    <Route component={Pagina404} />
  </Switch>
);
export default AppRoutes;
