import React, { Component } from 'react';

//componentes
import GradoAction from './Grado'

class Grado extends Component {

    render(){
        return(
            <div>
                <GradoAction />
            </div>
        );
    }

}
export default Grado;