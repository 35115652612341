import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
//import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./expedienteMateriasN.css";
import Alert from "../../modal/alert";

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      otro: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      trimestre: "",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      consul: false,
      iactividades: [],
      imaterias: [],
      icursos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      ianoEscolar: [],
      iexpediente: [],
      icantidadEva: [],
      ievaluadas: [],
      loading: false,
      tipocierre: "NO",
      nombreProfesor:'',
      nombreGrado:'',
      nombreMateria:'',
      nombreTrimestre:''
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      trimestre: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      imaterias: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      consul: false,
      iexpediente: [],
      icantidadEva: [],
      ievaluadas: [],
      loading: false,
      tipocierre: "NO",
      nombreProfesor:'',
      nombreGrado:'',
      nombreMateria:'',
      nombreTrimestre:''

    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        tipocierre: "SI",
      });
    } else {
      this.setState({
        tipocierre: "NO",
      });
    }
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    let index2 = e.target.selectedIndex;
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idprofesor") {
      this.buscarCurso(e.target.value);
      this.setState({nombreProfesor:e.target.options[index2].text,});
    }
    if (index === "idcurso"){
       this.buscarPlanE(e.target.value);
       this.setState({nombreGrado:e.target.options[index2].text,});
    }
    if (index === "trimestre") {
      this.buscarTrimestre(e.target.value);
      this.setState({nombreTrimestre:e.target.options[index2].text,});
    }
    if (index === "idmateria") {
      //this.consultarActividades(e.target.value);
      this.setState({nombreMateria:e.target.options[index2].text,});
    }
  }

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              idmateria: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  buscarTrimestre = (e) => {
    if (this.props.usuario1.nivel === "3") {
      let data = new FormData();
      data.append("codigo", e);
      data.append("tipocierre", this.state.tipocierre);
      data.append("boton", "consultarActivo");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
        } else {
          this.setState({
            trimestre: "",
            cedulaAlumno: "",
            mensajealerta: true,
            tituloM: "Menu Expediente de Notas.",
            cuerpoM:
              "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
          });
        }
      });
    }
  };

  eliminar = () => {
    if (this.state.idactividad.length > 0) {
      let data = new FormData();

      data.append("idactividad", this.state.idactividad);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/actividades.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          let e = "";
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Planificacion.",
        cuerpoM: "Debe existir un registro para poder eliminarlo.",
      });
    }
  };

  consultar(e) {
    if (e.length > 0) {
      this.setState({ idactividad: e });
      let data = new FormData();
      data.append("idactividad", e);
      data.append("boton", "buscarActividadesI");

      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            idmateria: res.data.variable2,
            idcurso: res.data.variable3,
            descripcion: res.data.variable4,
            ocultar: res.data.variable5,
            botonguardarOn: true,
            botonmodiOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  }

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  grabartexto() {
    if (
      this.state.descripcion.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.ocultar.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcion", this.state.descripcion);
      data.append("ocultar", this.state.ocultar);
      data.append("boton", "crearActividades");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Actividades",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  mostrarAsignacion(e, f, g) {
    if (e !== "") {
      let data = new FormData();
      data.append("cedulaAlumno", e);
      data.append("descripcionE", f);
      data.append("idevaluaciones", g);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", this.state.trimestre);
      data.append("boton", "consultarAsignacion3");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/backend/evaluacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Descripción de la Evaluación",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Descripción de la Evaluación",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Descripción de la Evaluación",
        cuerpoM: "Debe hacer click sobre una Nota.",
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Planificacion",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    if (this.props.usuario1.nivel === "3") {
      form.append("boton", "buscarPersonal3");
    } else {
      form.append("boton", "buscarPersonal2");
    }
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;

      this.setState({ iprofesor: xiprofesor });

      if (this.props.usuario1.nivel === "3") {
        //console.log(res.data.variable2)
        this.buscarCurso2(res.data.variable2);
      } else {
        this.buscarCurso();
      }
    });
  };

  buscarCurso2 = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarCurso = (e) => {
    let data = new FormData();
    data.append("idprofesor", e);
    data.append("boton", "buscarCurso");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            icursos: xicursos,
          });
        } else {
          this.setState({
            icursos: [],
          });
        }
      });
  };

  consultarExpediente = () => {
    this.setState({ loading: true });
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("trimestre", this.state.trimestre);
    data.append("idgrado", this.state.idcurso);
    data.append("idmateria", this.state.idmateria);
    data.append("idprofesor", this.state.idprofesor);
    data.append("boton", "buscarPlanMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/expedienteMaterias3.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiexpediente = res.data.variable1;
        let xicantidadEva = res.data.variable2;
        this.setState({
          iexpediente: xiexpediente,
          icantidadEva: xicantidadEva,
        });
        this.setState({ loading: false });
      });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Actividad.",
      cuerpoM: "Realmente Quiere Eliminar la Actividad...",
      idactividad: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  handleConsultar = () => {
    this.setState({
      mensajealerta: false,
      consul: false,
      listaOn: true,
    });
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    this.buscarAnioEscolar();
    this.buscarProfesor();
  }

  reporte = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("trimestre", this.state.trimestre);
    data.append("idgrado", this.state.idcurso);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/expedienteAcademico.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  imprimirExpediente = () => {
    let data = new FormData();
    data.append("nombreProfesor", this.state.nombreProfesor);
    data.append("nombreGrado", this.state.nombreGrado);
    data.append("nombreMateria", this.state.nombreMateria);
    data.append("nombreTrimestre", this.state.nombreTrimestre);
    data.append("arreglo",  JSON.stringify(this.state.iexpediente));
    data.append("arreglo2",  JSON.stringify(this.state.icantidadEva));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/imprimirExpedienteAcademicoExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "ExpedientaAcademico.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  render() {
    //console.log(this.state.iexpediente);
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>REPORTE DE ASIGNACIONES POR MATERIA. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              consul={this.state.consul}
              handleEliminar={this.handleEliminar}
              handleConsultar={this.handleConsultar}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.listaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Listado de estudiantes</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.ievaluadas.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td className="renglon" width="10">
                                {item.variable2}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  {/* <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Año Escolar --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option value="">--- Seleccione un Profesor ---</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idmateria"
                      name="idmateria"
                      value={this.state.idmateria}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Materia --</option>
                      {this.state.imaterias.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    {/* {this.props.usuario1.nivel !== "3" && (
                      <div className="contenedorcedula1f col-2">
                        <p className="">Sin Cierre:</p>

                        <input
                          type="checkbox"
                          id="oferta1"
                          name="tipocierre"
                          value="SI"
                          checked={this.state.tipocierre === "SI"}
                          onChange={this.onChange1.bind(this)}
                        />
                      </div>
                    )} */}
                    {/* <button className="boton" onClick={this.reporte.bind(this)}>
                      Reporte
                    </button> */}
                    <button
                      className="boton"
                      onClick={this.consultarExpediente.bind(this)}
                    >
                      Consultar
                    </button>
                    <button
                      className="boton"
                      onClick={this.imprimirExpediente.bind(this)}
                    >
                      Imprimir
                    </button>
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomixx" border="1">
                    {this.state.iexpediente.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : ""}
                          key={i}
                          id="tablax"
                        >
                          {item.variable1 === "NOMBRE ALUMNO" && (
                            <thead className="theadcreartabla">
                              <tr className="trcreartabla">
                                {item.variable16 > 0 && (
                                  <th scope="col" className="xtitunomina2E">
                                    {item.variable1}
                                  </th>
                                )}
                                {item.variable16 > 0 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable17 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable17 >= "6" &&
                                          item.variable17 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable17 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3" 
                                    }
                                  >
                                    {item.variable2}
                                  </th>
                                )}
                                {item.variable16 >= 2 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable18 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable18 >= "6" &&
                                          item.variable18 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable18 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable3}
                                  </th>
                                )}
                                {item.variable16 >= 3 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable19 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable19 >= "6" &&
                                          item.variable19 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable19 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable4}
                                  </th>
                                )}
                                {item.variable16 >= 4 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable20 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable20 >= "6" &&
                                          item.variable20 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable20 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable5}
                                  </th>
                                )}
                                {item.variable16 >= 5 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable21 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable21 >= "6" &&
                                          item.variable21 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable21 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable6}
                                  </th>
                                )}
                                {item.variable16 >= 6 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable22 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable22 >= "6" &&
                                          item.variable22 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable22 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable7}
                                  </th>
                                )}
                                {item.variable16 >= 7 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable23 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable23 >= "6" &&
                                          item.variable23 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable23 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable8}
                                  </th>
                                )}
                                {item.variable16 >= 8 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable24 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable24 >= "6" &&
                                          item.variable24 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable24 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable9}
                                  </th>
                                )}
                                {item.variable16 >= 9 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable25 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable25 >= "6" &&
                                          item.variable25 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable25 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable10}
                                  </th>
                                )}
                                {item.variable16 >= 10 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable26 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable26 >= "6" &&
                                          item.variable26 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable26 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable11}
                                  </th>
                                )}
                                {item.variable16 >= 11 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable27 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable27 >= "6" &&
                                          item.variable27 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable27 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable12}
                                  </th>
                                )}
                                {item.variable16 >= 12 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable28 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable28 >= "6" &&
                                          item.variable28 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable28 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable13}
                                  </th>
                                )}
                                {item.variable16 >= 13 && (
                                  <th
                                    scope="col"
                                    className={
                                      item.variable29 <= "5"
                                        ? "xtitunomina1EEx1"
                                        : item.variable29 >= "6" &&
                                          item.variable29 <= "8"
                                        ? "xtitunomina1EEx2"
                                        : item.variable29 >= "9"
                                        ? "xtitunomina1EEx3"
                                        : "xtitunomina1EEx3"
                                    }
                                  >
                                    {item.variable14}
                                  </th>
                                )}
                              </tr>
                            </thead>
                          )}
                          {item.variable1 !== "NOMBRE ALUMNO" && (
                            <tr className="trcreartabla">
                              {item.variable16 >= 1 && (
                                <td
                                  width="150"
                                  scope="col"
                                  className="xtitunomina2EE"
                                >
                                  {item.variable15}
                                </td>
                              )}
                              {item.variable16 >= 2 && (
                                <td
                                  width="160"
                                  scope="col"
                                  className={
                                    item.variable2 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable2 >= "6" &&
                                        item.variable2 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable2 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                      
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable17
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 0 &&
                                        j < item.variable2 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35 
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 3 && (
                                <td
                                  scope="col"
                                  className={
                                    item.variable3 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable3 >= "6" &&
                                        item.variable3 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable3 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable18
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 1 &&
                                        j < item.variable3 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 4 && (
                                <td
                                  scope="col"
                                  className={
                                    item.variable4 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable4 >= "6" &&
                                        item.variable4 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable4 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable19
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 2 &&
                                        j < item.variable4 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 5 && (
                                <td
                                  scope="col"
                                  className={
                                    item.variable5 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable5 >= "6" &&
                                        item.variable5 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable5 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable20
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 3 &&
                                        j < item.variable5 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 6 && (
                                <td
                                  scope="col"
                                  className={
                                    item.variable6 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable6 >= "6" &&
                                        item.variable6 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable6 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable21
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 4 &&
                                        j < item.variable6 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 7 && (
                                <td
                                  scope="col"
                                  className={
                                    item.variable7 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable7 >= "6" &&
                                        item.variable7 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable7 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable22
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 5 &&
                                        j < item.variable7 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 8 && (
                                <td
                                  className={
                                    item.variable8 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable8 >= "6" &&
                                        item.variable8 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable8 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable23
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 6 &&
                                        j < item.variable8 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 9 && (
                                <td
                                  className={
                                    item.variable9 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable9 >= "6" &&
                                        item.variable9 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable9 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable24
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 7 &&
                                        j < item.variable9 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 10 && (
                                <td
                                  className={
                                    item.variable10 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable10 >= "6" &&
                                        item.variable10 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable10 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable25
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 8 &&
                                        j < item.variable10 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 11 && (
                                <td
                                  className={
                                    item.variable11 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable11 >= "6" &&
                                        item.variable11 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable11 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable26
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 9 &&
                                        j < item.variable11 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 12 && (
                                <td
                                  className={
                                    item.variable12 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable12 >= "6" &&
                                        item.variable12 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable12 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable27
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 10 &&
                                        j < item.variable12 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable37
                                                  )
                                                : j === 8
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable38
                                                  )
                                                : j === 9
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable39
                                                  )
                                                : j === 10
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable40
                                                  )
                                                : j === 11
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable41
                                                  )
                                                : j === 12
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable42
                                                  )
                                                : j === 13
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable43
                                                  )
                                                : j === 14
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable44
                                                  )
                                                : j === 15
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable45
                                                  )
                                                : j === 16
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable46
                                                  )
                                                : j === 17
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable47
                                                  )
                                                : j === 18
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable48
                                                  )
                                                : j === 19
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable49
                                                  )
                                                : j === 20
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable50
                                                  )
                                                : j === 21
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable51
                                                  )
                                                : j === 22
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable52
                                                  )
                                                : j === 23
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable53
                                                  )
                                                : j === 24
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable54
                                                  )
                                                : j === 25
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable55
                                                  )
                                                : j === 26
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable56
                                                  )
                                                : j === 27
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable57
                                                  )
                                                : j === 28
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable58
                                                  )
                                                : j === 29
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable59
                                                  )
                                                : j === 30
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable60
                                                  )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                              {item.variable16 >= 13 && (
                                <td
                                  className={
                                    item.variable13 <= "5"
                                      ? "xtitunomina1Ex1"
                                      : item.variable13 >= "6" &&
                                        item.variable13 <= "8"
                                      ? "xtitunomina1Ex2"
                                      : item.variable13 >= "9"
                                      ? "xtitunomina1Ex3"
                                      : "xtitunomina1Ex3" 
                                  }
                                  // onClick={this.mostrarAsignacion.bind(
                                  //   this,
                                  //   item.variable1,
                                  //   item.variable28
                                  // )}
                                >
                                  {item.variable30.map((variable_, keyVar) =>
                                    Object.keys(variable_).map(
                                      (item2, j) =>
                                        keyVar === 11 &&
                                        j < item.variable13 && (
                                          <td
                                            className="valornota"
                                            onClick={
                                              j === 0
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable30
                                                  )
                                                : j === 1
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable31
                                                  )
                                                : j === 2
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable32
                                                  )
                                                : j === 3
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable33
                                                  )
                                                : j === 4
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable34
                                                  )
                                                : j === 5
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable35
                                                  )
                                                : j === 6
                                                ? this.mostrarAsignacion.bind(
                                                    this,
                                                    item.variable1,
                                                    item.variable17,
                                                    variable_.variable36
                                                  )
                                                  : j === 7
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable37
                                                    )
                                                  : j === 8
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable38
                                                    )
                                                  : j === 9
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable39
                                                    )
                                                  : j === 10
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable40
                                                    )
                                                  : j === 11
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable41
                                                    )
                                                  : j === 12
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable42
                                                    )
                                                  : j === 13
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable43
                                                    )
                                                  : j === 14
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable44
                                                    )
                                                  : j === 15
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable45
                                                    )
                                                  : j === 16
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable46
                                                    )
                                                  : j === 17
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable47
                                                    )
                                                  : j === 18
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable48
                                                    )
                                                  : j === 19
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable49
                                                    )
                                                  : j === 20
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable50
                                                    )
                                                  : j === 21
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable51
                                                    )
                                                  : j === 22
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable52
                                                    )
                                                  : j === 23
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable53
                                                    )
                                                  : j === 24
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable54
                                                    )
                                                  : j === 25
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable55
                                                    )
                                                  : j === 26
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable56
                                                    )
                                                  : j === 27
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable57
                                                    )
                                                  : j === 28
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable58
                                                    )
                                                  : j === 29
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable59
                                                    )
                                                  : j === 30
                                                  ? this.mostrarAsignacion.bind(
                                                      this,
                                                      item.variable1,
                                                      item.variable17,
                                                      variable_.variable60
                                                    )
                                                : ""
                                            }
                                          >
                                            {variable_[item2]}
                                          </td>
                                        )
                                    )
                                  )}
                                </td>
                              )}
                            </tr>
                          )}
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Actividades);
