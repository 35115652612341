import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import { buscarCantidadChatISinLeer } from "../../API/CoreI";
import {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
} from "../../accion/accion";

//componentes
import "./planificacion.css";
import Alert from "../../modal/alert";
import AsignacionesT from "../actividadesTemporales/actividadesT";
import Modal from "../modal3/modal3";
class PlanificacionAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      fecha: "",
      textoevo: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      url1: "",
      nombreUrl1: "",
      url2: "",
      nombreUrl2: "",
      habilitar: "NO",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      guard: false,
      asignacionOn: false,
      linkOn: false,
      imaterias: [],
      icursos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      ianoEscolar: [],
      irutas: [],
      inombreEva: [],
      mostrarOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      fecha: "",
      textoevo: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      url1: "",
      nombreUrl1: "",
      url2: "",
      nombreUrl2: "",
      habilitar: "NO",
      asignacionOn: false,
      imaterias: [],
      icursos: [],
      // iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      inombreEva: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      guard: false,
      linkOn: false,
      irutas: [],
      mostrarOn: false,
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "fechaDesde") {
      this.setState({ idfechaPlani: "", inombreEva: [], idcurso:'', idmatera:'', imaterias:[], ifechaplani:[] });
    }
    if (index === "trimestre") {
      this.setState({ idfechaPlani: "", ifechaplani: [] });
      this.buscarTrimestre(e.target.value);
    }
    if (index === "idprofesor") {
      this.buscarCurso(e.target.value);
      this.initData3(e.target.value);
    }
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    if (index === "idcurso") this.buscarFP(e.target.value);
    if (index === "idcurso") this.setState({ ifechaplani: [] });
    if (index === "idmateria")
      this.setState({ idfechaPlani: "", ifechaplani: [] });
    if (index === "idfechaPlani" && parseInt(e.target.value) > 0) {
      this.buscarFPI(e.target.value);
      this.consultarfecha(e.target.value);
      this.grabarEvaluacion();
    }
  }

  grabarEvaluacion() {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    data.append("idmateria", this.state.idmateria);
    data.append("fecha", this.state.fechaDesde);
    data.append("trimestre", this.state.trimestre);
    data.append("boton", "buscarEvaPro");
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/backend/dosificaciones.php",
      data: data,
    }).then((res) => {
      let xinombreEva = res.data.variable2;
      if (res.data.variable1) {
        this.setState({
          inombreEva: xinombreEva,
        });
      } else {
        this.setState({
          inombreEva: xinombreEva,
        });
      }
    });
  }

  buscarTrimestre = (e) => {
    let data = new FormData();
    data.append("codigo", e);
    data.append("boton", "consultarActivo");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
      } else {
        this.setState({
          trimestre: "",
          mensajealerta: true,
          tituloM: "Menu Control de Asistencia.",
          cuerpoM:
            "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
        });
      }
    });
  };

  eliminar = () => {
    if (this.state.idplani.length > 0) {
      let data = new FormData();

      data.append("idplanificacion", this.state.idplani);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Planificacion",
            cuerpoM: res.data.variable2,
          });
          this.consultarfecha(this.state.idfechaPlani);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Planificacion",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Planificacion.",
        cuerpoM: "Debe existir un registro para poder eliminarlo.",
      });
    }
  };

  consultarfecha(e) {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idcurso", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("idfechaPlani", e);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", this.state.trimestre);
      data.append("boton", "buscarFechasP");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/planificacion.php",
        data: data,
      }).then((res) => {
        let xifechaplani = res.data.variable2;
        this.setState({
          ifechaplani: [],
          ifechaplani: xifechaplani,
        });
      });
    }
  }

  consultarfecha2(e, f) {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idplanificacion", e);
      data.append("boton", "buscarFechasPI");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/planificacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            botonguardarOn: true,
            botonmodiOn: true,
            idplanificacion: e,
            textoevo: res.data.variable2,
            url1: res.data.variable3,
            url2: res.data.variable4,
            nombreUrl1: res.data.variable5,
            nombreUrl2: res.data.variable6,
            habilitar: f,
          });
          //this.consultaPermisos(e);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Planificacion",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  }

  consultaPermisos = (e) => {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idagenda", e);
    data.append("boton", "consultaPermisos");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/backend/autorizarMEAgenda.php",
      data: data,
    }).then((res) => {
      if (res.data.variable1) {
        if (res.data.variable2 === "") {
          this.setState({
            botonmodiOn: false,
          });
        }
      } else {
        this.setState({
          botonmodiOn: false,
        });
        //this.guardar1();
      }
    });
  };

  asignacionesActivo = () => {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividadesT");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      if (res.data.variable1) {
        this.grabartexto();
      } else {
        this.guardar1();
      }
    });
  };

  grabartexto() {
    if (
      this.state.textoevo.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.idfechaPlani.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.fechaDesde.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idcurso", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("idfechaPlani", this.state.idfechaPlani);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("texto", this.state.textoevo);
      data.append("fechaDesde", this.state.fechaDesde);
      data.append("url1", this.state.url1);
      data.append("url2", this.state.url2);
      data.append("nombreUrl1", this.state.nombreUrl1);
      data.append("nombreUrl2", this.state.nombreUrl2);
      data.append("trimestre", this.state.trimestre);

      data.append("boton", "Nuevo");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/planificacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.consultarfecha(this.state.idfechaPlani);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Planificacion",
            cuerpoM: res.data.variable2,
            textoevo: "",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Planificacion",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Planificacion",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos tengan registros",
      });
    }
  }

  modificartexto() {
    if (this.state.textoevo.length > 0) {
      let data = new FormData();
      data.append("idplanificacion", this.state.idplanificacion);
      data.append("texto", this.state.textoevo);
      data.append("url1", this.state.url1);
      data.append("url2", this.state.url2);
      data.append("nombreUrl1", this.state.nombreUrl1);
      data.append("nombreUrl2", this.state.nombreUrl2);
      data.append("boton", "modificar");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/planificacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Planificacion",
            cuerpoM: res.data.variable2,
            textoevo: "",
            botonguardarOn: false,
            botonmodiOn: false,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Planificacion",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  referencias = () => {
    if (this.state.linkOn) {
      this.setState({ linkOn: false });
    } else {
      this.setState({ linkOn: true });
    }
  };
  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Planificacion",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("fechaDesde", this.state.fechaDesde);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable9;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              idmateria: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  guardar1 = () => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Planificación.",
      cuerpoM:
        "No se a creado una asignación, realmente quiere Guardar la Planificación...",
      guard: true,
    });
  };

  handleGuardar = () => {
    this.setState({
      mensajealerta: false,
      guard: false,
    });
    this.grabartexto();
    //console.log('eliminar')
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Planificación.",
      cuerpoM: "Realmente Quiere Eliminar la Planificación...",
      idplani: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.consultaPermisos(this.state.idplanificacion);
    this.eliminar();
  };

  asignacion = () => {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0
    ) {
      if (this.state.asignacionOn) {
        this.setState({ asignacionOn: false });
      } else {
        this.setState({ asignacionOn: true });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Planificación.",
        cuerpoM:
          "Debe seleccionar los datos para la planificación, y luego accesar a las Asignaciones...",
      });
    }
  };

  consultarAdjuntos() {
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("idarchivos", this.state.idprofesor);
    data.append("boton", "consultarArchivosPlanificacionProfesores");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
            mostrarOn: true,
          });
        } else {
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
            adjuntosOn: false,
          });
        }
      });
    this.setState({
      loading: false,
    });
  }

  // buscarProfesor = () => {
  //   let form = new FormData();
  //   form.append("clave", this.props.usuario1.password);
  //   form.append("boton", "buscarPersonal3");
  //   let config = {
  //     headers: { "Content-Type": "text/html" },
  //   };
  //   const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
  //   axios.post(xurl, form, config).then((res) => {
  //     let iprofesor = res.data.variable1;
  //     iprofesor.map((item, i) => {
  //       this.setState({ idprofesor: item.variable1 });
  //     });
  //   });
  // };

  initData3 = async (e) => {
    let response = await buscarCantidadChatISinLeer(e);
    if (response.data.variable1) {
      this.props.saveBotonMensajeI(true);
    } else {
      this.props.saveBotonMensajeI(false);
    }
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    this.buscarAnioEscolar();
    this.buscarProfesor();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU DE PLANIFICACION. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={
                this.state.elimi === true && this.state.guard === false
                  ? this.state.elimi
                  : this.state.elimi === false && this.state.guard === true
                  ? this.state.guard
                  : ""
              }
              handleEliminar={
                this.state.elimi === true && this.state.guard === false
                  ? this.handleEliminar
                  : this.state.elimi === false && this.state.guard === true
                  ? this.handleGuardar
                  : ""
              }
            />
          )}
          {this.state.asignacionOn === true && (
            <div className="flotadorA">
              <div className="flotadorA1">
                <AsignacionesT
                  idprofesor={this.state.idprofesor}
                  idcurso={this.state.idcurso}
                  idmateria={this.state.idmateria}
                  anoEscolar={moment().format("YYYY")}
                  asignacionClose={this.asignacion}
                />
              </div>
            </div>
          )}

          {this.state.mostrarOn === true && (
            <Modal
              open={this.state.mostrarOn}
              someHTML2={this.state.irutas}
              onClose={() => this.setState({ mostrarOn: false })}
            />
          )}

          {this.state.linkOn === true && (
            <div className="flotadorR">
              <div className="flotadorA1">
                <div className="contenedor col-12">
                  <div className="titulo-cxc ">
                    <p>INDIQUE UN MAXIMO DE 2 LINK. </p>
                  </div>
                  <div className="row">
                    <div className="xcontenedorcedula1x col-3">
                      <input
                        className="form-control"
                        type="text"
                        name="url1"
                        id="url1"
                        autoComplete="off"
                        placeholder="Dirección URL 1"
                        onChange={this.onChange.bind(this)}
                        value={this.state.url1}
                      />
                    </div>
                    <div className="xcontenedorcedula1x col-3">
                      <input
                        className="form-control"
                        type="text"
                        name="nombreUrl1"
                        id="nombreUrl1"
                        autoComplete="off"
                        placeholder="Nombre a Mostrar"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nombreUrl1.toUpperCase()}
                      />
                    </div>

                    <div className="xcontenedorcedula1x col-3">
                      <input
                        className="form-control"
                        type="text"
                        name="url2"
                        id="url2"
                        autoComplete="off"
                        placeholder="Dirección URL 2"
                        onChange={this.onChange.bind(this)}
                        value={this.state.url2}
                      />
                    </div>
                    <div className="xcontenedorcedula1x col-3">
                      <input
                        className="form-control"
                        type="text"
                        name="nombreUrl2"
                        id="nombreUrl2"
                        autoComplete="off"
                        placeholder="Nombre a Mostrar"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nombreUrl2.toUpperCase()}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="botones col-12">
                      <div className="acciones">
                        <button
                          className="boton"
                          onClick={this.consultarAdjuntos.bind(this)}
                        >
                          Archivos Personales
                        </button>
                        <button
                          className="boton"
                          onClick={this.referencias.bind(this)}
                        >
                          Salir
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              {this.state.fechaDesde !== "" && (
                <div className="row">
                  <div className="cont-personal col-12">
                    <div className="contenedorcedula1f col-3">
                      <select
                        id="idprofesor"
                        name="idprofesor"
                        value={this.state.idprofesor}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Seleccionar Profesor --</option>
                        {this.state.iprofesor.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idcurso"
                        name="idcurso"
                        value={this.state.idcurso}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Seleccionar Grado --</option>
                        {this.state.icursos.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idmateria"
                        name="idmateria"
                        value={this.state.idmateria}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Materia --</option>
                        {this.state.imaterias.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="trimestre"
                        name="trimestre"
                        value={this.state.trimestre}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Seleccionar Trimestre --</option>
                        <option value="1">1ER. TRIMESTRE</option>
                        <option value="2">2DO. TRIMESTRE</option>
                        <option value="3">3ER. TRIMESTRE</option>
                      </select>
                    </div>
                    <div className="contenedorcedula1f col-3">
                      <select
                        id="idfechaPlani"
                        name="idfechaPlani"
                        value={this.state.idfechaPlani}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Tipo Planificacion --</option>
                        {this.state.ifechaPlani.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="desde col-2">
                  <span>Fecha Planificaciónx.</span>
                </div>
                <div className="contenedorcedula1x col-2">
                  <input
                    className="form-control"
                    type="date"
                    name="fechaDesde"
                    id="fechaDesde"
                    autoComplete="off"
                    onChange={this.onChange2.bind(this)}
                    value={this.state.fechaDesde.toUpperCase()}
                  />
                </div>

                <div className="botones col-8">
                  <div className="acciones">
                    <button
                      className={
                        !this.state.botonguardarOn ? "boton" : "botonOf"
                      }
                      onClick={
                        !this.state.botonguardarOn
                          ? this.asignacionesActivo.bind(this)
                          : ""
                      }
                    >
                      Guardar
                    </button>
                    {this.state.habilitar === "NO" && (
                      <button
                        className={this.state.botonmodiOn ? "boton" : "botonOf"}
                        onClick={
                          this.state.botonmodiOn
                            ? this.modificartexto.bind(this)
                            : ""
                        }
                      >
                        Modificar
                      </button>
                    )}
                    <button
                      className={
                        !this.state.botonguardarOn ? "boton" : "botonOf"
                      }
                      onClick={
                        !this.state.botonguardarOn
                          ? this.asignacion.bind(this)
                          : ""
                      }
                    >
                      Asignaciones
                    </button>
                    {this.state.habilitar === "NO" && (
                      <button
                        className="boton"
                        onClick={this.referencias.bind(this)}
                      >
                        Link y Archivos
                      </button>
                    )}
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="groupev col-9">
                  <Editor
                    apiKey="ay4ih1j1a9fjh8rkh2qqbe58x6apk6rg375carfgbw1v2mgy"
                    //apiKey="a1aqg2kfe80tdy89j82fg04h5k1zt0jikjqj7ng5ook86xz5"
                    name="textoevo"
                    id="textoevo"
                    value={this.state.textoevo}
                    init={{
                      //selector: '#editor',
                      force_br_newlines: true,
                      forced_root_block: false,
                      //force_p_newlines : false,
                      browser_spellcheck: true,
                      contextmenu: false,
                      plugins: "table, textcolor, spellchecker",
                      encoding: "UTF-8",
                      entity_encoding: "raw",
                      contextmenu_never_use_native: true,
                      max_height: 550,
                      max_width: 800,
                      height: 550,
                      width: 800,
                      custom_ui_selector: ".boton",
                      spellchecker_callback: function (
                        method,
                        text,
                        success,
                        failure
                      ) {
                        var words = text.match(this.getWordCharPattern());
                        if (method === "spellcheck") {
                          var suggestions = {};
                          for (var i = 0; i < words.length; i++) {
                            suggestions[words[i]] = ["First", "Second"];
                          }
                          success({ words: suggestions, dictionary: [] });
                        } else if (method === "addToDictionary") {
                          // Add word to dictionary here
                          success();
                        }
                      },
                      menu: {},
                      toolbar1:
                        "alignleft | aligncenter | alignright | alignjustify | fontsizeselect | fontselect | formatselect",
                      toolbar2:
                        "cut | copy paste | bold italic | underline | table | forecolor | backcolor",
                    }}
                    onEditorChange={this.handleEditorChange.bind(this)}
                  />
                </div>

                <div className="container col-3">
                  <div className="listadopcDO col-12">
                    <table className="tabla2pc">
                      <thead>
                        <tr>
                          <th className="tituevo">Fecha Planificaciones</th>
                          <th className="tituevo">
                            <span className="tituevo">
                              <span className="cerrarme3"></span>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="cuerpo">
                        {this.state.ifechaplani.map((item, i) => {
                          //console.log("paso "+i);

                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                            >
                              <td
                                className="itemtabla"
                                key={item.variable1}
                                onClick={this.consultarfecha2.bind(
                                  this,
                                  item.variable1,
                                  item.variable3
                                )}
                              >
                                {item.variable2}
                              </td>
                              {item.variable3 === "NO" && (
                                <td className="xcerr">
                                  {/* {this.props.usuario1.nivel === "1" && ( */}
                                    <span
                                      className="cerrarme1"
                                      onClick={this.eliminar1.bind(
                                        this,
                                        item.variable1
                                      )}
                                    ></span>
                                  {/* )} */}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="groupevDO col-12">
                  <table className="tabla2pc">
                    <thead>
                      <tr>
                        <th className="tituevo">Tipo de Evaluaciones</th>
                        <th className="tituevo">Nombre de Evaluaciones</th>
                        <th className="titucxc4">Porc.</th>
                      </tr>
                    </thead>
                    <tbody className="cuerpo">
                      {this.state.inombreEva.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                          >
                            <td className="itemtabla" key={item.variable1}>
                              {item.variable2}
                            </td>
                            <td className="itemtabla" key={item.variable1}>
                              {item.variable3}
                            </td>
                            <td className="itemtabla" key={item.variable1}>
                              {item.variable4}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanificacionAction);
