import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./cierreTriR.css";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      concepto: "",
      iconcepto: [],
      listaOn: false,
      activar: "",
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      concepto: "",
      listaOn: false,
      activar: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              codigo: res.data.variable1,
              cedula: res.data.variable2,
              nombre: res.data.variable3,
              motivo: res.data.variable4,
              ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  guardar = () => {
    let data = new FormData();
    data.append("concepto", this.state.concepto);
    data.append("activar", this.state.activar);
    data.append("boton", "guardar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/anioEscolar.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.buscarAnioEscolar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear y Activar Año Escolar.",
            cuerpoM: "Se guardo correctamente.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear y Activar Año Escolar.",
            cuerpoM: "Ocurrio un problema para guardar el registro.",
          });
        }
      });
  };

  activarAnioEscolar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();

      data.append("codigo", e);
      data.append("boton", "activarTrimestre");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.buscarAnioEscolar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Activar Trimestre.",
            cuerpoM: "Ocurrio un error, No Se Pudo Activar el Trimestre.",
          });
        }
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();

      data.append("codigo", e);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/anioEscolar.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear y Activar Año Escolar.",
            cuerpoM: "Se Elimino correctamente el registro.",
          });
          this.buscarAnioEscolar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear y Activar Año Escolar.",
            cuerpoM: "Ocurrio un error, No Se Pudo Eliminar el registro.",
          });
        }
      });
    }
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarCierreR");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiconcepto = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iconcepto: xiconcepto,
          });
        } else {
          this.setState({
            iconcepto: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  habilitarcerrarR(e) {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idhabilitarR", e);
      data.append("boton", "habilitarCierreR");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.buscarAnioEscolar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Cierre de Trimestre Representante",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Cierre de Trimestre Representante",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Cierre de Trimestre",
        cuerpoM: "Debe seleccionar Profesor, Grado y Trimestre.",
      });
    }
  }

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  componentDidMount() {
    this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ACTIVAR TRIMESTRE REPRESENTANTE. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          <div className="contenedorp row">
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.props.asignacionClose}>
                Salir
              </button>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc3">PROFESOR</th>
                    <th className="titucxc3x">GRADO</th>
                    <th className="titucxc3x">TRIMESTRE</th>
                    <th className="titucxc3x">HABILITAR</th>
                  </tr>
                </thead>

                {this.state.iconcepto.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxcx1"
                        onClick={this.activarAnioEscolar.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="totalescxcx1"
                        onClick={this.activarAnioEscolar.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="totalescxcx1"
                        onClick={this.activarAnioEscolar.bind(
                          this,
                          item.variable1
                        )}
                      >
                        {item.variable4}
                      </td>
                      <td className="titucxc3xpe">
                        <span
                          className="cerrarme6"
                          onClick={this.habilitarcerrarR.bind(
                            this,
                            item.variable1
                          )}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConceptoAction;
