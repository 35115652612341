import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardSubtitle,
  CardImg,
  CardTitle,
  CardText,
  CardColumns,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import renderHTML from "react-render-html";

const ModalExample = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.onClose}
        scrollable={true}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={props.onClose}>Consulta de Archivos</ModalHeader>
        <ModalBody
          body
          inverse
          style={{ backgroundColor: "#bbbbbb", borderColor: "#333" }}
        >
          {props.someHTML2.map((item, i) => {
            return (
              <Card className="mb-4">
                <CardHeader>Nombre: {item.variable2}</CardHeader>
                <CardHeader>URL: {item.variable3}</CardHeader>
                <CardBody>
                  <a
                    href={item.variable3}
                    target="popup"
                    onClick="window.open(this.href, this.target, 'width=350,height=420'); return false;"
                  >
                    {" "}
                    <img src={item.variable3} height="25px" width="25px" />{" "}
                  </a>
                </CardBody>
              </Card>
            );
          })}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={props.onClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
