import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./nomina.css";
import Alert from "../../modal/alert";
import Personal from "./empleado";
import FormularioColaborador from "../formularioColaborador/formularioColabora";
import NominaC from "./crearNomina";
import Decimo from "./decimo";
import Prestamo from "../prestamosE/prestamosE";
import RegistroPago from "../registrarPagoNomina/registroPagoNomina";
import RegistroPagoG from "../registroPagoNominaMultiple/registroPagoNominaM";
import Vacaciones from "./vacaciones";
import Antiguedad from "./antiguedad";

function Nomina(props) {
  //const [ifechamedi, setifechamedi] = React.useState([]);
  const [listaOn, setlistaOn] = React.useState(false);
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [personal, setpersonal] = React.useState([]);
  // const [listaCorreo, setlistaCorreo] = React.useState([]);
  const [flotadorPDOn, setflotadorPDOn] = React.useState(false);
  const [flotadorFCOn, setflotadorFCOn] = React.useState(false);
  const [flotadorRCOn, setflotadorRCOn] = React.useState(false);
  const [flotadorNOn, setflotadorNOn] = React.useState(false);
  const [flotadorDeOn, setflotadorDeOn] = React.useState(false);
  const [flotadorPreOn, setflotadorPreOn] = React.useState(false);
  const [flotadorPagoOn, setflotadorPagoOn] = React.useState(false);
  const [flotadorPagoGOn, setflotadorPagoGOn] = React.useState(false);
  const [flotadorVOn, setflotadorVOn] = React.useState(false);
  const [flotadorAOn, setflotadorAOn] = React.useState(false);
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");
  const [cedulaTabla, setcedulaTabla] = React.useState("");

  const handleClose = () => {
    setmensajealerta(false);
  };

  const colaboradoresClose = () => {
    if (flotadorFCOn) {
      setflotadorFCOn(false);
    } else {
      setflotadorFCOn(true);
    }
  };
  const requisitosClose = () => {
    if (flotadorRCOn) {
      setflotadorRCOn(false);
    } else {
      setflotadorRCOn(true);
    }
  };

  const personalClose = () => {
    if (flotadorPDOn) {
      setflotadorPDOn(false);
      setcedulaTabla("");
      buscarPersonal();
    } else {
      setflotadorPDOn(true);
    }
  };

  const decimoClose = (e) => {
    if (flotadorDeOn) {
      setflotadorDeOn(false);
    } else {
      setflotadorDeOn(true);
    }
  };

  const proceso = (e) => {
    if (flotadorPDOn) {
      setflotadorPDOn(false);
      buscarPersonal();
    } else {
      setcedulaTabla(e);
      setflotadorPDOn(true);
    }
  };

  const activarPrestamo = () => {
    if (flotadorPreOn) {
      setflotadorPreOn(false);
    } else {
      setflotadorPreOn(true);
    }
  };

  const activarPago = () => {
    if (flotadorPagoOn) {
      setflotadorPagoOn(false);
    } else {
      setflotadorPagoOn(true);
    }
  };

  const activarPagoG = () => {
    if (flotadorPagoGOn) {
      setflotadorPagoGOn(false);
    } else {
      setflotadorPagoGOn(true);
    }
  };

  const activarNomina = (e) => {
    if (flotadorNOn) {
      setflotadorNOn(false);
    } else {
      setflotadorNOn(true);
    }
  };

  const activarVacaciones = (e) => {
    if (flotadorVOn) {
      setflotadorVOn(false);
    } else {
      setflotadorVOn(true);
    }
  };

  const activarAntiguedad = () => {
    if (flotadorAOn) {
      setflotadorAOn(false);
    } else {
      setflotadorAOn(true);
    }
  };
  const buscarPersonal = () => {
    let form = new FormData();
    form.append("dedonde", "crearNomina");
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xpersonal = res.data.variable1;
      setpersonal(xpersonal);
    });
  };

  const consultarAntiguedad = () => {
    setmensajealerta(true);
    settituloM("Menu Generar Nomina.");
    setcuerpoM("Aun no esta Autorizado para ejecutar este Modulo, Disculpe.");
  };

  React.useEffect(() => {
    //buscarSalon();
    buscarPersonal();
  }, []);

  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
          <p>NOMINA DE INSTITUTO BILINGUE LAS NACIONES. </p>
        </div>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        {flotadorPDOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <Personal
                cedulaempleado={cedulaTabla}
                personalClose={personalClose}
              />
            </div>
          </div>
        )}
        {flotadorFCOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <FormularioColaborador personalClose={colaboradoresClose} />
            </div>
          </div>
        )}
        {flotadorRCOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <Personal personalClose={requisitosClose} />
            </div>
          </div>
        )}

        {flotadorNOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <NominaC personalClose={activarNomina} />
            </div>
          </div>
        )}

        {flotadorVOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <Vacaciones personalClose={activarVacaciones} />
            </div>
          </div>
        )}

        {flotadorAOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <Antiguedad personalClose={activarAntiguedad} />
            </div>
          </div>
        )}

        {flotadorDeOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <Decimo personalClose={decimoClose} />
            </div>
          </div>
        )}

        {flotadorPreOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <Prestamo personalClose={activarPrestamo} />
            </div>
          </div>
        )}

        {flotadorPagoOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <RegistroPago personalClose={activarPago} />
            </div>
          </div>
        )}

        {flotadorPagoGOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <RegistroPagoG personalClose={activarPagoG} />
            </div>
          </div>
        )}

        <div className="cont-listmedi row">
          <div className="listcxc col-12">
            <table className="tablacxc" border="1">
              <thead>
                <tr>
                  <th className="titunomina1">CEDULA</th>
                  <th className="titunomina2">NOMBRE</th>
                  <th className="titunomina3">CARGO</th>
                  <th className="titunomina1">NOMINA</th>
                  <th className="titunomina1">STATUS</th>
                </tr>
              </thead>
              {personal.map((item, i) => {
                return (
                  <tr
                    className={i % 2 === 0 ? "odd" : "odd"}
                    key={item.variable1}
                    id="tabla"
                    onClick={
                      props.usuario1.nivel !== "2" &&
                      (() => {
                        proceso(item.variable1);
                      })
                    }
                  >
                    <td className="totalesnomina1" width="10">
                      {item.variable2}
                    </td>
                    <td className="totalesnomina2" width="10">
                      {item.variable3}
                    </td>
                    <td className="totalesnomina3" width="10">
                      {item.variable4}
                    </td>
                    <td
                      className="totalesnomina1"
                      width="10"
                      id={item.variable1}
                      data-valor={item.variable5}
                      //onContextMenu={this.handleClick}
                    >
                      {item.variable5}
                    </td>
                    <td className="totalesnomina1" width="10">
                      {item.variable6}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="contenedorcedula1 col-12">
          {/* <button className="boton" onClick={colaboradoresClose}>
            Formulario Colaboradores
          </button> */}
          {props.usuario1.nivel !== "5" && (
            <button className="boton" onClick={personalClose}>
              Crear Empleados
            </button>
          )}
          {props.usuario1.nivel !== "5" && props.usuario1.nivel !== "2" && (
            <button className="boton" onClick={activarNomina}>
              Nomina
            </button>
          )}
          {props.usuario1.nivel !== "5" &&
            props.usuario1.nivel !== "2" &&
            props.usuario1.nivel !== "10" && (
              <button className="boton" onClick={decimoClose}>
                Crear XIII Mes
              </button>
            )}
          {props.usuario1.nivel !== "5" &&
            props.usuario1.nivel !== "2" &&
            props.usuario1.nivel !== "10" && (
              <button className="boton" onClick={activarVacaciones}>
                Vacaciones
              </button>
            )}
          {props.usuario1.nivel !== "5" &&
            props.usuario1.nivel !== "2" &&
            props.usuario1.nivel !== "10" && (
              <button className="boton" onClick={activarAntiguedad}>
                Antiguedad
              </button>
            )}
          {props.usuario1.nivel !== "5" &&
            props.usuario1.nivel !== "2" &&
            props.usuario1.nivel !== "10" && (
              <button className="boton" onClick={activarPrestamo}>
                Prestamos
              </button>
            )}
          {props.usuario1.nivel !== "10" && props.usuario1.nivel !== "2" && (
            <button className="boton" onClick={activarPago}>
              Pago Nomina Ind.
            </button>
          )}
          {props.usuario1.nivel !== "10" && props.usuario1.nivel !== "2" && (
            <button className="boton" onClick={activarPagoG}>
              Pago Nomina Gene.
            </button>
          )}
          <Link to="/">
            <button className="boton">Salir</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Nomina);
