import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";
import moment from "moment";

//componentes
import "./entradasInv.css";
import Alert from "../../modal/alert";
import FeatherIcon from "feather-icons-react";

class EntradaInventario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajealerta: false,
      cuentaContable: "",
      idinventario: "",
      nombrepro: "",
      costo: "",
      cantidad: "",
      precio: "",
      stockminimo: "",
      impuesto: "",
      flotadorCOn: false,
      irutas: [],
      inventario: [],
      cuenta: [],
      mes: [],
      anio: [],
      ianoEscolar: [],
      itenEntrada: [],
      nombreImagen: "",
      guardarOn: false,
      codigo3: moment().format("MM"),
      codigo4: moment().format("YYYY"),
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      idinventario: "",
      cuentaContable: "",
      nombrepro: "",
      costo: "",
      cantidad: "",
      precio: "",
      stockminimo: "",
      impuesto: "",
      flotadorCOn: false,
      // irutas:[],
      nombreImagen: "",
      guardarOn: false,
    });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            cantidad: "",
          });
        }
      });
  };

  guardar = () => {
    //console.log(e);
    if (this.state.nombrepro.length > 0) {
      let data = new FormData();
      data.append("idproducto", this.state.idproducto);
      data.append("cantidad", this.state.cantidad);
      data.append("costo", this.state.costo);
      data.append("precio", this.state.precio);
      data.append("boton", "guardarEntradas");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/inventario.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          this.setState({
            loading: false,
          });
          if (res.data.variable1) {
            this.limpiar();
            this.buscarEntradas();
            this.setState({
              guardarOn: true,
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  buscarEntradas = () => {
    let data = new FormData();
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    data.append("boton", "buscarEntradas");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        // if (res.data.variable1) {
        this.setState({
          itenEntrada: res.data.variable2,
        });
        // }
      });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  eliminarItenFactura = (e) => {
    let data = new FormData();
    data.append("identrada", e);
    data.append("boton", "eliminarEntrada");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarEntradas();
        }
      });
  };

  componentDidMount() {
    this.consultarInventario();
    this.cargarFechas();
    this.buscarAnioEscolar();
    this.buscarEntradas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ENTRADA PRODUCTO AL INVENTARIO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}
          <div className="contenedorcedula1f1 col-4">
            <select
              id="idproducto"
              name="idproducto"
              value={this.state.idproducto}
              onChange={this.onChange1.bind(this)}
            >
              {this.state.tipoFac === "SERVICIOS" ? (
                <option>-- Seleccionar Servicios --</option>
              ) : (
                <option>-- Seleccionar Producto --</option>
              )}

              {this.state.inventario.map((item, i) => {
                return (
                  <option key={i} value={item.variable1}>
                    {item.variable2}{" "}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula1 col-5">
              <input
                className="form-control"
                type="text"
                name="nombrepro"
                id="nombrepro"
                autoComplete="off"
                placeholder="Nombre del Producto"
                maxLength="300"
                onChange={this.onChange.bind(this)}
                value={this.state.nombrepro.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="cantidad"
                id="cantidad"
                autoComplete="off"
                placeholder="Cantidad."
                maxLength="5"
                onChange={this.onChange.bind(this)}
                value={this.state.cantidad}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="costo"
                id="costo"
                autoComplete="off"
                placeholder="Costo."
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.costo}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="precio"
                id="precio"
                autoComplete="off"
                placeholder="Precio."
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.precio}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="contenedorcedula3 col-12">
              {this.props.usuario1.nivel !== "5" && (
                <button
                  className={this.state.guardarOn ? "botonOf" : "boton"}
                  onClick={this.guardar.bind(this)}
                  disabled={this.state.guardarOn}
                >
                  Guardar
                </button>
              )}
              {/* {this.props.usuario1.nivel !== "5" && (
                <button
                  className={this.state.guardarOn ? "boton" : "botonOf"}
                  // onClick={this.modificar.bind(this)}
                  disabled={!this.state.guardarOn}
                >
                  Modificar
                </button>
              )}
              {this.props.usuario1.nivel !== "5" && (
                <button
                  className={this.state.guardarOn ? "boton" : "botonOf"}
                  // onClick={this.eliminar.bind(this)}
                  disabled={!this.state.guardarOn}
                >
                  Eliminar
                </button>
              )} */}
              <button className="boton" onClick={this.limpiar}>
                Limpiar
              </button>
              {/* <button className={this.state.guardarOn?"boton":"botonOf"} onClick={this.Upload.bind(this)} disabled={!this.state.guardarOn}>Eliminar</button> */}
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula3 col-12">
              <div className="fechas col-12">
                <select
                  id="codigo3"
                  name="codigo3"
                  value={this.state.codigo3}
                  onChange={this.onChange.bind(this)}
                >
                  <option>--Mes--</option>
                  {this.state.mes.map((item, i) => {
                    return (
                      <option key={item.variable1} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
                <select
                  id="codigo4"
                  name="codigo4"
                  value={this.state.codigo4}
                  onChange={this.onChange.bind(this)}
                >
                  <option>--Año--</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={item.variable1} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
                <button className="boton" onClick={this.buscarEntradas}>
                  Buscar
                </button>
              </div>
              <div className="cont-listmedi row">
                <div className="listcxc col-12">
                  <table className="tablacxc" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc2">PRODUCTO</th>
                        <th className="titucxc3">CANTIDAD</th>
                        <th className="titucxc1">COSTO</th>
                        <th className="titucxc1">PRECIO</th>
                        <th className="titucxc1">FECHA</th>
                        <th className="titucxc3">
                          <FeatherIcon
                            icon="delete"
                            size="30px"
                            stroke="#999"
                            color="red"
                          />
                        </th>
                      </tr>
                    </thead>
                    {this.state.itenEntrada.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td className="totalescxc" width="10">
                            {item.variable2}
                          </td>
                          <td className="renglon" width="10">
                            {item.variable3}
                          </td>
                          <td className="totalescxc1" width="10">
                            {item.variable4}
                          </td>
                          <td className="totalescxc1" width="10">
                            {item.variable5}
                          </td>
                          <td className="totalescxc1" width="10">
                            {item.variable6}
                          </td>
                          <td className="titucxc3" width="10">
                            {this.props.usuario1.nivel === "1" && (
                              <FeatherIcon
                                icon="delete"
                                size="30px"
                                stroke="#999"
                                color="red"
                                onClick={this.eliminarItenFactura.bind(
                                  this,
                                  item.variable1
                                )}
                              ></FeatherIcon>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(EntradaInventario);
