import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./correosInterno.css";
import AdjuntarArchivo from "../adjuntarArchivosIC/adjuntararchivosI";
import Alert from "../../modal/alert";

function CorreosInternos(props) {
  const [ifechamedi, setifechamedi] = React.useState([]);
  const [ianoEscolar, setIanoEscolar] = React.useState([]);
  const [listaOn, setlistaOn] = React.useState(false);
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [correoR, setcorreoR] = React.useState([]);
  const [listaCorreo, setlistaCorreo] = React.useState([]);
  const [flotadorMOn, setflotadorMOn] = React.useState(false);
  const [correoaEnviar, setcorreoaEnviar] = React.useState([]);
  const [iseccion, setiseccion] = React.useState([]);
  const [correoI, setcorreoI] = React.useState("");
  const [flotadorCOn, setflotadorCOn] = React.useState(false);
  const [asuntoOn, setasuntoOn] = React.useState(false);
  const [data, setData] = React.useState({
    asunto: "",
    cuerpo: "",
    descripcion: "",
    anoEscolar: "",
  });
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");

  const limpiar = () => {
    setmensajealerta(false);
    setcorreoI("");
    setflotadorMOn(false);
    setcorreoaEnviar([]);
    setlistaCorreo([]);
    setcorreoI("");
    setflotadorCOn(false);
    setasuntoOn(false);
    setData({});
  };

  const cerrarLista = () => {
    //console.log(listaOn)
    if (listaOn) {
      setlistaOn(false);
    }
    if (!listaOn) {
      setlistaOn(true);
    }
  };

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);
    //if (index === "descripcion") buscarCorreosInternos(e.target.value);
  };

  const onChange4 = (e) => {
    let element = e.target;
    let listaCorreo_ = Array.from(listaCorreo);
    if (element.checked) {
      listaCorreo_.push(element.value);
    } else {
      let pos = listaCorreo_.indexOf(element.value);
      listaCorreo_.splice(pos, 1);
    }
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
    setlistaCorreo(listaCorreo_);
  };

  const handleClose = () => {
    setmensajealerta(false);
  };

  const buscarCorreosInternos = (e) => {
    if (data.anoEscolar.length > 0) {
      let form = new FormData();
      form.append("anoEscolar", data.anoEscolar);
      form.append("grado", data.descripcion);
      form.append("boton", "buscarCorreoInternos");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/correosC.php";
      axios.post(xurl, form, config).then((res) => {
        let xcorreo = res.data.variable1;

        setcorreoR(xcorreo);
      });
    }else {
      setmensajealerta(true);
      settituloM("Menu Envio de Correos.");
      setcuerpoM("Ocurrio un problema, debe seleccionar un Año Escolar.");
    }
  };

  const buscarCorreosxEnviar = () => {
    let form = new FormData();
    form.append("boton", "buscarCorreoxEnviarI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/correosC.php";
    axios.post(xurl, form, config).then((res) => {
      let xcorreo = res.data.variable1;

      setcorreoaEnviar(xcorreo);
    });
  };

  const agregarEnviarCorreo = (e) => {
    console.log(e);
    if (e.length > 0) {
      let form = new FormData();
      form.append("correo", e);
      form.append("boton", "guardarCorreosxEnviarInternos");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/correosC.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          buscarCorreosxEnviar();
        } else {
          setmensajealerta(true);
          settituloM("Menu Envio de Correos.");
          setcuerpoM("Ocurrio un problema, Verifique por favor.");
        }
      });
    }
  };

  const eliminarCorreoxEnviar = (e) => {
    if (e.length > 0) {
      let form = new FormData();
      form.append("correo", e);
      form.append("boton", "eliminarCorreosxEnviarInternos");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/correosC.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          buscarCorreosxEnviar();
        } else {
          setmensajealerta(true);
          settituloM("Menu Envio de Correos.");
          setcuerpoM("Ocurrio un problema, Verifique por favor.");
        }
      });
    }
  };

  const eliminarCorreoxEnviarT = () => {
    let form = new FormData();
    form.append("boton", "eliminarCorreosxEnviarInternosT");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/correosC.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log(res.data.variable4);
      //console.log(res.data.variable3);
      if (res.data.variable1) {
        buscarCorreosxEnviar();
      } else {
        setmensajealerta(true);
        settituloM("Menu Envio de Correos.");
        setcuerpoM("Ocurrio un problema, Verifique por favor.");
      }
    });
  };

  const adjuntarArchivo = () => {
    if (flotadorCOn === true) {
      setflotadorCOn(false);
    } else {
      setflotadorCOn(true);
    }
  };

  const asunto = () => {
    // var e = document.getElementById('cuerpo');
    // console.log(cuerpo)
    // let value =cuerpo
    if (asuntoOn === true) {
      setasuntoOn(false);
    } else {
      setasuntoOn(true);
      // if (cuerpo.length>0){
      //    e.target.value="aaa";
      //     console.log(e)

      // }
    }
  };

  const enviarCorreo = () => {
    let form = new FormData();
    form.append("asunto", data.asunto);
    form.append("cuerpo", data.cuerpo);
    form.append("listaCorreo", listaCorreo);
    form.append("boton", "enviarCorreoInternos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/correosC.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log(res.data.variable4);
      //console.log(res.data.variable3);
      if (res.data.variable1) {
        limpiar();
        buscarCorreosxEnviar();

        setmensajealerta(true);
        settituloM("Menu Envio de Correos.");
        setcuerpoM("Los Correos se Enviaron Satisfactoriamente.");
      } else {
        setmensajealerta(true);
        settituloM("Menu Envio de Correos.");
        setcuerpoM("Ocurrio un problema, Verifique por favor.");
      }
    });
  };

  const buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        setIanoEscolar(res.data.variable3);
      }
    });
  };

  const agregarTodos = () => {
    let form = new FormData();
    form.append("grado", data.descripcion);
    form.append("boton", "agregarTodosInternos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/correosC.php";
    axios.post(xurl, form, config).then((res) => {
      buscarCorreosxEnviar();
    });
  };
  const buscarSalon = () => {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/backend/grado.php/?boton=buscarGrados3"
      )
      .then((res) => {
        console.log(res);
        if (res.data) {
          let xseccion = res.data;
          //console.log(res.data)

          setiseccion(xseccion);
        }
      });
  };

  React.useEffect(() => {
    buscarSalon();
    buscarAnioEscolar();
    buscarCorreosxEnviar();
  }, []);

  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
          <p>CORREOS SOLO PARA REPRESENTANTES COORDINACIÓN. </p>
        </div>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        {flotadorCOn === true && (
          <div className="flotadorc">
            <div className="flotadorc1">
              <AdjuntarArchivo adjuntarArchivo={adjuntarArchivo} />
            </div>
          </div>
        )}

        {asuntoOn === true && (
          <div className="flotadora">
            <div className="flotadora1">
              <div className="contenedor-titulo row">
                <div className="titulo-pago col-12">
                  <p>
                    INGRESE UN ASUNTO E INFORMACION EN EL CUERPO SI ASI LO
                    DESEA.{" "}
                  </p>
                </div>
                <div className="contenedor-cedula row">
                  <div className="contenedorcedula1 col-4">
                    <input
                      className="form-control"
                      type="text"
                      name="asunto"
                      id="asunto"
                      autoComplete="off"
                      placeholder="Asunto del Mensaje."
                      maxLength="100"
                      onChange={onChange}
                      value={data.asunto}
                    />
                  </div>
                </div>
                <div className="contenedor-cedula row">
                  <div className="contenedorcedula1 col-12">
                    <textarea
                      className="form-control"
                      name="cuerpo"
                      id="cuerpo"
                      rows="7"
                      cols="100"
                      placeholder="Cuerpo del Mensaje."
                      onChange={onChange}
                      defaultValue={data.cuerpo}
                    />
                  </div>
                </div>
                <div className="contenedorcedula1 col-12">
                  <button className="boton" onClick={asunto}>
                    salir
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="cont-tablacorreo row">
          <div className="tablacorreo1 col-11">
            <div className="cont-titulomedi col-10">
              <span className="titulocasos">Correos X Grado a Enviar</span>
              <select
                id="descripcion"
                name="descripcion"
                value={data.descripcion}
                onChange={onChange}
              >
                <option value="0">-- Seleccione Grado --</option>
                {iseccion.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2}
                    </option>
                  );
                })}
              </select>
              <span></span>
              <select
                id="anoEscolar"
                name="anoEscolar"
                value={data.anoEscolar}
                onChange={onChange}
              >
                <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                {ianoEscolar.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>

              <button className="botonca" onClick={buscarCorreosInternos}>
                Buscar
              </button>
            </div>

            {/* <span className="titulo-casos">Datos del Pago</span> */}

            <div className="cont-listcorreo2 row">
              <div className="listcorreo col-12">
                <table className="tablacorreo2x" border="1">
                  <tbody className="evocuerpo1">
                    {correoR.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : ""}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td
                            className="renglon1"
                            width="10"
                            onClick={() => {
                              agregarEnviarCorreo(item.variable2);
                            }}
                          >
                            {item.variable2}
                          </td>
                          <td
                            className="renglon2"
                            width="10"
                            onClick={() => {
                              agregarEnviarCorreo(item.variable2);
                            }}
                          >
                            {item.variable3}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="contenedor-cedula row">
          <div className="cedulac col-12">
            <div>
              <button className="botonc" onClick={agregarTodos}>
                Agregar Todos
              </button>
            </div>
          </div>
        </div>

        <div className="cont-tablacorreo2 row">
          <div className="tablacorreo2 col-12">
            <div className="cont-titulomedi col-12">
              <span className="titulocasos">
                Correos a Enviar la Información.
              </span>
            </div>
            {/* <span className="titulo-casos">Datos del Pago</span> */}
            <div className="cont-listcorreo2 row">
              <div className="listcorreo2 col-12">
                <table className="tablacorreo2x" border="1">
                  <tbody className="evocuerpo1">
                    {correoaEnviar.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : ""}
                          key={item.variable1}
                          id="tabla"
                        >
                          {item.variable2.length > 0 && (
                            <td className="renglon" width="10">
                              <input
                                className=""
                                type="checkbox"
                                name={item.variable2}
                                id={item.variable2}
                                value={item.variable2}
                                onChange={onChange4}
                              />
                            </td>
                          )}
                          {item.variable2.length > 0 && (
                            <td
                              className="renglon"
                              width="10"
                              onClick={() => {
                                eliminarCorreoxEnviar(item.variable2);
                              }}
                            >
                              {item.variable2}
                            </td>
                          )}
                          {item.variable3.length > 0 && (
                            <td className="renglon" width="10">
                              <input
                                className=""
                                type="checkbox"
                                name={item.variable3}
                                id={item.variable3}
                                value={item.variable3}
                                onChange={onChange4}
                              />
                            </td>
                          )}
                          {item.variable3.length > 0 && (
                            <td
                              className="renglon"
                              width="10"
                              onClick={eliminarCorreoxEnviar.bind(
                                this,
                                item.variable2
                              )}
                            >
                              {item.variable3}
                            </td>
                          )}
                          {item.variable4.length > 0 && (
                            <td className="renglon" width="10">
                              <input
                                className=""
                                type="checkbox"
                                name={item.variable4}
                                id={item.variable4}
                                value={item.variable4}
                                onChange={onChange4}
                              />
                            </td>
                          )}
                          {item.variable4.length > 0 && (
                            <td
                              className="renglon"
                              width="10"
                              onClick={eliminarCorreoxEnviar.bind(
                                this,
                                item.variable2
                              )}
                            >
                              {item.variable4}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="contenedorcedula1 col-12">
          <button className="boton" onClick={enviarCorreo}>
            Enviar Correo
          </button>
          <button className="boton" onClick={asunto}>
            Asunto/Mensaje
          </button>
          <button className="boton" onClick={adjuntarArchivo}>
            Adjuntar Archivo
          </button>
          <button className="boton" onClick={eliminarCorreoxEnviarT}>
            Eliminar Todos los Correo
          </button>
          <Link to="/">
            <button className="boton">Salir</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CorreosInternos);
