import React, { Component } from "react";

//componentes
import Nomina from "./nomina";

class Index extends Component {
  render() {
    return (
      <div>
        <Nomina />
      </div>
    );
  }
}
export default Index;
