import axios from "axios";
import moment from "moment";
const config = {
  headers: { "Content-Type": "text/html" },
};

// Busca los datos del profesor

// Busca los chats de un profesor 

export const BuscarChats = async (profesor) => {
  let form = new FormData();
  form.append("idprofesor", profesor); 
  form.append("boton", "buscarChatEnviar");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chatInterno.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca si el profesor tiene mensajes sin leer

export const buscarCantidadChatISinLeer = async (profesor) => {
  let form = new FormData();
  form.append("idprofesor", profesor);
  form.append("boton", "buscarCantidadChatSinLeer");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chatInterno.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Marcar como leido los mensajes de un chat

export const MarcarSalaLeido = async (id_sala) => {
  let form = new FormData();
  form.append("idsala", id_sala);
  form.append("boton", "cambiarEstadoMensaje");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chatInterno.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Actualizar los mensajes de un chat

export const ActualizarMensajesChatI = async (idprofesor) => {
  let form = new FormData();
  form.append("idprofesor", idprofesor);
  form.append("boton", "actualizarMensajesChat");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chatInterno.php";
  let response = await axios.post(xurl, form, config);
  return response;
};
