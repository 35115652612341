//dependencias
import React, { Component } from 'react';

//componentes
import DiasFeriados from './diasFeriados'

class Index extends Component {

    render(){
        return(
            <div>
                <DiasFeriados />
            </div>
        );
    }

}
export default Index;