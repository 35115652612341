import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardSubtitle,
  CardImg,
  CardTitle,
  CardText,
  CardColumns,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import renderHTML from "react-render-html";
import axios from "axios";
import Alert from "../../modal/alert";
import Alert1 from "../../modal/alert";
import "./modal.css";

const ModalExample = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [someHTML, setSomeHTML] = useState([]);
  const [fecha, setFecha] = useState("");
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [mensajealerta1, setmensajealerta1] = React.useState(false);
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");
  const [evaluacionD, setEvaluacionD] = React.useState([]);

  const toggle = () => setModal(!modal);

  const onChange = (e) => {
    //console.log(e.target.name)
    setFecha(e.target.value);
    //console.log(e.target.value)
    if (fecha.length === 10) {
      consultarDosificacion(e.target.value);
    }
  };

  const actualizarAgenda = () => {
    if (fecha.length > 0) {
      let data = new FormData();
      data.append("idagenda", props.idagenda);
      data.append("idprofesor", props.idprofesor);
      data.append("fechadesde", fecha);
      data.append("boton", "crearNombreEP");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/actualizarFechaAgenda.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          //this.getDates(this.state.fecha1, this.state.fecha2)
          //let xsomeHTML = res.data.variable2;
          setmensajealerta1(true);
          settituloM("Menu Actualiza Fecha de Agendas.");
          setcuerpoM("Se Actualizo la fecha de la Agenda de forma Correcta");
        }
      });
    } else {
      setmensajealerta(true);
      settituloM("Menu Actualiza Fecha de Agendas.");
      setcuerpoM("Debe Ingresar una Fecha");
    }
  };

  const consultarDosificacion = (e) => {
    let data = new FormData();
    data.append("idprofesor", props.idprofesor);
    data.append("idgrado", props.idcurso);
    data.append("idmateria", props.idmateria);
    data.append("fecha", e);
    data.append("trimestre", props.trimestre);
    data.append("boton", "buscarEvaPro");
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/backend/dosificaciones.php",
      data: data,
    }).then((res) => {
      let xinombreEva = res.data.variable2;
      if (res.data.variable1) {
        setEvaluacionD(xinombreEva);
      } else {
        setEvaluacionD([]);
      }
    });
  };

  const handleClose = () => {
    setmensajealerta(false);
  };
  const handleClose1 = () => {
    setmensajealerta1(false);
    props.onClose();
  };

  useEffect(() => {
    setSomeHTML(props.someHTML2);
  }, []);

  return (
    <div>
      {mensajealerta === true && (
        <Alert
          tituloMensaje={tituloM}
          cuerpoMensaje={cuerpoM}
          open={mensajealerta}
          handleClose={handleClose}
        />
      )}
      {mensajealerta1 === true && (
        <Alert1
          tituloMensaje={tituloM}
          cuerpoMensaje={cuerpoM}
          open={mensajealerta1}
          handleClose={handleClose1}
        />
      )}
      <Modal
        isOpen={props.open}
        toggle={props.onClose}
        scrollable={true}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={props.onClose}>
          Consulta de Planificación
        </ModalHeader>
        <ModalBody
          body
          inverse
          style={{ backgroundColor: "#bbbbbb", borderColor: "#333" }}
        >
          {someHTML.map((item, i) => {
            return (
              <Card className="mb-4">
                {item.variable4 === "SI" ? (
                  <CardHeader>
                    {renderHTML(item.variable3)}
                    {/* {props.usuario1.nivel !== "3" && (
                      <Button
                        color="success"
                        size="sm"
                        block
                        className="habilitarB"
                        onClick={() => props.deshabilitarI(item.variable1)}
                      >
                        Deshabilitar
                      </Button>
                    )} */}
                  </CardHeader>
                ) : (
                  <CardHeader>
                    {renderHTML(item.variable3)} ***DESHABILITADO AL
                    REPRESENTANTE***
                    {/* {props.usuario1.nivel !== "3" && (
                      <Button
                        color="success"
                        size="sm"
                        block
                        className="habilitarB"
                        onClick={() => props.habilitarI(item.variable1)}
                      >
                        Habilitar
                      </Button>
                    )} */}
                  </CardHeader>
                )}
                <CardBody>
                  {/* <CardTitle tag="h5">Special Title Treatment</CardTitle> */}
                  <CardText>{renderHTML(item.variable2)}</CardText>
                  <CardText>{renderHTML(item.variable7)}</CardText>
                  <CardText>{renderHTML(item.variable6)}</CardText>
                  <CardText>{renderHTML(item.variable5)}</CardText>
                </CardBody>
              </Card>
            );
          })}
        </ModalBody>

        <ModalFooter>
          <div className="col-12">
            {evaluacionD.length > 0 && (
              <div className="row">
                <div className="groupevDOA col-12">
                  <table className="tabla2pc">
                    {/* <thead>
                      <tr>
                        <th className="tituevo">Tipo de Evaluaciones</th>
                        <th className="tituevo">Nombre de Evaluaciones</th>
                        <th className="titucxc4">Porc.</th>
                      </tr>
                    </thead> */}
                    <tbody className="cuerpo">
                      {evaluacionD.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                          >
                            <td className="itemtabla" key={item.variable1}>
                              {item.variable2}
                            </td>
                            <td className="itemtabla" key={item.variable1}>
                              {item.variable3}
                            </td>
                            <td className="itemtabla" key={item.variable1}>
                              {item.variable4}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
             <div className="row">
             {/*  <div className="contenedorfecha col-3">
                <input
                  className="form-control"
                  type="date"
                  name="fecha"
                  id="fecha"
                  autoComplete="off"
                  onChange={onChange}
                  value={fecha}
                />
              </div>*/}
              
              <div className="cont-botones col-6">
                {/* <button className ="botones-funcion" onClick={actualizarAgenda}>
                  Actualizar
                </button>
                <span> </span> */}
                <button className ="botones-funcion" onClick={props.onClose}>
                  Cerrar
                </button>
              </div>
            </div> 
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ModalExample);
