import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

//componentes
import "./evaluacionM.css";
import Alert from "../../modal/alert";

class EvaluacionAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idcurso: "",
      idcurso2: "",
      descripcionE: "",
      inputValue: "",
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: moment().format("YYYY"),
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      ianoEscolar: [moment().format("YYYY")],
      imaterias: [],
      icursos: [],
      icursos2: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      iactividades: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      activarObservacionOn: false,
      iescala: [],
      iescalaL: [],
      inombreEvaluacion: [],
      observacion: "",
      activarObsTrue: false,
      menoresTrue: false,
      idmenores: "0",
      cambioGradoBoletinPNOn: false,
      iobservacion: [],
      observacion1:'',
    };
  }

  limpiar = () => {
    this.setState({
      idprofesor: "",
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      idcurso2: "",
      inputValue: "",
      descripcionE: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      idarchivos: "",
      ievaluaciones: [],
      inombreEvaluacion: [],
      listaOn: false,
      modificarOn: false,
      activarObservacionOn: false,
      iescala: [],
      observacion: "",
      activarObsTrue: false,
      idmenores: "0",
      menoresTrue: false,
      icursos: [],
      icursos2: [],
      iprofesor: [],
      ialumnos: [],
      cambioGradoBoletinPNOn: false,
      observacion1:'',
    });
  };

  limpiar2(e) {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      idcurso: "",
      idcurso2: "",
      idplanE: "",
      cedulaAlumno: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      imaterias: [],
      icursos: [],
      icursos2: [],
      ialumnos: [],
      observacion: "",
      activarObsTrue: false,
      idmenores: "0",
      menoresTrue: false,
      cambioGradoBoletinPNOn: false,
      observacion1:'',
    });
  }
  limpiar3() {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      modificar: false,
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    let index2 = e.target.value;
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "trimestre") {
      if (this.props.usuario1.nivel === "3") this.buscarProfesor();
      if (this.props.usuario1.nivel !== "3")
        this.buscarProfesorVarios(e.target.value);
      this.setState({ descripcionE: "" });
      // if (this.state.idmenores.length > 0) {
      this.buscarTrimestre(e.target.value);
      // } else {
      //   this.setState({
      //     trimestre: "",
      //     mensajealerta: true,
      //     tituloM: "Menu Asignar Notas.",
      //     cuerpoM: "Debe Seleccionar Si es < 1 año o > 1 año, gracias.",
      //   });
      // }
    }
    if (index === "idcurso") {
      this.buscarPlanE2(e.target.value);
      this.buscarNombreEva(e.target.value);
      this.setState({ menoresTrue: false });

      if (e.target.value === "34") {
        this.setState({ menoresTrue: true });
      }

      if (e.target.value === "35") {
        this.setState({ menoresTrue: true });
      }
    }
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "cedulaAlumno")
      this.setState({ iescala: [], observacion: "", modificarOn: false });
    if (index === "nombreEvaluacion") {
      this.setState({ iescala: [] });
      let combo = document.getElementById("nombreEvaluacion");
      let texto = combo.options[combo.selectedIndex].text;
      let n = texto.search("Final");
      if (n >= 0) {
        this.setState({ activarObsTrue: true, iescala: [] });
      } else {
        this.setState({ activarObsTrue: false });
      }
      //console.log(n)
    }
  }

  onChange3(e) {
    let index = e.target.getAttribute("id");
    let index2 = e.target.value;
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "trimestre") {
      this.buscarProfesor2();
      this.setState({ descripcionE: "" });
      this.buscarTrimestre(e.target.value);
    }
    if (index === "observacion1") this.buscarObservacion(e.target.value);
    if (index === "idprofesor") this.buscarCurso(e.target.value);

    if (index === "idcurso") this.buscarNombreEva(e.target.value);

    if (index === "idcurso2") {
      this.buscarPlanE2(e.target.value);
      // this.buscarNombreEva(e.target.value);
      // this.setState({ menoresTrue: false });
    }

    if (index === "cedulaAlumno")
      this.setState({ iescala: [], observacion: "", modificarOn: false });
  }

  buscarNombreEva = (e) => {
    //if (parseInt(e) > 0) {
    let data = new FormData();
    data.append("idgrado", e);
    data.append("idprofesor", this.state.idprofesor);
    data.append("trimestre", this.state.trimestre);
    data.append("boton", "buscarNombreEvaPN");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/dosificacionesPN.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            inombreEvaluacion: ximaterias,
          });
        } else {
          this.setState({
            inombreEvaluacion: [],
          });
        }
      });
    // } else {
    //   this.setState({
    //     mensajealerta: true,
    //     tituloM: "Menu Asignar Notas.",
    //     cuerpoM: "Debe Seleccionar un Profesor, gracias.",
    //   });
    // }
  };

  buscarTrimestre = (e) => {
    let data = new FormData();
    data.append("codigo", e);
    data.append("boton", "consultarActivoMaternal");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        //this.buscarNombreEva();
      } else {
        this.setState({
          trimestre: "",
          inombreEvaluacion: [],
          mensajealerta: true,
          tituloM: "Menu Control de Asistencia.",
          cuerpoM:
            "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
        });
      }
    });
  };

  imprimirBM = () => {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.nombreEvaluacion.length > 0 &&
      this.state.cedulaAlumno.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      this.limpiar3();
      let data = new FormData();
      data.append("idcurso", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      data.append("nivelProfesor", this.props.usuario1.nivel);
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("nombreEva", this.state.nombreEvaluacion);
      data.append("anoEscolar", this.state.anoEscolar);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletinMaternales.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Escalas de Notas.",
        cuerpoM: "Debe indicar el Curso y Trimestre.",
      });
    }
  };

  imprimirBMAD = () => {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.nombreEvaluacion.length > 0 &&
      this.state.cedulaAlumno.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      this.limpiar3();
      let data = new FormData();
      data.append("idcurso", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      data.append("nivelProfesor", this.props.usuario1.nivel);
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("nombreEva", this.state.nombreEvaluacion);
      //data.append("anoEscolar", this.state.anoEscolar);
      data.append("anoEscolar", this.props.usuario1.anioE);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletinMaternalesAD.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Escalas de Notas.",
        cuerpoM: "Debe indicar el Curso y Trimestre.",
      });
    }
  };

  imprimirBMADT = () => {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.nombreEvaluacion.length > 0 &&
      this.state.cedulaAlumno.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      this.limpiar3();
      let data = new FormData();
      data.append("idcurso", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      data.append("nivelProfesor", this.props.usuario1.nivel);
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", "");
      data.append("nombreEva", this.state.nombreEvaluacion);
      //data.append("anoEscolar", this.state.anoEscolar);
      data.append("anoEscolar", this.props.usuario1.anioE);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletinMaternalesAD.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Escalas de Notas.",
        cuerpoM: "Debe indicar el Curso y Trimestre.",
      });
    }
  };

  consultar() {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.nombreEvaluacion.length > 0 &&
      this.state.cedulaAlumno.length > 0
    ) {
      this.limpiar3();
      let data = new FormData();
      data.append("idcurso", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      data.append("trimestre", this.state.trimestre);
      if (this.props.usuario1.nivel === "3")
        data.append("anoEscolar", this.state.anoEscolar);
      if (this.props.usuario1.nivel !== "3")
        data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("nombreEvaluacion", this.state.nombreEvaluacion);
      data.append("tipoMenor", this.state.idmenores);
      data.append("boton", "buscarNombreEscalas");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/boletinMaternales.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xiescala = res.data.variable2;
            this.setState({
              iescala: xiescala,
              observacion: res.data.variable3,
            });
          } else {
            this.setState({
              iescala: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Escalas de Notas.",
        cuerpoM: "Debe indicar el Curso, Alumno y Trimestre.",
      });
    }
  }

  guardar = () => {
    let pasoObser = 1;
    if (this.state.activarObsTrue && this.state.observacion.length === 0) {
      pasoObser = 0;
    }
    if (
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.nombreEvaluacion.length > 0 &&
      pasoObser === 1 &&
      this.state.cedulaAlumno.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", this.state.trimestre);
      data.append("observacion", this.state.observacion);
      data.append("nombreEvaluacion", this.state.nombreEvaluacion);
      data.append("arregloEscala", JSON.stringify(this.state.iescala));

      data.append("boton", "guardarEvaluacion");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/boletinMaternales.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Escalas de Notas.",
            cuerpoM: res.data.variable2,
            modificarOn: false,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Escalas de Notas.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Escalas de Notas.",
        cuerpoM: "Debe indicar el Curso, Alumno, Trimestre Y Observaciones.",
      });
    }
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e !== "") {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCursoMaternal");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            let xicursos2 = res.data.variable3;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
                icursos2: xicursos2,
              });
            } else {
              this.setState({
                icursos: [],
                icursos2: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Escalas de Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Escalas de Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Escalas de Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE2 = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE2");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          let xialumnos = res.data.variable3;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              ialumnos: xialumnos,
              idmateria: "",
              cedulaAlumno: "",
              descripcionE: "",
              iescala: [],
              observacion: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Escalas de Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  buscarProfesor2 = () => {
    let form = new FormData();
    form.append("boton", "buscarSoloprofesor");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarProfesor = () => {
    //if (this.props.usuario1.nivel === "3") {
    let form = new FormData();

    //form.append("clave", "CECI497");
    //form.append("clave", "DORIS2499");
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let iprofesor = res.data.variable1;
      iprofesor.map((item, i) => {
        this.setState({ idprofesor: item.variable1 });
        this.buscarCurso(item.variable1);
      });
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  observa = (e) => {
    if (this.state.activarObservacionOn) {
      this.setState({ activarObservacionOn: false });
    } else {
      this.setState({ activarObservacionOn: true, modificarOn: true });
      //console.log(e);
    }
  };

  cambioGradoBoletinPN = (e) => {
    if (this.state.cambioGradoBoletinPNOn) {
      this.limpiar();
      this.setState({ cambioGradoBoletinPNOn: false });
    } else {
      this.setState({ cambioGradoBoletinPNOn: true });
      //console.log(e);
    }
  };

  onChange6 = (index, value) => {
    //console.log(index, value);
    let escala_ = Array.from(this.state.iescala);
    // escala_[index].cuentaContable = 0;
    escala_[index].variable4 = value;
    this.setState({
      iescala: escala_,
      modificarOn: true,
    });
  };

  buscarEscala = () => {
    let data = new FormData();
    data.append("boton", "buscarEscala");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/boletinMaternales.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiescalas = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iescalaL: xiescalas,
          });
        } else {
          this.setState({
            iescalaL: [],
          });
        }
      });
  };

  buscarProfesorVarios = (e) => {
    let form = new FormData();

    form.append("trimestre", e);
    form.append("anioEscolar", this.props.usuario1.anioE);
    form.append("boton", "buscarPersonal22");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  // copiar = () => {
  //   let form = new FormData();
  //   form.append("boton", "copiar");
  //   let config = {
  //     headers: { "Content-Type": "text/html" },
  //   };
  //   const xurl =
  //     "https://institutobilinguelasnaciones.com/backend/boletinMaternales.php";
  //   axios.post(xurl, form, config).then((res) => {
  //     this.setState({
  //       mensajealerta: true,
  //       tituloM: "Menu Copiar.",
  //       cuerpoM: "se copio de forma correcta.",
  //     });
  //   });
  // };

  adquirir = () => {
    let form = new FormData();
    form.append("cedulaAlumno", this.state.cedulaAlumno);
    form.append("boton", "adquirir");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/boletinMaternales.php";
    axios.post(xurl, form, config).then((res) => {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Copiar.",
        cuerpoM: "se copio de forma correcta.",
      });
    });
  };
  consultarActividades() {
    let data = new FormData();
    data.append("boton", "buscar");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/backend/observacionBPN.php",
      data: data,
    }).then((res) => {
      let xiobservaciones = res.data.variable2;
      this.setState({
        iobservacion: xiobservaciones,
      });
    });
  }

  buscarObservacion(e) {
    let data = new FormData();
    data.append("idobservacion", e);
    data.append("boton", "buscarI");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/backend/observacionBPN.php",
      data: data,
    }).then((res) => {
      let xobservaciones = res.data.variable2;
      this.setState({
        observacion: xobservaciones,
      });
    });
  }

  componentDidMount() {
    this.buscarDE();
    this.consultarActividades();
    // if (this.props.usuario1.nivel === "3") this.buscarProfesor();
    // if (this.props.usuario1.nivel !== "3") this.buscarProfesorVarios();
    this.buscarEscala();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>EVALUACION MATERNALES, PRE-KINDER Y KINDER.</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.activarObservacionOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <div className="contenedor-cedula col-12">
                  <label className="labelcedulac">Observacion.</label>

                  <select
                    id="observacion1"
                    name="observacion1"
                    value={this.state.observacion1}
                    onChange={this.onChange3.bind(this)}
                  >
                    <option>-- Seleccionar Observacion --</option>
                    {this.state.iobservacion.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <div className="cont-Observ col-12">
                    <textarea
                      className="form-control"
                      name="observacion"
                      id="observacion"
                      rows="3"
                      cols="135"
                      readOnly="readOnly"
                      maxLength="400"
                      value={this.state.observacion}
                      onChange={this.onChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="titulo-reprecxc row">
                  <div className="xcontenedorcedula1x col-12">
                    <button className="boton" onClick={this.observa.bind(this)}>
                      Salir de Observación
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.cambioGradoBoletinPNOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <div className="contenedor-cedula col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-- Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-- Seleccionar Profesor --</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-4">
                    <select
                      id="idcurso2"
                      name="idcurso2"
                      value={this.state.idcurso2}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-- Seleccionar Grado Actual --</option>
                      {this.state.icursos2.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="titulo-reprecxc row">
                  <div className="xcontenedorcedula1x col-12">
                    <div className="contenedorcedula1 col-4">
                      <select
                        id="cedulaAlumno"
                        name="cedulaAlumno"
                        value={this.state.cedulaAlumno}
                        onChange={this.onChange3.bind(this)}
                      >
                        <option>-- Seleccionar Alumno --</option>
                        {this.state.ialumnos.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="contenedorcedula1 col-5">
                      <select
                        id="nombreEvaluacion"
                        name="nombreEvaluacion"
                        value={this.state.nombreEvaluacion}
                        onChange={this.onChange3.bind(this)}
                      >
                        <option>-- Nombre Evaluacion --</option>
                        {this.state.inombreEvaluacion.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.variable1}
                              data-descripcion={item.variable3}
                            >
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <button
                      className="boton"
                      onClick={this.imprimirBMAD.bind(this)}
                    >
                      Imprimir Boletin
                    </button>
                    <button
                      className="boton"
                      onClick={this.cambioGradoBoletinPN.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>

              {this.props.usuario1.nivel !== "3" && (
                <div className="contenedorcedula1f col-3">
                  <select
                    id="idprofesor"
                    name="idprofesor"
                    value={this.state.idprofesor}
                    onChange={this.onChange2.bind(this)}
                  >
                    <option>-- Seleccionar Profesor --</option>
                    {this.state.iprofesor.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              {this.state.menoresTrue && (
                <div className="contenedorcedula1f col-4">
                  <select
                    id="idmenores"
                    name="idmenores"
                    value={this.state.idmenores}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Seleccionar--</option>
                    <option value="1">MENORES 1 AÑO</option>
                    <option value="2">MAYORES 1 AÑO</option>
                  </select>
                </div>
              )}
              <div className="contenedorcedula1 col-4">
                <select
                  id="cedulaAlumno"
                  name="cedulaAlumno"
                  value={this.state.cedulaAlumno}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Alumno --</option>
                  {this.state.ialumnos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="titulo-reprecxc row">
              <div className="contenedorcedula1x col-12">
                <select
                  id="nombreEvaluacion"
                  name="nombreEvaluacion"
                  value={this.state.nombreEvaluacion}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Nombre Evaluacion --</option>
                  {this.state.inombreEvaluacion.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.variable1}
                        data-descripcion={item.variable3}
                      >
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  {this.state.modificarOn && this.props.usuario1.nivel === "3" && (
                    <button className="boton" onClick={this.guardar.bind(this)}>
                      Guardar
                    </button>
                  )}
                  <button className="boton" onClick={this.consultar.bind(this)}>
                    Consultar
                  </button>
                  {/* <button className="boton" onClick={this.adquirir.bind(this)}>
                  adquirir
                  </button> */}
                  {this.state.iescala.length > 0 && this.state.activarObsTrue && (
                    <button className="boton" onClick={this.observa.bind(this)}>
                      Observación
                    </button>
                  )}
                  {this.props.usuario1.nivel === "3" && (
                    <button
                      className="boton"
                      onClick={this.imprimirBM.bind(this)}
                    >
                      Imprimir Boletines
                    </button>
                  )}
                  {this.props.usuario1.nivel !== "3" && (
                    <button
                      className="boton"
                      onClick={this.imprimirBMAD.bind(this)}
                    >
                      Imprimir Boletines Ind.
                    </button>
                  )}

                  {this.props.usuario1.nivel !== "3" && (
                    <button
                      className="boton"
                      onClick={this.imprimirBMADT.bind(this)}
                    >
                      Imprimir Todos los Boletines.
                    </button>
                  )}
                  {this.props.usuario1.nivel !== "3" && (
                    <button
                      className="boton"
                      onClick={this.cambioGradoBoletinPN.bind(this)}
                    >
                      Imprimir Boletin Cambio de Seccion
                    </button>
                  )}
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                {this.state.iescala.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      {item.variable3 !== "" && (
                        // <tr>
                        //   <thead>
                        <tr>
                          <td className="titucxc2">{item.variable3}</td>
                          {/* <th className="titucxc2"></th> */}
                        </tr>
                        //   </thead>
                        // </tr>
                      )}
                      {item.variable3 === "" && (
                        <td className="totalescxcx5">{item.variable2}</td>
                      )}
                      {item.variable3 === "" && (
                        <td className="contenedorcedulaNomi" width="10">
                          <div className="contenedorcedula col-12">
                            <select
                              id={item.variable1}
                              name={item.variable1}
                              value={item.variable4}
                              //onChange={this.onChange6.bind(this)}
                              onChange={(e) =>
                                this.onChange6(i, e.target.value)
                              }
                            >
                              <option>Escala</option>

                              {this.state.iescalaL.map((item2, i) => {
                                return (
                                  <option key={i} value={item2.variable1}>
                                    {item2.variable2}{" "}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(EvaluacionAction);
