import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

//componentes
import "./nomina.css";
import Alert from "../../modal/alert";

function ReporteNominaE(props) {
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [anio, setanio] = React.useState([]);
  const [mes, setmes] = React.useState([]);
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");
  const [trimestre, settrimestre] = React.useState(0);
  const [anopagar, setanopagar] = React.useState("");
  const [mesapagar, setmesapagar] = React.useState("");
  const [openvio, setopenvio] = React.useState("");

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    if (index === "trimestre") {
      settrimestre(e.target.value);
    }
    if (index === "anopagar") {
      setanopagar(e.target.value);
    }
    if (index === "mesapagar") {
      setmesapagar(e.target.value);
    }
  };

  const onChange1=(e) =>{
    //console.log(e.target.name)
    setopenvio(e.target.value)
    //console.log(e.target.value)
  }

  const reporteGeneral = () => {
    let data = new FormData();
    data.append("anoapagar", anopagar);
    data.append("trimestre", trimestre);
    data.append("mesapagar", mesapagar);
    data.append("openvio", openvio);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteNominaGeneral2.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  const handleClose = () => {
    setmensajealerta(false);
  };

  const cargarFechas = () => {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        //let xdia = res.data.variable3;
        //dia: xdia,
        setmes(xmes);
        setanio(xanio);
      }
    });
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    //buscarPersonal();
    cargarFechas();
  }, []);

  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
          <p>GENERAR REPORTE NOMINA EMPLEADOS. </p>
        </div>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}

        <div className="cont-listmedi row">
          {/* <div className="row">
            <div className="cont-personal col-12">
              <div className="radio">
                <label className="container">
                  N/Interna
                  <input
                    type="radio"
                    value="1"
                    name="openvio"
                    checked={openvio === "1"}
                    onChange={onChange1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="radio">
                <label className="container">
                  N/Externa
                  <input
                    type="radio"
                    value="2"
                    name="openvio"
                    checked={openvio === "2"}
                    onChange={onChange1}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div> */}
          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              Quincena a consultar.
              <select
                id="trimestre"
                name="trimestre"
                value={typeof trimestre !== "undefined" ? trimestre : ""}
                onChange={onChange}
              >
                <option value="0">Seleccione Quincena</option>
                <option value="1">Primera Quincena.</option>
                <option value="2">Segunda Quincena.</option>
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Mes a Pagar.
              <select
                id="mesapagar"
                name="mesapagar"
                value={typeof mesapagar !== "undefined" ? mesapagar : ""}
                onChange={onChange}
              >
                <option value="0">Mes a Pagar</option>
                {mes.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Año a Pagar.
              <select
                id="anopagar"
                name="anopagar"
                value={typeof anopagar !== "undefined" ? anopagar : ""}
                onChange={onChange}
              >
                <option value="0">Año a Pagar</option>
                {anio.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>

        <div className="cont-personal col-12">
          <button className="botonN" onClick={reporteGeneral}>
            Generar Reporte
          </button>
          <button className="boton" onClick={props.personalClose}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ReporteNominaE);
