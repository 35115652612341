import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./bloqueH.css";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      horario: "",
      numeroH: "",
      ihorario: [],
      inumeroH: [],
      icursos: [],
      igradohorario:[],
      listaOn: false,
      numeroHOn: false,
      asociarGHOn: false,
      activar: "",
      idgrado: "",
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      horario: "",
      numeroH: "",
      listaOn: false,
      numeroHOn: false,
      asociarGHOn: false,
      activar: "",
      idgrado: "",
      ihorario: [],
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  guardarAH= () => {
    if (
      this.state.numeroH.length > 0 &&
      this.state.idgrado.length > 0 
    ) {
      let data = new FormData();
      data.append("idgrado", this.state.idgrado);
      data.append("numeroH", this.state.numeroH);

      data.append("boton", "guardarAH");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable3);
          //console.log(res.data.variable2);
          if (res.data.variable1) {
            this.consultarGH();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Grado a Horario.",
              cuerpoM: "Se guardo correctamente.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Grado a Horario.",
              cuerpoM: "El Grado ya esta Asociado a el Horario.",
            });
          }
        });
    }
  };

  guardar = () => {
    if (
      parseFloat(this.state.codigo) > 0 &&
      this.state.horario.length > 0 &&
      this.state.numeroH.length > 0
    ) {
      let data = new FormData();
      data.append("bloque", this.state.codigo);
      data.append("horario", this.state.horario);
      data.append("numeroH", this.state.numeroH);

      data.append("boton", "guardar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable3);
          //console.log(res.data.variable2);
          if (res.data.variable1) {
            this.consultar(this.state.numeroH);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Bloque de Horarios.",
              cuerpoM: "Se guardo correctamente.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Bloque de Horarios.",
              cuerpoM: "El No. del Bloque ya esta Registrado.",
            });
          }
        });
    }
  };

  eliminarGH = (e) => {
    if (e> 0) {
      let data = new FormData();

      data.append("codigo", e);
      data.append("boton", "EliminarGH");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Asociar Grado a Horarios.",
            cuerpoM: "Se Elimino correctamente el registro.",
          });
          this.consultarGH();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Asociar Grado a Horarios.",
            cuerpoM: "Ocurrio un error, No Se Pudo Eliminar el registro.",
          });
        }
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();

      data.append("codigo", e);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Bloque de Horarios.",
            cuerpoM: "Se Elimino correctamente el registro.",
          });
          this.consultar(this.state.numeroH);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Bloque de Horarios.",
            cuerpoM: "Ocurrio un error, No Se Pudo Eliminar el registro.",
          });
        }
      });
    }
  };

  consultarNH = () => {
    let data = new FormData();
    data.append("boton", "ConsultarNH");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xinumeroH = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            inumeroH: xinumeroH,
            numeroHOn: true,
          });
        } else {
          this.setState({
            inumeroH: [],
          });
        }
      });
  };

  consultarNH2 = () => {
    let data = new FormData();
    data.append("boton", "ConsultarNH");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xinumeroH = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            inumeroH: xinumeroH,
          });
        } else {
          this.setState({
            inumeroH: [],
          });
        }
      });
  };

  consultarGH= () => {
    let data = new FormData();
    data.append("boton", "ConsultarGH");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xihorario = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            igradohorario: xihorario,
          });
        } else {
          this.setState({
            igradohorario: [],
          });
        }
      });
  };

  consultar = (e) => {
    let data = new FormData();
    data.append("numeroH", e);
    data.append("boton", "Consultar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/bloqueHorario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xihorario = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            ihorario: xihorario,
            numeroHOn: false,
            numeroH: e,
          });
        } else {
          this.setState({
            ihorario: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarSalones = () => {
    let data = new FormData();
    data.append("boton", "buscarG");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/habilitarBoletin.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;

        this.setState({
          icursos: xicursos,
        });
      });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  asociarGH = () => {
    //console.log(this.state.listaOn)
    if (this.state.asociarGHOn) {
      this.setState({
        asociarGHOn: false,
      });
    }
    if (!this.state.asociarGHOn) {
      this.setState({
        asociarGHOn: true,
      });
    }
  };

  cerrarGH = () => {
    //console.log(this.state.listaOn)
    if (this.state.numeroHOn) {
      this.setState({
        numeroHOn: false,
      });
    }
    if (!this.state.numeroHOn) {
      this.setState({
        numeroHOn: true,
      });
    }
  };

  componentDidMount() {
    this.consultarNH2();
    this.buscarSalones();
    this.consultarGH();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>Bloques de Horario. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.numeroHOn && (
            <div className="cont-tablanomina row">
              <div className="tablanomina1 col-7">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Horarios Creados</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={() => {
                      this.cerrarGH();
                    }}
                  >
                    X
                  </span>
                </div>
                <span className="titulo-nomina"></span>

                <div className="cont-listnomi row">
                  <div className="listnomi col-12">
                    <table className="tablanomi" border="1">
                      <thead className="theadcreartabla">
                        <tr className="trcreartabla">
                          {/* <th className="xtitunomina1">item.</th> */}
                          <th className="xtitunomina1">Numero de Horario.</th>
                        </tr>
                      </thead>

                      <tbody className="evocuerpo1">
                        {this.state.inumeroH.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="nombrePersonal"
                                width="20"
                                onClick={() => {
                                  this.consultar(item.variable1);
                                }}
                              >
                                {item.variable2}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.asociarGHOn && (
            <div className="cont-tablanomina row">
              <div className="tablanomina1 col-7">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Asociar Grados a Horarios</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={() => {
                      this.asociarGH();
                    }}
                  >
                    X
                  </span>
                </div>
                <span className="titulo-nomina"></span>
                <div className="cont-personal col-12">
                  <select
                    id="idgrado"
                    name="idgrado"
                    value={this.state.idgrado}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Seleccionar Grado --</option>
                    {this.state.icursos.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <span className="separar"></span>
                  <select
                    id="numeroH"
                    name="numeroH"
                    value={this.state.numeroH}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Horario Numero--</option>
                    {this.state.inumeroH.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <span className="separar"></span>
                  <button className="boton" onClick={this.guardarAH.bind(this)}>
                    Agregar
                  </button>
                </div>

                <div className="cont-listnomi row">
                  <div className="listnomi col-12">
                    <table className="tablanomi" border="1">
                      <thead className="theadcreartabla">
                        <tr className="trcreartabla">
                          {/* <th className="xtitunomina1">item.</th> */}
                          <th className="xtitunomina2">Grado.</th>
                          <th className="xtitunomina2">Horario Asociado.</th>
                          <th className="titucxc3x">
                            <span className="titulo-eliminar">
                              <span className="cerrarme3"></span>
                            </span>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="evocuerpo1">
                        {this.state.igradohorario.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td className="nombrePersonal">
                                {item.variable2}
                              </td>
                              <td className="nombrePersonal">
                                {item.variable3}
                              </td>
                              <td className="xcerr">
                                <span
                                  className="cerrarme1"
                                  onClick={this.eliminarGH.bind(
                                    this,
                                    item.variable1
                                  )}
                                ></span>{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="contenedorp row">
            <div className="contenedorcedula1x col-3">
              <input
                className="form-control"
                type="number"
                name="numeroH"
                id="numeroH"
                autoComplete="off"
                placeholder="No. Horario"
                maxLength="2"
                onChange={this.onChange.bind(this)}
                value={this.state.numeroH}
              />
            </div>
            <div className="contenedorcedula1x col-3">
              <input
                className="form-control"
                type="number"
                name="codigo"
                id="codigo"
                autoComplete="off"
                placeholder="No. Bloque"
                maxLength="2"
                onChange={this.onChange.bind(this)}
                value={this.state.codigo}
              />
            </div>
            <div className="contenedorcedula1x col-6">
              <input
                className="form-control"
                type="text"
                name="horario"
                id="horario"
                autoComplete="off"
                placeholder="Horario"
                maxLength="150"
                onChange={this.onChange.bind(this)}
                value={this.state.horario.toUpperCase()}
              />
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.guardar.bind(this)}>
                Agregar
              </button>
              <button className="boton" onClick={this.consultarNH.bind(this)}>
                Consulta Horarios
              </button>
              <button className="boton" onClick={this.asociarGH.bind(this)}>
                Asociar Grados a Horarios
              </button>
              <button className="boton" onClick={this.limpiar.bind(this)}>
                Limpiar Datos
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc4">Bloque No.</th>
                    <th className="titucxc2">Bloques de Horario</th>
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.ihorario.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx1">{item.variable2}</td>
                      <td className="totalescxcx1">{item.variable3}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConceptoAction;
