//dependencias
import React, { Component } from 'react';

class Pagina404 extends Component {
    render() {
        return(
            <div className="Pagina404">
                <h1>Error de Pagina404</h1>
            </div>
        );
    }
}
export default Pagina404;