import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";
import moment from "moment";

//componentes
import "./despachoP.css";
import Alert from "../../modal/alert";
import FeatherIcon from "feather-icons-react";

class DespachoP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajealerta: false,
      cuentaContable: "",
      idinventario: "",
      nombrepro: "",
      costo: "",
      cantidad: "",
      precio: "",
      stockminimo: "",
      impuesto: "",
      flotadorCOn: false,
      irutas: [],
      inventario: [],
      cuenta: [],
      mes: [],
      anio: [],
      ianoEscolar: [],
      itenEntrada: [],
      nombreImagen: "",
      guardarOn: false,
      codigo3: moment().format("MM"),
      codigo4: moment().format("YYYY"),
      modificarSaldoOn: false,
      montoD: "",
      montoT: "",
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      idinventario: "",
      cuentaContable: "",
      nombrepro: "",
      costo: "",
      cantidad: "",
      precio: "",
      stockminimo: "",
      impuesto: "",
      flotadorCOn: false,
      // irutas:[],
      nombreImagen: "",
      guardarOn: false,
      modificarSaldoOn: false,
      montoD: "",
      montoT: "",
    });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  onChange4 = (e, f, g) => {
    
    let data = new FormData();
    data.append("facturaRe", e);
    data.append("idproducto", f);
    data.append("cantidad", g);
    data.append("claveUsu",  this.props.usuario1.password);
    data.append("boton", "despacharPendiente");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarPendientes();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Despacho Pendientes.",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Despacho Pendientes.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  consultarPendientes = () => {
    let data = new FormData();
    data.append("boton", "consultaFacturasP");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarPendientes()
          let xrutas = res.data.variable2;
          this.setState({
            itenEntrada: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  componentDidMount() {
    this.consultarPendientes();
    // this.cargarFechas();
    // this.buscarAnioEscolar();
    // this.buscarEntradas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ENTRADA PRODUCTO AL INVENTARIO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula3 col-12">
              <div className="fechas col-12">
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
              <div className="cont-listmedi row">
                <div className="listcxc col-12">
                  <table className="tablacxc" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc1">RECIBO</th>
                        <th className="titucxc1">FECHA</th>
                        <th className="titucxc2">ALUMNO</th>
                        <th className="titucxc2">PRODUCTO</th>
                        <th className="titucxc1">CANTIDAD</th>
                        <th className="titucxc1">PENDIENTE</th>
                      </tr>
                    </thead>
                    {this.state.itenEntrada.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={i}
                          id="tabla"
                        >
                          <td className="totalescxc" width="10">
                            {item.variable1}
                          </td>
                          <td className="renglon" width="10">
                            {item.variable6}
                          </td>
                          <td className="totalescxc1" width="10">
                            {item.variable5}
                          </td>
                          <td className="totalescxc1" width="10">
                            {item.variable3}
                          </td>
                          <td className="totalescxc1" width="10">
                            {item.variable4}
                          </td>
                          <td className="totalescxcx" width="10">
                            <input
                              className="chskfacRe"
                              type="checkbox"
                              name={i}
                              id={i}
                              value="SI"
                              checked={item.variable7 === "SI"}
                              onChange={this.onChange4.bind(
                                this,
                                item.variable1,item.variable2,item.variable4
                              )}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(DespachoP);
