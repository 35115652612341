import React, { Component } from 'react';

//componentes
import MensualidadG from './mensualidadG'

class Index extends Component {

    render(){
        return(
            <div>
                <MensualidadG />
            </div>
        );
    }

}
export default Index;