import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./consultaIT2.css";
import Alert from "../../modal/alert";

class SeccionesAAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      cedulaAlumno: "",
      idseccionA: "",
      trimestre: "",
      idmateria: "",
      anoEscolar: [moment().format("YYYY")],
      ianoEscolar: [],
      iseccionesA: [],
      iinasistencia: [],
      ialumnos: [],
      isecciones: [],
      imaterias: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
      xtotales: "",
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      irutas1: [],
      irutas2: [],
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      irutas1: [],
      irutas2: [],
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarAlumnos(e.target.value);
    //this.buscarSeccionesA(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.buscarAlumnos(e.target.value);
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  imprimir = () => {
    let data = new FormData();
    data.append("arregloPaciente", JSON.stringify(this.state.iinformeFechasS));
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteHorarioPaciente.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  buscarI = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idmateria.length > 0 &&
      this.state.idseccion.length > 0
    ) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("trimestre", this.state.trimestre);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      if (this.state.cedulaAlumno === "") {
        data.append("boton", "buscarInasistenciaG");
      } else {
        data.append("boton", "buscarInasistencia");
      }

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/alumnoMaestro.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              xtotales: res.data.variable3,
            });
          } else {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              mensajealerta: true,
              tituloM: "Menu Consultar Inasistencias.",
              cuerpoM: res.data.variable3,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencias.",
        cuerpoM:
          "Debe seleccionar un Trimestre, un Alumno, un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (this.state.idseccionA.length > 0) {
      let data = new FormData();
      data.append("idseccionesA", this.state.idseccionA);
      data.append("boton", "eliminarSeccionesA");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
          this.buscarSeccionesA(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Grados Alumnos.",
        cuerpoM: "Debe existir un registro para poder eliminar la Sección.",
      });
    }
  };

  buscarSeccionesA = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("cedulaAlumno", e);
        data.append("boton", "buscarSeccionesA");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xisecciones = res.data.variable3;
            let xiseccionesA = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                isecciones: xisecciones,
                iseccionesA: xiseccionesA,
              });
            } else {
              this.setState({
                iseccionesP: [],
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Grados Alumnos.",
          cuerpoM: "Debe existir un Alumno para realizar el proceso.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Seccion Alumnos.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSeccionesAG = () => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("boton", "buscarSeccionesAG");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xisecciones = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              isecciones: xisecciones,
            });
          } else {
            this.setState({
              isecciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencia.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSecciones = () => {
    let data = new FormData();
    data.append("boton", "buscarSecciones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/secciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiseccionesA = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iseccionesA: xiseccionesA,
          });
        } else {
          this.setState({
            iseccionesA: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosG");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Asignar Grados a Alumnos",
      cuerpoM:
        "Puede que el Grado Asignado ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...",
      idseccionA: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarIT = (e) => {
    let data = new FormData();
    data.append("cedulaAlumno", e);
    data.append("boton", "consultarInformeNaciones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiinformeFechasS = res.data.variable2;
        let xiinformeFechas = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
            iinformeFechasS: xiinformeFechasS,
            iinformeFechas: xiinformeFechas,
          });
          this.consultarAdjuntosInformes(e);
        } else {
          this.consultarAdjuntosInformes(e);
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
            // mensajealerta: true,
            // tituloM: "Menu Consulta de Pacientes",
            // cuerpoM: "El Alumno no tiene datos a consultar",
          });
        }
      });
  };

  consultarAdjuntosInformes(e) {
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("cedulaAlumno", e);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "consultarAdjuntosInformes");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/adjuntarArchivosInformes.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xrutas1 = res.data.variable2;

        if (res.data.variable1) {
          this.setState({
            irutas1: xrutas1,
          });
        } else {
          if (
            this.state.iinformeFechas.length === 0 &&
            this.state.iinformeFechasS.length === 0
          ) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Consulta de Pacientes",
              cuerpoM: "El Alumno no tiene datos a consultar",
            });
          }
          this.setState({
            irutas1: xrutas1,
          });
        }
      });
    this.setState({
      loading: false,
    });
  }

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Paciente con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                identificacionp1: res.data.variable1 + " " + res.data.variable2,
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3" ||
      this.state.tbusqueda === "4"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
      if (this.state.tbusqueda === "4") {
        this.buscarPorNombres3();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  componentDidMount() {
    //this.buscarIT();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxcT col-12">
          <div className="titulo-cxcT ">
            <p>CONSULTA TERAPIAS X DIAS E INFORMES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {!this.state.verplanillaOn && (
            <div className="contenedorp row">
              <div className="titulo-planillaiT col-12">
                <p>
                  SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                  CORRESPONDIENTA A SU SELECCIÓN.{" "}
                </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar por --</option>
                    <option value="1">CEDULA/PASAPORTE</option>
                    <option value="2">NOMBRE ALUMNO</option>
                    {/* <option value="3">CURSO/GRADO</option>
                    <option value="4">TODOS LOS PACIENTES</option> */}
                  </select>
                </div>
              </div>
              {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="grado"
                      name="grado"
                      value={this.state.grado}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccione Grado --</option>
                      {this.state.igrados.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda < "3" && (
                <div className="contenedorcedula1 row">
                  <input
                    className="form-control"
                    type="text"
                    name="identificacionp1"
                    id="identificacionp1"
                    autoComplete="off"
                    placeholder={
                      this.state.tbusqueda === "1"
                        ? "Identificación Paciente."
                        : "Nombre Paciente."
                    }
                    maxLength="300"
                    onChange={this.onChange.bind(this)}
                    value={this.state.identificacionp1.toUpperCase()}
                  />
                </div>
              )}
              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarPlanilla.bind(this)}
                >
                  Consultar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Otra Consulta
                </button>
                {/* <button
                  className={
                    this.state.iinformeFechasS.length > 0 ? "boton" : "botonOf"
                  }
                  onClick={
                    this.state.iinformeFechasS.length > 0
                      ? this.imprimir.bind(this)
                      : ""
                  }
                >
                  Imprimir Horario
                </button> */}
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>

              {this.state.listaOn && (
                <div className="cont-tablamedi row">
                  <div className="tablamedi1T col-11">
                    <div className="cont-titulomedi col-12">
                      <span className="titulocasosT">Listado de Alumnos</span>
                      <span
                        className="titulo-cerrarcasos"
                        onClick={this.cerrarLista.bind(this)}
                      >
                        X
                      </span>
                    </div>
                    {/* <span className="titulo-casos">Datos del Pago</span> */}
                    <div className="cont-listmedi row">
                      <div className="listmedi col-12">
                        <table className="tablameT" border="1">
                          <tbody className="evocuerpo1">
                            {this.state.nalumno.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "oddT" : ""}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td
                                    className="renglon"
                                    width="10"
                                    onClick={this.consulta.bind(
                                      this,
                                      item.variable1
                                    )}
                                  >
                                    {item.variable2}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {!this.state.verplanillaOn && (
            <div className="row">
              {this.state.iinformeFechasS.map((item, i) => {
                return (
                  <div className="cont-diasterapia">
                    {item.variable11 === "0" && (
                      <div className="cont-diasterapia2 row">
                        <div className="diasNombre col-11">
                          HORARIO DE TERAPIA
                        </div>
                        <div className="diasH">HORARIO</div>
                        <div className="diasS">LUNES</div>
                        <div className="diasS">MARTES</div>
                        <div className="diasS">MIERCOLES</div>
                        <div className="diasS">JUEVES</div>
                        <div className="diasS">VIERNES</div>
                        <div className="diasS">SABADO</div>
                        <div className="diasS">DOMINGO</div>
                      </div>
                    )}
                    {item.variable11 !== "1" && (
                      <div className="group row">
                        <div className="XdiasH">{item.variable3}</div>
                        <div className="XdiasS">{item.variable4}</div>
                        <div className="XdiasS">{item.variable5}</div>
                        <div className="XdiasS">{item.variable6}</div>
                        <div className="XdiasS">{item.variable7}</div>
                        <div className="XdiasS">{item.variable8}</div>
                        <div className="XdiasS">{item.variable9}</div>
                        <div className="XdiasS">{item.variable10}</div>
                      </div>
                    )}
                    {item.variable11 === "1" &&
                      this.state.iinformeFechas.map((item2, j) => {
                        return (
                          <div className="">
                            {item.variable1 === item2.variable1 && (
                              <div className="">
                                {item2.variable17 === 1 && (
                                  <div className="cont-diasterapia2 row">
                                    <div className="diasNombre col-11">
                                      INFORME DE LA FUNDACIÓN
                                    </div>

                                    <div className="diasHt">TERAPIA</div>
                                    <div className="diasSt">F/INICIO</div>
                                    <div className="diasSt">P/EVALUACIÓN</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasSt">INF/AVANCE 1</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasSt">INF/AVANCE 2</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasSt">INF/AVANCE 3</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasEsp">ESPECIALISTA</div>
                                  </div>
                                )}

                                <div className="group row">
                                  <div className="XdiasHt">
                                    {item2.variable2}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable3}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable4}
                                  </div>
                                  {item2.variable6 !== "" && (
                                    <div className="XdiasSt">
                                      <a
                                        href={item2.variable6}
                                        target="__blank"
                                      >
                                        {item2.variable5}
                                      </a>
                                    </div>
                                  )}
                                  {item2.variable6 === "" && (
                                    <div className="XdiasSt">
                                      {item2.variable5}
                                    </div>
                                  )}

                                  <div className="XdiasSt">
                                    {item2.variable7}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable8}
                                  </div>

                                  {item2.variable9 !== "" && (
                                    <div className="XdiasSt">
                                      <a
                                        href={item2.variable9}
                                        target="__blank"
                                      >
                                        {item2.variable10}
                                      </a>
                                    </div>
                                  )}
                                  {item2.variable9 === "" && (
                                    <div className="XdiasSt">
                                      {item2.variable10}
                                    </div>
                                  )}

                                  <div className="XdiasSt">
                                    {item2.variable11}
                                  </div>

                                  {item2.variable12 !== "" && (
                                    <div className="XdiasSt">
                                      <a
                                        href={item2.variable12}
                                        target="__blank"
                                      >
                                        {item2.variable13}
                                      </a>
                                    </div>
                                  )}
                                  {item2.variable12 === "" && (
                                    <div className="XdiasSt">
                                      {item2.variable13}
                                    </div>
                                  )}

                                  <div className="XdiasSt">
                                    {item2.variable14}
                                  </div>

                                  {item2.variable15 !== "" && (
                                    <div className="XdiasSt">
                                      <a
                                        href={item2.variable15}
                                        target="__blank"
                                      >
                                        {item2.variable16}
                                      </a>
                                    </div>
                                  )}
                                  {item2.variable15 === "" && (
                                    <div className="XdiasSt">
                                      {item2.variable16}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          )}
          {this.state.irutas1.length > 0 && (
            <div className="row">
              {this.state.irutas1.map((item, i) => {
                return (
                  <div className="cont-diasterapia">
                    {item.variable10 === "1" && (
                      <div className="col-12">
                        <div className="cont-diasterapia2 row">
                          <div className="diasNombre col-12">
                            INFORME COLEGIO
                          </div>
                          <div className="group row">
                            <div className="diasS">No. Informe.</div>
                            <div className="diasS"> 1er Trimestre</div>
                            <div className="diasS"> 1er Trimestre</div>
                            <div className="diasS"> 2do Trimestre</div>
                            <div className="diasS"> 2do Trimestre</div>
                            <div className="diasS"> 3er Trimestre</div>
                            <div className="diasS"> 3er Trimestre</div>
                          </div>
                          <div className="group row">
                            <div className="diasS"></div>
                            <div className="diasS">Fecha Esta.</div>
                            <div className="diasS">Fecha Ent.</div>
                            <div className="diasS">Fecha Esta.</div>
                            <div className="diasS">Fecha Ent.</div>
                            <div className="diasS">Fecha Esta.</div>
                            <div className="diasS">Fecha Ent.</div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="group row">
                      {item.variable10 === "1" && (
                        <div className="diasS">Informe No. 1</div>
                      )}
                      {item.variable10 === "2" && (
                        <div className="diasS">Informe No. 2</div>
                      )}

                      <div className="XdiasS">{item.variable2}</div>
                      <div className="XdiasS">
                        <a href={item.variable1} target="__blank">
                          {item.variable3}{" "}
                          {/* {item.variable11} */}
                        </a>
                      </div>
                      <div className="XdiasS">{item.variable5}</div>
                      <div className="XdiasS">
                        <a href={item.variable4} target="__blank">
                          {item.variable6}{" "}
                          {/* {item.variable12} */}
                        </a>
                      </div>
                      <div className="XdiasS">{item.variable8}</div>
                      <div className="XdiasS">
                        <a href={item.variable7} target="__blank">
                          {item.variable9}{" "}
                          {/* {item.variable13} */}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
              <span className="saltar"></span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SeccionesAAction;
