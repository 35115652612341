import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";

 

//componentes
import './generarPagosC.css';
import Alert from '../../modal/alert';

class GenerarPagosC extends Component {
    constructor(props) {
        super(props);
        this.state = {           
            montodebe:this.props.montoDebe,
            montohaber:this.props.montoAbono,
            montosaldo:this.props.montoModificar,
            btnEnviarOn:false,
            listaOn:false,
            mensajealerta:false,
        }
       
      
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    aprobarPago =()=>{
        if (parseFloat(this.state.montosaldo) >= parseFloat(this.props.montoAbono)){
            let data = new FormData();
            data.append("anoEscolar", this.props.usuario1.anioE)
            data.append('idcxc', this.props.idcxc); 
            data.append('idcontrolPago', this.props.idcontrolPago); 
            data.append('monto', this.state.montosaldo); 
            data.append('boton', 'modificarMonto');
            let config = {
                headers: {'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/backend/cxc.php';
            axios.post(xurl, data, config)

            .then(res => {
            // console.log('respuesta ',res)
                if (res.data.variable1) {
                    this.setState({
                        btnEnviarOn:true
                    });
                } else {
                    this.setState({
                        btnEnviarOn:false
                    });

                }
            })
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Modificar Monto.',
                cuerpoM: 'El monto a modificar debe ser mayor que el monto que tiene abonado a esta deuda, Verifique por favor.',
            });
        }
    }


    render(){
        return(
            <div className="principal-pago row">
                <div className="contenedor-pago col-12">
                    <div className="titulo-pago ">
                        <p>MODIFICAR MONTO DE LA DEUDA GENERADA. </p>
                    </div>
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    
                    }
                    <div className="contenedor-titulo row">
                        
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Monto deuda.</label>   
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="montodebe"
                                        id="montodebe"
                                        autoComplete="off"
                                        readOnly="readonly"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.montodebe.toUpperCase()}
                                    />  
                        </div>
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Monto Abonado.</label>   
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="montohaber"
                                        id="montohaber"
                                        autoComplete="off"
                                        readOnly="readonly"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.montohaber}
                                    />                
                        </div>
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Monto A Modificar.</label>   
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="montosaldo"
                                        id="montosaldo"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.montosaldo}
                                    />                
                        </div>

                        {!this.state.btnEnviarOn &&
                        <div className="contenedorcedula1 col-12">
                            <button className="boton" onClick={this.aprobarPago.bind(this)}>Modificar Monto</button>
                            <button className="boton" onClick={this.props.generarPagoClose}>Salir</button>
                        </div>        
                        }
                    </div>                 
                    
                    {this.state.btnEnviarOn &&
                        <div className="titulo-pago col-12">
                            <p>EL PROCESO SE COMPLETO DE FORMA EXITOSA.</p>
                            <button className="boton" onClick={this.props.generarPagoClose}>Continuar</button>
                        </div>
                    }    
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token
    }
}
export default connect(mapStateToProps,)(GenerarPagosC);