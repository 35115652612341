import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
//import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./observacionBPN.css";
import Alert from "../../modal/alert";

class observacionBPNAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      observacion: "",
      iobservacion: [],
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      fecha: "",
      textoevo: "",
      anoEscolar: "",
      observacion: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  eliminar = (e) => {
    let data = new FormData();

    data.append("idobservacion", e);
    data.append("boton", "Eliminar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/observacionBPN.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Observaciones",
          cuerpoM: res.data.variable2,
        });

        this.consultarActividades();
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Observaciones",
          cuerpoM: res.data.variable2,
        });
      }
    });
  };

  consultarActividades() {
    let data = new FormData();
    data.append("boton", "buscar");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/backend/observacionBPN.php",
      data: data,
    }).then((res) => {
      let xiobservaciones = res.data.variable2;
      this.setState({
        iobservacion: xiobservaciones,
      });
    });
  }

  guardar() {
    if (this.state.observacion.length > 0) {
      let data = new FormData();
      data.append("observacion", this.state.observacion);
      data.append("boton", "guardar");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/backend/observacionBPN.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Observaciones",
            cuerpoM: res.data.variable2,
          });
          this.consultarActividades();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Observaciones",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Observaciones",
        cuerpoM: "El Campo de Observación debe estar lleno.",
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.consultarActividades();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU PARA CREAR OBSERVACIONES DE BOLETINES PRIMER NIVEL. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="contenedor-cedula col-12">
                  <label className="labelcedulac">Observacion.</label>
                  {/* <label className="opcion">Examen Físico.</label> */}
                  <textarea
                    className="form-control"
                    name="observacion"
                    id="observacion"
                    rows="3"
                    cols="135"
                    maxLength="400"
                    value={this.state.observacion}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              </div>
              <div className="cont-Obser row">
                <div className="botones col-12">
                  <div className="acciones">
                    <button className="boton" onClick={this.guardar.bind(this)}>
                      Guardar
                    </button>

                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div className="cont-listmedix row">
                <div className="listcxcx col-12">
                  <table className="tablacxcx" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc3">CODIGO</th>
                        <th className="titucxc3o">OBSERVACION</th>
                        <th className="titucxc3x">
                          <span className="cerrarme1"></span>
                        </th>
                      </tr>
                    </thead>

                    {this.state.iobservacion.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td className="totalescxcx">{item.variable1}</td>
                          <td className="totalescxcx">{item.variable2}</td>

                          <td className="totalescxc">
                            <span
                              className="cerrarme1"
                              onClick={this.eliminar.bind(this, item.variable1)}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(observacionBPNAction);
