import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
//componentes
import "./nomina.css";
import Alert from "../../modal/alert";
import ReporteG from "./reporteDecimoE";

function Decimo(props) {
  const [listaOn, setlistaOn] = React.useState(false);
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [activarOn, setactivarOn] = React.useState(false);
  const [flotadorNOn, setflotadorNOn] = React.useState(false);
  const [personal, setpersonal] = React.useState([]);
  const [anio, setanio] = React.useState([]);
  const [mes, setmes] = React.useState([]);
  const [nominaPersonal, setnominaPersonal] = React.useState([]);
  const [tipoNomina, settipoNomina] = React.useState(0);
  const [titulo1, settitulo1] = React.useState("");
  const [monto1, setmonto1] = React.useState(0);
  const [titulo2, settitulo2] = React.useState("");
  const [monto2, setmonto2] = React.useState(0);
  const [titulo3, settitulo3] = React.useState("");
  const [monto3, setmonto3] = React.useState(0);
  const [titulo4, settitulo4] = React.useState("");
  const [monto4, setmonto4] = React.useState(0);
  const [titulo5, settitulo5] = React.useState("");
  const [monto5, setmonto5] = React.useState(0);
  const [montoT, setmontoT] = React.useState(0);
  const [montoN, setmontoN] = React.useState(0);
  const [montoP, setmontoP] = React.useState(0);
  const [decimoP, setdecimoP] = React.useState(0);
  //const [otrosD, setotrosD] = React.useState(0);
  const [montoF, setmontoF] = React.useState(0);

  const [trimestre, settrimestre] = React.useState(0);
  const [anopagar, setanopagar] = React.useState("");
  const [mesapagar, setmesapagar] = React.useState("");
  const [ssoDecimo, setssoDecimo] = React.useState(0);

  const [data, setData] = React.useState({
    cedulaNomina: "",
    otrosD: "",
  });
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");

  const limpiar = () => {
    settitulo1("");
    setmonto1(0);
    settitulo2("");
    setmonto2(0);
    settitulo3("");
    setmonto3(0);
    settitulo4("");
    setmonto4(0);
    settitulo5("");
    setmonto5(0);
    setmontoT(0);
    setmontoN(0);
    setdecimoP(0);
    setmontoF(0);
    settipoNomina(0);
    setssoDecimo(0);
    setmensajealerta(false);
    setactivarOn(false);
    setflotadorNOn(false);

    let data_ = Object.assign({}, data);
    data_ = {
      cedulaNomina: 0,
      otrosD: 0,
    };
    setData(data_);
  };

  const cerrarLista = () => {
    //console.log(listaOn)
    if (listaOn) {
      setlistaOn(false);
    } else {
      setlistaOn(true);
      buscarNomina();
    }
  };

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);
    if (index === "trimestre") {
      settrimestre(e.target.value);
      limpiar();
    }
    if (index === "anopagar") {
      setanopagar(e.target.value);
      limpiar();
    }
  };

  const onChange1 = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    let valor = 0;
    if (parseFloat(e.target.value) > 0) valor = parseFloat(e.target.value);
    if (parseFloat(montoF) - parseFloat(valor) >= 0) {
      data_[index] = e.target.value;
      setData(data_);
      setmontoF(parseFloat(montoN) - (parseFloat(decimoP) + parseFloat(valor)));
    }
  };

  const handleClose = () => {
    setmensajealerta(false);
  };

  const buscarNomina = () => {
    let form = new FormData();
    form.append("trimestre", trimestre);
    form.append("anoapagar", anopagar);
    form.append("boton", "buscarNominaDecimo");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        let xnominaPersonal = res.data.variable2;
        setnominaPersonal(xnominaPersonal);
      } else {
        let xnominaPersonal = res.data.variable2;
        setnominaPersonal(xnominaPersonal);
        setmensajealerta(true);
        settituloM("Menu Generar Nomina.");
        setcuerpoM(res.data.variable3);
      }
    });
  };

  const buscarPersonalI = (e) => {
    if (trimestre.length > 0) {
      if (anopagar.length > 0) {
        if (e.target.value.length > 0) {
          limpiar();
          let rcedu = e.target.value;
          let form = new FormData();
          form.append("cedula", rcedu);
          form.append("boton", "buscarPersonalI");
          let config = {
            headers: { "Content-Type": "text/html" },
          };
          const xurl =
            "https://institutobilinguelasnaciones.com/backend/nomina.php";
          axios.post(xurl, form, config).then((res) => {
            if (res.data.variable1) {
              settipoNomina(res.data.variable8);
              setssoDecimo(res.data.variable17);
              let data_ = Object.assign({}, data);
              data_["cedulaNomina"] = rcedu;
              setData(data_);
            }
          });
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Nomina.");
          setcuerpoM(
            "No se encontro la tabla deducciones, Favor comunicarse con el Ing. Hender perozo"
          );
        }
      } else {
        setmensajealerta(true);
        settituloM("Menu Generar Nomina.");
        setcuerpoM("Debe elegir un Año, Gracias");
      }
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe elegir una Quincena, Gracias");
    }
  };

  const buscarPersonal = () => {
    let form = new FormData();
    form.append("dedonde", "crearNomina");
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xpersonal = res.data.variable1;
      setpersonal(xpersonal);
    });
  };

  const guardarDecimoG = () => {
    if (anopagar.length > 0 && trimestre.length > 0) {
      let form = new FormData();
      form.append("trimestre", trimestre);
      form.append("anoapagar", anopagar);
      form.append("boton", "guardarDecimoG");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setmensajealerta(true);
          settituloM("Menu Generar Decimo.");
          setcuerpoM(res.data.variable2);
        }
      });
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe existir un Cuatrimestre y un año");
    }
  };

  const guardarDecimo = () => {
    if (data.cedulaNomina.length > 0) {
      let form = new FormData();
      form.append("cedulaNomina", data.cedulaNomina);
      form.append("tipoNomina", tipoNomina);
      form.append("trimestre", trimestre);
      form.append("anoapagar", anopagar);
      form.append("montoT", montoT);
      form.append("montoN", montoN);
      form.append("decimoP", decimoP);
      form.append("otrosD", data.otrosD);
      form.append("montoF", montoF);
      form.append("titulo1", titulo1);
      form.append("monto1", monto1);
      form.append("titulo2", titulo2);
      form.append("monto2", monto2);
      form.append("titulo3", titulo3);
      form.append("monto3", monto3);
      form.append("titulo4", titulo4);
      form.append("monto4", monto4);
      form.append("titulo5", titulo5);
      form.append("monto5", monto5);

      form.append("boton", "guardarDecimo");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setmensajealerta(true);
          settituloM("Menu Generar Decimo.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Decimo.");
          setcuerpoM(res.data.variable2);
        }
      });
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe existir un Empleado");
    }
  };

  const consultaRecibo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("iddecimo", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboDecimoEmpleados.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  const reporteMensual = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idnomina", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboEmpleados.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  const eliminarEmpleado = () => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("boton", "eliminarEmpleado");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          limpiar();
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const buscarDatosNomina = (e) => {
    let form = new FormData();
    form.append("cedulaNomina", data.cedulaNomina);
    form.append("tipoNomina", tipoNomina);
    form.append("anoapagar", anopagar);
    form.append("ssoDecimo", ssoDecimo);
    form.append("trimestre", trimestre);
    form.append("boton", "decimo");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        settitulo1(res.data.variable2);
        setmonto1(parseFloat(res.data.variable3).toFixed(2));
        settitulo2(res.data.variable4);
        setmonto2(parseFloat(res.data.variable5).toFixed(2));
        settitulo3(res.data.variable6);
        setmonto3(parseFloat(res.data.variable7).toFixed(2));
        settitulo4(res.data.variable8);
        setmonto4(parseFloat(res.data.variable9).toFixed(2));
        settitulo5(res.data.variable10);
        setmonto5(parseFloat(res.data.variable11).toFixed(2));
        setmontoT(parseFloat(res.data.variable12).toFixed(2));
        setmontoN(parseFloat(res.data.variable13).toFixed(2));
        setdecimoP(parseFloat(res.data.variable14).toFixed(2));
        setmontoF(parseFloat(res.data.variable15).toFixed(2));
      } else {
        setmensajealerta(true);
        settituloM("Menu XIII Mes.");
        setcuerpoM(res.data.variable2);
      }
    });
  };

  const cargarFechas = () => {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        //let xdia = res.data.variable3;
        //dia: xdia,
        setmes(xmes);
        setanio(xanio);
      }
    });
  };
  const eliminarNomina = (e) => {
    let form = new FormData();
    form.append("idnomina", e);
    form.append("boton", "eliminarNominaDecimo");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setmensajealerta(true);
        settituloM("Menu Nomina Decimo.");
        setcuerpoM(res.data.variable2);
        buscarNomina();
      } else {
        setmensajealerta(true);
        settituloM("Menu Nomina Decimo.");
        setcuerpoM(res.data.variable2);
      }
    });
  };

  const activarReporte = () => {
    if (flotadorNOn) {
      setflotadorNOn(false);
    } else {
      setflotadorNOn(true);
    }
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    buscarPersonal();
    cargarFechas();
  }, []);
  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        {/* <div className="titulo-cxc ">
          <p>CREAR NOMINA DE EMPLEADOS. </p>
        </div> */}
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        {flotadorNOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <ReporteG personalClose={activarReporte} />
            </div>
          </div>
        )}
        {listaOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  {/* Nomina Creada a la {quincena} Quincena {mesapagar}-{anopagar} */}
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarLista();
                  }}
                >
                  X
                </span>
              </div>
              <span className="titulo-nomina"></span>

              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead>
                      <tr>
                        <th className="xtitunomina1">item.</th>
                        <th className="xtitunomina2">Nombre Empleado.</th>
                        <th className="xtitunomina3">Sal./Acumu.</th>
                        <th className="xtitunomina3">Sal./Prom.</th>
                        <th className="xtitunomina1">S.S.O. {ssoDecimo}%</th>
                        <th className="xtitunomina3">Otros/Desc.</th>
                        <th className="xtitunomina3">Total Pagar</th>
                        <th className="xtitunomina1">
                          <span className="cerrarme3"></span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {nominaPersonal.map((item, i) => {
                        return (
                          <tr
                            // className={i % 2 === 0 ? "odd" : ""}
                            className={item.variable8 === "1"
                                ? "odd1"
                                : item.variable8 ==="2"
                                ? "odd2"
                                : item.variable8 ==="3"
                                ? "odd3"
                                : item.variable8 ==="4"
                                ? "odd4"
                                : item.variable8 ==="5"
                                ? "odd5"
                                :""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable1}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable2}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable3}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable4}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable5}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable7}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable6}
                            </td>
                            <td width="20">
                              <span
                                className="cerrarme1"
                                onClick={() => {
                                  eliminarNomina(item.variable1);
                                }}
                              ></span>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="cont-listmedi row">
          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              Cuatrimestre a consultar.
              <select
                id="trimestre"
                name="trimestre"
                value={typeof trimestre !== "undefined" ? trimestre : ""}
                onChange={onChange}
              >
                <option value="0">Seleccione Cuatrimestre</option>
                <option value="1">Primer Cuatrimestre.</option>
                <option value="2">Segundo Cuatrimestre.</option>
                <option value="3">Tercer Cuatrimestre.</option>
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Año a Pagar.
              <select
                id="anopagar"
                name="anopagar"
                value={typeof anopagar !== "undefined" ? anopagar : ""}
                onChange={onChange}
              >
                <option value="0">Año a Pagar</option>
                {anio.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="cont-personal col-4">
            <label className="titulo-salario2">
              Seleccione un Empleado.
              <select
                id="cedulaNomina"
                name="cedulaNomina"
                value={
                  typeof data.cedulaNomina !== "undefined"
                    ? data.cedulaNomina
                    : ""
                }
                onChange={buscarPersonalI}
              >
                <option value="0">-- Seleccione Empleado --</option>
                {personal.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2 + " ==> " + item.variable3}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
        <div className="cont-personal col-12">
          <button className="botonN" onClick={buscarDatosNomina}>
            Generar Decimo Individual
          </button>
          <button className="botonN" onClick={guardarDecimoG}>
            Generar Decimo Grupal
          </button>
        </div>
        <div className="cont-listmedi row">
          <div className="titulo-cxc2 col-10 ">
            <p className="confi">RECIBO DE PAGO DECIMO. </p>
          </div>
        </div>
        <div className="cont-listmedi row">
          <div className="cont-personal col-5">
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo1}.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto1"
                  id="monto1"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto1 !== "undefined" ? monto1 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo2}.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto2"
                  id="monto2"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto2 !== "undefined" ? monto2 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo3}.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto3"
                  id="monto3"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto3 !== "undefined" ? monto3 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo4}.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto4"
                  id="monto4"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto4 !== "undefined" ? monto4 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo5}.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto5"
                  id="monto5"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto5 !== "undefined" ? monto5 : ""}
                />
              </div>
            </div>
          </div>

          <div className="cont-personal col-5">
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">Salario Acumulado.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montoT"
                  id="montoT"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montoT !== "undefined" ? montoT : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">Salario Promedio.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montoN"
                  id="montoN"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montoN !== "undefined" ? montoN : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">
                  S.Social XIII MES {ssoDecimo}%.
                </label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="decimoP"
                  id="decimoP"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof decimoP !== "undefined" ? decimoP : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">Otros Descuentos.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="otrosD"
                  id="otrosD"
                  //readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange1}
                  value={typeof data.otrosD !== "undefined" ? data.otrosD : 0}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">Total a Pagar.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montoF"
                  id="montoF"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montoF !== "undefined" ? montoF : ""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contenedorcedula1 col-12">
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? guardarDecimo : ""}
          >
            Guardar Decimo
          </button>
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? cerrarLista : ""}
          >
            Consultar Decimo
          </button>
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? activarReporte : ""}
          >
            Reporte General
          </button>
          <button className="boton" onClick={limpiar}>
            Limpiar Datos
          </button>
          <button className="boton" onClick={props.personalClose}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Decimo);
