//dependencias
import React, { Component } from 'react';

//componentes
import InicioAction from './InicioAction';
class Inicio extends Component {
    render() {
        return(
            <div className="Inicio">
                <InicioAction /> 
            </div>
        );
    }
}
export default Inicio;