import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./reporteAca.css";
import Alert from "../../modal/alert";
import AsignacionesCorregidas from "../asignacionesCorregidas/asignacionesC";

class Reporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      ianoEscolar: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo1: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      trimestre: "",
      mesVeranito: "",
      iprofesor: [],
      iprofesor2: [],
      icursos: [],
      verplanillaOn: false,
      asignacionesEOn: false,
      nalumno: [],
      loading: false,
      identificacion1: "",
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "trimestre") this.buscarProfesor2(e.target.value);
  }

  onChange4 = (e) => {
    let element = e.target;
    if (e.target.name !== "") {
      this.setState({ [e.target.name]: e.target.value });
      //console.log(e.target.value);
      //console.log(e.target.name);
      let data = new FormData();
      data.append("chequeo", e.target.value);
      data.append("recibo", e.target.name);
      data.append("boton", "guardarChequeo");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/recibosxMes.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.ventasxMesTabla();
          } else {
            this.setState({});
          }
        });
    }
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  limpiar = () => {
    this.setState({
      fecha: "",
      ifechamedi: [],
      icursos: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo1: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      verplanillaOn: false,
      asignacionesEOn: false,
      nalumno: [],
      idprofesor: "",
      iprofesor: [],
      iprofesor2: [],
      trimestre: "",
      mesVeranito: "",
      loading: false,
      identificacion1: "",
    });
  };

  buscarTipoReporte = () => {
    if (this.state.tbusqueda === "1") this.morosoxSalon();
    if (this.state.tbusqueda === "2") this.morosoSoloAlumno();
    if (this.state.tbusqueda === "3") this.pagosxMes();
    if (this.state.tbusqueda === "4") this.ventasxMesTabla();
    if (this.state.tbusqueda === "5") this.alumnosxSeccion();
    if (this.state.tbusqueda === "6") this.canjesMes();
    if (this.state.tbusqueda === "7") this.veranitoXEdad();
    if (this.state.tbusqueda === "8") this.gradoSeccion();
    if (this.state.tbusqueda === "9") this.gradosinSeccion();
    if (this.state.tbusqueda === "10") this.contactoRepresentante();
    if (this.state.tbusqueda === "11") this.datosMeduca();
    if (this.state.tbusqueda === "12") this.reporteMensualidad();
    if (this.state.tbusqueda === "13") this.reporteConsultaHabilitar();
    if (this.state.tbusqueda === "14") this.asignacionesEvaluadas();
    if (this.state.tbusqueda === "15") this.contactoRepresentante();
    if (this.state.tbusqueda === "16") this.gradoSeccionFN();
    if (this.state.tbusqueda === "17") this.imprimirBoletin();
    if (this.state.tbusqueda === "18") this.reporteBecados();
    if (this.state.tbusqueda === "19") this.reporteMensualPendiente();
    if (this.state.tbusqueda === "20") this.reporteIngresos();
    if (this.state.tbusqueda === "21") this.reporteContratos();
    if (this.state.tbusqueda === "22") this.reporteReIn();
    if (this.state.tbusqueda === "23") this.contactoRepresentanteCorreo();
    if (this.state.tbusqueda === "24") this.veranitoXEdadExcel();
    if (this.state.tbusqueda === "25") this.reporteVeranitoCXC();
    if (this.state.tbusqueda === "26") this.contactoRepresentanteCorreoExcel();
    if (this.state.tbusqueda === "27") this.reporteGradoSeccionExcel();
    if (this.state.tbusqueda === "28") this.contactoRepresentanteCorreoExcel2();
    if (this.state.tbusqueda === "29") this.gradoEdad();
    if (this.state.tbusqueda === "30") this.alumnosxSeccionMensualidad();
    if (this.state.tbusqueda === "31") this.boletinAnoAnterior();
    if (this.state.tbusqueda === "32") this.reporteFAnoAnterior();
    if (this.state.tbusqueda === "33") this.alumnosxSeccionHorario();
    if (this.state.tbusqueda === "34") this.alumnosxSeccionMensualidadExcel();
    if (this.state.tbusqueda === "35") this.cuadroHonorSecciones();
  };

  cuadroHonorSecciones = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      // this.state.trimestre.length > 0 &&
      this.state.idcurso.length > 0
    ) {
      this.setState({ loading: true });
      let data = new FormData();
      data.append("idusuario", this.props.usuario1.password);
      data.append("idcurso", this.state.idcurso);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", '2');

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/fpdf/reportes/cuadroHonorSeccion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({ loading: false });
          this.imprimirCuadroHonorSecciones();
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  imprimirCuadroHonorSecciones = () => {
    if (this.state.anoEscolar.length > 0) {
      //this.setState({ loading: true });
      let data = new FormData();
      data.append("idusuario", this.props.usuario1.password);
      data.append("anoEscolar", this.state.anoEscolar);
      //data.append("trimestre", this.state.trimestre);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/imprimirCuadroHonorSeccion.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
      //this.setState({ loading: false });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };


  reporteReIn = () => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/ReporteInscritoReinscrito.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteContratos = () => {
    if (
      this.state.codigo3.length > 0 &&
      // this.state.anoEscolar.length > 0 &&
      this.state.codigo4.length > 0
    ) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      //data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteContrato.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteIngresos = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      //this.setState({ loading: true });
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anoEscolar", this.state.codigo4);
      data.append("dia", this.state.codigo1);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteIngresosTipos.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        //this.limpiar2()
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteMensualPendiente = () => {
    if (
      this.state.codigo3.length > 0 &&
      // this.state.anoEscolar.length > 0 &&
      this.state.codigo4.length > 0
    ) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      //data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteCobroMensual.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  reporteBecados = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteBecados.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  reporteFAnoAnterior = () => {
    if (this.state.identificacion1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacion1);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletin4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  boletinAnoAnterior = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.identificacion1.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacion1);
      data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletin3.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  imprimirBoletin = () => {
    if (
      this.state.idcurso.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0
    ) {
      let data = new FormData();
      data.append("idcurso", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", this.state.trimestre);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletin.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  asignacionesEvaluadas = () => {
    if (this.state.asignacionesEOn) {
      this.setState({ asignacionesEOn: false });
    } else {
      this.setState({ asignacionesEOn: true });
    }
  };

  reporteConsultaHabilitar = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reportePlanificacionXHabilitar.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  reporteMensualidad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteMontoMensualidad.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  contactoRepresentante = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    if (this.state.tbusqueda === "10") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteContactoRepresentante.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
    if (this.state.tbusqueda === "15") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteContactoRepresentante2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  contactoRepresentanteCorreoExcel2 = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/alumnosxSeccionExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "CorreosRepresentante2.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  contactoRepresentanteCorreoExcel = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteCorreoExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "CorreosRepresentante.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  contactoRepresentanteCorreo = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteRepresentanteCorreos.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  datosMeduca = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteMeduca.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  gradosinSeccion = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteAlumnosSinSeccion.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  gradoSeccionFN = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteGradoSFechaN.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  gradoSeccion = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteGradoS.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  veranitoXEdad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("mes", this.state.mesVeranito);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/veranitoXEdad.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  reporteVeranitoCXC = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("mes", this.state.mesVeranito);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteCxCVeranito.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  veranitoXEdadExcel = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("mes", this.state.mesVeranito);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/correosRepresentanteExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "VeranitoCorreos.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  morosoxSalon = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/morosoxSalon.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  morosoSoloAlumno = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/morosoNombres.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  alumnosxSeccionMensualidadExcel = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("mes", this.state.mesVeranito);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/alumnosxSeccionMensualidadExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "MensualidadExcel.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  alumnosxSeccionMensualidad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/alumnosxSeccionMensualidad.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  alumnosxSeccionHorario = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/alumnosxSeccionHorario.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  alumnosxSeccion = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/alumnosxSeccion.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  gradoEdad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("mes", this.state.mesVeranito);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/alumnosxGradoFN.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "GradoEdad.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  ventasxMesTabla() {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      data.append("boton", "ventasxMesTabla");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/recibosxMes.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          let xalumno = res.data.variable2;
          this.setState({
            verplanillaOn: true,
            nalumno: xalumno,
          });
        }
      });
    }
  }

  recibosxMes = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/recibosxMes.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  recibosxMesExcel = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/recibosxMesExcel.php",
        responseType: "arraybuffer",
        data: data,
      }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", "IngresosMes.xlsx"); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();
      });
      // .then(function (response) {
      //   let blob = new Blob([response.data], { type: "multipart/form-data" });
      //   var fileURL = URL.createObjectURL(blob);
      //   window.open(fileURL);
      // });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  pagosxMes = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/pagoxMes.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  canjesMes = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/canjesMes.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  consultaRecibo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  cerrarRecibo = () => {
    if (this.state.verplanillaOn) {
      this.setState({ verplanillaOn: false });
    } else {
      this.setState({ verplanillaOn: true });
    }
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarProfesor2 = (e) => {
    let form = new FormData();
    form.append("trimestre", e);
    form.append("anioEscolar", this.state.anoEscolar);
    form.append("boton", "buscarPersonal22");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor2: xiprofesor,
      });
    });
  };

  reporteGradoSeccionExcel = () => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteGradoSeccionExcel.php",
        responseType: "arraybuffer",
        data: data,
      }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", "reporteGradoSeccionExcel.xlsx"); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();
      });
      // .then(function (response) {
      //   let blob = new Blob([response.data], { type: "multipart/form-data" });
      //   var fileURL = URL.createObjectURL(blob);
      //   window.open(fileURL);
      // });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  buscarCurso2 = () => {
    let data = new FormData();
    data.append("boton", "buscarCurso2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            icursos: xicursos,
          });
        } else {
          this.setState({
            icursos: [],
          });
        }
      });
  };

  componentDidMount() {
    this.cargarFechas();
    this.buscarAnioEscolar();
    this.buscarProfesor();
    this.buscarCurso2();
  }

  ax = () => {};
  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago1 col-12">
          <div className="titulo-pago ">
            <p>MENU PARA GENERAR REPORTES ACADEMICOS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.asignacionesEOn && (
            <div className="flotador1x">
              <div className="flotador1">
                <AsignacionesCorregidas
                  salir={() => {
                    this.setState({ asignacionesEOn: false });
                  }}
                />
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="flotador1x">
              <div className="flotador1">
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc11">RECIBO</th>
                          <th className="titucxc22">FECHA</th>
                          <th className="titucxc22">RUC/CEDULA</th>
                          <th className="titucxc33">A NOMBRE DE</th>
                          <th className="titucxc33">ALUMNO</th>
                          <th className="titucxc22">MONTO</th>
                          <th className="titucxc22">Cotejar</th>
                        </tr>
                      </thead>
                      {this.state.nalumno.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                          >
                            <td
                              className="totalescxc11"
                              width="10"
                              id="tabla"
                              onClick={this.consultaRecibo.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable1}
                            </td>
                            <td className="totalescxc11" width="10">
                              {item.variable2}
                            </td>
                            <td className="totalescxc22" width="10">
                              {item.variable3}
                            </td>
                            <td className="totalescxc33" width="10">
                              {item.variable4}
                            </td>
                            <td className="totalescxc33" width="10">
                              {item.variable5}
                            </td>
                            <td className="totalescxc44" width="10">
                              {item.variable6}
                            </td>
                            <td className="totalescxcx" width="10">
                              <input
                                type="checkbox"
                                className="opvacunacionsi"
                                name={item.variable1}
                                id={item.variable1}
                                checked={item.variable7 === "A"}
                                onChange={this.onChange4.bind(this)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.recibosxMes}>
                      Imprimir Reporte
                    </button>
                    <button className="boton" onClick={this.recibosxMesExcel}>
                      Imprimir Excel
                    </button>
                    <button className="boton" onClick={this.cerrarRecibo}>
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!this.state.tokenVerificarOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>SELECCIONE EL TIPO DE REPORTE A EJECUTAR. </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar Reporte por --</option> 
                    {/* <option value="5">Reporte Alumnos por Salón Horario</option>
                    <option value="29">Reporte Por Grado Por Edad Excel</option>
                    <option value="7">
                      Reporte de Veranito por Mes y Edad
                    </option>
                    <option value="24">
                      Reporte Excel de Veranito por Mes y Edad
                    </option>*/}
                    <option value="8">Reporte por Grado y Sección</option>
                    <option value="27">
                      Reporte por Grado y Seccion Excel
                    </option>
                     {/* <option value="9">Reporte Alumnos sin Sección</option>
                    <option value="16">
                      Reporte Grado/Sección/F. nacimiento
                    </option>*/}
                    <option value="10">Reporte Contacto Representante</option>
                    <option value="23">Reporte Correos Representante</option>
                    <option value="26">
                      Correos Representante Excel por Grado y Seccion
                    </option>
                    <option value="28">
                      Correos Representante Excel por Grado
                    </option>
                     {/*<option value="22">Reporte Reinscrito e Incritos</option>
                    <option value="15">Reporte Contacto Madres</option>
                    <option value="11">Reporte Datos Meduca</option> */}
                    <option value="13">Planificación por Habilitar</option>
                    {/* <option value="14">Asignaciones Evaluadas.</option>
                    <option value="34">Reporte de Maestros</option>*/}
                    <option value="17">Boletines</option>
                    <option value="31">Boletines Años Anteriores</option>
                    <option value="32">Reporte F Años Anteriores</option>
                    {/* <option value="18">Reporte Becarios</option> */}
                    <option value="35">Reporte Cuadro Honor X Sección</option>
                  </select>
                </div>
              </div>

              {this.state.tbusqueda === "35" && (
                <div className="row">
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div className="contenedorcedula1f col-4">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- TRIMESTRE --</option>
                      <option value="1">1er. Trimestre</option>
                      <option value="2">2do. Trimestre</option>
                      <option value="3">3er. Trimestre</option>
                    </select>
                  </div> */}
                </div>
              )}
              {this.state.tbusqueda === "20" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo1"
                      name="codigo1"
                      value={this.state.codigo1}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Dia--</option>
                      {this.state.dia.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>

                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "18" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "19" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "22" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "24" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="mesVeranito"
                      name="mesVeranito"
                      value={this.state.mesVeranito}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- MES --</option>
                      <option value="1">ENERO</option>
                      <option value="2">FEBRERO</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "1" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "2" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "4" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "5" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "6" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "7" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="mesVeranito"
                      name="mesVeranito"
                      value={this.state.mesVeranito}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- MES --</option>
                      <option value="1">ENERO</option>
                      <option value="2">FEBRERO</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "25" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="mesVeranito"
                      name="mesVeranito"
                      value={this.state.mesVeranito}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- MES --</option>
                      <option value="1">ENERO</option>
                      <option value="2">FEBRERO</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "8" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "9" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "10" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "11" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "12" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "15" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "16" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "17" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-3">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- TRIMESTRE --</option>
                      <option value="1">1er. Trimestre</option>
                      <option value="2">2do. Trimestre</option>
                      <option value="3">3er. Trimestre</option>
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-6">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option value="">Seleccione Profesor</option>
                      {this.state.iprofesor2.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {/* {this.state.tbusqueda === "19" && (
                <div className="row">
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-5">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )} */}

              {this.state.tbusqueda === "21" && (
                <div className="row">
                  <div className="contenedorcedula1f col-5">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "23" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "26" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "27" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "28" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "29" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "30" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "31" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>

                    <input
                      className="form-control"
                      type="text"
                      name="identificacion1"
                      id="identificacion1"
                      autoComplete="off"
                      maxLength="20"
                      required
                      placeholder="Identificacion:"
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacion1}
                    />
                  </div>
                </div>
              )}

              {this.state.tbusqueda === "32" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <input
                      className="form-control"
                      type="text"
                      name="identificacion1"
                      id="identificacion1"
                      autoComplete="off"
                      maxLength="20"
                      required
                      placeholder="Identificacion:"
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacion1}
                    />
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "33" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "34" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarTipoReporte.bind(this)}
                >
                  Consultar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Reporte);
