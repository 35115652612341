import React, { Component } from "react";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";

//componentes
import "./subirarchivos.css";
import Alert from "../../modal/alert";

class SubirArchivos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      mensajealerta: false,
      rutasArchivos: "",
      irutas: [],
      identificacion: "",
      paso: false,
      btnOK: false,
      mensaje: "",
      loading: false,
      listaOn: false,
      cedulaA: "",
      cedulaR: "",
      nombreA: "",
      nombreR: "",
      tbusqueda: "",
      flotadorPOn: false,
      flotadorMOn: false,
      nivelA: "",
      certificadoN: "",
      solvencia: "",
      cartaConducta: "",
      cartaSalud: "",
      pruebaA: "",
      pruebaP: "",
      fotosE: "",
      cedulaE: "",
      cedulaP: "",
      idreg: "",
      fotosP: "",
      controlV: "",
      actaN: "",
      tarjetaV: "",
    };
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    if (e.target.checked) {
      this.setState({
        [e.target.name]: true,
      });
    } else {
      this.setState({
        [e.target.name]: false,
      });
    }
  }

  limpiar = () => {
    this.setState({
      botonOn: true,
      mensajealerta: false,
      rutasArchivos: "",
      irutas: [],
      identificacion: "",
      paso: false,
      mensaje: "",
      loading: false,
      listaOn: false,
      cedulaA: "",
      cedulaR: "",
      nombreA: "",
      nombreR: "",
      tbusqueda: "",
      flotadorPOn: false,
      flotadorMOn: false,
      nivelA: "",
      certificadoN: "",
      solvencia: "",
      cartaConducta: "",
      cartaSalud: "",
      pruebaA: "",
      pruebaP: "",
      fotosE: "",
      cedulaE: "",
      cedulaP: "",
      idreg: "",
      actaN: "",
      tarjetaV: "",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  eliarchivo = (e, i) => {
    //console.log(e);
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("cedulaRepresentante", this.state.identificacion);
    data.append("archivoArriba", e);
    data.append("boton", "Eliminar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/archivo.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          let xrutas = res.data.variable3;
          this.setState({
            irutas: xrutas,
          });
        } else {
          if (res.data.variable2.length > 0) {
            this.setState({
              paso: true,
              mensaje: res.data.variable2,
            });
          } else {
            this.setState({
              paso: true,
              mensaje:
                "Es posible que uno o mas archivos no fueron guardados en el servidor",
            });
          }
        }
        //console.log(res);
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  Upload = () => {
    //console.log(e.target.files)
    this.setState({
      loading: true,
    });
    const form_data = new FormData();

    form_data.append("cedulaRepresentante", this.state.cedulaR);
    form_data.append("boton", "Consultar");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/archivo.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          let xrutas = res.data.variable3;
          this.setState({
            irutas: xrutas,
          });
        } else {
          if (res.data.variable2.length > 0) {
            this.setState({
              paso: true,
              mensaje: res.data.variable2,
            });
          } else {
            this.setState({
              paso: true,
              mensaje: "El Estudiante no tiene Documentos Consignados",
            });
          }
        }
        //console.log(res);
      });
  };

  buscarPlanilla = () => {
    if (this.state.tbusqueda === "1" || this.state.tbusqueda === "2" || this.state.tbusqueda === "3") {
      if (this.state.tbusqueda === "1") {
        this.buscarAlumnoIndividual();
      } 
      if (this.state.tbusqueda === "2")  {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.buscarPorRepresentante();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM: "Debe seleccionar un tipo de busqueda, gracias...",
      });
    }
  };

  buscarPorRepresentante = () => {
    let data = new FormData();

    data.append("nombres", this.state.identificacion);
    data.append("boton", "buscarRepresentante");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };


  buscarAlumnoIndividual = () => {
    if (this.state.identificacion.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacion);
      data.append("boton", "verificarAlumno");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data)
          if (res.data.variable1) {
            this.setState({
              cedulaR: res.data.variable2,
              nombreR: res.data.variable3,
              cedulaA: res.data.variable4,
              nombreA: res.data.variable5,
              nivelA: res.data.variable6,
              paso: true,
            });
            this.Upload();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Alumno",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();

    data.append("nombres", this.state.identificacion);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "verificarAlumno1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data)
          if (res.data.variable1) {
            this.setState({
              cedulaR: res.data.variable2,
              nombreR: res.data.variable3,
              cedulaA: res.data.variable4,
              nombreA: res.data.variable5,
              nivelA: res.data.variable6,
              paso: true,
              listaOn: false,
            });
            this.Upload();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  guardarCotejar = () => {
    if (this.state.nivelA === "M") {
      let data = new FormData();

      data.append("cedulaAlumno", this.state.cedulaA);
      data.append("idreg", this.state.idreg);
      data.append("actaN", this.state.actaN);
      data.append("cedulaP", this.state.cedulaP);
      data.append("fotosE", this.state.fotosE);
      data.append("fotosP", this.state.fotosP);
      data.append("controlV", this.state.controlV);
      data.append("cartaSalud", this.state.cartaSalud);
      data.append("boton", "guardarM");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/requisitosCotejar.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data);
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cotejar Documentos",
              cuerpoM: "Se guargo la informacion con exito, gracias...",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cotejar Documentos",
              cuerpoM:
                "No fueron actualizados los datos por favor verifique, gracias...",
            });
          }
        });
    }

    if (this.state.nivelA === "P") {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedulaA);
      data.append("idreg", this.state.idreg);
      data.append("cedulaE", this.state.cedulaE);
      data.append("cedulaP", this.state.cedulaP);
      data.append("fotosE", this.state.fotosE);
      data.append("cartaSalud", this.state.cartaSalud);
      data.append("pruebaP", this.state.pruebaP);
      data.append("pruebaA", this.state.pruebaA);
      data.append("cartaConducta", this.state.cartaConducta);
      data.append("solvencia", this.state.solvencia);
      data.append("certificadoN", this.state.certificadoN);
      data.append("tarjetaV", this.state.tarjetaV);

      data.append("boton", "guardarP");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/requisitosCotejar.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data);
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cotejar Documentos",
              cuerpoM: "Se guargo la informacion con exito, gracias...",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cotejar Documentos",
              cuerpoM:
                "No fueron actualizados los datos por favor verifique, gracias...",
            });
          }
        });
    }
  };

  cotejar1 = () => {
    if (this.state.nivelA === "M") {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedulaA);
      data.append("boton", "consultarM");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/requisitosCotejar.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data)
          if (res.data.variable1) {
            this.setState({
              idreg: res.data.variable2,
              actaN: res.data.variable3,
              cedulaP: res.data.variable4,
              fotosE: res.data.variable5,
              fotosP: res.data.variable6,
              controlV: res.data.variable7,
              cartaSalud: res.data.variable8,
            });
          } else {
            this.setState({
              idreg: "",
              actaN: "",
              cedulaP: "",
              fotosE: "",
              fotosP: "",
              controlV: "",
              cartaSalud: "",
            });
          }
        });

      this.setState({
        flotadorMOn: !this.state.flotadorMOn,
      });
    }
    if (this.state.nivelA === "P") {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedulaA);
      data.append("boton", "consultarP");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/requisitosCotejar.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data);
          if (res.data.variable1) {
            this.setState({
              idreg: res.data.variable2,
              cedulaE: res.data.variable3,
              cedulaP: res.data.variable4,
              fotosE: res.data.variable5,
              cartaSalud: res.data.variable6,
              pruebaP: res.data.variable7,
              pruebaA: res.data.variable8,
              cartaConducta: res.data.variable9,
              solvencia: res.data.variable10,
              certificadoN: res.data.variable11,
              tarjetaV: res.data.variable12,
            });
          } else {
            this.setState({
              idreg: "",
              cedulaE: "",
              cedulaP: "",
              fotosE: "",
              cartaSalud: "",
              pruebaP: "",
              pruebaA: "",
              cartaConducta: "",
              solvencia: "",
              certificadoN: "",
              tarjetaV: "",
            });
          }
        });

      this.setState({
        flotadorPOn: !this.state.flotadorPOn,
      });
    }
  };

  grabarCM = () => {};

  grabarCP = () => {};

  render() {
    return (
      <div className="cont-servi-principal row">
        <div className="eslogan1su1 col-12">
          <div>
            <p className="titulosu1">
              Modulo para Cotejar Documentos de Inscripción.
            </p>
          </div>
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              eliminar={this.state.eliplan}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              handleEliminar={this.handleEliminar}
            />
          )}

          {!this.state.paso && (
            <div className="preview row">
              <div className="titulo-notificacion col-12">
                <p>
                  Deberá colocar la Cédula/Pasaporte del Estudiante o consultar
                  por el Nombre.
                </p>
              </div>
            </div>
          )}

          {this.state.flotadorPOn === true && (
            <div className="flotador">
              <div className="flotador1">
                <div className="titulo-notificacion col-12">
                  <p>COTEJAR DOCUMENTOS DE PRIMARIA.</p>
                </div>
                <div className="cont-check row">
                  <div className="cont-opcion22c">
                    <label className="opcion2c">
                      Cédula/Pasaporte Estudiante.
                    </label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="cedulaE"
                      id="cedulaE"
                      checked={this.state.cedulaE}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Cédula/Pasaporte Padres.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="cedulaP"
                      id="cedulaP"
                      checked={this.state.cedulaP}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Fotos Estudiante.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="fotosE"
                      id="fotosE"
                      checked={this.state.fotosE}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Prueba Psicologica.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="pruebaP"
                      id="pruebaP"
                      checked={this.state.pruebaP}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Prueba Academica.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="pruebaA"
                      id="pruebaA"
                      checked={this.state.pruebaA}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Carta Buena Salud.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="cartaSalud"
                      id="cartaSalud"
                      checked={this.state.cartaSalud}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Carta Buena Conducta.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="cartaConducta"
                      id="cartaConducta"
                      checked={this.state.cartaConducta}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">
                      Solvencia Colegio Anterior.
                    </label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="solvencia"
                      id="solvencia"
                      checked={this.state.solvencia}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Certificado Nota.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="certificadoN"
                      id="certificadoN"
                      checked={this.state.certificadoN}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">tarjeta de Vacuna.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="tarjetaV"
                      id="tarjetaV"
                      checked={this.state.tarjetaV}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                </div>
                <div className="botones col-12">
                  <div className="acciones">
                    <button
                      className="boton"
                      onClick={this.guardarCotejar.bind(this)}
                    >
                      Guardar
                    </button>
                    <button
                      className="boton"
                      onClick={this.cotejar1.bind(this)}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.flotadorMOn === true && (
            <div className="flotador">
              <div className="flotador1">
                <div className="titulo-notificacion col-12">
                  <p>COTEJAR DOCUMENTOS DE MATERNAL.</p>
                </div>
                <div className="cont-check row">
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Cédula/Pasaporte Padres.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="cedulaP"
                      id="cedulaP"
                      checked={this.state.cedulaP}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Acta de Nacimiento.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="actaN"
                      id="actaN"
                      checked={this.state.actaN}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Fotos Estudiante.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="fotosE"
                      id="fotosE"
                      checked={this.state.fotosE}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Fotos Padres.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="fotosP"
                      id="fotosP"
                      checked={this.state.fotosP}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Control de Vacuna.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="controlV"
                      id="controlV"
                      checked={this.state.controlV}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                  <div className="cont-opcion22c">
                    <label className="opcion2c">Carta Buena Salud.</label>
                    <input
                      className="form-control"
                      type="checkbox"
                      name="cartaSalud"
                      id="cartaSalud"
                      checked={this.state.cartaSalud}
                      onChange={this.onChange2.bind(this)}
                    />
                  </div>
                </div>
                <div className="botones col-12">
                  <div className="acciones">
                    <button
                      className="boton"
                      onClick={this.guardarCotejar.bind(this)}
                    >
                      Guardar
                    </button>
                    <button
                      className="boton"
                      onClick={this.cotejar1.bind(this)}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!this.state.paso && (
            <div className="contenido1 row">
              <div className="form-groupef1 col-12">
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="tbusqueda"
                      name="tbusqueda"
                      value={this.state.tbusqueda}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Buscar por --</option>
                      <option value="1">CEDULA/PASAPORTE</option>
                      <option value="2">NOMBRE ESTUDIANTE</option>
                      <option value="3">NOMBRE REPRESENTANTE</option>
                    </select>
                  </div>
                </div>
                <div className="contenedorcedula1 col-8">
                  <input
                    className="form-control"
                    type="text"
                    name="identificacion"
                    id="identificacion"
                    autoComplete="off"
                    placeholder={
                      this.state.tbusqueda === "1"
                        ? "Identificación Alumno."
                        : this.state.tbusqueda==="2" ? "Nombre Alumno."
                        : this.state.tbusqueda==="3" ? "Nombre del Representante."
                        :''
                    }
                    maxLength="300"
                    onChange={this.onChange.bind(this)}
                    value={this.state.identificacion.toUpperCase()}
                  />
                </div>
                <div className="xcontenedorcedula1 col-12">
                  <button
                    className="boton"
                    onClick={this.buscarPlanilla.bind(this)}
                  >
                    Consultar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>

                {this.state.listaOn && (
                  <div className="cont-tablamedi row">
                    <div className="tablamedi1 col-11">
                      <div className="cont-titulomedi col-12">
                        <span className="titulocasos">
                          Listado de estudiantes
                        </span>
                        <span
                          className="titulo-cerrarcasos"
                          onClick={this.cerrarLista.bind(this)}
                        >
                          X
                        </span>
                      </div>
                      {/* <span className="titulo-casos">Datos del Pago</span> */}
                      <div className="cont-listmedi row">
                        <div className="listmedi col-12">
                          <table className="tablame" border="1">
                            <tbody className="evocuerpo1">
                              {this.state.nalumno.map((item, i) => {
                                return (
                                  <tr
                                    className={i % 2 === 0 ? "odd" : ""}
                                    key={item.variable1}
                                    id="tabla"
                                  >
                                    <td
                                      className="renglon"
                                      width="10"
                                      onClick={this.consulta.bind(
                                        this,
                                        item.variable1
                                      )}
                                    >
                                      {item.variable2}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.paso && (
            <div className="contenido1 row">
              <div className="form-groupef1 col-12">
                <div className="cont-buscar1 row">
                  <div className="contenedor-cedula col-6">
                    <label className="labelcedulac">Acudiente.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="nombreR"
                      id="nombreR"
                      autoComplete="off"
                      readOnly="readonly"
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombreR}
                    />
                  </div>
                  <div className="contenedor-cedula col-6">
                    <label className="labelcedulac">Estudiante.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="nombreA"
                      id="nombreA"
                      autoComplete="off"
                      readOnly="readonly"
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombreA}
                    />
                  </div>
                </div>
                <div className="preview row">
                  <div className="titulo-notificacion col-12">
                    <p>Archivos ya enviados a el servidor.</p>
                  </div>
                  {this.state.irutas.map((item, i) => {
                    return (
                      <div className="cont-foto">
                        <span
                          className="cerrar"
                          onClick={this.eliarchivo.bind(this, item.variable5)}
                        >
                          X
                        </span>{" "}
                        <a
                          href={
                            "https://institutobilinguelasnaciones.com/backend/" +
                            item.variable4
                          }
                          target="__blank"
                        >
                          {item.variable6}
                          {/* <img
                            src={
                              "https://institutobilinguelasnaciones.com/backend/" +
                              item.variable4
                            }
                            key={item.variable5}
                            alt={item.variable5}
                            className="fotos"
                            name={item.variable5}
                          /> */}
                          {" "}
                        </a>
                      </div>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="titulo-notificacion col-12">
                    <p>{this.state.mensaje}</p>
                  </div>
                  <div className="accionesb col-12">
                    <button
                      className="boton"
                      onClick={this.cotejar1.bind(this)}
                    >
                      Cotejar
                    </button>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Otra Consulta
                    </button>
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SubirArchivos;
