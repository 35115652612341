import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./reciboI.css";
import Alert from "../../modal/alert";

class ReciboI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recibo: "",
      mensajealerta: false,
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  limpiar = () => {
    this.setState({
      recibo: "",
      mensajealerta: false,
    });
  };

  buscarRecibo = async () => {
    let data = new FormData();
    data.append("recibo", this.state.recibo);
    data.append("boton", "buscarRecibo");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/facturaRe2.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          if (res.data.variable2==='I'){
            this.consultaRecibo()
          }
          if (res.data.variable2==='E'){
            this.consultaReciboE()
          }
          //this.consultarProveedores()
        }
        else{
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consulta Recibo.",
            cuerpoM: "El Recibo no se Encontro",
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultaRecibo = () => {
    if (this.state.recibo.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", this.state.recibo);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };
  consultaReciboE = () => {
    if (this.state.recibo.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", this.state.recibo);
      data.append("tipoFact", "SERVICIOS");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago1 col-12">
          <div className="titulo-pago ">
            <p>MENU CONSULTA DE RECIBO INDIVIDUAL. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="contenedor-cedula col-12">
            <label className="labelcedulac">No. Recibo.</label>
            <input
              className="form-control"
              type="text"
              name="recibo"
              id="recibo"
              autoComplete="off"
              onChange={this.onChange.bind(this)}
              value={this.state.recibo.toUpperCase()}
            />
          </div>

          <div className="xcontenedorcedula1 col-12">
            <button className="boton" onClick={this.buscarRecibo.bind(this)}>
              Consultar
            </button>
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default ReciboI;
