import React, { Component } from 'react';

//componentes
import ConfiguracionAction from './Configuracion'

class Configuracion extends Component {

    render(){
        return(
            <div>
                <ConfiguracionAction />
            </div>
        );
    }

}
export default Configuracion;