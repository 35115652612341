import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import moment from "moment";
//componentes
import "./reporteP.css";
import Alert from "../../modal/alert";
import Modal from "../modal2/modal";
class ReportePla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      anoEscolar2: moment().format("YYYY"),
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      verplanillaOn: false,
      nalumno: [],
      ianoEscolar: [],
      icursos: [],
      ifechaPlani: [],
      someHTML: [],
      someHTML2: [],
      iprofesor: [],
      imaterias: [],
    };
  }

  limpiar = () => {
    this.setState({
      fecha: "",
      ifechamedi: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      anoEscolar2: moment().format("YYYY"),
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      someHTML: [],
      someHTML2: [],
      verplanillaOn: false,
      nalumno: [],
    });
  };
  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idcurso") {
      this.buscarFP(e.target.value);
      this.buscarPlanE2(e.target.value);
    }
  }
  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
      someHTML: [],
      someHTML2: [],
    });
  }
  onChange4(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
      someHTML: [],
      someHTML2: [],
      fecha1: "",
      fecha2: "",
    });
  }
  buscarPlanE2 = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE2");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          //let xialumnos = res.data.variable3;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              // ialumnos: xialumnos,
              // idmateria: "",
              // cedulaAlumno: "",
              // descripcionE: "",
              // trimestre: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };
  buscarFP = (e) => {
    if (e.length > 0) {
      let form = new FormData();
      form.append("idgrado", e);
      form.append("boton", "buscarFP");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios.post(xurl, form, config).then((res) => {
        let xifechaPlani = res.data.variable2;
        this.setState({
          ifechaPlani: xifechaPlani,
          idfechaPlani: "",
          someHTML: [],
          someHTML2: [],
          fecha1: "",
          fecha2: "",
        });
      });
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Grado, gracias.",
      });
    }
  };
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
                someHTML: [],
                someHTML2: [],
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Reporte Planificacion.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Reporte Planificacion.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  imprimirPlanificacion = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idfechaPlani.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.fecha1.length > 0 &&
      this.state.tipoR.length > 0
    ) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idfechaPlanificacion", this.state.idfechaPlani);
      data.append("idprofesor", this.state.idprofesor);
      data.append("tipoR", this.state.tipoR);
      data.append("fecha1", this.state.fecha1);
      data.append("fecha2", this.state.fecha2);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reportePlanificacionFechas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  consulta = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idfechaPlani.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.tipoR.length > 0
    ) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", this.state.idcurso);
      data.append("idfechaPlanificacion", this.state.idfechaPlani);
      if (parseInt(this.state.anoEscolar) < parseInt(this.state.anoEscolar2)) {
        data.append("idprofesor", "");
        data.append("idmateria", this.state.idmateria);
      }
      if (
        parseInt(this.state.anoEscolar) === parseInt(this.state.anoEscolar2)
      ) {
        data.append("idprofesor", this.state.idprofesor);
      }
      data.append("tipoR", this.state.tipoR);
      data.append("fecha1", this.state.fecha1);
      data.append("fecha2", this.state.fecha2);
      data.append("boton", "consulta");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          let xsomeHTML = res.data.variable2;
          this.setState({
            //anoEscolar: res.data.variable2,
            someHTML: xsomeHTML,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Deben Seleccionar todas las opciones, gracias.",
      });
    }
  };

  consultaI = (e) => {
    //if (e.length > 0) {
    let data = new FormData();
    data.append("fecha1", e);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("idgrado", this.state.idcurso);
    data.append("idfechaPlanificacion", this.state.idfechaPlani);
    if (parseInt(this.state.anoEscolar) < parseInt(this.state.anoEscolar2)) {
      data.append("idprofesor", "");
      data.append("idmateria", this.state.idmateria);
    }
    if (parseInt(this.state.anoEscolar) === parseInt(this.state.anoEscolar2)) {
      data.append("idprofesor", this.state.idprofesor);
    }

    data.append("tipoR", this.state.tipoR);
    data.append("boton", "consultaI");
    let config = {
      headers: {
        "Content-Type": "text/html",
        "Content-Type": "charset=utf-8",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xsomeHTML = res.data.variable2;
        this.setState({
          mostrarOn: true,
          someHTML2: xsomeHTML,
        });
      }
    });
    //}
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  componentDidMount() {
    this.buscarAnioEscolar();
    this.cargarFechas();
    this.buscarProfesor();
  }

  ax = () => {};
  render() {
    //console.log(this.state.fecha1)
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>REPORTE DE PLANIFICACION. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Año Escolar --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Profesor --</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {parseInt(this.state.anoEscolar) <
                    parseInt(this.state.anoEscolar2) && (
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="idmateria"
                        name="idmateria"
                        value={this.state.idmateria}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option>-- Seleccionar Materia --</option>
                        {this.state.imaterias.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}

                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idfechaPlani"
                      name="idfechaPlani"
                      value={this.state.idfechaPlani}
                      onChange={this.onChange3.bind(this)}
                    >
                      <option>-- Tipo Planificacion --</option>
                      {this.state.ifechaPlani.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-2">
                    <select
                      id="tipoR"
                      name="tipoR"
                      value={this.state.tipoR}
                      onChange={this.onChange4.bind(this)}
                    >
                      <option>-- Tipo Consulta --</option>
                      <option value="1">Mensual</option>
                      <option value="2">Por Fechas</option>
                      <option value="3">Diario</option>
                    </select>
                  </div>
                  {/* <div className="contenedorcedula1f col-3"> */}
                </div>
                <div className="cont-personal col-12">
                  {this.state.tipoR === "1" ? (
                    <div className="contenedorcedula1f col-3">
                      <select
                        id="fecha1"
                        name="fecha1"
                        value={this.state.fecha1}
                        onChange={this.onChange3.bind(this)}
                      >
                        <option>-- Seleccione el Mes --</option>
                        {this.state.mes.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tipoR === "2" || this.state.tipoR === "3" ? (
                    <div className="contenedorcedula1f col-3">
                      <input
                        className="form-control"
                        type="date"
                        name="fecha1"
                        id="fecha1"
                        autoComplete="off"
                        onChange={this.onChange3.bind(this)}
                        value={this.state.fecha1.toUpperCase()}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tipoR === "2" ? (
                    <div className="contenedorcedula1f col-3">
                      <input
                        className="form-control"
                        type="date"
                        name="fecha2"
                        id="fecha2"
                        autoComplete="off"
                        onChange={this.onChange3.bind(this)}
                        value={this.state.fecha2.toUpperCase()}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="contenedorcedula1f col-5">
                    <div className="acciones">
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={this.consulta.bind(this)}
                      >
                        Consultar
                      </button>
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={this.imprimirPlanificacion.bind(this)}
                      >
                        Imprimir Selección
                      </button>

                      <Link to="/">
                        <button className="boton">Salir</button>
                      </Link>
                      <button
                        className="boton"
                        onClick={this.limpiar.bind(this)}
                      >
                        Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container col-12">
            <div className="titulo-cxc ">
              <p>CONSULTA DE PLANIFICACIÓN POR FECHA. </p>
            </div>

            {this.state.mostrarOn === true && (
              <Modal
                open={this.state.mostrarOn}
                someHTML2={this.state.someHTML2}
                onClose={() => this.setState({ mostrarOn: false })}
              />
            )}

            <div className="listadopcr col-12">
              <table className="tabla2pcr">
                <tbody className="cuerpo">
                  {/* {this.state.dateArray.map((item, i) => { */}
                  {this.state.someHTML.map((item, i) => {
                    //console.log(this.state.dateArray);

                    return (
                      <tr className={i % 2 === 0 ? "oddr" : ""} key={i}>
                        {item.variable1 !== "" ? (
                          <td
                            className="itemtablaR"
                            key={i}
                            onClick={this.consultaI.bind(this, item.variable1)}
                          >
                            {item.variable1}
                          </td>
                        ) : (
                          ""
                        )}
                        {item.variable2 !== "" ? (
                          <td
                            className="itemtablaR"
                            key={i}
                            onClick={this.consultaI.bind(this, item.variable2)}
                          >
                            {item.variable2}
                          </td>
                        ) : (
                          ""
                        )}
                        {item.variable3 !== "" ? (
                          <td
                            className="itemtablaR"
                            key={i}
                            onClick={this.consultaI.bind(this, item.variable3)}
                          >
                            {item.variable3}
                          </td>
                        ) : (
                          ""
                        )}
                        {item.variable4 !== "" ? (
                          <td
                            className="itemtablaR"
                            key={i}
                            onClick={this.consultaI.bind(this, item.variable4)}
                          >
                            {item.variable4}
                          </td>
                        ) : (
                          ""
                        )}
                        {item.variable5 !== "" ? (
                          <td
                            className="itemtablaR"
                            key={i}
                            onClick={this.consultaI.bind(this, item.variable5)}
                          >
                            {item.variable5}
                          </td>
                        ) : (
                          ""
                        )}
                        {item.variable6 !== "" ? (
                          <td
                            className="itemtablaR"
                            key={i}
                            onClick={this.consultaI.bind(this, item.variable6)}
                          >
                            {item.variable6}
                          </td>
                        ) : (
                          ""
                        )}
                        {item.variable7 !== "" ? (
                          <td
                            className="itemtablaR"
                            key={i}
                            onClick={this.consultaI.bind(this, item.variable7)}
                          >
                            {item.variable7}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ReportePla);
