//dependencias
import React, { Component } from 'react';

//componentes
import CierreRe from './cierreRe'

class Index extends Component {

    render(){
        return(
            <div>
                <CierreRe />
            </div>
        );
    }

}
export default Index;