import React, { Component } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import axios from "axios";
import Alert from "../../modal/alert";
import AdjuntaTareas from "../subirArchivoRespuestaTarea/adjuntarTareas";

//componentes

function EvaluarTareas(props) {
  const [mensaje, setMensaje] = React.useState("");
  const [retraso, setRetraso] = React.useState("");
  const [mensajealerta, setMensajealerta] = React.useState(false);
  const [tituloM, setTituloM] = React.useState("");
  const [cuerpoM, setCuerpoM] = React.useState("");

  const InitData = async () => {
    let form = new FormData();
    form.append("idarchivos", props.idarchivos);
    form.append("boton", "consultarResultadoTarea");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setRetraso(res.data.variable4);
        setMensaje(res.data.variable5);
      }
    });
  };

  const EnviarMensaje = async () => {
    let form = new FormData();
    form.append("idarchivos", props.idarchivos);
    form.append("retraso", retraso);
    form.append("comentario", mensaje);
    form.append("boton", "guardarResultadoTarea");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setMensajealerta(true);
        setTituloM("Menu Evaluar Tarea");
        setCuerpoM(res.data.variable2);
      } else {
        setMensajealerta(true);
        setTituloM("Menu Evaluar Tarea");
        setCuerpoM(res.data.variable2);
      }
    });
  };

  const handleClose = () => {
    setMensajealerta(false);
  };

  React.useEffect(() => {
    InitData();
  }, []);

  return (
    <div>
      <Container>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        <Row className="mt-3 ml-3 mb-6">
          <Col>
            <SelectRetraso
              className=""
              id="retraso"
              name="retraso"
              value={retraso}
              onChange={(e) => setRetraso(e.target.value)}
            >
              <option>-- Tipo de Entrega --</option>
              <option value="1">Entrega Correcta</option>
              <option value="2">Entrega con Retraso</option>
            </SelectRetraso>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "-webkit-center", height: "250px" }}>
            <MessageField
              className="form-control"
              id="mensaje"
              name="mensaje"
              rows={10}
              placeholder="Observaciones de la Tarea"
              autoComplete="off"
              value={mensaje}
              onChange={(e) => setMensaje(e.target.value)}
            />
          </Col>
          <Col className="text-center col-1"></Col>
          <Col style={{ textAlign: "-webkit-center", height: "250px" }}>
            <Adjuntar>
              <AdjuntaTareas idarchivos={props.idarchivos} />
            </Adjuntar>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingLeft: "0" }} className="text-center col-5">
            <Send onClick={EnviarMensaje}>
              <FeatherIcon icon="save" stroke="#fff" size="20" />{" "}
            </Send>
            <Send onClick={props.handleClose}>
              <FeatherIcon icon="log-out" stroke="#fff" size="20" />{" "}
            </Send>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(EvaluarTareas);

const Container = styled.div`
  height: 70vh;
`;
const DataPonderacion = styled.input`
  height: 20px;
`;
const DataNota = styled.input`
  height: 20px;
`;
const SelectRetraso = styled.select`
  height: 25px;
`;
const MessageField = styled.textarea`
  margin-Top 20px;
  width:400px;  
`;

const Send = styled.button`
  margin-top: 40px;
  margin-right: 10px;
  border-radius: 3px;
  width: 10%;
  height: 50%;
  border: solid 1px #eee;
  background-color: #028d7b;
  text-align: center;
  transition: all 0.3s;
  :hover {
    opacity: 0.8;
  }
  svg {
    width: 20px;
    stroke: #ffff;
  }
`;
const Espacio = styled.div`
  background-color: #028d7b;
  // height: 300px;
  // width: 300px;
`;

const Adjuntar = styled.div`
  margin-Top: 20px;
  margin-right: 20px;
  // height: 300px;
  // width: 300px;
`;
