import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Comprobante from "../comprobante/comprobante";

//componentes
import "./cxpAbonos.css";
import FeatherIcon from "feather-icons-react";
import ModificarMonto from "../modificarMonto/generarPagosC";
import Alert from "../../modal/alert";

class CxPAbonos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idabonocxp: "",
      fecha: "",
      monto1: "",
      saldo: "",
      descripcion1: "",
      tbusqueda: "",
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      proveedores: [],
      cxpAbonos: [],
      cuentaContableProveedor: "",
      asientoOn: false,
      botonAsientoOn: false,
      cuentaContable2: "",
      cuenta: [],
    };
  }

  limpiar = () => {
    this.setState({
      idabonocxp: "",
      fecha: "",
      monto1: "",
      saldo: "",
      descripcion1: "",
      guardarOn: false,
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      cuentaContableProveedor: "",
      asientoOn: false,
      botonAsientoOn: false,
      cuentaContable2: "",
    });
  };

  limpiar2 = () => {
    this.setState({
      idcxp: "",
      fecha: "",
      monto1: "",
      saldo: "",
      documento: "",
      descripcion1: "",
      tbusqueda: "",
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      cxpAbonos: [],
      botonAsientoOn: false,
      cuentaContable2: "",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.value)
    let index = e.target.getAttribute("id");
    let valor = 0;
    if (e.target.value != "") valor = e.target.value;
    // console.log(valor);
    // console.log(this.state.saldo);

    if (valor <= parseFloat(this.state.saldo)) {
      // console.log('pasoooo');

      this.setState({
        [e.target.name]: e.target.value,
      });

      if (index === "subtotal") {
        this.state.impuesto = 0;
        this.state.monto1 = parseFloat(e.target.value).toFixed(2);
      }
      // if (index === "impuesto") {
      //   let valor = 0;
      //   if (e.target.value !== "") valor = e.target.value;

      //   this.state.monto1 = (
      //     parseFloat(this.state.subtotal) + parseFloat(valor)
      //   ).toFixed(2);
      // }
    }
  }

  onChange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarCxP(e.target.value);
    //console.log(e.target.value)
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange8(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.value === "5") {
      this.controlNumeroBancoG();
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultaRecibo = () => {
    this.setState({ flotadorREOn: false });
    let data = new FormData();
    data.append("idabonocxp", this.state.idabonocxp);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPagoProve.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  consultarCxPI = (e) => {
    let data = new FormData();
    data.append("idabonocxp", e);
    data.append("boton", "consultarCxPIA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            idabonocxp: res.data.variable2,
            monto1: res.data.variable3,
            descripcion1: res.data.variable4,
            fecha: res.data.variable5,
            banco: res.data.variable6,
            referencia: res.data.variable7,
            subtotal: res.data.variable3,
            impuesto: res.data.variable9,
            guardarOn: true,
          });
        }
      });
  };

  consultarProveedores = () => {
    let data = new FormData();
    data.append("boton", "consultarProveedor");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarCxP = (e) => {
    let data = new FormData();
    data.append("idcxp", e);
    data.append("boton", "consultarCxPA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let jsado = 0;
          jsado = parseFloat(this.props.monto) - parseFloat(res.data.variable3);
          let xfacturasP = res.data.variable2;
          this.setState({
            saldo: parseFloat(jsado).toFixed(2),
            cxpAbonos: xfacturasP,
            cuentaContableProveedor: res.data.variable4,
          });
        } else {
          let xfacturasP = res.data.variable2;
          this.setState({
            cxpAbonos: xfacturasP,
            saldo: this.props.saldo,
            cuentaContableProveedor: res.data.variable4,
          });
        }
      });
  };

  eliminarCxP = () => {
    let data = new FormData();
    data.append("idabonocxp", this.state.idabonocxp);
    data.append("boton", "eliminarA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.limpiar();
          this.consultarCxP(this.props.idcxp);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Se Elimino el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  guardarCxP = () => {
    if (this.state.banco.length > 0 && this.state.referencia !== "") {
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.password);
      data.append("idproveedor", this.props.proveedor);
      data.append("documento", this.props.documento);
      data.append("descripcion", this.state.descripcion1);
      data.append("banco", this.state.banco);
      data.append("fecha", this.state.fecha);
      data.append("idcxp", this.props.idcxp);
      data.append("monto", this.state.monto1);
      data.append("subtotal", this.state.subtotal);
      data.append("impuesto", this.state.impuesto);
      data.append("referencia", this.state.referencia);

      data.append("boton", "guardarAI");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable4);
          //console.log(res.data.variable3);
          if (res.data.variable1) {
            this.consultarCxP(this.props.idcxp);
            this.limpiar();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Cuentas por Pagar.",
              cuerpoM: "Se Guardo el Registro con exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Cuentas por Pagar.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Cuentas por Pagar.",
        cuerpoM: "Debe Ingresar un Banco y una Referencia, gracias",
      });
    }
  };

  modificarCxP = () => {
    let data = new FormData();
    data.append("idabonocxp", this.state.idabonocxp);
    data.append("subtotal", this.state.subtotal);
    data.append("impuesto", this.state.impuesto);
    data.append("monto", this.state.monto1);
    data.append("descripcion", this.state.descripcion1);
    data.append("fecha", this.state.fecha);
    data.append("banco", this.state.banco);
    data.append("referencia", this.state.referencia);
    data.append("boton", "modificarA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.limpiar();
          this.consultarCxP(this.props.idcxp);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Se Modifico el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      this.setState({ datosChequeOn: true });
    }
  };

  asientoContable = () => {
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
      });
    }
  };

  agregarItemAsientoPorPagar2 = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idproveedor", this.props.proveedor);
    data.append("fecha", this.state.fecha);
    data.append("subtotal", this.state.subtotal);
    data.append("descripcion", this.state.descripcion1);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("cuentaContable", this.state.cuentaContableProveedor);
    data.append("documento", this.props.documento);
    data.append("cuentaContable2", this.state.cuentaContable2);

    data.append("boton", "pagosAsientoContable1");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.guardarCxP();
        this.setState({
          // asientoOn: true,
          // listaNominaOn: false,
          // botonAsientoOn: true,
          loading: false,
        });
      });
  };

  agregarItemAsientoPorPagar = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idproveedor", this.props.proveedor);
    data.append("fecha", this.state.fecha);
    data.append("subtotal", this.state.subtotal);
    data.append("descripcion", this.state.descripcion1);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("cuentaContable", this.state.cuentaContableProveedor);
    data.append("documento", this.props.documento);
    data.append("cuentaContable2", this.state.cuentaContable2);

    data.append("boton", "pagosAsientoContable1");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable2 === 0 && this.state.idabonocxp.length > 0) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asiento Contable.",
            cuerpoM:
              "Este Asiento Contable no Fue Guardado y no se encuentra Registrado Contablemente, La Recomendacion es Eliminar el Pago y Registrarlo Nuevamente.",
          });
        }
        this.setState({
          asientoOn: true,
          listaNominaOn: false,
          botonAsientoOn: true,
          loading: false,
        });
      });
  };

  controlNumeroBancoG = () => {
    let data = new FormData();
    data.append("boton", "controlNumeroBG");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          referencia: res.data.variable1,
        });
      });
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  componentDidMount() {
    //this.buscarCursos();
    this.cargarCuentas();
    this.consultarCxP(this.props.idcxp);
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc3 col-12">
          <div className="titulo-cxc ">
            <p>
              Documento No. {this.props.documento} Monto: {this.props.monto}{" "}
              Resta: {this.props.saldo}{" "}
            </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.asientoOn && (
            <div className="flotadorPe">
              <div className="flotadorPe1">
                <Comprobante
                  claveUsuario={this.props.usuario1.password}
                  comprobanteClose={this.asientoContable}
                />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="subtotal"
                id="subtotal"
                autoComplete="off"
                placeholder="Total"
                maxLength="10"
                readOnly={this.props.tipoC === "FP" ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.subtotal > 0 ? this.state.subtotal : ""}
              />
            </div>
            {/* <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="impuesto"
                id="impuesto"
                autoComplete="off"
                placeholder="Impuesto"
                maxLength="10"
                readOnly={this.props.tipoC === "FP" ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={
                  parseFloat(this.state.impuesto) > 0 ? this.state.impuesto : ""
                }
              />
            </div>
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="monto1"
                id="monto1"
                autoComplete="off"
                placeholder="Total"
                maxLength="10"
                readOnly={this.props.tipoC === "FP" ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={
                  parseFloat(this.state.monto1) > 0 ? this.state.monto1 : ""
                }
              />
            </div> */}

            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange4.bind(this)}
                value={this.state.fecha}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula col-4">
              <input
                className="form-control"
                type="text"
                name="descripcion1"
                id="descripcion1"
                autoComplete="off"
                placeholder="Descripcion"
                maxLength="200"
                onChange={this.onChange4.bind(this)}
                value={this.state.descripcion1.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula col-3">
              <select
                id="banco"
                name="banco"
                value={this.state.banco}
                onChange={this.onChange8.bind(this)}
              >
                <option>-- Seleccionar Banco --</option>
                <option value="1"> Banco BAC</option>
                <option value="2"> Banco BANESCO</option>
                <option value="5"> Banco GENERAL</option>
                <option value="3"> Efectivo</option>
                <option value="4"> Otros</option>
              </select>
            </div>
            {this.state.banco !== "4" && (
              <div className="contenedorcedula col-3">
                <input
                  className="form-control"
                  type="text"
                  name="referencia"
                  id="referencia"
                  autoComplete="off"
                  placeholder="Referencia"
                  maxLength="200"
                  onChange={this.onChange4.bind(this)}
                  value={this.state.referencia}
                />
              </div>
            )}
            {this.state.banco === "4" && (
              <div className="contenedorcedula1 col-4">
                <select
                  id="cuentaContable2"
                  name="cuentaContable2"
                  value={this.state.cuentaContable2}
                  onChange={this.onChange4.bind(this)}
                >
                  <option>Cuenta Contable</option>

                  {this.state.cuenta.map((item, i) => {
                    return (
                      <option key={i} value={item.variable2}>
                        {item.variable6 + " - " + item.variable3}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="row">
            <div className="contenedorcedula2 col-12">
              {this.props.tipoC != "FP" && this.state.botonAsientoOn === true && (
                <button
                  className={this.state.guardarOn ? "botonOf" : "boton"}
                  onClick={
                    this.state.guardarOn ? "" : this.agregarItemAsientoPorPagar2
                  }
                >
                  Guardar
                </button>
              )}
              <button
                className="boton"
                onClick={this.agregarItemAsientoPorPagar}
              >
                Asiento Contable
              </button>
              {/* <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                disabled={!this.state.guardarOn}
                onClick={this.state.guardarOn ? this.modificarCxP : ""}
              >
                Modificar
              </button> */}
              <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                disabled={!this.state.guardarOn}
                onClick={this.eliminarCxP}
              >
                Eliminar
              </button>
              <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                disabled={!this.state.guardarOn}
                onClick={this.consultaRecibo}
              >
                Imprimir Recibo
              </button>
              <button className="boton" onClick={this.limpiar2.bind(this)}>
                Limpiar
              </button>
              <button className="boton" onClick={this.props.generarPagoClose}>
                Salir
              </button>
            </div>
          </div>
          {this.state.modificarSaldoOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <ModificarMonto
                  idcxc={this.state.idcxc}
                  montoDebe={this.state.montoDebe}
                  montoAbono={this.state.montoAbono}
                  montoModificar={this.state.montoModificar}
                  idcontrolPago={this.state.idcontrolPago}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">FECHA</th>
                    <th className="titucxc3">No. Recibo</th>
                    <th className="titucxc3">No. Control</th>
                    <th className="titucxc3">MONTO</th>
                    <th className="titucxc2X">DESCRIPCION</th>
                  </tr>
                </thead>
                {this.state.cxpAbonos.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxc"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable7}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable8}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable4}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPAbonos);
