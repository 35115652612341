import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

//componentes
import "./anioEscolarT.css";
import { saveCliente, saveToken, saveBotonMensaje, saveBotonMensajeI } from "../../accion/accion";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      concepto: "",
      iconcepto: [],
      listaOn: false,
      activar: "",
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      concepto: "",
      listaOn: false,
      activar: "",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  activarAnioEscolar = (e) => {
    if (parseFloat(e) > 0) {
      let clav = this.props.usuario1.password;
      let username = this.props.usuario1.usuario;
      let nivel = this.props.usuario1.nivel;
      let anioE = e;
      let cedulaU = this.props.usuario1.cedulaU;
      this.props.saveCliente(username, clav, nivel, anioE, cedulaU);
      this.buscarAnioEscolar(e);
    }
  };

  buscarAnioEscolar = (e) => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar2");
    if (parseFloat(e)>0){ 
        data.append("anoEscolar", e);
    }else{
        data.append("anoEscolar", this.props.usuario1.anioE);
    }
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/anioEscolar.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiconcepto = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iconcepto: xiconcepto,
          });
        } else {
          this.setState({
            iconcepto: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarAnioEscolar();
  }

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>Año Escolar Activo. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="contenedorp row">
            <div className="xcontenedorcedula1x col-12">
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc2">Año Escolar Activo</th>
                    {/* <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th> */}
                  </tr>
                </thead>

                {this.state.iconcepto.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxcx1"
                        onClick={this.activarAnioEscolar.bind(
                          this,
                          item.variable2
                        )}
                      >
                        {item.variable2}
                      </td>
                      {/* <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>{" "}
                      </td> */}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConceptoAction);
