//dependencias
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { buscarCantidadChatSinLeer } from "../../API/Core";
import { buscarCantidadChatISinLeer } from "../../API/CoreI";
import {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
} from "../../accion/accion";
import axios from "axios";
import moment from "moment";
//componentes

//imagenes
import logo1 from "../../imagenes/Logo.png";

//css
import "./Header.css";
import socket from "../socketio/socketio";

class HeaderNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajeOn: false,
      flotadorOn: false,
      tbusqueda1: moment().format("YYYY"),
      iconcepto: [],
    };
  }

  onChange(e) {
    //console.log(e.target.name)
    //let nomb = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (parseFloat(e.target.value) > 0) {
      let clav = this.props.usuario1.password;
      let username = this.props.usuario1.usuario;
      let nivel = this.props.usuario1.nivel;
      let anioE = e.target.value;
      let cedulaU = this.props.usuario1.cedulaU;
      this.props.saveCliente(username, clav, nivel, anioE, cedulaU);
      this.buscarAnioEscolar(e.target.value);
    }
  }

  activarChat = () => {
    if (this.state.flotadorOn) {
      this.setState({ flotadorOn: false });
    } else {
      this.setState({ flotadorOn: true });
    }
  };

  initData2 = async (e) => {
    let response = await buscarCantidadChatSinLeer(e);
    if (response.data.variable1) {
      this.props.saveBotonMensaje(true);
    } else {
      this.props.saveBotonMensaje(false);
    }
  };

  initData3 = async (e) => {
    let response = await buscarCantidadChatISinLeer(e); 
    if (response.data.variable1) {
      this.props.saveBotonMensajeI(true);
    } else {
      this.props.saveBotonMensajeI(false);
    }
  };

  buscarAnioEscolar = (e) => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar2");
    if (parseFloat(e) > 0) {
      data.append("anoEscolar", e);
    } else {
      data.append("anoEscolar", this.state.tbusqueda1);
    }
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/anioEscolar.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiconcepto = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iconcepto: xiconcepto,
          });
        } else {
          this.setState({
            iconcepto: [],
          });
        }
      });
  };

  componentDidMount() {
    this.initData2();
    this.initData3();
    this.buscarAnioEscolar();
  }

  render() {
    const has_message = this.props.botonMen.botonMensaje;
    const has_messageI = this.props.botonMenI.botonMensajeI;
    // console.log(this.props.usuario1.usuario);
    // console.log(this.props.usuario1.password);
    //console.log(has_message)
    return (
      <div>
        <div className="cont-cabecera row">
          <div className="col-cabecera col-12">
            <div className="logo1-cabecera col-2 ">
              <img className="logo1" src={logo1} alt="" />
            </div>
            <div className="usu-cabecera col-8">
              {this.props.usuario1.nivel === "3" && (
                <LinkContainer2>
                  <Link to="/chatI">
                    {has_messageI === true && <BadgeNotification2 />}
                    <FeatherIcon icon="list" size="10" stroke="#888" />
                  </Link>
                </LinkContainer2>
              )}
              {this.props.usuario1.usuario !==
                "NAIR MOLINA YALUL" && (
                <LinkContainer>
                  <Link to="/chat">
                    {has_message === true && <BadgeNotification />}
                    <FeatherIcon icon="mail" size="10" stroke="#888" />
                  </Link>
                </LinkContainer>
              )}
              {(this.props.usuario1.password &&
                this.props.usuario1.nivel === "1" && (
                  <div className="container">
                    <select
                      id="tbusqueda1"
                      name="tbusqueda1"
                      value={this.state.tbusqueda1}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año de Trabajo--</option>
                      {this.state.iconcepto.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable2}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <span className="usuariox">
                      {" "}
                      {this.props.usuario1.anioE}
                    </span>
                  </div>
                ))}

              {(this.props.usuario1.password &&
                this.props.usuario1.nivel === "2" && (
                  <div className="container">
                    <select
                      id="tbusqueda1"
                      name="tbusqueda1"
                      value={this.state.tbusqueda1}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año de Trabajo--</option>
                      {this.state.iconcepto.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable2}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <span className="usuariox">
                      {" "}
                      {this.props.usuario1.anioE}
                    </span>
                  </div>
                ))}

              {this.props.usuario1.password ? (
                <span className="usuario">
                  Usuario: {this.props.usuario1.usuario}{" "}
                </span>
              ) : (
                <span className="usuario">Usuario: </span>
              )}
            </div>
            <div className="logo1-cabecera col-2 ">
              <img className="logo1" src={logo1} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
    botonMen: state.botonMen,
    botonMenI: state.botonMenI,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);

const LinkContainer = styled.div`
  a {
    position: relative;
    float: right;

    text-align: center;
    background: #fff;

    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-decoration: none !important;
    color: inherit !important;
    font-size: 12px;
    padding-top: 5px;
    svg {
      width: 20px;
      height: 20px;
      stroke: #888 !important;
      transition: all 0.3s ease;
    }
    :hover svg {
      opacity: 0.8;
    }
  }
`;

const LinkContainer2 = styled.div`
  a {
    position: relative;
    float: left;

    text-align: center;
    background: #fff;

    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-decoration: none !important;
    color: inherit !important;
    font-size: 12px;
    padding-top: 5px;
    svg {
      width: 20px;
      height: 20px;
      stroke: #888 !important;
      transition: all 0.3s ease;
    }
    :hover svg {
      opacity: 0.8;
    }
  }
`;

const BadgeNotification = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f00;
  border-radius: 50%;
  top: -2px;
  right: -1px;
`;

const BadgeNotification2 = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f00;
  border-radius: 50%;
  top: -2px;
  right: -1px;
`;
