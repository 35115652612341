//dependencias
import React, { Component } from 'react';

//Component
import LoginAction from './Login'

class Login extends Component {

  render() {

    return (
      <div>
          <LoginAction />
      </div>
    );
  }
}

export default Login; 