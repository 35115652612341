import React, { Component } from 'react';

//componentes
import AutorizarMEPlani from './autorizarMEPlani'

class Index extends Component {

    render(){
        return(
            <div>
                <AutorizarMEPlani />
            </div>
        );
    }

}
export default Index;