import React, { Component } from 'react';

//componentes
import PlanificacionH from './planificacionHabilitadas'

class Index extends Component {

    render(){
        return(
            <div>
                <PlanificacionH />
            </div>
        );
    }
}
export default Index;