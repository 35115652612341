import React from "react";
import { Row, Col, Container } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import axios from "axios";
import { BuscarAlumno } from "../../API/Core";
import AsyncSelect from "react-select/async";

export default function FuncionName(props) {
  const [debounce, setDebounce] = React.useState(0);
  
  const loadOptions = async (inputValue, callback) => {
    try {
      let response = await BuscarAlumno(props.id_profesor, inputValue);
      let data = [];
      let results = response.data.variable2;
      for (let x = 0; x < results.length; x++) {
        data.push({
          label: `${results[x].variable2.toLowerCase()} (${results[
            x
          ].variable4.toLowerCase()})`,
          value: {
            materia: results[x].variable3,
            cedula: results[x].variable1,
            nombre: results[x].variable2,
          },
        });
      }
      return callback(data);
    } catch (error) {
      console.log(error);
      return callback(null);
    }
  }
  // const loadOptions = (inputValue, callback) => {
  //   let debounce_ = debounce;
  //   clearTimeout(debounce_);
  //   debounce_ = setTimeout(async () => { 
  //     try {
  //       let response = await BuscarAlumno(props.id_profesor, inputValue);
  //       let data = [];
  //       let results = response.data.variable2;
  //       for (let x = 0; x < results.length; x++) {
  //         data.push({
  //           label: `${results[x].variable2.toLowerCase()} (${results[
  //             x
  //           ].variable4.toLowerCase()})`,
  //           value: {
  //             materia: results[x].variable3,
  //             cedula: results[x].variable1,
  //             nombre: results[x].variable2,
  //           },
  //         });
  //       }
  //       return callback(data);
  //     } catch (error) {
  //       console.log(error);
  //       return callback(null);
  //     }
  //   }, 6000000);
  //   setDebounce(debounce_);
  // };

  const handleOnchange = (e) => {
    console.log("e "+e)
    if (e !== null) {
      props.onChange(e.value);
    } else {
      props.onChange(e);
    }
  };

  return (
    <FieldContainer> 
      <AsyncSelect
        loadOptions={loadOptions}
        defaultOptions
        classNamePrefix="react-select"
        isClearable
        onChange={handleOnchange}
        placeholder="Nombre del alumno..."
        noOptionsMessage={() => "No hay coincidencias."}
        loadingMessage={() => "Buscango alumnos ..."}
      />
    </FieldContainer>
  );
}

const FieldContainer = styled.div`
  input {
    margin: 0 !important;
  }

  .react-select__menu * {
    font-size: 14px;
    text-transform: capitalize;
  }
`;
