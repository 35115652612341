import React, { Component } from 'react';

//componentes
import Facturacion from './aprobarRe'

class Index extends Component {

    render(){
        return(
            <div>
                <Facturacion />
            </div>
        );
    }

}
export default Index;