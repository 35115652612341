import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./materias.css";
import Alert from "../../modal/alert";

class CanjeAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materia: "",
      descripcion: "",
      codigoM: "",
      imaterias: [],
      listaOn: false,
      modificarOn: false,

      verplanillaOn: false,
    };
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  guardar = () => {
    if (this.state.materia.length > 0) {
      let data = new FormData();
      data.append("materia", this.state.materia);
      data.append("descripcion", this.state.descripcion);
      data.append("boton", "guardarMateria");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/materia.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarMaterias();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Materias.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Materia.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  limpiar = () => {
    this.setState({
      codigoM: "",
      materia: "",
      descripcion: "",
      listaOn: false,
      modificarOn: false,
    });
  };

  modificar = () => {
    let data = new FormData();
    data.append("codigoM", this.state.codigoM);
    data.append("materia", this.state.materia);
    data.append("descripcion", this.state.descripcion);
    data.append("boton", "modificarMateria");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarMaterias();
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Materias.",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Materia.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  modificarSeleccion = (e, f, g) => {
    this.setState({
      codigoM: e,
      materia: f,
      descripcion: g,
      modificarOn: true,
    });
  };

  eliminar = (e) => {
    //console.log(this.state.abonoCanje);
    if (parseFloat(e) > 0) {
      let data = new FormData();

      data.append("idmateria", e);
      data.append("boton", "eliminarMateria");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/materia.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Conceptos.",
            cuerpoM: res.data.variable2,
          });
          this.buscarMaterias();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Conceptos.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Canjes.",
        cuerpoM: "Debe existir un registro para poder eliminar la Materia.",
      });
    }
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarMaterias();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE MATERIAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="contenedorp row">
            <div className="titulo-reprecxc row">
              <div className="contenedorcedula1x col-5">
                <input
                  className="form-control"
                  type="text"
                  name="materia"
                  id="materia"
                  autoComplete="off"
                  placeholder="Nombre de la Materia"
                  maxLength="100"
                  onChange={this.onChange.bind(this)}
                  value={this.state.materia.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula1 col-7">
                <input
                  className="form-control"
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  autoComplete="off"
                  placeholder="Descripcion de la Materia"
                  maxLength="200"
                  onChange={this.onChange.bind(this)}
                  value={this.state.descripcion.toUpperCase()}
                />
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              {!this.state.modificarOn && (
                <button className="boton" onClick={this.guardar.bind(this)}>
                  Agregar
                </button>
              )}
              {this.state.modificarOn && (
                <button className="boton" onClick={this.modificar.bind(this)}>
                  Modificar
                </button>
              )}
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">MATERIAS</th>
                    <th className="titucxc2">DESCRIPCION</th>
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme5"></span>
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.imaterias.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme4m"
                          onClick={this.modificarSeleccion.bind(
                            this,
                            item.variable1,
                            item.variable2,
                            item.variable3
                          )}
                        ></span>{" "}
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CanjeAction;
