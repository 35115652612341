import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";
import moment from "moment";

//componentes
import "./comprobante.css";
import Alert from "../../modal/alert";
import FeatherIcon from "feather-icons-react";

class Comprobante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajealerta: false,
      flotadorCOn: false,
      irutas: [],
      cuenta: [],
      comprobante: [],
      guardarOn: false,
      descripcion: "",
      debe: "",
      haber: "",
      montoD: 0,
      montoH: 0,
      fecha: moment().format("YYYY-MM-DD"),
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      cuentaContable: "",
      flotadorCOn: false,
      guardarOn: false,
      descripcion: "",
      debe: "",
      haber: "",
      montoD: 0,
      montoH: 0,
      fecha: moment().format("YYYY-MM-DD"),
    });
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.value;
    let curso = this.state.icurso[index].variable1;
    //let concepto= this.state.icurso[index].variable2
    this.setState({
      curso: curso,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarComprobanteT = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.claveUsuario);
    data.append("boton", "buscarComprobanteT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcomp = res.data.variable2;
      this.setState({
        comprobante: xcomp,
        montoD: res.data.variable3,
        montoH: res.data.variable4,
      });
    });
  };

  guardar = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("codigo", this.state.cuentaContable);
    data.append("fecha", this.state.fecha);
    data.append("descripcion", this.state.descripcion);
    data.append("debe", this.state.debe);
    data.append("haber", this.state.haber);
    data.append("boton", "guardarComprobanteT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          this.buscarComprobanteT();
          this.setState({
            debe: "",
            haber: "",
          });
        } else {
          this.setState({
            guardarOn: true,
            mensajealerta: true,
            tituloM: "Menu Asiento Contable.",
            cuerpoM: "El Codigo no se pudo guardar, verifique por favor",
          });
        }
      });
  };

  eliminar = (e) => {
    let data = new FormData();
    data.append("idcomprobanteT", e);
    data.append("boton", "eliminarComprobanteT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarComprobanteT();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asiento Contable.",
            cuerpoM: "El Codigo No se pudo Eliminar, Verifique.",
          });
        }
      });
  };

  componentDidMount() {
    this.cargarCuentas();
    this.buscarComprobanteT();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ASIENTOS CONTABLES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula1 col-6">
              <select
                id="cuentaContable"
                name="cuentaContable"
                value={this.state.cuentaContable}
                onChange={this.onChange.bind(this)}
              >
                <option>Cuenta Contable</option>

                {this.state.cuenta.map((item, i) => {
                  return (
                    <option key={i} value={item.variable2}>
                      {item.variable6 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula1 col-4">
              <input
                className="form-control"
                type="text"
                name="descripcion"
                id="descripcion"
                autoComplete="off"
                placeholder="Descripcion Asiento"
                maxLength="60"
                onChange={this.onChange.bind(this)}
                value={this.state.descripcion.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="debe"
                id="debe"
                autoComplete="off"
                placeholder="Monto Debe."
                maxLength="10"
                readOnly={this.state.haber.length > 0 ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.debe}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="haber"
                id="haber"
                autoComplete="off"
                placeholder="Monto Haber."
                maxLength="10"
                readOnly={this.state.debe.length > 0 ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.haber}
              />
            </div>

            <div className="contenedorcedula3 col-3">
              <button
                className={this.state.guardarOn ? "botonOf" : "boton"}
                onClick={this.guardar.bind(this)}
                disabled={this.state.guardarOn}
              >
                Agregar
              </button>
              <button className="boton" onClick={this.limpiar}>
                Limpiar
              </button>
              <button className="boton" onClick={this.props.comprobanteClose}>
                Salir
              </button>
            </div>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="ititucxc3">CODIGO</th>
                    <th className="ititucxc3">FECHA</th>
                    <th className="ititucxc1">DESCRIPCIÓN</th>
                    <th className="ititucxc3">DEBE</th>
                    <th className="ititucxc3">HABER</th>
                    <th className="titucxc3">
                      <FeatherIcon icon="delete" size="20px" stroke="#999" />
                    </th>
                  </tr>
                </thead>
                {this.state.comprobante.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="rtotalescxc1" width="10">
                        {item.variable7}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable3}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable6}
                      </td>
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="totalC col-8">
              <span>******* TOTALES *******</span>
            </div>
            <div className="totalC col-2">{this.state.montoD.toFixed(2)}</div>
            <div className="totalC col-2">{this.state.montoH.toFixed(2)}</div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Comprobante);
