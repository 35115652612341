//dependencias
import React,{ Component } from 'react';
import { connect } from 'react-redux'

//css
import './SideBar.css';

//componentes
import SideBarAction from './SideBarAction';

class SideBar extends Component {
    constructor(props){
        super(props);
        this.state={};
    }
    render() {
        return(
            <div className="sidebar"> 
                {this.props.usuario1.usuario !== undefined &&
                    <SideBarAction />                    
                } 
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token,
    }
}
export default connect(mapStateToProps,)(SideBar);