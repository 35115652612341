import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

//componentes
import "./planificacionHabilitadas.css";
import Alert from "../../modal/alert";
import Modal from "../modal2/modal";

class planificacionH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcion: "",
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      ianoEscolar: [],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      someHTML2: [],
      mostrarOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      idcurso: "",
      idprofesor: "",
      descripcion: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      fechaDesde: "",
      trimestre: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      ievaluaciones: [],
      ialumnos: [],
      listaOn: false,
      modificarOn: false,
      someHTML2: [],
      mostrarOn: false,
    });
  };

  limpiar2(e) {
    this.setState({
      idmateria: "",
      idcurso: "",
      idprofesor: "",
      idplanE: "",
      trimestre: "",
      cedulaAlumno: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "trimestre") this.buscarProfesor2(e.target.value);
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.imaterias.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : 0;
    //console.log(index)
    let xcantidadE = this.state.imaterias[index].variable3;
    let xidmateria = this.state.imaterias[index].variable4;
    this.setState({
      [e.target.name]: e.target.value,
      cantidadE: xcantidadE,
      idmateria: xidmateria,
    });
  };

  onChange4 = (e) => {
    let data = new FormData();
    data.append("idplanificacion", e);
    data.append("boton", "consultaPlaniIndividual");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xsomeHTML = res.data.variable2;
          this.setState({
            mostrarOn: true,
            someHTML2: xsomeHTML,
          });
        } else {
          this.setState({});
        }
      });
  };

  consultar() {
    let data = new FormData();
    data.append("idgrado", this.state.idcurso);
    data.append("idprofesor", this.state.idprofesor);
    data.append("trimestre", this.state.trimestre);
    data.append("boton", "buscarPlanificacion");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xialumno = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            ialumnos: xialumno,
          });
        } else {
          this.setState({
            ialumnos: [],
          });
        }
      });
  }

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Control de Asistencia.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Control de Asistencia.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarTrimestre = (e) => {
    let data = new FormData();
    data.append("codigo", e);
    data.append("boton", "consultarActivo");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/activarTrimestre.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
      } else {
        this.setState({
          trimestre: "",
          mensajealerta: true,
          tituloM: "Menu Control de Asistencia.",
          cuerpoM:
            "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("boton", "buscarSoloprofesor");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    //this.buscarMaterias();
    //this.buscarDE();
    this.buscarAnioEscolar();
    this.buscarProfesor();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>PLANIFICACION HABILITADAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.mostrarOn === true && (
            <Modal
              open={this.state.mostrarOn}
              someHTML2={this.state.someHTML2}
              onClose={() => this.setState({ mostrarOn: false })}
            />
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-3">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>

              <div className="contenedorcedula1f col-3">
                <select
                  id="idprofesor"
                  name="idprofesor"
                  value={this.state.idprofesor}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Profesor --</option>
                  {this.state.iprofesor.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.consultar.bind(this)}>
                Consultar
              </button>
              <button className="boton" onClick={this.limpiar.bind(this)}>
                Limpiar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">MATERIA</th>
                    <th className="titucxc5">FECHA AGENDA</th>
                    <th className="titucxc5">FECHA CREADA</th>
                    <th className="titucxc5">FECHA HABILITADA</th>
                    <th className="titucxc5">HABILITADA</th>
                    <th className="titucxc5">VECES HABILITADAS</th>
                  </tr>
                </thead>

                {this.state.ialumnos.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxcx"
                        onClick={this.onChange4.bind(this, item.variable1)}
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="totalescxcx"
                        onClick={this.onChange4.bind(this, item.variable1)}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="totalescxcx"
                        onClick={this.onChange4.bind(this, item.variable1)}
                      >
                        {item.variable4}
                      </td>
                      <td
                        className="totalescxcx"
                        onClick={this.onChange4.bind(this, item.variable1)}
                      >
                        {item.variable5}
                      </td>
                      <td
                        className="totalescxcx"
                        onClick={this.onChange4.bind(this, item.variable1)}
                      >
                        {item.variable6}
                      </td>
                      <td
                        className="totalescxcx"
                        onClick={this.onChange4.bind(this, item.variable1)}
                      >
                        {item.variable7}
                      </td>

                      {/* <td className="totalescxcx" width="10">
                        <input
                          type="radio"
                          className="opvacunacionsi"
                          name={item.variable1}
                          id={item.variable1}
                          value="R"
                          checked={item.variable3 === "R"}
                          onChange={this.onChange4.bind(this)}
                        />
                      </td> */}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(planificacionH);
