import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

//componentes
import "./nomina.css";
import Alert from "../../modal/alert";

function ReporteDecimoE(props) {
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [anio, setanio] = React.useState([]);
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");
  const [trimestre, settrimestre] = React.useState(0);
  const [anopagar, setanopagar] = React.useState("");

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    if (index === "trimestre") {
      settrimestre(e.target.value);
    }
    if (index === "anopagar") {
      setanopagar(e.target.value);
    }
  };

  const reporteGeneral = () => {
    let data = new FormData();
    data.append("anoapagar", anopagar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reporteVacacionesGeneral.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  const handleClose = () => {
    setmensajealerta(false);
  };

  const cargarFechas = () => {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xanio = res.data.variable2;
        setanio(xanio);
      }
    });
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    //buscarPersonal();
    cargarFechas();
  });

  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
          <p>GENERAR REPORTE DECIMO EMPLEADOS. </p>
        </div>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}

        <div className="cont-listmedi row">
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Año a Pagar.
              <select
                id="anopagar"
                name="anopagar"
                value={typeof anopagar !== "undefined" ? anopagar : ""}
                onChange={onChange}
              >
                <option value="0">Año a Pagar</option>
                {anio.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>

        <div className="cont-personal col-12">
          <button className="botonN" onClick={reporteGeneral}>
            Generar Reporte
          </button>
          <button className="boton" onClick={props.personalClose}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ReporteDecimoE);
