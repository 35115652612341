import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./seccionesP.css";
import Alert from "../../modal/alert";

class SeccionesPAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      idprofesor: "",
      idseccionP: "",
      iseccionesA: [],
      iprofesor: [],
      iseccionesP: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarSeccionesP(e.target.value);
  }
  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  guardar = () => {
    if (this.state.idseccion.length > 0 && this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "guardarSeccionP");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/seccionesP.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarSeccionesP(this.state.idprofesor);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Sección.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Sección.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (this.state.idseccionP.length > 0) {
      let data = new FormData();
      data.append("idseccionesP", this.state.idseccionP);
      data.append("boton", "eliminarSeccionesP");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/seccionesP.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Profesor.",
            cuerpoM: res.data.variable2,
          });
          this.buscarSeccionesP(this.state.idprofesor);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Profesor.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Grados Profesor.",
        cuerpoM: "Debe existir un registro para poder eliminar la Sección.",
      });
    }
  };

  buscarSeccionesP = (e) => {
    let data = new FormData();
    data.append("idprofesor", e);
    data.append("boton", "buscarSeccionesP");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/seccionesP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiseccionesP = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iseccionesP: xiseccionesP,
          });
        } else {
          this.setState({
            iseccionesP: [],
          });
        }
      });
  };

  buscarSecciones = () => {
    let data = new FormData();
    data.append("boton", "buscarSecciones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/secciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiseccionesA = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iseccionesA: xiseccionesA,
          });
        } else {
          this.setState({
            iseccionesA: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("dedonde", "seccionesP");
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Asignar Grados a Profesores",
      cuerpoM:
        "Puede que el Grado Asignado ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...",
      idseccionP: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };
  componentDidMount() {
    //this.buscarSeccionesP();
    this.buscarSecciones();
    this.buscarProfesor();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ASIGNAR GRADOS A PROFESORES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-5">
                <select
                  id="idprofesor"
                  name="idprofesor"
                  value={this.state.idprofesor}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Profesor --</option>
                  {this.state.iprofesor.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable3}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="contenedorcedula1f col-2">
                <select
                  id="idseccion"
                  name="idseccion"
                  value={this.state.idseccion}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.iseccionesA.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2 + " Seccion: " + item.variable3}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.guardar.bind(this)}>
                Agregar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc3">PROFESOR</th>
                    <th className="titucxc3">GRADO/SECCION</th>
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.iseccionesP.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar1.bind(this, item.variable1)}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SeccionesPAction;
